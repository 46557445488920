import React, { useEffect, useState } from 'react'
import "./ResumeSteps.css"
import { useTranslation } from "react-i18next";
import { useOutletContext } from 'react-router';
import { HEAR_ABOUT_US, SURROGATE_MOTHER_PREFERRED_METHOD } from '../../../../constant';
import { deleteDocs, resumeForm1, uploadMultipleFiles } from "../../../../service/Apis"
import { userId } from '../../../../service/userData';
import { resumeAllKeys } from './resumeKeys';
import { useNavigate } from 'react-router-dom/dist';
import { Country, State, City } from 'country-state-city';
import { RxCross1 } from 'react-icons/rx';
import Loader from '../../../../components/Loader/Loader';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { MultipleImageUpload } from '../../../../components/Inputs/MultipleImageUpload';

import Toaster from '../../../../components/Toaster';


export const ResumeStep1 = () => {
    let { t } = useTranslation();
    let navigate = useNavigate();
    const [loader, setLoader] = useState(false)
    let { navigation, data, surrogateData } = useOutletContext();
    let allCountries = Country.getAllCountries()
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [statesListing, setStatesListing] = useState([])
    const [citiesListing, setCitiesListing] = useState([])
    let [imagesValue, setImagesValue] = useState([]);
    const [imgForRemove, setImgForRemove] = useState([])
    const [toaster, setToaster] = useState({
        show: false,
        message: "",
    });
    let step1Keys = resumeAllKeys.step1;
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        middleName: '',
        maidenName: '',
        email: '',
        nickName: "",
        homePhone: '',
        workPhone: '',
        cellPhone: '',
        country: '',
        method_contect: [],
        current_Working: null,
        access_desktop: null,
        current_address: '',
        lived_duration: "",
        city: "",
        state: '',
        zipCode: '',
        hear_about_us: '',
        ever_appled_other_agencies: null,
        photos: []
    });



    useEffect(() => {
        let obj = {}
        for (let i in step1Keys) {
            if (data.resumeData[i]) {
                obj[i] = data?.resumeData?.[i] || step1Keys[i]
            }
        }
        if (obj.country !== undefined) {
            let cRes = allCountries?.find(res2 => res2?.name == obj?.country)
            setCountry(`${cRes.name}&&${cRes.isoCode}`)
            getStates(`${cRes.name}&&${cRes.isoCode}`)
        }
        if (obj.city !== undefined) {
            setCity(obj?.city)
        }
        if (data.resumeData?.photos) {
            let photoArr = data.resumeData?.photos?.map((res, index) => {
                return {
                    ...res,
                    fileType: 'server'
                }
            })
            setImagesValue(photoArr);
        }

        setFormData(pre => ({ ...pre, ...data.resumeData }));
        // setPhotos(pre => ({ ...pre, ...data.resumeData }))
    }, [data.resumeData, surrogateData])

    useEffect(() => {
        if (statesListing.length !== 0) {
            let sRes = statesListing?.find(res2 => res2?.name == formData.state)
            if (sRes !== undefined) {
                setState(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`)
                getCities(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`)
            }
        }

    }, [statesListing])



    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            setFormData((prevState) => ({
                ...prevState,
                [name]: checked,
            }));
        } else {
            if (value == " ") {
                e.target.value = ''
            } else {

                setFormData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
            }
        }
    };

    function setPhoneCodebyLanguage(key) {
        switch (key) {
            case 'en':
                return 'US'
                break;
            case 'es':
                return 'ES'
                break;
            case 'zh':
                return 'CN'
                break;
            case 'en-US':
                return 'US'
                break;
            default:
                return 'US'
                break;
        }
    }

    async function formSubmit(e) {
        e.preventDefault()
        setLoader(true);
        if (imagesValue.length == 0) {
            setToaster({
                show: true,
                message: "please select images",
            });
            setLoader(false);
            return;
        }
        let img = imagesValue;
        let httpimage = [];
        let formData2 = new FormData();
        let nonhttpImage = [];

        img.forEach((e) => {
            if (e.fileUrl.includes("blob")) {
                nonhttpImage.push(e);
            } else {
                httpimage.push({
                    fileName: e.fileName,
                    fileUrl: e.fileUrl,
                });
            }
        });
        if (imgForRemove.length > 0) {
            let imagesData = await Promise.all(
                imgForRemove.map(async (e) => await deleteDocs({ fileName: e.fileName }))
            );
        }

        for (const item of nonhttpImage.map((e) => e.file)) {
            formData2.append("uploadMultiDocs", item);
        }

        let uploadResponse = await uploadMultipleFiles(formData2);
        let data = {
            ...formData,
            country: country.split('&&')[0],
            state: state.split('&&')[0],
            city: city,
            photos: [...httpimage, ...uploadResponse.data.data],
        }

        try {
            let userID = userId();
            await resumeForm1(userID, data);
            setLoader(false)
            navigation.next()
        }
        catch (err) {
            setLoader(false)
            console.log(err)
        }
        return
        // if (url.length < 1) {
        //     setImgErr(true)
        //     window.scrollTo(0, 0);
        //     return
        // }
        // const formData2 = new FormData();
        // setLoader(true)


        // for (const item of file) {
        //     formData2.append("uploadMultiDocs", item);
        // }
        // let uploadResponse

        // try {
        //     uploadResponse = await uploadMultipleFiles(formData2);
        // } catch (err) {
        //     console.log(err)
        // }




        // let imagesData = await Promise.all(
        //     images.map(async (e) => {
        //         if (url.includes(e.fileUrl)) {
        //             return e;
        //         } else {
        //             await deleteDocs({ fileName: e.fileName });
        //             return null;
        //         }
        //     })
        // );
        // try {

        //     let userID = userId();
        //     let data = {
        //         ...formData,
        //         country: country.split('&&')[0],
        //         state: state.split('&&')[0],
        //         city: city,
        //         photos: [
        //             ...imagesData.filter((s) => s !== null),
        //             ...uploadResponse.data.data,
        //         ],
        //     };


        //     await resumeForm1(userID, data);
        //     setLoader(false)
        //     navigation.next()
        // }
        // catch (err) {
        //     setLoader(false)
        //     console.log(err)
        // }
    }



    function radioInputChange(e, val) {
        let name = e.target.name

        setFormData((prevState) => ({
            ...prevState,
            [name]: val,
        }));
    };

    function multipleCheckBoxChange(e) {
        let { value, checked } = e.target;

        let pre = { ...formData };
        if (checked) {
            pre.method_contect.push(value)
        }
        else {
            pre.method_contect = pre.method_contect.filter(e => e !== value)
        }
        setFormData({ ...pre })
    }

    function getStates(res) {
        let isoCode = res.split('&&')[1]
        setCountry(res)
        let states = State.getStatesOfCountry(isoCode)
        setStatesListing(states)
    }


    function getCities(res) {
        let isoCode = res.split('&&')[1]
        let cCode = res.split('&&')[2]
        setState(res)
        let cities = City.getCitiesOfState(cCode, isoCode)
        setCitiesListing(cities)
    }

    function multipleImageInputChange(e) {
        setImagesValue(e);
    }

    return (
        <>
            <Loader open={loader} />
            <Toaster
                event={toaster.message}
                show={toaster.show}
                severity={"error"}
                setToaster={setToaster}
            />
            <div className='ResumeStep_con'>
                <div className='FormParent'>
                    <form onSubmit={formSubmit}>
                        <div className='SubHeading'>
                            <h2 >{t("General Information")}</h2>
                        </div>

                        <MultipleImageUpload
                            value={imagesValue}
                            onChange={multipleImageInputChange}
                            maximg={5}
                            setDeletedImage={setImgForRemove}
                        />

                        <div className='px-lg-4 px-md-4 px-sm-3 px-2 mt-4'>
                            <div className='row mb-4'>
                                <div className="col-md-4 mb-lg-0 mb-md-0 md-sm-4 mb-4">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("First Name")}<span className='text-danger'>*</span></label>

                                    <input
                                        required
                                        type='text'
                                        className='form-control'
                                        //placeholder='First Name'
                                        name='firstName'
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className=" col-md-4 mb-lg-0 mb-md-0 md-sm-4 mb-4 ">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Middle Initial")}</label>
                                    <input

                                        type='text'
                                        className='form-control'
                                        //placeholder='Middle Name'
                                        name='middleName'
                                        value={formData.middleName}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className=" col-md-4">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Last Name")}<span className='text-danger'>*</span></label>

                                    <input
                                        required
                                        type='text'
                                        className='form-control'
                                        //placeholder='Last Name'
                                        name='lastName'
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                    />

                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className=" col-md-6 mb-lg-0 mb-md-0 md-sm-4 mb-4">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Maiden Name")}</label>
                                    <input

                                        type='text'
                                        className='form-control'
                                        //placeholder='Maiden Name'
                                        name='maidenName'
                                        value={formData.maidenName}
                                        onChange={handleInputChange}
                                    />

                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Nickname")}</label>
                                    <input

                                        type='text'
                                        className='form-control'
                                        //placeholder='Nick Name'
                                        name='nickName'
                                        value={formData.nickName}
                                        onChange={handleInputChange}
                                    />

                                </div>

                            </div>
                            <div className='row mb-4'>
                                <div className="col-md-12 ">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Email Address")}</label>
                                    <input
                                        disabled
                                        type='email'
                                        className='form-control'
                                        //placeholder='Email'
                                        name='email'
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className=" col-md-4 mb-lg-0 mb-md-0 md-sm-4 mb-4">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Home Phone")}<span className='text-danger'>*</span></label>
                                    <PhoneInput
                                        international
                                        defaultCountry={setPhoneCodebyLanguage(localStorage.getItem('i18nextLng'))}
                                        withCountryCallingCode
                                        disabled
                                        required
                                        value={formData?.homePhone}
                                        onChange={(e) => {
                                            if (e == " ") {
                                                e = "";
                                            } else {
                                                // setPhoneNumber(e)

                                                // formRef.current.mobile_No = e
                                                // setPhoneNumberErr("");
                                            }
                                        }}
                                    />
                                    {/* <input
                                        required
                                        disabled
                                        type='text'
                                        className='form-control'
                                        //placeholder='Home Phome'
                                        name='homePhone'
                                        value={formData?.homePhone}
                                        onChange={handleInputChange}
                                    /> */}

                                </div>
                                <div className=" col-md-4 mb-lg-0 mb-md-0 md-sm-4 mb-4">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Work Phone")}</label>
                                    <PhoneInput
                                        international
                                        defaultCountry={setPhoneCodebyLanguage(localStorage.getItem('i18nextLng'))}
                                        withCountryCallingCode
                                        value={formData?.workPhone}
                                        onChange={(e) => {
                                            if (e == " ") {
                                                e = "";
                                            } else {
                                                // setPhoneNumber(e)
                                                setFormData(pre => {
                                                    return { ...pre, workPhone: e }
                                                })
                                                // formRef.current.mobile_No = e
                                                // setPhoneNumberErr("");
                                            }
                                        }}
                                    />
                                    {/* <input

                                        type='text'
                                        className='form-control'
                                        //placeholder='Work Phone'
                                        name='workPhone'
                                        value={formData.workPhone}
                                        onChange={handleInputChange}
                                    /> */}

                                </div>
                                <div className=" col-md-4 ">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Cell Phone")}</label>
                                    <PhoneInput
                                        international
                                        defaultCountry={setPhoneCodebyLanguage(localStorage.getItem('i18nextLng'))}
                                        withCountryCallingCode
                                        value={formData?.cellPhone}
                                        onChange={(e) => {
                                            if (e == " ") {
                                                e = "";
                                            } else {
                                                // setPhoneNumber(e)
                                                setFormData(pre => {
                                                    return { ...pre, cellPhone: e }
                                                })
                                                // formRef.current.mobile_No = e
                                                // setPhoneNumberErr("");
                                            }
                                        }}
                                    />
                                    {/* <input

                                        type='text'
                                        className='form-control'
                                        //placeholder='Cell Phone'
                                        name='cellPhone'
                                        value={formData.cellPhone}
                                        onChange={handleInputChange}
                                    /> */}

                                </div>
                            </div>
                            <div className='mb-4'>
                                <label className="form-label fs-5" >{t("Preferred method(s) of contact")}<span className='text-danger'>*</span></label>

                                <div className='CheckBox_parent row mb-4'>

                                    {SURROGATE_MOTHER_PREFERRED_METHOD.map((res, key) => {
                                        return (
                                            <div className="col-md-2">
                                                <div className="form-check">
                                                    <input
                                                        checked={formData.method_contect.includes(res)}
                                                        type="checkbox"
                                                        name="ok"
                                                        onChange={multipleCheckBoxChange}
                                                        value={res} className="form-check-input"
                                                        required={!formData.method_contect.some(e => e)}

                                                    />
                                                    <label className="form-check-label LabeSubHeading" >
                                                        {t(res)}
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>

                            <div className='mb-4 Form_checkbox1'>
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Are you currently working with an intake coordinator at NHP (NewHopePoints)?")}<span className='text-danger'>*</span></label>


                                <div className='row mb-4'>
                                    <div className="col-md-3">
                                        <div className="form-check">
                                            <input
                                                onChange={(e) => radioInputChange(e, true)}
                                                className="form-check-input"
                                                type="radio"
                                                required
                                                checked={formData.current_Working === true}
                                                name="current_Working"

                                            />
                                            <label className="form-check-label LabeSubHeading" >
                                                {t("Yes")}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-check">
                                            <input
                                                onChange={(e) => radioInputChange(e, false)}
                                                className="form-check-input"
                                                type="radio"
                                                name="current_Working"
                                                checked={formData.current_Working === false}

                                            />
                                            <label className="form-check-label LabeSubHeading" >
                                                {t("No")}
                                            </label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='Form_checkbox2 mb-4'>
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Do you have access to a desktop/laptop computer?")}<span className='text-danger'>*</span></label>



                                <div className='row mb-4' >
                                    <div className="col-md-3">
                                        <div className="form-check">
                                            <input
                                                onChange={(e) => radioInputChange(e, true)}
                                                className="form-check-input"
                                                type="radio"
                                                name="access_desktop"
                                                required
                                                checked={formData.access_desktop === true}

                                            />
                                            <label className="form-check-label LabeSubHeading" >
                                                {t("Yes")}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-check">
                                            <input
                                                onChange={(e) => radioInputChange(e, false)}
                                                className="form-check-input"
                                                type="radio"
                                                required
                                                name="access_desktop"
                                                checked={formData.access_desktop === false}

                                            />
                                            <label className="form-check-label LabeSubHeading" >
                                                {t("No")}
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                        </div>
                                        <div className="col-md-3">
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className=" col-md-12 ">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Current Addresss")}<span className='text-danger'>*</span></label>

                                    <input
                                        type='text'
                                        required
                                        className='form-control'
                                        //placeholder='Current Address'
                                        name='current_address'
                                        value={formData.current_address}
                                        onChange={handleInputChange}
                                    />
                                    {/* <i class="bi bi-link-45deg View"></i> */}
                                </div>
                            </div>

                            <div className='row SelectIUnput mb-4'>
                                <div className='col-md-6 mb-4'>
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Country")} <span className='text-danger'>*</span></label>
                                    <select className={`form-select`} value={country} required onChange={(e) => {
                                        getStates(e.target.value)
                                        setState('')
                                        setCity('')
                                    }}>
                                        <option value='' selected disabled>{t('Select')}</option>
                                        {allCountries.map((res, index) => {
                                            return <option key={index} value={`${res.name}&&${res.isoCode}`}>{res.name}</option>
                                        })}



                                    </select>

                                </div>


                                <div className='col-md-6 mb-lg-0 mb-md-0 md-sm-4 mb-4'>
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("State of Residence")} <span className='text-danger'>*</span></label>
                                    <select className={`form-select`} value={state} required onChange={(e) => {
                                        getCities(e.target.value)
                                        setCity('')
                                    }} >
                                        <option selected disabled value={''}>{t("select")}</option>
                                        {statesListing?.map((res, index) => {
                                            return <option key={index} value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}>{res.name}</option>
                                        })}

                                    </select>

                                </div>
                                <div className='col-md-6 mb-lg-0 mb-md-0 md-sm-4 mb-4'>
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("City")}<span className='text-danger'>*</span></label>
                                    <select className={`form-select`} value={city} required onChange={(e) => setCity(e.target.value)} >
                                        <option selected disabled value={''}>{t("select")}</option>
                                        {citiesListing?.map((res, index) => {
                                            return <option key={index} value={`${res.name}`}>{res.name}</option>
                                        })}

                                    </select>

                                </div>
                                <div className='col-md-6'>
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Zip Code")}<span className='text-danger'>*</span></label>
                                    <input
                                        type='number'
                                        required
                                        className="form-control"
                                        name="zipCode"
                                        value={formData.zipCode}
                                        onChange={handleInputChange} />

                                </div>
                            </div>
                            <div className='row SelectIUnput2 mb-4'>
                                <div className='col-md-6 mb-lg-0 mb-md-0 md-sm-4 mb-4'>
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("How long have you lived at this address?")} <span className='text-danger'>*</span></label>
                                    <input
                                        type='text'
                                        required
                                        className="form-control"
                                        name="lived_duration"
                                        value={formData.lived_duration}
                                        onChange={handleInputChange} />

                                </div>
                                <div className='col-md-6 d-flex flex-column'>
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Closest major airport")}<span className='text-danger'>*</span></label>
                                    <input
                                        type='text'
                                        required
                                        className="form-control mt-auto"
                                        name="closest_airport"
                                        value={formData.closest_airport}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>


                            <div className='Form_checkbox3 mb-4'>
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5 mb-3" >{t("How did you hear about us?")} <span className='text-danger'>*</span></label>

                                <div className='row mb-4'>

                                    {HEAR_ABOUT_US.map((res, key) => {
                                        return (
                                            <div className="col-md-3 mb-lg-4 mb-sm-4 mb-3">
                                                <div className="form-check">
                                                    <input
                                                        required
                                                        checked={formData.hear_about_us === res}
                                                        className="form-check-input" onChange={(e) => radioInputChange(e, res)} type="radio" name="hear_about_us" />
                                                    <label className="form-check-label LabeSubHeading " >
                                                        {t(res)}
                                                    </label>
                                                </div>

                                            </div>
                                        )
                                    })}




                                </div>




                                <div className='Form_checkbox3 mb-4'>

                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5 mb-3" >{t("Have you EVER applied with any other agencies/programs to be a Surrogate or Egg Donor?")} <span className='text-danger'>*</span></label>


                                    <div className='row mb-4'>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input
                                                    required
                                                    className="form-check-input"
                                                    onChange={(e) => radioInputChange(e, true)}
                                                    type="radio"
                                                    checked={formData.ever_appled_other_agencies === true}
                                                    name="ever_appled_other_agencies"
                                                />
                                                <label className="form-check-label LabeSubHeading " >
                                                    {t("Yes")}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input
                                                    required
                                                    className="form-check-input"
                                                    onChange={(e) => radioInputChange(e, false)} name="ever_appled_other_agencies"
                                                    type="radio"
                                                    checked={formData.ever_appled_other_agencies === false}
                                                />
                                                <label className="form-check-label LabeSubHeading " >
                                                    {t("No")}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ButtonForm mt-4">
                            <button className="btn_fill" type="submit">{t("Next")}</button>
                        </div>

                    </form>
                </div>
            </div>

        </>
    )
}
