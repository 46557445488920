import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from 'react-router';
import { userData, userId } from '../../../../service/userData';
import { resumeForm10 } from '../../../../service/Apis';
import { resumeAllKeys } from './resumeKeys';
import "./ResumeSteps.css"
import Loader from '../../../../components/Loader/Loader';
export const ResumeStep10 = () => {
    let { t } = useTranslation();
    let { navigation, data } = useOutletContext()
    let step10Keys = resumeAllKeys.step10;
    let navigate = useNavigate()
    let [loader, setLoader] = useState(false);

    // useEffect(() => {
    //     let obj = {}
    //     for (let i in step10Keys) {
    //         if (data.resumeData[i]) {
    //             obj[i] = data?.resumeData?.[i] || step10Keys[i]
    //         }
    //     }
    //     setFormData(pre => ({ ...pre, ...obj }));
    // }, [data.resumeData])

    useEffect(() => {
        let obj = {};
        for (let i in step10Keys) {
            if (data.resumeData.hasOwnProperty(i)) {
                obj[i] = data?.resumeData?.[i]
            }
            else {
                obj[i] = step10Keys[i]
            }
        }
        setFormData((pre) => ({ ...pre, ...obj }));
    }, [data.resumeData]);
    const [formData, setFormData] = useState({
        spouse_aware_about_surrogate: "",
        reason_spouse_aware_about_surrogate: '',
        support_emotionally_during_after_surrogacy: "",
        concerns_sharing_information_children: "",
        explain_concerns_sharing_information_children: '',
        anybody_not_supportive_surrogate: "",
        explain_anybody_not_supportive_surrogate: '',
        share_primary_reason_surrogate: '',
        describe_personality: '',
        biggest_strengths: '',
        spending_free_time_hobby: '',
        tellUs_envision_feeling_emotional_level: ''
    })

    async function formsubmit10(e) {
        try {
            setLoader(true)
            e.preventDefault()
            let userID = userId();
            let data = { ...formData };

            let res = await resumeForm10(userID, formData);
            navigation.next()

        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoader(false)
        }
    }
    function radioInputChange(e, val) {
        let name = e.target.name

        setFormData((prevState) => ({
            ...prevState,
            [name]: val,
        }));
    };
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target


        if (type === 'checkbox') {
            setFormData((prevState) => ({
                ...prevState,
                [name]: checked,
            }));
        } else {

          if(value == ' '){
            e.target.value = ''
          }else{
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
          }
        }
    };





    return (
        <>
            <Loader open={loader} />
            <div className="ResumeStep_con">
                <div className='Spouse / Partner Information'>
                    <form onSubmit={formsubmit10}>
                        <div className="First_section">
                           
                                <div className='SubHeading mb-lg-4 mb-md-4 mb-sm-3 mb-3'>{t("Expectations for Your Gestational Surrogate Journey")}</div>
                        
                            <div className='CheckBoxParent row mb-4'>
                                <label className="form-label fs-5" >
                                    {t("Are your partner and other family members aware of your plan to become a Surrogate?")}<span className='text-danger'>*</span>
                                </label>
                                <div className="col-md-3">
                                    <div className="form-check">
                                        <input onChange={(e) => radioInputChange(e, true)} className="form-check-input"
                                            type="radio" name="spouse_aware_about_surrogate" 
                                            value="true"
                                            required
                                            checked={formData.spouse_aware_about_surrogate == true} />
                                        <label className="form-check-label LabelSubHeading" >
                                            {t("Yes")}
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-3">  <div className="form-check">
                                    <input onChange={(e) => {
                                        radioInputChange(e, false)
                                        setFormData(pre => {
                                            return {
                                                ...pre,
                                                reason_spouse_aware_about_surrogate: '',
                                                support_emotionally_during_after_surrogacy: ''
                                            }
                                        })
                                    }} className="form-check-input"
                                        type="radio"
                                        name="spouse_aware_about_surrogate"
                                        
                                        required
                                        checked={formData.spouse_aware_about_surrogate === false} />
                                    <label className="form-check-label LabelSubHeading" >
                                        {t("No")}
                                    </label>
                                </div>
                                </div>
                            </div>
                            {formData.spouse_aware_about_surrogate && (
                                <>
                                    <div className="row mb-4">
                                        <div className="col-md-12">
                                            <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please share their response")}<span className='text-danger'>*</span></label>
                                            <textarea
                                                rows={5}
                                                required
                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                name='reason_spouse_aware_about_surrogate'
                                                value={formData.reason_spouse_aware_about_surrogate}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-4">

                                        <div className="col-md-12">
                                            <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Who will support you emotionally during and after your surrogacy experience?")}<span className='text-danger'>*</span></label>
                                            <textarea
                                                rows={5}
                                                required
                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                name='support_emotionally_during_after_surrogacy'
                                                value={formData.support_emotionally_during_after_surrogacy}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className='CheckBoxParent row mb-4'>
                                <label className="form-label fs-5" >
                                    {t("Do you have any concerns about sharing this information with your children?")}<span className='text-danger'>*</span>
                                </label>
                                <div className="col-md-3">
                                    <div className="form-check">
                                        <input onChange={(e) => radioInputChange(e, true)} className="form-check-input"
                                            type="radio" name="concerns_sharing_information_children" 
                                            value="true"
                                            required
                                            checked={formData.concerns_sharing_information_children == true} />
                                        <label className="form-check-label LabelSubHeading" >
                                            {t("Yes")}
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-3">  <div className="form-check">
                                    <input onChange={(e) => {
                                        radioInputChange(e, false)
                                        setFormData(pre => {
                                            return {
                                                ...pre,
                                                explain_concerns_sharing_information_children: ''
                                            }
                                        })
                                    }} className="form-check-input"
                                        type="radio" name="concerns_sharing_information_children" 
                                        value="false"
                                        required
                                        checked={formData.concerns_sharing_information_children === false} />
                                    <label className="form-check-label LabelSubHeading" >
                                        {t("No")}
                                    </label>
                                </div>
                                </div>
                            </div>
                            {formData.concerns_sharing_information_children && (
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please explain")}<span className='text-danger'>*</span></label>
                                        <textarea
                                            rows={5}
                                            required
                                            type='text'
                                            className='form-control'
                                            placeholder=''
                                            name='explain_concerns_sharing_information_children'
                                            value={formData.explain_concerns_sharing_information_children}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className='CheckBoxParent row mb-4'>
                                <label className="form-label fs-5" >
                                    {t("Is there anybody in your life who is NOT supportive of your desire to become a Surrogate?")}<span className='text-danger'>*</span>
                                </label>
                                <div className="col-md-3">
                                    <div className="form-check">
                                        <input onChange={(e) => radioInputChange(e, true)} className="form-check-input"
                                            type="radio" name="anybody_not_supportive_surrogate" 
                                            required
                                            value='true'
                                            checked={formData.anybody_not_supportive_surrogate == true}
                                        />

                                        <label className="form-check-label LabelSubHeading" >
                                            {t("Yes")}
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-3">  <div className="form-check">
                                    <input onChange={(e) => {
                                        radioInputChange(e, false)
                                        setFormData(pre => {
                                            return {
                                                ...pre,
                                                explain_anybody_not_supportive_surrogate: '',
                                                share_primary_reason_surrogate: ''
                                            }
                                        })
                                    }} className="form-check-input"
                                        required
                                        type="radio" name="anybody_not_supportive_surrogate" 
                                        value='false'
                                        checked={formData.anybody_not_supportive_surrogate === false}

                                    />
                                    <label className="form-check-label LabelSubHeading" >
                                        {t("No")}
                                    </label>
                                </div>
                                </div>
                            </div>
                            {formData.anybody_not_supportive_surrogate && (
                                <>
                                    <div className="row mb-4">
                                        <div className="col-md-12">
                                            <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please explain")}<span className='text-danger'>*</span></label>
                                            <textarea
                                                rows={5}
                                                required
                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                name='explain_anybody_not_supportive_surrogate'
                                                value={formData.explain_anybody_not_supportive_surrogate}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-4">

                                        <div className="col-md-12">
                                            <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please share your primary reason for your decision to become a Surrogate")}<span className='text-danger'>*</span></label>
                                            <textarea
                                                rows={5}
                                                required
                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                name='share_primary_reason_surrogate'
                                                value={formData.share_primary_reason_surrogate}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="Second_Section">
                            <h1 className='SubHeading '>{t("Please tell us all about you!")}</h1>
                            <div className="row mb-4">

                                <div className="col-md-12">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Describe your personality")}<span className='text-danger'>*</span></label>
                                    <textarea
                                        rows={5}
                                        required
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        name='describe_personality'
                                        value={formData.describe_personality}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row mb-4">

                                <div className="col-md-12">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Tell us about your biggest strengths")}<span className='text-danger'>*</span></label>
                                    <textarea
                                        rows={5}
                                        required
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        name='biggest_strengths'
                                        value={formData.biggest_strengths}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row mb-4">

                                <div className="col-md-12">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("How do you enjoy spending your free time? What are your interests and hobbies?")}<span className='text-danger'>*</span></label>
                                    <textarea
                                        rows={5}
                                        required
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        name='spending_free_time_hobby'
                                        value={formData.spending_free_time_hobby}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row mb-4">

                                <div className="col-md-12">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Tell us how you envision feeling, on an emotional level, about carrying and delivering a child that will not be your own")}<span className='text-danger'>*</span></label>
                                    <textarea
                                        rows={5}
                                        required
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        name='tellUs_envision_feeling_emotional_level'
                                        value={formData.tellUs_envision_feeling_emotional_level}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ButtonForm mt-3">
                            <button className="btn_non_fill" type="button" onClick={navigation.previous}>{t('Previous')}</button>
                            <button className="btn_fill" type="submit">{t('Next')}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
