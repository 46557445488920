import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import "./paymentDialog.css"
import { CongratsDialog } from '../CongraDialog/CongratsDialog'

export const PaymentDialog = ({payment,setPayment}) => {

  const[Congratulation,setCongratulation]=useState()

  function paymentDone (){
    setCongratulation(true)
    setPayment(false)
  }
    return (
        <>
        <CongratsDialog Congratulation={Congratulation} setCongratulation={setCongratulation}/>
          <div className='Payment_con'>
          <Dialog
                closable={false}
                visible={payment}
                onHide={() => setPayment(false)}
                style={{ width: "60vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div className='Payment_con'>
                    <div className='GoldText mb-3 text-center'>
                        <h3>Gold Membership Plan</h3>
                    </div>
                    <div className='PaymentMethodText mb-4 text-center'>
                        <p>Payment Method </p>
                    </div>

<div className='d-flex'>
<div className="form-check align-item-center mt-1" >
  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
  </div>
  <div className='CraeditCardDiv mb-3'>
  <label className="form-check-label CreaditCradText" htmlFor="flexRadioDefault1">
    Credit Card
  </label> 
    <label className="form-check-label accptCrads" htmlFor="flexRadioDefault1">
    We accept all major credit cards.
  </label> 
</div>
<div></div>
  
</div>
<div className="images">
    <img src="/images/Frame 440.png" alt="" />
    <img src="/images/Frame 441.png" alt="" />
    <img src="/images/Frame 442.png" alt="" />
    <img src="/images/Frame 443.png" alt="" />
  
</div>
          



<div className='row mb-4'>
<div className="col-md-6">
        
          <input
            type="text"
            className="form-control p-3"
            placeholder="Card number"
            name="name"
            required
           
          />
        </div>
        <div className="col-md-6">
         
          <input
            type="text"
            className="form-control p-3"
            placeholder="Name of card"
            name="name"
            required
           
          />
        </div>
        <div className="col-md-6 EyeView">
         
          <input
            type="text"
            className="form-control p-3"
            placeholder="Security Code"
            name="name"
            required
           
          />
             <i className="bi bi-eye View"></i>
        </div>
        <div className="col-md-6">
         
          <input
            type="date"
            className="form-control p-3"
            placeholder="Expiration date (MM/YY)"
            name="name"
            required
        
       
          />
        </div>

</div>
<div className="form-check align-item-center mt-1 mb-5" >
  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
  <label className="form-check-label CreaditCradText" htmlFor="flexRadioDefault1">
  Paypol
  </label> 
  </div>
<div className='d-flex justify-content-end gap-2'>
  <button className='CancelPayButton' onClick={()=>setPayment(false)}>Cancel</button>
  <button className='PayButton' onClick={paymentDone}>Pay</button>
</div>

                </div>
            </Dialog>
          </div>
        </>
    )
}
