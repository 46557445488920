import React from 'react'
import style from './ContactUs.module.css'

const ContactUsTest = () => {
    return (
        <div>
            <div className={`${style.main_div}`}>
                <div className={`${style.banner}`}>
                    <img src='/images/contactBanner.png' className='w-100' alt='' />
                </div>
                <div className={`${style.partition_container}`}>
                    <div className={`${style.partition_container_left}`}>
                        <img src="/images/contact_us.png" alt="couple and child" className={``}/>
                    </div>
                    <div className={`${style.partition_container_right}`}>
                       <div className={`${style.rightContainerInner}`}>
                       <div className={`${style.left_one}`}>
                            <form>
                                <label for="fname" className={`lufga_20px ${style.label_input}`}>Full Name</label>
                                <input type="text" id="fname" name="fname" />
                                <label for="lname" className={`lufga_20px mt-4 ${style.label_input}`}>Email</label>
                                <input type="text" id="lname" name="lname" />
                                <label for="msg" className={`lufga_20px mt-4 ${style.label_input}`}>Message</label>
                                <input type="text" id="msg" name="msg" />

                                <button className={`mt-5 ${style.button_submit}`}>
                                    Contact Us
                                </button>
                            </form>

                        </div>
                        <div className={`${style.right_one}`}>
                            <div>
                            <div className={`lufga_24px`}>
                                Contact
                            </div>
                            <div className={`lufga_18px mb-5`}>
                                info@newhopepoints.org
                                <br />
                                newhopepoints@gmail.com
                            </div>

                            <div className={`lufga_24px`}>
                                Based in
                            </div>

                            <div className={`lufga_18px`}>
                                2469 FM 359 Rd. S, Ste X<br />
                                Brookshire, TX 77423, USA
                            </div>
                            </div>
                            <div>
                            <div className={`pt-5 mt-5 ${style.link_section}`}>
                                <img src='/images/facebool_black.png' alt='facebook logo' />
                                <img src='/images/instagram_black.png' alt='facebook logo' />
                                <img src='/images/twitter_black.png' alt='facebook logo' />
                            </div>
                            </div>
                        </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsTest;
