export const resumeAllKeys = {
    step1: {
        firstName: "",
        lastName: "",
        middleName: "",
        maidenName: "",
        email: "",
        homePhone: "",
        nickName:"",
        workPhone: "",
        nickName: "",
        cellPhone: "",
        method_contect: [],
        current_Working: "",
        access_desktop: "",
        current_address: "",
        city: "",
        country: "",
        state: "",
        zipCode: "",
        hear_about_us: "",
        ever_appled_other_agencies: "",
        lived_duration:'',
        closest_airport:''
    },

    step2: {
        age: "",
        dob: "",
        height: { feet: "", inches: "" },
        weight: { LBs: "", oz: "" },
        race: [],
        religious_affliation: "",
        lived_another_country: "",
        us_citizen: "",
        many_years_in_us: "",
        travel_outsite_us: "",
        language_speak: [],
        relationship_status: "",
        ever_married: "",
        ever_divorced: "",
        emergency_contect_name: "",
        emergency_contect_number: "",
        emergency_alter_contect_number: ""
    },
    step3: {
        base_compensation: "",
        mock_or_cancelled_cycle_fee: "",
        med_start_fee: "",
        embryo_transfer_fee: "",
        monthly_allowance: "",
        multiple_fee: "",
        invasive_procedure_fee: '',
        childCare_perHour: "",
        houseKeeping_perWeek: "",
        c_section_fee: "",
        multiples: "",
        singleton: "",
        partial_hysterectomy: "",
        full_hysterectomy: "",
        time_off_requested_after_vaginal_delivery: "",
        time_off_requested_after_cSection_delivery: "",
        time_off_pregnancy_post_delivery_recovery: ""
    },
    step4: {
        willing_to_carry_for: [],
        top_choice_intended_parent: "",
        second_choice_intended_parent: "",
        third_choice_intended_parent: "",
        describe_the_ideal_intended_Parent: "",
        NOT_want_work_with_intended_parents: "",
        note_future_intended_Parent: "",
        do_you_want: "",
        diagoned_condition_terminate: "",
        multiple_pergancy_reduce_request: "",
        diagoned_downSyndrom_terminate: "",
        wlling_amniocentesis: "",
        type_contect_desire_with_ip: "",
        interest_continuing_contect_post_birth: "",
        comfortable_vist_during_pregnancy: "",
        comfortable_join_doctor_appointment: "",
        comfortable_taking_photos: "",
        comfortable_being_delivery_room: "",
        birth_experience: [""],
        specific_envision_birth_experience: "",
    },
    step5: {
        medical_insurance: "",
        insurance_through: '',
        health_insurance_company: '',
        date_expired: "",
        maternity_coverage: "",
        yearly_deductible: "",
        coverage_percentage: "",
        Copay_amount: "",
        monthly_premium_payment: "",
        yearly_maximum_out_of_pocket_expense: "",
        currently_employed: "",
        occupation: '',
        joining_date: "",
        working_hours_per_week: "",
        annual_salary: "",
        employer_offer_short_term_disability_plan: "",
        currently_enrolled_plan: "",
        high_school_name_location: '',
        graduation_date: "",
        top_choice_education_ip: '',
        attend_college: "",
        pursuing_degree_list: '',
        other_certificates_training: '',

    },
    step6: {
        spouse_name: '',
        spouse_dob: '',
        spouse_email: '',
        spouse_marriage_date: '',
        spouse_employed: "",
        spouse_occupation: '',
        spouse_startDate_employer: '',
        spouse_working_hours_per_week: "",
        hourly_wages_annual_salary: "",
        spouse_ever_meet_psychologist: "",
        spouse_meet_psychologist_circumstances: '',
        spouse_ever_prescribed_psychiatric: "",
        spouse_prescribed_psychiatric_circumstances: '',
        spouse_ever_hospitalized: "",
        spouse_hospitalized_circumstances: ''
    },
    step7: {
        ever_surrogate: "",
        surrogate_type: [],
        surrogate_times: "",
        surrogate_outcome: "",
        ever_egg_donor: "",
        egg_Donor_times: "",
        egg_Donor_outcome: "",
        many_time_pregnant: "",
        no_child_deliverd: "",
        child_details: [],
        physical_custody_children: "",
        child_physical_health_problem: "",
        explain_child_physical_problem: "",
        child_psychological_problem: "",
        explain_child_psychological_problem: "",
        ever_placed_child_adoption: "",
        explain_placed_child_adoption: "",
        deceased_child: "",
        explain_deceased_child: "",
        any_child_adoption: "",
        explain_child_adoption: "",
        breastfeeding: "",
        breastfeeding_completion_date: "",
        fertility_treatment: "",
        explain_fertility_treatment: "",
        delivered_premature_baby: "",
        explain_delivered_premature_baby: "",
        miscarriage: "",
        explain_miscarriage: "",
        ever_stillborn_baby: "",
        explain_stillborn_baby: "",
        ever_abortion: "",
        explain_date_abortion: "",
        pregnancy_delivery_complications:[],
        ever_cesarean_section: "",
        explain_cesarean_section: "",
        reason_cSection: "",
        regular_menstrual_cycles: "",
        menstrual_cycles_days: "",
        birth_control_method: [],
        birth_control_method_begin: ""
    },
    step8: {
        recent_OB: "",
        recent_pop_smear: "",
        result_pop_smear: "",
        blood_type: "",
        have_allergies: "",
        allergies_to_what: "",
        any_precription_medication: "",
        list_all_medications: "",
        tested_HIV_AIDS: "",
        hepatitis_B: "",
        list_hiv_hepatitis_result: "",
        suffered_depression: "",
        describe_suffered_depression: [],
        depression_treatment: [],
        seen_psychologist: "",
        explain_seen_psychologish: "",
        psychiatric_medication: "",
        explain_psychiatric_medication: "",
        hospitalized_psychiatric_issue: "",
        explain_hospitalized_psychiatric: "",
        eating_habits_detail: "",
        your_exercise: "",
        frequency_exercise: "",
        eating_disorder: "",
        explain_eating_disorder: "",
        have_any_surgery: "",
        explain_surgery: "",
        hospitalized_major_illness: "",
        explain_hospitalized_major_illness: "",
        chronic_medical_conditions: "",
        explain_chronic_medical_conditions: "",
        smoke_cigarettes: "",
        smoke_cigarettes_Frequency: "",
        smoked_tobacco_past_year: "",
        date_smoked_tobacco: "",
        smoked_tobacco_inHome: "",
        explain_smoked_tobacco_inHome: "",
        drink_alcohol: "",
        drink_alcohol_often: "",
        ever_used_marijuana: "",
        explain_ever_used_marijuana: "",
        ever_used_illegal_drugs: "",
        explain_ever_used_illegal_drugs: "",
        spouse_ever_diagnosed_STDs: [],
        explain_spouse_diagnosed_STDs: "",
        diagnosed_any_illnesses: [],
        explain_diagnosed_any_illnesses: "",
    },
    step9: {
        spouse_legal_cases: "",
        explain_spouse_legal_cases: '',
        spouse_arrest_conflicts: "",
        spouse_convicted_felony: "",
        spouse_accused_spousal_abuse: "",
        child_support_payments: "",
        turned_down_adoption_agency: "",
        substance_abuse_program: "",
        declared_bankruptcy: "",
        explain_all_items_answered: '',
        lost_custody_child:""
    },
    step10: {
        spouse_aware_about_surrogate: "",
        reason_spouse_aware_about_surrogate: '',
        support_emotionally_during_after_surrogacy: "",
        concerns_sharing_information_children: "",
        explain_concerns_sharing_information_children: '',
        anybody_not_supportive_surrogate: "",
        explain_anybody_not_supportive_surrogate: '',
        share_primary_reason_surrogate: '',
        describe_personality: '',
        biggest_strengths: '',
        spending_free_time_hobby: '',
        tellUs_envision_feeling_emotional_level: ''
    },
    step11:{
        reference_name1: "",
        relationship1: "",
        phone_no1: "",
        reference_name2: "",
        relationship2: "",
        phone_no2: "",
        photos: [],
        agreement_name: "",
    }
}