import React, { useEffect, useState } from 'react'
import './ResumeSteps.css'
import { useTranslation } from "react-i18next";
import { useOutletContext } from 'react-router';
import { SURROGATE_MARITAL_STATUS, SURROGATE_MOTHER_RACE } from '../../../../constant';
import { resumeForm2 } from '../../../../service/Apis';
import { userId } from '../../../../service/userData';
import { resumeAllKeys } from './resumeKeys';
import moment from 'moment';
import Loader from '../../../../components/Loader/Loader';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


export const ResumeStep2 = () => {
    let { t } = useTranslation();
    let { navigation, data } = useOutletContext()
    const [loader, setLoader] = useState(false)
    let step2Keys = resumeAllKeys.step2;
    // useEffect(() => {
    //     let obj = {}
    //     console.log(step2Keys)
    //     console.log(data)
    //     for (let i in step2Keys) {
    //         console.log(i)
    //         if (data.resumeData[i]) {
    //             obj[i] = data?.resumeData?.[i] || step2Keys[i]
    //         }
    //     }
    //     console.log(obj)
    //     setFormData(pre => ({ ...pre, ...obj }));
    // }, [data.resumeData])


    useEffect(() => {
        let obj = {};
        for (let i in step2Keys) {
            if (data.resumeData.hasOwnProperty(i)) {
                obj[i] = data?.resumeData?.[i]
            }
            else {
                obj[i] = step2Keys[i]
            }
        }

        setFormData((pre) => ({ ...pre, ...obj }));
    }, [data.resumeData]);

    const [formData, setFormData] = useState(
        {
            age: "",
            dob: "",
            height: {
                feet: "",
                inches: ""
            },
            weight: {
                LBs: "",
                oz: ""
            },
            race: [],
            many_years_in_us: "",
            religious_affliation: "",
            lived_another_country: "",
            us_citizen: "",
            travel_outsite_us: "",
            language_speak: [],
            relationship_status: "",
            ever_married: "",
            ever_divorced: "",
            emergency_contect_name: "",
            emergency_contect_number: "",
            emergency_alter_contect_number: ""
        }
    )

    const handleInputChange2 = (e) => {
        const { name, value, type, checked } = e.target;
        console.log(name,':',value)

        console.log(value === ' ')
        if (value === ' ') {
            e.target.value = ''
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }

    };


    function multipleCheckBoxChange(e) {
        let { value, checked } = e.target;
        let pre = { ...formData };
        if (checked) {
            pre.race.push(value)
        }
        else {
            pre.race = pre.race.filter(e => e !== value)
        }
        setFormData({ ...pre })
    };

    async function formSubmit(e) {
        e.preventDefault();

        setLoader(true)
        let userID = userId();
        let data = { ...formData };
        if (data.ever_married == '') {
            data.ever_married = false
        }

        try {
            let res = await resumeForm2(userID, data);
            setLoader(false)
            navigation.next()
            setLoader(false)
        }
        catch (err) {
            console.log(err)
        }
    };

    function setPhoneCodebyLanguage(key){
        switch (key)
     {
          case 'en':
            return 'US'
            break;
          case 'es':
            return 'ES'
            break;
          case 'zh':
            return 'CN'
            break;
          case 'en-US':
            return 'US'
            break;
          default:
            return 'US'
            break;
        }
      }


    function radioInputChange(e, val) {
        let name = e.target.name;

        setFormData((prevState) => ({
            ...prevState,
            [name]: val,
        }));
    };


    function ageCalculate(res) {
        const dob = moment(res);
        const now = moment();
        const age = now.diff(dob, 'years');
        setFormData(pre => {
            return { ...pre, age: age }
        })
    }

    return (
        <>
            <Loader open={loader} />
            <div className='ResumeStep_con'>
                <div className='FormParent'>
                    <form onSubmit={formSubmit}>
                        
                            <div className='SubHeading mb-lg-4 mb-md-4 mb-sm-3 mb-3' >{t("Physical/Personal Information")}</div>
                     

                        <div className='row mb-4'>
                            <div className="col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Date of Birth")}<span className='text-danger'>*</span></label>
                                <input type='date'
                                    className='form-control'
                                    //placeholder=''
                                    name='dob'
                                    required
                                    max={moment().format('YYYY-MM-DD')}
                                    value={formData.dob && moment(formData.dob).format("YYYY-MM-DD")}
                                    onChange={(e) => {
                                        handleInputChange2(e)
                                        ageCalculate(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Age")}<span className='text-danger'>*</span></label>
                                <input type='text'
                                    className='form-control'
                                    disabled
                                    //placeholder=''
                                    required
                                    name='age'
                                    value={formData.age}
                                // onChange={handleInputChange2}
                                />

                            </div>

                        </div>
                        {/* <div className="row mb-4">
                            <div className='col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4'>
                                <label htmlFor="exampleInputEmail1" className={`form-label  fs-5`}>{t("Height")} <span className='text-danger'>*</span></label>

                                <div className='d-flex justify-content-between gap-2'>
                                    <input type='number'
                                        className='form-control'
                                        //placeholder=''
                                        value={formData.height.feet}
                                        required
                                        onChange={(e) => {
                                            setFormData(pre => {
                                                pre.height.feet = e.target.value;
                                                return { ...pre }
                                            })
                                        }}
                                    />

                                    <div className="d-flex align-items-center">{t("Ft")}</div>
                                    <input type='number'
                                        className='form-control'
                                        //placeholder=''
                                        value={formData.height.inches}
                                        required
                                        onChange={(e) => {
                                            setFormData(pre => {
                                                pre.height.inches = e.target.value;
                                                return { ...pre }
                                            })
                                        }}
                                    />
                                    <div className="d-flex align-items-center">{t("Inches")}</div>
                                </div>


                            </div>

                            <div className='col-md-6'>
                                <label htmlFor="exampleInputEmail1" className={`form-label fs-5`}>{t("Weight")} <span className='text-danger'>*</span></label>

                                <div className='d-flex justify-content-between gap-2'>
                                    <input type='number'
                                        className='form-control'
                                        //placeholder=''
                                        required
                                        value={formData.weight.LBs}
                                        onChange={(e) => {
                                            setFormData(pre => {
                                                pre.weight.LBs = e.target.value;
                                                return { ...pre }
                                            })
                                        }}
                                    />
                                    <div className="d-flex align-items-center">{t("Lbs")}</div>
                                    <input type='number'
                                        className='form-control'
                                        //placeholder=''
                                        required
                                        value={formData.weight.oz}
                                        onChange={(e) => {
                                            setFormData(pre => {
                                                pre.weight.oz = e.target.value;
                                                return { ...pre }
                                            })
                                        }}
                                    />
                                    <div className="d-flex align-items-center">{t("Oz")}</div>
                                </div>


                            </div>

                        </div> */}


                        <div className='mb-3'> <label className="form-check-label fs-5" htmlFor="flexCheckDefault">
                            {t("Race (check all that apply)")}<span className='text-danger'> *</span>
                        </label>
                        </div>

                        <div className='row mt-3 mb-4'>

                            {SURROGATE_MOTHER_RACE.map((res, key) => {
                                return (
                                    <div key={key} className='col-lg-3 col-md-4 col-sm-6 col-12 mb-2'>
                                        <div class="form-check">
                                            <input
                                                required={formData?.race?.length === 0}
                                                className="form-check-input"
                                                type="checkbox"
                                                name={res}
                                                checked={formData.race.includes(res)}
                                                // id={`resumeRace${key}`}
                                                onChange={multipleCheckBoxChange}
                                                value={res}
                                            />
                                            <label className="form-check-label LabelSubHeading"
                                            // htmlFor={`resumeRace${key}`}
                                            >
                                                {t(res)}
                                            </label>
                                        </div>
                                    </div>

                                )
                            })}
                        </div>







                        <div className='row mb-4'>
                            <div className="col-md-12 ">
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5  " >{t("Religious Affiliation")}<span className='text-danger'>*</span></label>

                                <input type='text'
                                    className='form-control'
                                    //placeholder='Religious Affiliation'
                                    required
                                    name='religious_affliation'
                                    value={formData.religious_affliation}
                                    onChange={handleInputChange2}
                                />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                <label className="form-check-label mb-2 fs-5" htmlFor="flexCheckDefault">{t("Are you a US Citizen?")}<span className='text-danger'>*</span></label>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div class="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                required
                                                name="us_citizen"
                                                value={"Yes"}
                                                // id="usCitizen1"
                                                checked={formData.us_citizen === "Yes"}
                                                onChange={handleInputChange2}
                                            />
                                            <label className="form-check-label LabelSubHeading"
                                            // htmlFor="usCitizen1"
                                            >
                                                {t("Yes")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="form-check">

                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value={"No"}
                                                required
                                                name="us_citizen"
                                                checked={formData.us_citizen === "No"}
                                                onChange={handleInputChange2}
                                            />
                                            <label className="form-check-label LabelSubHeading"
                                          
                                            >

                                                {t("No")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="form-check">

                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="us_citizen"
                                                required
                                                checked={formData.us_citizen === "Us Green Card"}
                                                value={"Us Green Card"}
                                                onChange={handleInputChange2}
                                            />
                                            <label className="form-check-label LabelSubHeading"
                                         
                                            >
                                                {t("US Green Card")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <label className="form-check-label fs-5 mb-2" htmlFor="flexCheckDefault">{t("Have you ever lived in another country?")} <span className='text-danger'>*</span></label>
                                    <div className="col-md-4">
                                        <div class="form-check">

                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                checked={formData.lived_another_country === true}
                                                name="lived_another_country"
                                                required
                                                // id="lived_another_city1"
                                                onChange={(e) => radioInputChange(e, true)}
                                            />
                                            <label className="form-check-label LabelSubHeading"
                                            //  htmlFor="lived_another_city1"
                                            >
                                                {t("Yes")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="form-check">

                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                required
                                                checked={formData.lived_another_country === false}
                                                name="lived_another_country"
                                                // id="lived_another_city2"
                                                onChange={(e) => radioInputChange(e, false)}
                                            />
                                            <label className="form-check-label LabelSubHeading"
                                            //  htmlFor="lived_another_city2"
                                            >
                                                {t("No")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">

                                <label className="form-check-label mb-2 fs-5" htmlFor="flexCheckDefault">{t("How many years you stay in USA?")}</label>
                                <input type='number'
                                    className='form-control'
                                    //placeholder=''
                                    required
                                    name='many_years_in_us'
                                    value={formData.many_years_in_us}
                                    onChange={handleInputChange2}
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-check-label fs-5 mb-2" htmlFor="flexCheckDefault">{t("Have you traveled outside the continental United States in the past year?")} <span className='text-danger'>*</span></label>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div class="form-check">

                                            <input
                                                name="travel_outsite_us"
                                                className="form-check-input"
                                                type="radio"
                                                required
                                                // id="travel_outsite_us1"
                                                checked={formData.travel_outsite_us == true}
                                                onChange={(e) => radioInputChange(e, true)}
                                            />
                                            <label className="form-check-label LabelSubHeading"
                                            // htmlFor="travel_outsite_us1"
                                            >

                                                {t("Yes")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="form-check">

                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                required
                                                name="travel_outsite_us"
                                                // id="travel_outsite_us2"
                                                checked={formData.travel_outsite_us === false}
                                                onChange={(e) => radioInputChange(e, false)}
                                            />
                                            <label className="form-check-label LabelSubHeading"
                                            //  htmlFor="travel_outsite_us2"
                                            >

                                                {t("No")}
                                            </label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-12 ">

                                <label className="form-check-label fs-5 mb-2" htmlFor="flexCheckDefault">{t("What languages do you speak/write?")} <span className='text-danger'>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    name='language_speak'
                                    value={formData.language_speak}
                                    onChange={(e) => {
                                        if (e.target.value == ' ') {
                                            e.target.value = ''
                                        } else {
                                            setFormData(pre => {
                                                pre.language_speak = [e.target.value]
                                                return { ...pre };
                                            })
                                        }
                                    }}
                                //placeholder="Languages"
                                />
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='mb-3'> <label className="form-check-label fs-5" htmlFor="flexCheckDefault">
                                {t("Current marital/relationship status")}<span className='text-danger'> *</span>
                            </label></div>

                            {SURROGATE_MARITAL_STATUS.map((res, key) => {
                                return (
                                    <div className="col-md-3" key={key}>
                                        <div class="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value={res}
                                                required
                                                checked={formData.relationship_status === res}
                                                name="relationship_status"
                                                // id={`relationship_status${key}`}
                                                onChange={(e) => {
                                                    handleInputChange2(e)
                                                    setFormData(pre => {
                                                        return {
                                                            ...pre,
                                                            ever_married: '',
                                                            ever_divorced: ''
                                                        }
                                                    })
                                                }}
                                            />
                                            <label className="form-check-label LabelSubHeading"
                                            //  htmlFor={`relationship_status${key}`}
                                            >
                                                {t(res)}
                                            </label>
                                        </div>

                                    </div>

                                )
                            })}

                        </div>

                        {formData.relationship_status !== '' ? <div className="row mb-4">
                            {formData.relationship_status !== 'Married' ? <div className="col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                <div className='row'>
                                    <div className='mb-3'> <label className="form-check-label fs-5" htmlFor="flexCheckDefault">
                                        {t("Have you EVER been married?")}<span className='text-danger'> *</span>
                                    </label></div>
                                    <div className="col-md-3">
                                        <div class="form-check">

                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                required
                                                // id="ever_married1"
                                                name="ever_married"
                                                checked={formData.ever_married == true}
                                                onChange={(e) => radioInputChange(e, true)}
                                            />
                                            <label className="form-check-label LabelSubHeading"
                                            //  htmlFor="ever_married1"
                                            >
                                                {t("Yes")}
                                            </label>
                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <div class="form-check">

                                            <input
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                // id="ever_married2"
                                                name="ever_married"
                                                checked={formData.ever_married === false}
                                                onChange={(e) => radioInputChange(e, false)}
                                            />
                                            <label className="form-check-label LabelSubHeading"
                                            // htmlFor="flexRadioDefault2"
                                            >

                                                {t("No")}
                                            </label>
                                        </div>

                                    </div>

                                </div>
                            </div> : ''}
                            <div className="col-md-6">
                                <div className='row'>
                                    <div className='mb-3'> <label className="form-check-label fs-5" htmlFor="flexCheckDefault">
                                        {t("Have you EVER been divorced?")}<span className='text-danger'> *</span>
                                    </label></div>
                                    <div className="col-md-3">
                                        <div class="form-check">

                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                // id="ever_divorced1"
                                                name="ever_divorced"
                                                required
                                                checked={formData.ever_divorced === true}
                                                onChange={(e) => radioInputChange(e, true)}
                                            />
                                            <label className="form-check-label LabelSubHeading"
                                            // htmlFor="ever_divorced1"
                                            >
                                                {t("Yes")}
                                            </label>
                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <div class="form-check">

                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                // id="ever_divorced2"
                                                required
                                                name="ever_divorced"
                                                checked={formData.ever_divorced === false}
                                                onChange={(e) => radioInputChange(e, false)}
                                            />
                                            <label className="form-check-label LabelSubHeading"
                                            //  htmlFor="ever_divorced2"
                                            >

                                                {t("No")}
                                            </label>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div> : ''}
                        <div className="row mb-4">
                            <div className="col-md-6">

                                <label className="fs-5 mb-2" htmlFor="flexCheckDefault">{t("Emergency contact name/relation to surrogate")}  <span className='text-danger'>*</span></label>
                                <input
                                    type="text"
                                    required
                                    name='emergency_contect_name'
                                    onChange={handleInputChange2}
                                    value={formData.emergency_contect_name}
                                    className="form-control"
                                //placeholder="Emergency contact name"
                                />
                            </div>
                            <div className="col-md-6">

                                <label className="fs-5 mb-2" htmlFor="flexCheckDefault">{t("Emergency contact phone number")} <span className='text-danger'>*</span></label>
                                <PhoneInput
                                        international
                                        defaultCountry={setPhoneCodebyLanguage(localStorage.getItem('i18nextLng'))}
                                        withCountryCallingCode
                                        value={formData?.emergency_contect_number}
                                        onChange={(e) => {
                                            if (e == " ") {
                                                e = "";
                                            } else {
                                               setFormData(pre=>{
                                                return {
                                                    ...pre,
                                                    emergency_contect_number:e
                                                }
                                               })
                                            }
                                        }}
                                    />
                                {/* <input
                                    type="number"
                                    required
                                    name='emergency_contect_number'
                                    onChange={handleInputChange2}
                                    value={formData.emergency_contect_number}
                                    className="form-control"
                                //placeholder="Emergency contact Number"
                                /> */}
                            </div>
                        </div>
                        <div className="row mb-3">

                            <div className="col-md-12">

                                <label className="fs-5 mb-2" htmlFor="flexCheckDefault">{t("Emergency contact alternate phone number")}</label>
                                <PhoneInput
                                        international
                                        defaultCountry={setPhoneCodebyLanguage(localStorage.getItem('i18nextLng'))}
                                        withCountryCallingCode
                                        value={formData.emergency_alter_contect_number}
                                        onChange={(e) => {
                                            if (e == " ") {
                                                e = "";
                                            } else {
                                               setFormData(pre=>{
                                                return {
                                                    ...pre,
                                                    emergency_alter_contect_number:e
                                                }
                                               })
                                            }
                                        }}
                                    />
                                {/* <input
                                    type="number"
                                    name='emergency_alter_contect_number'
                                    onChange={handleInputChange2}
                                    value={formData.emergency_alter_contect_number}
                                    className="form-control"
                                //placeholder="Emergency contact Alternate Number"
                                /> */}
                            </div>
                        </div>





                        <div className="ButtonForm mt-4">
                            <button className=" btn_non_fill" type="button" onClick={navigation.previous}>{t("Previous")}</button>
                            <button className="btn_fill" type="submit">{t("Next")}</button>
                        </div>


                    </form>
                </div>
            </div>

        </>
    )
}
