import React from "react";
import { useTranslation } from "react-i18next";
import style from "./LatestNews.module.css";
import "../../test/home.css";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";

const LatestNews = () => {
  const [cards, setCards] = useState([
    {
      title:
        "Musk helped his female subordinate Zilis give birth to twins by sperm donation",
      image: "news_pic_one.png",
      source: "https://www.wenxuecity.com/news/2024/02/19/125428411.html",
      source_name: "wenxuecity.com",
      date: "feb 19,2024",
      isThirdPartyUrl: true,
    },
    {
      title:
        "Elon Musk's 77-Year-Old Dad Says He's Asked To Donate His Sperm...",
      image: "news_pic_two.png",
      source:
        "https://www.dailystar.co.uk/news/us-news/elon-musks-dad-reveals-asked-30818420",
      source_name: "dailystar.co",
      date: "aug 30,2023",
      isThirdPartyUrl: true,
    },
    {
      title: "Chinese rich guy make 100 Surrogate babies",
      image: "news_pic_three.png",
      source: "https://www.youtube.com/watch?v=HrmRgip-APQ",
      source_name: "youtube",
      date: "may 14,2022",
      isThirdPartyUrl: true,
    },
    {
      title:
        "Experienced Surrogates Can Earn Up to $104,000 in Their Second Surrogacy Journey",
      image: "news_pic_four.png",
      source_name: "",
      source: "internalnews",
      date: "mar 04,2024",
      isThirdPartyUrl: false,
    },
  ]);

  let { t } = useTranslation();

  return (
    <div>
      <div className={`container-fluid px-5 my-4`}>
        <div className={`row`}>
          <div className={`col rounded`}>
            <img
              className="w-100"
              src="/images/news_card_upper_image.png"
              alt="news banner"
            />
          </div>
        </div>

        <div className="row px-4 mt-5 justify-content-center">
          {cards?.map((card, index) => (
            <div
              key={index}
              className="col-12 col-sm-6 col-md-4 p-lg-2 p-md-2 p-sm-2 p-2"
            >
              <div className={`${style.card_wrapper} h-100`}>
                <Link
                  style={{height:'100%'}}
                  to={card.isThirdPartyUrl ? card.source : "internalnews"}
                  target={!card.isThirdPartyUrl?'_self':'_blank'}
                  className="d-flex align-items-end"
                >
                  <div className={`card ${style.card}`}>
                    <img
                      className="card-img-top"
                      src={`/images/${card.image}`}
                      alt={t("Card image cap")}
                    />
                    <div className="card-body">
                      <div className="lufga_18px px-2">{t(card.title)}</div>
                      <div className="lufga_18px d-flex justify-content-between mt-3">
                        <div className="d-flex align-items-end">
                          <span className="custom_gray lufga_12px d-flex align-items-end">
                            {t(card.source_name)}
                          </span>
                        </div>
                        <div className="lufga_12px custom_gray d-flex align-items-end">
                          {t(card.date)}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
