import React from 'react'
import style from './UnderConstruction.module.css'
import { useTranslation } from 'react-i18next';


const UnderConstruction = () => {

  let { t } = useTranslation();

  return (
    <div className='d-flex flex-column'>
      <div className={`${style.image_section}`}>
        <img src='/images/undercons.png' alt='Under Construction Image' />
      </div>
        <div className={`${style.content_section}`}>
          <div className='lufga_20px'> {t("Under Construction")}</div>
          <div className='lufga_20px custom_color'>{t("Coming Soon")}</div>
          <div className='lufga_20px'>{t("Check Back Later")}</div>
        </div>
      </div>
  )
}

export default UnderConstruction;