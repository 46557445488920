import React, { useState, useRef, useEffect } from 'react';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import "./Resume.css"
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from 'react-router';
import { getResumeData, getUserDataById } from '../../../../service/Apis';
import { userId } from '../../../../service/userData';

export const Resume = () => {
    let { t } = useTranslation();

    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef(null);
    let [resumeData, setResumeDate] = useState({});

    const [userData,setuserData] = useState(null)
    let token = new URLSearchParams(window.location.search).get("token");
    let email = new URLSearchParams(window.location.search).get("email");
    let id = new URLSearchParams(window.location.search).get("id");
    let step = new URLSearchParams(window.location.search).get("step");


    const localUserData = JSON.parse(localStorage.getItem('userData'))
    
   

    useEffect(() => {
        if (token && email && id && step) {
            setUserDataInLocal();
           
        }
    }, []);


    async function getUserData(){
       
        let idd = userId()
        try{
            let res = await getUserDataById(idd)
            console.log(res.data.data)
            setuserData(res.data.data)

        }catch(err){
            console.log(err)
        }
    }

    function setUserDataInLocal() {
        localStorage.setItem("userData", JSON.stringify({ token, userId: id, email }));
        navigate(`/surrogateMother/resume/step-${step}`);
        window.location.reload()
    };


    const items = [
        { label: '' },
        { label: '' },
        { label: '' },
        { label: '' },
        { label: '' },
        { label: '' },
        { label: '' },
        { label: '' },
        { label: '' },
        { label: '' },
        { label: '' },
    ];
    let navigate = useNavigate()

    const nextPage = () => {

        navigate(`/surrogateMother/resume/step-${parseInt(getCurrentpage()) + 1}`)
        setActiveIndex(parseInt(getCurrentpage()) - 1)
        getProfileData()
        getUserData()
    }

    const previousPage = () => {
        navigate(`/surrogateMother/resume/step-${parseInt(getCurrentpage()) - 1}`)
        setActiveIndex(parseInt(getCurrentpage()) - 1)
    }

    function getCurrentpage() {
        let path = window.location.pathname
        let currentPage = path.split("-")[path.split("-").length - 1]
        return currentPage
    };
    useEffect(() => {
        getUserData()
        getProfileData()
    }, []);

    useEffect(() => {
        setActiveIndex(getCurrentpage() - 1)
    }, []);

    async function getProfileData() {
        let userID = userId();
        try {
            let res = await getResumeData(userID);
            setResumeDate(res.data.data)
        }
        catch (err) {
            console.log(err)
        }
    };
    function goToPage(page) {
        navigate(`/surrogateMother/resume/step-${page}`);
    }


    function goToDashBoard(res){
    window.location.href = `https://dashboard.newhopepoints.org/surrogate/dashboard_?token=${encodeURIComponent(res?.token)}&email=${encodeURIComponent(res?.email)}&id=${encodeURIComponent(res?.userId)}`
       
    }


    return (
        <>
        
        <div className="Resume_con">
            <div className='mb-5'>
                <div><h1  className='MainHeading'>{t("Surrogacy Mother")}</h1></div>
                <div className='SubHeading'>
                    {/* <p>{t("Register to begin your journey with us!")}</p> */}
                </div>
            </div>
            <div className='d-flex justify-content-end'>
                <button className='btn_non_fill p-0' onClick={()=>goToDashBoard(localUserData)}>Save & Go to dashboard</button>
            </div>
            <div className='mb-4 fs-5'>{userData !== null && userData.resuma_step !== undefined?userData?.resuma_complete?'11':(+(userData?.resuma_step) - 1):'0'} {t("of 11")} {+(userData?.resuma_step) == 2?'step':'steps'} {t("completed")}</div>
            <Toast ref={toast} />
            <Steps model={items} activeIndex={activeIndex}  onSelect={(e) => {
                setActiveIndex(e.index)
                goToPage(e.index + 1)
            }} readOnly={true} />

            <Outlet context={{
                navigation: { next: nextPage, previous: previousPage },
                data: { resumeData },
                callAllData: getProfileData,
                surrogateData: userData
            }
            } />
        </div>
        </>
    );
};
