import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useOutletContext } from 'react-router';
import { resumeForm9 } from '../../../../service/Apis';
import { userId } from '../../../../service/userData';
import { resumeAllKeys } from './resumeKeys';
import "./ResumeSteps.css"
import Loader from '../../../../components/Loader/Loader';
export const ResumeStep9 = () => {
  let { t } = useTranslation();
  let { navigation, data } = useOutletContext()

  let step9Keys = resumeAllKeys.step9;

  let [loader, setLoader] = useState(false)
  const [formData, setFormData] = useState(
    {
      spouse_legal_cases: "",
      explain_spouse_legal_cases: '',
      spouse_arrest_conflicts: "",
      spouse_convicted_felony: "",
      spouse_accused_spousal_abuse: "",
      child_support_payments: "",
      turned_down_adoption_agency: "",
      substance_abuse_program: "",
      declared_bankruptcy: "",
      explain_all_items_answered: '',
      lost_custody_child: ""
    }
  )
  // useEffect(() => {
  //   let obj = {}
  //   for (let i in step9Keys) {
  //     if (data.resumeData[i]) {
  //       obj[i] = data?.resumeData?.[i] || step9Keys[i]
  //     }
  //   }
  //   setFormData(pre => ({ ...pre, ...data?.resumeData }));
  // }, [data.resumeData])
  useEffect(() => {
    let obj = {};
    for (let i in step9Keys) {
      if (data.resumeData.hasOwnProperty(i)) {
        obj[i] = data?.resumeData?.[i]
      }
      else {
        obj[i] = step9Keys[i]
      }
    }

    setFormData((pre) => ({ ...pre, ...obj }));
  }, [data.resumeData]);

  async function formsubmit9(e) {
    setLoader(true)
    try {
      e.preventDefault()
      let userID = userId();
      let data = { ...formData };
      await resumeForm9(userID, data);
      navigation.next()

    }
    catch (err) {
      console.log(err)
    }
    finally {
      setLoader(false);
    }
  }
  function radioInputChange(e, val) {
    let name = e.target.name

    setFormData((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;


    if (type == 'checkbox') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {

      if (value == ' ') {
        e.target.value = ''
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  };
  return (
    <>
      <Loader open={loader} />
      <div className="ResumeStep_con">
        <div className='Spouse / Partner Information'>
          <form onSubmit={formsubmit9}>
            <div className="First_Section">
            
                <div className='SubHeading mb-lg-4 mb-md-4 mb-sm-3 mb-3'>{t("Legal Information")}</div>
           
              <div className='CheckBoxParent row pb-4'>
                <label className="form-label fs-5" >
                  {t("Do either you or your spouse/partner have any legal claims/cases currently pending?")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input onChange={(e) => radioInputChange(e, true)}
                      className="form-check-input"
                      required
                      type="radio"
                      name="spouse_legal_cases"

                      value='true'
                      checked={formData.spouse_legal_cases == true}
                    />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input onChange={(e) => {
                    radioInputChange(e, false)
                    setFormData(pre => {
                      return {
                        ...pre,
                        explain_spouse_legal_cases: ''
                      }
                    })
                  }}
                    className="form-check-input"
                    required
                    type="radio"
                    name="spouse_legal_cases"

                    value='false'
                    checked={formData.spouse_legal_cases === false}
                  />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>
              {formData.spouse_legal_cases && (

                <div className="row pb-4">

                  <div className="col-md-12">
                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list date(s) and explain")}<span className='text-danger'>*</span></label>
                    <textarea
                      rows={5}
                      required

                      className='form-control'
                      placeholder=''
                      name='explain_spouse_legal_cases'
                      value={formData?.explain_spouse_legal_cases}
                      onChange={handleInputChange} />
                  </div>
                </div>
              )}

              <div className='MiddleSubHeading pb-4 fs-4'>
                {t("Have you or your spouse/partner ever")}
              </div>
              <div className='CheckBoxParent row pb-4'>
                <label className="form-label fs-5" >
                  {t("Been arrested or had conflicts with the law (include DUI arrests)")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      onChange={(e) => radioInputChange(e, true)}
                      className="form-check-input" type="radio"
                      name="spouse_arrest_conflicts"
                      required
                      checked={formData.spouse_arrest_conflicts == true}

                    />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input
                    onChange={(e) => radioInputChange(e, false)}
                    className="form-check-input"
                    type="radio"
                    name="spouse_arrest_conflicts"

                    required
                    checked={formData.spouse_arrest_conflicts === false}

                  />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>

              <div className='CheckBoxParent row pb-4'>
                <label className="form-label fs-5" >
                  {t("Been convicted of a felony")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input onChange={(e) => radioInputChange(e, true)}
                      className="form-check-input"
                      required
                      checked={formData.spouse_convicted_felony == true}
                      type="radio"
                      name="spouse_convicted_felony"


                    />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input onChange={(e) => radioInputChange(e, false)}
                    className="form-check-input"
                    required
                    type="radio"
                    checked={formData.spouse_convicted_felony === false}
                    name="spouse_convicted_felony"


                  />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>

              <div className='CheckBoxParent row pb-4'>
                <label className="form-label fs-5" >
                  {t("Been accused and/or convicted of child abuse or spousal abuse")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input onChange={(e) => radioInputChange(e, true)}
                      className="form-check-input"
                      required
                      type="radio"
                      name="spouse_accused_spousal_abuse"

                      checked={formData.spouse_accused_spousal_abuse == true}

                    />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input onChange={(e) => radioInputChange(e, false)}
                    className="form-check-input" type="radio"
                    required
                    name="spouse_accused_spousal_abuse"
                    checked={formData.spouse_accused_spousal_abuse === false}


                  />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>

              <div className='CheckBoxParent row pb-4'>
                <label className="form-label fs-5" >
                  {t("Lost custody of a child")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      onChange={(e) => radioInputChange(e, true)}
                      className="form-check-input" type="radio"
                      required
                      checked={formData.lost_custody_child == true}
                      name="lost_custody_child"


                    />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input onChange={(e) => radioInputChange(e, false)}
                    className="form-check-input" type="radio"
                    required
                    checked={formData.lost_custody_child === false}
                    name="lost_custody_child"


                  />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>

              <div className='CheckBoxParent row pb-4'>
                <label className="form-label fs-5" >
                  {t("Been late with child support payments")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input onChange={(e) => radioInputChange(e, true)}
                      className="form-check-input" type="radio"
                      required
                      name="child_support_payments"
                      checked={formData.child_support_payments == true}


                    />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input onChange={(e) => radioInputChange(e, false)}
                    className="form-check-input" type="radio"
                    required
                    name="child_support_payments"

                    checked={formData.child_support_payments === false}

                  />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>

              <div className='CheckBoxParent row pb-4'>
                <label className="form-label fs-5" >
                  {t("Been turned down by an adoption agency")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input onChange={(e) => radioInputChange(e, true)}
                      className="form-check-input" type="radio"
                      required
                      name="turned_down_adoption_agency"

                      checked={formData.turned_down_adoption_agency == true}

                    />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input onChange={(e) => radioInputChange(e, false)}
                    className="form-check-input" type="radio"
                    required
                    name="turned_down_adoption_agency"

                    checked={formData.turned_down_adoption_agency === false}

                  />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>

              <div className='CheckBoxParent row pb-4'>
                <label className="form-label fs-5" >
                  {t("Been in a substance abuse program")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input onChange={(e) => radioInputChange(e, true)}
                      className="form-check-input" type="radio"
                      required
                      name="substance_abuse_program"
                      checked={formData.substance_abuse_program == true}


                    />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input onChange={(e) => radioInputChange(e, false)}
                    className="form-check-input" type="radio"
                    required
                    name="substance_abuse_program"

                    checked={formData.substance_abuse_program === false}

                  />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>
              <div className='CheckBoxParent row pb-4'>
                <label className="form-label fs-5" >
                  {t("Declared bankruptcy")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input onChange={(e) => radioInputChange(e, true)}
                      className="form-check-input" type="radio"
                      required
                      name="declared_bankruptcy"

                      checked={formData.declared_bankruptcy == true}
                    />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input onChange={(e) => radioInputChange(e, false)}
                    className="form-check-input" type="radio"
                    required
                    name="declared_bankruptcy"

                    checked={formData.declared_bankruptcy === false}
                  />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>

              <div className="row pb-4">
                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please explain all items you answered yes to above")} - {t('(If any)')}</label>
                  <textarea
                    rows={5}
                    type='text'
                    className='form-control'
                    placeholder=''
                    name='explain_all_items_answered'
                    value={formData?.explain_all_items_answered}
                    onChange={handleInputChange}
                  />
                </div>
              </div>


            </div>
            <div className="ButtonForm mt-3">
              <button className="btn_non_fill" type="button" onClick={navigation.previous}>{t('Previous')}</button>
              <button className="btn_fill" type="submit" >{t('Next')}</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
