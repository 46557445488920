import { Dialog } from 'primereact/dialog'
import React from 'react'
import './Embryodonorsform.css'

export const EmbryoDonorCongratsDialog = ({ Congratulation, setCongratulation }) => {
  return (
    <>
      <div className='Congrats_con'>
  
        <Dialog
          closable={false}
          visible={Congratulation}
          style={{ width: "50vw", border: '3px solid rgba(24, 124, 139, 1)', borderRadius: '25px' }}
          contentClassName="success_of_resume_creation_dialog"
          onHide={() => setCongratulation(false)}
          breakpoints={{ "960px": "75vw", "641px": "98vw" }}
        >
          <div className=''>
            <div className='CongratsImage2'>
              <img src="/images/istockphoto-1125716911-612x612 1.png" alt="" />
            </div>

            <div className=''>
              <h1 className='CongratulationsText'>Congratulations! Your Profile has been Created</h1>
            </div>
            <div className='d-flex justify-content-center'>
              <button className='ExpolreButton' onClick={() => setCongratulation(false)}>Explore Now</button>
            </div>

          </div>
        </Dialog>
      </div >


    </>
  )
}
