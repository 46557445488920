import React from 'react'
import style from "./AskAnyQuestion.module.css"
import { useTranslation } from "react-i18next";

const AskAnyQuestion = () => {

    let { t } = useTranslation();
  return (

            <div className="any_question_section">
                <div className="left_of_any_question">
                    <div>
                        <img src="/images/aq1.png" alt="images" />
                    </div>
                    <div>
                        <img src="/images/aq2.png" alt="images" />
                    </div>
                    <div>
                        <img src="/images/aq3.png" alt="images" />
                    </div>
                </div>
                <div className="content_of_any_question d-flex flex-column gap-3">
                    <div className="upper_section_of_ask_question">
                        <div className="lufga_36px text-white">
                            {t("Do you still have any questions?")}
                        </div>
                    </div>
                    <div className="middle_section_of_ask_question">
                        <div className="lufga_20px text-white">
                            {t(
                                "Don't hesitate to leave us your phone number. We will contact you"
                            )}
                        </div>
                        <div className="lufga_20px text-white">
                            {t("to discuss any questions you may have")}
                        </div>
                    </div>
                    <div className="lower_section_of_ask_question d-flex justify-content-center">
                        <div className="input_width">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control py-2"
                                    placeholder={t("Enter your phone number")}
                                    aria-label="Recipient's username"
                                ></input>

                                <button
                                    type="button"
                                    className="btn btn-dark"
                                    id="button-addon2"
                                >
                                    {t("Submit")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right_of_any_question">
                    <div>
                        <img src="/images/aq4.png" alt="images" />
                    </div>
                    <div>
                        <img src="/images/aq5.png" alt="images" />
                    </div>
                    <div>
                        <img src="/images/aq6.png" alt="images" />
                    </div>
                </div>
            </div>
        
        
  )
}

export default AskAnyQuestion