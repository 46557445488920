import { Dialog } from 'primereact/dialog'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import Loader from '../../../components/Loader/Loader'
import { forgetPasswordEmailSend } from '../../../service/Apis'

export const ForgetPassword = () => {

    const { role } = useParams()
    const navigate = useNavigate()
    let { t } = useTranslation()
    const [loader, setLoader] = useState(false)
    const emailRef = useRef('')
    const [error,setError] = useState('')


    async function handleSubmit(e) {
        e.preventDefault()
        setLoader(true)
        try {
            let res = await forgetPasswordEmailSend({email: emailRef.current})
            if(res.status == 200){
                emailRef.current = ''
                navigate('/intendedparents/emailSend')
            }
            setLoader(false)
        } catch (err) {
            console.log(err)
            setError(err.response.data.errormessage)
            setLoader(false)
        }
    }

    return (
        <>
            <Loader open={loader} />
            <Dialog
                closable={false}
                visible={true}
                onHide={() => { }}
                style={{ width: "60vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div className="DialogParent">
                    <div className="gap-3">
                        <form onSubmit={handleSubmit}>
                            <h1 className="SignUphedaing text-center mb-5">{t("Forgot Password")}</h1>


                            <div className="w-100">
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5">{t("Email Address")}</label>
                                <input type="email" className={`form-control ${error.length !==0?'border border-danger':''}`} required placeholder={t("Enter Email")} defaultValue={emailRef.current} onChange={(e) => {
                                    emailRef.current = e.target.value
                                    setError('')
                                }} />
                                <div className='text-danger'>{error}</div>
                            </div>


                            <div className="d-flex justify-content-between mt-5">
                                <div className="BackLogin fs-5 pointer" onClick={() => navigate(`/signIn`,{state:role})}>{t("Back to Login Screen")}</div>
                                <div>
                                    <span className="TextCustomBottom fs-5">{t("Don’t have any account?")}</span>&nbsp;
                                    <span className="Create fs-5 pointer" onClick={() => navigate(`/intendedparents/signUp/${role}`)}>{t("Create New")}</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mt-5 gap-4">
                                <button className="btn py-3" type="button" onClick={() => navigate(`/signIn`,{state:role})}>{t("Cancel")}</button>
                                <button className="btn bg_primary text-white py-3" type="submit" >{t("Send Reset Link")}</button>
                            </div>
                        </form>

                    </div>

                </div>
            </Dialog>
        </>
    )
}
