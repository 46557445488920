import { useTranslation } from "react-i18next";
import "./home.css";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Home = () => {
  let { t } = useTranslation();

  let languageCode = localStorage.getItem("langCode");
  
  const [cards, setCards] = useState([
    {
      title: "Intended Parents",
      image: "first_image_of_card.png",
      navlink: "intendedparents",
    },
    {
      title: "Eggs/Sperm Donor",
      image: "second_image_of_card.png",
      navlink: "underconstruction",
    },
    {
      title: "Surrogate Mother",
      image: "third_image_of_card.png",
      navlink: "surrogateMother",
    },
    {
      title: "Legal Firms",
      image: "forth_image_of_card.png",
      navlink: "underconstruction",
    },
    {
      title: "IVF Clinic",
      image: "fifth_image_of_card.png",
      navlink: "ivfclinic",
    },
    {
      title: "Children Care Provider",
      image: "sixth_image_of_card.png",
      navlink: "underconstruction",
    },
    {
      title: "Education Service Provider",
      image: "seventh_image_of_card.png",
      navlink: "underconstruction",
    },
    {
      title: "Adoption Parent(s)",
      image: "eighth_image_of_card.png",
      navlink: "adoptingparent",
    },
    {
      title: "Embryo Donor",
      image: "become_card.png",
      navlink: "embryodonor",
    },
    {
      title: "Broker/Agent",
      image: "ninth_image_of_card.png",
      navlink: "underconstruction",  
    },
    {
      title: "Child Care Agency",
      image: "tenth_image_of_card.png",
      navlink: "underconstruction",
    },
    
    {
      title: "Confinement center",
      image: "twelth_image_of_card.png",
      navlink: "underconstruction",
    },
  ]);

  const [currentlang, setCureentLang] = useState([]);
  let te = localStorage.getItem("langCode");

  let currentLanguage = te === "en-US" ? "en" : te || "en";

  let arr2 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  switch (currentLanguage) {
    case "es":
      arr2 = [2, 1, 0, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      break;
    case "zh":
      arr2 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      break;
    case "en":
      arr2 = [2, 1, 0, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      break;

    default:
      break;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(t)
  }, [t]);

  let navigate = useNavigate();

  return (
    <div className="main_div">
      <div className="upper_section container-fluid p-0">
        <div className="upper_section_content">
          <div className="lufga_20px mb-5">{t("One home extra")}</div>
          <div className="lufga_20px_bg_lightblue">
            {t("We help intended parents and adoption parents conceive, raise, and educate super children.")}
          </div>
          <div className="volkhov_48px pt-3">
           {t("American Surrogate mothers help people dreams come true and earn $40,000 to $90,000 a year")}
          </div>
          <div className="lufga_18px_bg_gray pt-2">
            {t("Transform Lives, Create Families, and Make a Difference with Your Gift of Surrogacy")}
          </div>
          <div className="button_section_for_upper_section pt-3">
            <button
              className="lufga_20px py-4 fw-500 findOutMore"
              onClick={() => {
                if (languageCode === "zh") {
                  window.open(
                    "https://www.blogs.newhopepoints.org/2024/05/06/%e4%ba%86%e8%a7%a3%e6%9b%b4%e5%a4%9a%e5%85%b3%e4%ba%8e%e5%87%86%e7%88%b6%e6%af%8d%e7%9a%84%e4%bf%a1%e6%81%af/"
                  );
                } else {
                  navigate("/surrogateMother/signUp/surrogate_donor");
                }
              }}
            >
              {/* <a href={languageCode ==="zh"?"https://www.blogs.newhopepoints.org/2024/05/06/%e4%ba%86%e8%a7%a3%e6%9b%b4%e5%a4%9a%e5%85%b3%e4%ba%8e%e5%87%86%e7%88%b6%e6%af%8d%e7%9a%84%e4%bf%a1%e6%81%af/":"https://www.newhopepoints.org/surrogateMother/signUp/surrogate_donor"} className="lufga_20px py-4 fw-500 findOutMore text-decoration-none"></a> */}
              {t("Find out more")}
            </button>
            <a
              // href={
              //   languageCode === "zh"
              //     ? ""
              //     : "https://www.blogs.newhopepoints.org/2024/03/26/surrogacy-101-surrogacy-process-step-by-step/"
              // }

              onClick={()=>{
                if(languageCode === "es"){
                  window.open("https://www.blogs.newhopepoints.org/2024/05/06/una-guia-para-padres-en-potencia-que-exploran-opciones-de-subrogacion/")
                }else if(languageCode === "en"){
                  window.open("https://www.blogs.newhopepoints.org/surrogacy-101-understanding-the-surrogacy-process/")

                }else{
                  window.open("https://www.blogs.newhopepoints.org/%e6%8e%a2%e7%b4%a2%e4%bb%a3%e5%ad%95%e9%80%89%e6%8b%a9%e7%9a%84%e6%84%8f%e5%90%91%e7%88%b6%e6%af%8d%e6%8c%87%e5%8d%97/")
                }
              }}
              className="lufga_20px py-4 fw-600 text-decoration-none pointer"
            >
              {t("Learn more")}
            </a>
          </div>
        </div>
        <div className="upper_section_image_couple">
          <img src="/images/couple&child.png" />
        </div>
      </div>

      <div className="all_stories">
        <div className="lufga_36px my-md-5 my-3">{t("If you are the")}...</div>
        <div className="row ">
          {arr2?.map((res, index) => (
            <div
              key={index}
              className="col-12 col-sm-6 col-md-4 col-lg-3 p-lg-2 p-md-2 p-sm-2 p-2"
            >
              <Link to={cards[res].navlink} className={"navLink"}>
                <div className="card">
                  <img
                    className="card-img-top"
                    src={`/images/${cards[res].image}`}
                    alt={t("Card image cap")}
                  />
                  <div className="card-body">
                    <div className="lufga_18px">{t(cards[res].title)}</div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div className="choose_us_section">
        <div className="text_section_of_choose">
          <div className="text_content_of_choose">
            <div>
              <div className="lufga_36px">{t("Why Choose Us")}</div>
              <div className="lufga_20px">
                {t(
                  "One-stop solution from an embryo to graduates of famous university – Start to make your dream to be true!"
                )}
              </div>
            </div>
            <div>
              <div>
                <div className="lufga_20px">{t("No Middle Man")}</div>
                <div className="lufga_16px_bg_gray">
                  {t(
                    "Cost efficiently - We use AI Match Technology directly set up the bridge between parents and surrogate mother or donor"
                  )}
                </div>
              </div>
              <div>
                <div className="lufga_20px">
                  <span className="custom_color">
                    {t("Earn More than before")}
                  </span>{" "}
                  {t("if you are a surrogate mother or donor.")}
                </div>

                <div className="lufga_16px_bg_gray">
                  {t(
                    "we have special bidding process to make it possible to earn more than expected"
                  )}
                </div>
              </div>
              <div>
                <div className="lufga_20px">
                  <span className="custom_color">{t("Residual Income")}</span>{" "}
                  {t(
                    "can be earned for everyone by our unique referral programs."
                  )}
                </div>
                <div className="lufga_16px_bg_gray">
                  {t(
                    "Competitive compensation packages, insurance, & financial protection, demonstrating a commitment to their well-being"
                  )}
                </div>
              </div>
              <div>
                <div className="lufga_20px">
                  {t("Specialized Legal Expertise")}
                </div>
                <div className="lufga_16px_bg_gray">
                  {t(
                    "Collaborate with experienced surrogacy attorneys to guide on legal matters, contracts, and any potential legal challenges"
                  )}
                </div>
              </div>
              <div className="">
                <div className="lufga_20px ps-2">
                  {t("View our Services >")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="image_section_of_choose">
          {localStorage.getItem("langCode") == "es" ? (
            <img src="/images/spanishImg.png" alt={t("View our Services >")} />
          ) : (
            ""
          )}
          {localStorage.getItem("langCode") == "en-US" ||
          localStorage.getItem("langCode") == "en" ? (
            <img src="/images/illus.png" alt={t("View our Services >")} />
          ) : (
            ""
          )}
          {localStorage.getItem("langCode") == "zh" ? (
            <img src="/images/chinesImg.png" alt={t("View our Services >")} />
          ) : (
            ""
          )}
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Home;
