import { Dialog } from 'primereact/dialog'
import React from 'react'
import './CongratsDialog.css'

export const CongratsDialog = ({Congratulation,setCongratulation}) => {
  return (
    <>
    <div className='Congrats_con'>
   <Dialog
    closable={false}
      visible={Congratulation}
      className='p-0 m-0'
      onHide={() => setCongratulation(false)}
      style={{ width: "60vw",padding:"0px",margin:"0px" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
    >
    <div className='CongratsImage'>
      <div className='CongratsImage2'>
        <img src="/images/istockphoto-1125716911-612x612 1.png" alt="" />
      </div>

    <div className='mb-4'>
      <h1 className='CongratulationsText'>Congratulations! You availed NHP Silver Membership</h1>
    </div>
    <div className='d-flex justify-content-center'>
      <button className='ExpolreButton'>Explore Now</button>
    </div>

    </div>
      </Dialog>
   </div>

    
    </>
  )
}
