import React, { useEffect, useState } from "react";
import "../SurrogateMother/surrogateMother.css";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { addDocument } from "../../../Firebase/cloudFirestore/setData";
import { Outlet, useNavigate } from "react-router";
import { DialogSignUp } from "../DialogSignUp/DialogSignUp";
import style from "./AdoptionParent.module.css";

export const AdoptionParent = () => {
  const [showVideo, setShowVideo] = useState(false);

  const [visible, setVisible] = useState(false);
  const [signIn, setSignIn] = useState(false);
  // const [visible, setVisible] = useState(false);
  const [forget, setForget] = useState(false);

  const navigate = useNavigate();
  let { t } = useTranslation();

  let te = localStorage.getItem("langCode");

  let currentLanguage = te === "en-US" ? "en" : te || "en";

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowVideo(true);
    }, 3000); // 3000 milliseconds = 3 seconds

    // Clean up the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [showVideo]);

  useEffect(() => {
    setShowVideo(false);

    const timer = setTimeout(() => {
      setShowVideo(true);
    }, 3000); // 3000 milliseconds = 3 seconds

    // Clean up the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [currentLanguage]);

  let cardsArr = [
    {
      name: "Become a Parent (Sign Up)",
      imgUrl: "/images/image34.png",
      role: "adopting_parent",
    },
    {
      name: "Find an Eggs/Sperms donor",
      imgUrl: "/images/image35.png",
      role: "",
    },
    {
      name: "Find Embryo Donor",
      imgUrl: "/images/imagethree.png",
      role: "",
    },
    {
      name: "Find Surrogate Mothers",
      imgUrl: "/images/surrogate_card_image_one.png",
      role: "",
    },
    {
      name: "Find an Agent",
      imgUrl: "/images/surrogate_card_image_two.png",
      role: "",
    },
    {
      name: "Find your Attorney",
      imgUrl: "/images/ip_card_five.png",
      role: "",
    },
  ];

  function SecondSubmit(e) {
    e.preventDefault();
    setForget(true);
    setSignIn(false);
  }

  function ForgetPassword(e) {
    e.preventDefault();
    setForget(true);
    setSignIn(false);
  }
  function HowDoesSubmit() {
    navigate("approved");
  }

  return (
    <>
      <div className="english_page">
        <div className="main_div">
          <div className="upper_section">
            <div className="upper_section_content py-md-2 py-5">
              <div className="lufga_20px_bg_lightblue pe-4">
                {t(
                  "Why don't you adopt from an embryo or a new born baby NOW?"
                )}
              </div>
              <div className="volkhov_48px pt-3">{t("Adoption Parent")}</div>
              <div className="lufga_20px_bg_gray pt-2 pe-4">
                {t(
                  "Some of successful people don't have time or experience to grow a kid, they are looking for a good couples who can take care the kids and well educate the children."
                )}
              </div>
              <div className="lufga_20px_bg_gray pt-2 pe-4">
                {t(
                  "Some of them can offer life time financial support to the kids until them graduating from the ivy league school."
                )}
              </div>

              <div>
                <button
                  className="mt-3"
                  style={{
                    background: "#187C8B",
                    padding: "21px 26px",
                    border: "None",
                    borderRadius: "12px",
                    color: "white",
                    fontFamily: "Lufga",
                    fontSize: "20",
                    fontWeight: "500",
                  }}
                  // onClick={navigate('/underconstruction')}
                >
                  {t("Try Us Now")}
                </button>
              </div>
            </div>
            <div className="upper_section_image_couple">
              


              <img src="/images/group100.png" alt="couple image" />
            </div>
          </div>
          <div className="all_stories">
            <div className="card_container">
              <div className="card_padding">
                <div className="row row_section">
                  {cardsArr?.map((res) => {
                    return (
                      <div
                        className="col-12 col-md-4 col-sm-6 col-lg-4 p-2"
                        onClick={() =>
                          res.role !== "" ? navigate(`signUp/${res.role}`) : {}
                        }
                      >
                        <div className="card">
                          <img
                            className="card-img-top"
                            src={res.imgUrl}
                            alt="Card image cap"
                          />

                          <div className="card-body">
                            <div className="lufga_14px">{t(res.name)}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className={`${style.vv_2}`}>
            <img src="/images/ivf_vs_conception.png" />
            <div className={`${style.v_2_11}`}>
              <div
                style={{ fontWeight: "600" }}
                className={`lufga_28px text-center`}
              >
                {t("NewHopePoints Talent Cultivation Platform")}

                <div
                  style={{ fontWeight: "600" }}
                  className={`clinic_green lufga_28px text-center mt-3`}
                >
                  {t("Your Pathway to Parenthood")}
                </div>
              </div>

              <div className={`clinic_green lufga_18px text-center mt-4`}>
                {t(
                  "New Hope Points offers a revolutionary adoption method starting from embryos created through advanced IVF technology. These embryos are screened for genetic defects and infectious diseases."
                )}
              </div>

              <div className={`clinic_green lufga_18px text-center`}>
                {t(
                  "Adoptive parents can choose their own surrogates or use those provided by donors. Some donors even offer financial support for the child's education. This method ensures a nurturing environment and promotes the success of the child. Interested in embryo adoption or donation? Contact New Hope Points today"
                )}
              </div>
              <div className={`clinic_green lufga_20px text-center`}>
                {t("Text 432.556.6835 or email info@newhopepoints.org.")}
              </div>

              <div>
                <button
                  className="mt-3"
                  style={{
                    background: "#187C8B",
                    padding: "14px 75px",
                    border: "None",
                    borderRadius: "26px",
                    color: "white",
                    fontFamily: "Lufga",
                    fontSize: "20",
                    fontWeight: "500",
                  }}
                  // onClick={HowDoesSubmit}
                >
                  {t("Try this process")}
                </button>
              </div>
            </div>
          </div>

          <div className={`${style.vv_2_11}`}>
            <div
              style={{ fontWeight: "600" }}
              className={`lufga_28px text-center mt-4`}
            >
              {t("Lastest News and Resources")}
            </div>
            <div
              style={{ fontWeight: "600" }}
              className={`lufga_16px_bg_gray pt-2 pe-4 text-center mt-1`}
            >
              {t("See the developments that have occurred in the world")}
            </div>
          </div>
          

          <div className="img_section container">
            <div className="se_section row mt-4 mb-5">
              <div class={`${style.back_img} col-lg-6 col-12`}>
                <div class={`${style.back_col}`}>
                  {" "}
                  <div className=" lufga image_on_text">
                    {" "}{t("The Relationship Between Stress and Infertility")}</div>
                  <p className="lufga"> {t("Some couples find it very difficult to conceive even after trying for many years, while others easily get pregnant.")}
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-12 ps-4 mt-2">
                <div className="fist_img  ">
                  <div className="vitro d-flex flex-column flex-md-row gap-2">
                    <img src="/images/ivf_questions.png" alt="couple image" />
                    <div className="d-flex lufga flex-column justify-content-between">
                      <div className={style.text_spacee}>{t("Common IVF Questions: Is In Vitro Fertilization Painful?")}</div>
                      <p className={style.text_spacep}>{t("In-Vitro fertilization (IVF) is known as the most effective form of Assisted Reproductive...")}</p>
                    </div>
                  </div>
                  <div className="virro_2 d-flex flex-column flex-md-row gap-2 mt-3">
                    <img src="/images/ivf_treatment.png" alt="couple image" />
                    <div className="lufga d-flex flex-column justify-content-between">
                      <div className={style.text_spacee}>{t("Tips to Balance Your Career and IVF Treatment Simultaneously")}
                      </div>
                      <p className={style.text_spacep}>{t("Considering how stressful and urgent work could turn out to be on certain days...")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="any_question_section">
            <div className="left_of_any_question">
              <div>
                <img src="/images/aq1.png" alt="images" />
              </div>
              <div>
                <img src="/images/aq2.png" alt="images" />
              </div>
              <div>
                <img src="/images/aq3.png" alt="images" />
              </div>
            </div>
            <div className="content_of_any_question d-flex flex-column gap-3">
              <div className="upper_section_of_ask_question">
                <div className="lufga_36px text-white">
                  {t("Do you still have any questions?")}
                </div>
              </div>
              <div className="middle_section_of_ask_question">
                <div className="lufga_20px text-white">
                  {t(
                    "Don't hesitate to leave us your phone number. We will contact you"
                  )}
                </div>
                <div className="lufga_20px text-white">
                  {t("to discuss any questions you may have")}
                </div>
              </div>
              <div className="lower_section_of_ask_question d-flex justify-content-center">
                <div className="input_width">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control py-2"
                      placeholder={t("Enter your phone number")}
                      aria-label="Recipient's username"
                    ></input>

                    <button
                      type="button"
                      className="btn btn-dark"
                      id="button-addon2"
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="right_of_any_question">
              <div>
                <img src="/images/aq4.png" alt="images" />
              </div>
              <div>
                <img src="/images/aq5.png" alt="images" />
              </div>
              <div>
                <img src="/images/aq6.png" alt="images" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Outlet />
    </>
  );
};
