import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import {
  METHOD_OF_BIRTH_CONTROL,
  SURROGATE_PREGNANCY_COMPLICATIONS,
} from "../../../../constant";
import { resumeForm7 } from "../../../../service/Apis";
import { userId } from "../../../../service/userData";
import { resumeAllKeys } from "./resumeKeys";
import moment from "moment";
import "./ResumeSteps.css";
import Loader from "../../../../components/Loader/Loader";

export const ResumeStep7 = () => {
  let { t } = useTranslation();
  let { navigation, data } = useOutletContext();
  let step7Keys = resumeAllKeys.step7;
  let [loader, setLoader] = useState(false)
  const [childErr,setChildErr] = useState(false)
  useEffect(() => {
    let obj = {};
    for (let i in step7Keys) {
      if (data.resumeData.hasOwnProperty(i)) {
        obj[i] = data?.resumeData?.[i]
      }
      else {
        obj[i] = step7Keys[i]
      }
    }
    console.log(obj)
    setFormData((pre) => ({ ...pre, ...obj }));
  }, [data.resumeData]);


  let [formData, setFormData] = useState({
    ever_surrogate: "",
    surrogate_type: [],
    surrogate_times: "",
    surrogate_outcome: "",
    ever_egg_donor: "",
    egg_Donor_times: "",
    egg_Donor_outcome: "",
    many_time_pregnant: "",
    no_child_deliverd: "",
    child_details: [],
    physical_custody_children: "",
    child_physical_health_problem: "",
    explain_child_physical_problem: "",
    child_psychological_problem: "",
    explain_child_psychological_problem: "",
    ever_placed_child_adoption: "",
    explain_placed_child_adoption: "",
    deceased_child: "",
    explain_deceased_child: "",
    any_child_adoption: "",
    explain_child_adoption: "",
    breastfeeding: "",
    breastfeeding_completion_date: "",
    fertility_treatment: "",
    explain_fertility_treatment: "",
    delivered_premature_baby: "",
    pregnancy_delivery_complications: [],
    explain_delivered_premature_baby: "",
    miscarriage: "",
    explain_miscarriage: "",
    ever_stillborn_baby: "",
    explain_stillborn_baby: "",
    ever_abortion: "",
    explain_date_abortion: "",
    ever_cesarean_section: "",
    explain_cesarean_section: "",
    reason_cSection: "",
    regular_menstrual_cycles: "",
    menstrual_cycles_days: "",
    birth_control_method: [],
    birth_control_method_begin: "",
  });

  function radioInputChange(e, val) {
    let name = e.target.name;

    setFormData((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  }

  function multipleCheckBoxChange(e) {
    let { value, checked, name } = e.target;
    let pre = { ...formData };
    if (checked) {
      pre[name].push(value);
    } else {
      pre[name] = pre[name].filter((e) => e !== value);
    }
    setFormData({ ...pre });
  }

  function handleInputChange(e) {
    const { name, value, type, checked } = e.target;

    if(value == ' '){
      e.target.value = ''
    }else{
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }
  function radioInputChange(e, val) {
    let name = e.target.name;

    setFormData((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  }

  async function formSubmit(e) {
    e.preventDefault();
     setLoader(true)
    let userID = userId();
    let data = { ...formData };
    // console.log(data);

    if(data.no_child_deliverd == '7+'){
      data.no_child_deliverd = data.child_details.length
    }
    console.log(data)
// return
    try {
      let res = await resumeForm7(userID, data);
      navigation.next();
  
    } catch (err) {
      console.log(err);
    }
    finally {
      setLoader(false)
    }
  }

  function addMoreChild() {
    let dd = { ...formData };

    dd.child_details.push({
      gender: "",
      dob: "",
      conceive: "",
      weeks_at_delivery: "",
      body_weight: {
        lbs: "",
        oz: "",
      },
      ob_name: "",
      delivery_hospital_state: "",
      this_baby_was: "",
    });
    setFormData({ ...dd });
  }
  function deleteChild(ind) {
    let dd = { ...formData };
    dd.child_details.splice(ind, 1);
    setFormData({ ...dd });
  }


  function noOfChildChange(e) {

    let { name, value } = e.target;
    let totalRequiredChild = 0;



    if (value !== "7+") {
      totalRequiredChild = parseInt(value);
    } else {
      totalRequiredChild = 8;
    }

    setFormData(pre => {
      let dd = { ...pre };
      dd.no_child_deliverd = value

      if (dd.child_details.length < totalRequiredChild) {

        [...Array(totalRequiredChild - dd.child_details.length)].forEach(() => {
          dd.child_details.push({
            gender: "",
            dob: "",
            conceive: "",
            weeks_at_delivery: "",
            body_weight: {
              lbs: "",
              oz: "",
            },
            ob_name: "",
            delivery_hospital_state: "",
            this_baby_was: "",
          });
        });

      }
      else {
        dd.child_details.splice(totalRequiredChild, dd.child_details.length)
      }
      return ({ ...dd });
    });
 

  }

  function removeChild(index){
    console.log(index)
    let dd = { ...formData };
    dd.child_details.splice(index, 1);
    setFormData({ ...dd });
  }

  return (
    <>
      <Loader open={loader} />
      <div className="ResumeStep_con">
        <div className="Spouse / Partner Information">
          <form onSubmit={formSubmit}>
            <div className="First_section">
              <div>
                <h1 className="SubHeading mb-lg-4 mb-md-4 mb-sm-3 mb-3">{t("Reproductive Information")}</h1>
              </div>
              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Have you ever been a Surrogate?")}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      required
                      name="ever_surrogate"
                      id="ever_surrogate1"
                      checked={formData.ever_surrogate == true}
                      onChange={(e) => radioInputChange(e, true)}
                      value="true"
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="ever_surrogate1"
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      required
                      name="ever_surrogate"
                      id="ever_surrogate2"
                      checked={formData.ever_surrogate === false}
                      onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData(pre => {
                          return ({
                            ...pre,
                            surrogate_times: "",
                            surrogate_type: [],
                            surrogate_outcome: ""
                          })
                        })
                      }}
                      value="false"
                    // checked={!formData.declared_bankruptcy}
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="ever_surrogate2"
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>

              {formData.ever_surrogate === true && <>
                <div className="CheckBoxParent row pb-4">
                  <label
                    className="form-label LabelHeading"
                    htmlFor="flexRadioDefault1"
                  >
                    {t("Type?")}
                    <span className="text-danger">*</span>
                  </label>

                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        required={formData?.surrogate_type.length == 0}
                        className="form-check-input"
                        type="checkbox"
                        id="surrogate_type1"
                        name="surrogate_type"
                        checked={formData.surrogate_type.includes(
                          "Traditional Surrogate"
                        )}
                        value={"Traditional Surrogate"}
                        onChange={multipleCheckBoxChange}
                      />
                      <label
                        className="form-check-label LabelSubHeading"
                        htmlFor="surrogate_type1"
                      >
                        {t("Traditional Surrogate")}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        required={formData?.surrogate_type.length == 0}
                        id="surrogate_type2"
                        name="surrogate_type"
                        checked={formData.surrogate_type.includes(
                          "Gestational Surrogate"
                        )}
                        value={"Gestational Surrogate"}
                        onChange={multipleCheckBoxChange}
                      />
                      <label
                        className="form-check-label LabelSubHeading"
                        htmlFor="surrogate_type2"
                      >
                        {t("Gestational Surrogate")}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row pb-4">
                  <div className="col-md-4">
                    <div>
                      <label
                        htmlFor="surrogate_times"
                        className="form-label LabelHeading"
                      >
                        {t("Number of times")}
                        <span className="text-danger">* </span>:
                      </label>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <input
                      type="number"
                      required
                      name="surrogate_times"
                      className="form-control"
                      placeholder=""
                      value={formData.surrogate_times}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>



                <div className="row pb-4">
                  <div className=" col-md-12 ">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("List all outcomes")}
                      <span className="text-danger">*</span>
                    </label>

                    <textarea
                    rows={5}
                      type="text"
                      className="form-control"
                      placeholder=""
                      required
                      name="surrogate_outcome"
                      value={formData.surrogate_outcome}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </>}

              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Have you ever been an Egg Donor?")}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      required
                      name="ever_egg_donor"
                      id="ever_egg_donor1"
                      checked={formData.ever_egg_donor == true}
                      onChange={(e) => radioInputChange(e, true)}
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="ever_egg_donor1"
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      required
                      name="ever_egg_donor"
                      id="ever_egg_donor1"
                      checked={formData.ever_egg_donor === false}
                      onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData((prevState) => ({
                          ...prevState,
                          egg_Donor_times: "",
                          egg_Donor_outcome: ""
                        }));
                      }}
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="ever_egg_donor2"
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>
              {formData.ever_egg_donor === true &&
                <>
                  <div className="row pb-4">
                    <div className="col-md-4">
                      <div>
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label LabelHeading"
                        >
                          {t("Number of times")}
                          <span className="text-danger">* </span>:
                        </label>
                      </div>
                    </div>

                    <div className="col-md-8">
                      <input
                        type="number"
                        className="form-control"
                        placeholder=""
                        required
                        value={formData.egg_Donor_times}
                        name="egg_Donor_times"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>



                  <div className="row pb-4">
                    <div className=" col-md-12 ">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label LabelHeading"
                      >
                        {t("List all outcomes")}
                        <span className="text-danger">*</span>
                      </label>

                      <textarea
                    rows={5}
                        type="text"
                        className="form-control"
                        placeholder=""
                        required
                        value={formData.egg_Donor_outcome}
                        name="egg_Donor_outcome"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </>
              }
              <div className="row pb-4">
                <div className="col-md-6">
                  <div>
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("How many times have you been pregnant?")}
                      <span className="text-danger">* </span>:
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <input
                    type="number"
                    required
                    className="form-control"
                    placeholder=""
                    name="many_time_pregnant"
                    onChange={(e)=>{
                      if(e.target.value === '0'){
                        e.target.value = ''
                        setChildErr(true)
                      }else{
                        setChildErr(false)
                        handleInputChange(e)
                      }
                    }}
                    value={formData.many_time_pregnant}
                  />
                  {childErr?<div className="text-danger">{t('Minimum number should be 1')}</div>:''}
                </div>
              </div>

              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading mb-3"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Number of children you have delivered")}
                  <span className="text-danger">*</span>
                </label>
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      required
                      name="no_child_deliverd"
                      value={"1"}
                      checked={formData?.no_child_deliverd == "1"}
                      id="numberOfChild1"
                      onChange={noOfChildChange}
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="numberOfChild1"
                    >
                      {t("1")}
                    </label>
                  </div>
                </div>

                <div className="col">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      required
                      name="no_child_deliverd"
                      id="numberOfChild2"
                      value={"2"}
                      checked={formData?.no_child_deliverd == "2"}
                      onChange={noOfChildChange}
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="numberOfChild2"
                    >
                      {t("2")}
                    </label>
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      required
                      name="no_child_deliverd"
                      id="numberOfChild3"
                      value={"3"}
                      checked={formData?.no_child_deliverd == "3"}
                      onChange={noOfChildChange}
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="numberOfChild3"
                    >
                      {t("3")}
                    </label>
                  </div>
                </div>

                <div className="col">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      required
                      name="no_child_deliverd"
                      id="numberOfChild4"
                      value={"4"}
                      checked={formData?.no_child_deliverd == "4"}
                      onChange={noOfChildChange}
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="numberOfChild4"
                    >
                      {t("4")}
                    </label>
                  </div>
                </div>

                <div className="col">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="no_child_deliverd"
                      id="numberOfChild5"
                      value={"5"}
                      required
                      checked={formData?.no_child_deliverd == "5"}
                      onChange={noOfChildChange}
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="numberOfChild5"
                    >
                      {t("5")}
                    </label>
                  </div>
                </div>

                <div className="col">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="no_child_deliverd"
                      id="numberOfChild6"
                      value={"6"}
                      required
                      checked={formData?.no_child_deliverd == "6"}
                      onChange={noOfChildChange}
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="numberOfChild6"
                    >
                      {t("6")}
                    </label>
                  </div>
                </div>

                <div className="col">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="no_child_deliverd"
                      id="numberOfChild7"
                      value={"7"}
                      required
                      checked={formData?.no_child_deliverd == "7"}
                      onChange={noOfChildChange}
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="numberOfChild7"
                    >
                      {t("7")}
                    </label>
                  </div>
                </div>

                <div className="col">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      required
                      name="no_child_deliverd"
                      id="numberOfChild8"
                      value={"7+"}
                      checked={formData?.no_child_deliverd > 7 || formData?.no_child_deliverd == '7+'}
                      onChange={noOfChildChange}
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="numberOfChild8"
                    >
                      {t("7+")}{" "}
                    </label>
                  </div>
                </div>
              </div>

              <div className="MiddleSubHeading mb-4">
               
                  {t(
                    "Complete the following for each child you have delivered"
                  )}
                  :
                
              </div>

              {formData.child_details.map((res, key) => {
                return (
                  <div className="child1">
                    <div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="MiddleSubHeading pb-3">{`Child ${key + 1
                          }`}</span>
                        {/* <div>
                          <i
                            onClick={() => deleteChild(key)}
                            className="bi bi-trash text-danger pointer"
                          />
                        </div> */}
                      </div>

                      <div className="CheckBoxParent row pb-4">
                        <div className="col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                          <label
                            className="form-label LabelHeading"
                            htmlFor="flexRadioDefault1"
                          >
                            {t("Gender")}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="row">
                            <div className="col-md-6 ">
                              <div className="form-check">
                                <input
                                  required
                                  checked={res.gender == "Male"}
                                  className="form-check-input"
                                  type="radio"
                                  name={`childGender${key}`}
                                  id={`childGender${key}1`}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFormData((pre) => {
                                        pre.child_details[key].gender = "Male";
                                        return { ...pre };
                                      });
                                    }
                                  }}
                                />
                                <label
                                  className="form-check-label LabelSubHeading"
                                  htmlFor={`childGender${key}1`}
                                >
                                  {t("Male")}
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-check">
                                <input
                                  required
                                  checked={res.gender == "Female"}
                                  className="form-check-input"
                                  type="radio"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFormData((pre) => {
                                        pre.child_details[key].gender =
                                          "Female";
                                        return { ...pre };
                                      });
                                    }
                                  }}
                                  name={`childGender${key}`}
                                  id={`childGender${key}2`}
                                />
                                <label
                                  className="form-check-label LabelSubHeading"
                                  htmlFor={`childGender${key}2`}
                                >
                                  {t("Female")}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label LabelHeading"
                          >
                            {t("Date of Birth")}
                            <span className="text-danger">*</span>
                          </label>

                          <input
                            value={
                              res.dob && moment(res.dob).format("YYYY-MM-DD")
                            }
                            required
                            type="date"
                            max={moment().format('YYYY-MM-DD')}
                            className="form-control"
                            placeholder=""
                            onChange={(e) => {
                              setFormData((pre) => {
                                pre.child_details[key].dob = e.target.value;
                                return { ...pre };
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row pb-4">
                      <div className="col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label LabelHeading"
                        >
                          {t("Months to conceive")}
                          <span className="text-danger">*</span>
                        </label>

                        {/* <select
                          required
                          value={res.conceive}
                          onChange={(e) => {
                            setFormData((pre) => {
                              pre.child_details[key].conceive = e.target.value;
                              return { ...pre };
                            });
                          }}
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>{t("select")}</option>
                          <option value={1}>{t("07")}</option>
                          <option value={2}>{t("08")}</option>
                          <option value={3}>{t("09")}</option>
                        </select> */}

                        <input type="number"
                          required
                          value={res.conceive}
                          onChange={(e) => {
                            setFormData((pre) => {
                              pre.child_details[key].conceive = e.target.value;
                              return { ...pre };
                            });
                          }}
                          className="form-control"
                        />



                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label LabelHeading"
                        >
                          {t("Weeks at Delivery")}
                          <span className="text-danger">*</span>
                        </label>

                        <input
                          required
                          onChange={(e) => {
                            setFormData((pre) => {
                              pre.child_details[key].weeks_at_delivery =
                                e.target.value;
                              return { ...pre };
                            });
                          }}
                          value={res.weeks_at_delivery}
                          type="number"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="row pb-4">
                      <div className="col-md-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label LabelHeading  "
                        >
                          {t("Body's Weight")}{" "}
                          <span className="text-danger">*</span>
                        </label>

                        <div className="d-flex align-items-center">

                          <input type="number"
                            required
                            onChange={(e) => {
                              setFormData((pre) => {
                                pre.child_details[key].body_weight.lbs =
                                  e.target.value;
                                return { ...pre };
                              });
                            }}
                            value={res.body_weight.lbs}
                            className="form-control"
                          />
                          <span className="mx-2">
                            Pounds
                          </span>
                          {/* <select
                            required
                            onChange={(e) => {
                              setFormData((pre) => {
                                pre.child_details[key].body_weight.lbs =
                                  e.target.value;
                                return { ...pre };
                              });
                            }}
                            value={res.body_weight.lbs}
                            className="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>{t("select")}</option>
                            <option value={1}>{t("07")}</option>
                            <option value={2}>{t("08")}</option>
                            <option value={3}>{t("09")}</option>
                          </select> */}

                          {/* <select
                            required
                            value={res.body_weight.oz}
                            onChange={(e) => {
                              setFormData((pre) => {
                                pre.child_details[key].body_weight.oz =
                                  e.target.value;
                                return { ...pre };
                              });
                            }}
                            className="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>{t("select")}</option>
                            <option value={1}>{t("07")}</option>
                            <option value={2}>{t("08")}</option>
                            <option value={3}>{t("09")}</option>
                          </select> */}

                          <input type="number"
                            required
                            value={res.body_weight.oz}
                            onChange={(e) => {
                              setFormData((pre) => {
                                pre.child_details[key].body_weight.oz =
                                  e.target.value;
                                return { ...pre };
                              });
                            }}
                            className="form-control"
                          />
                          <span className="ms-2">
                            Ounce
                          </span>

                        </div>
                      </div>
                    </div>

                    <div className="row pb-4">
                      <div className="col-md-12">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label LabelHeading"
                        >
                          {t("Name of OB")}
                          <span className="text-danger">*</span>
                        </label>

                        <input
                          required
                          type="text"
                          value={res.ob_name}
                          onChange={(e) => {
                            if(e.target.value == ' '){
                              e.target.value = ''
                            }else{
                              setFormData((pre) => {
                                pre.child_details[key].ob_name = e.target.value;
                                return { ...pre };
                              });
                            }
                          }}
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="row pb-4">
                      <div className="col-md-12">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label LabelHeading"
                        >
                          {t("Delivery Hospital and State")}
                          <span className="text-danger">*</span>
                        </label>

                        <input
                          required
                          value={res.delivery_hospital_state}
                          onChange={(e) => {
                            if(e.target.value == ' '){
                              e.target.value = ''
                            }else{
                            setFormData((pre) => {
                              pre.child_details[key].delivery_hospital_state =
                                e.target.value;
                              return { ...pre };
                            });
                          }
                          }}
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="CheckBoxParent row pb-4">
                      <label
                        className="form-label LabelHeading"
                        htmlFor="flexRadioDefault1"
                      >
                        {t("This baby was")}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-3">
                        <div className="form-check">
                          <input
                            required
                            onChange={(e) => {
                              setFormData((pre) => {
                                pre.child_details[key].this_baby_was =
                                  e.target.value;
                                return { ...pre };
                              });
                            }}
                            className="form-check-input"
                            type="radio"
                            name={`this_baby_was${key}`}
                            id={`this_baby_was${key}1`}
                            value="My Own Child"
                            checked={
                              formData.child_details[key].this_baby_was ==
                              "My Own Child"
                            }
                          />
                          <label
                            className="form-check-label LabelSubHeading"
                            htmlFor={`this_baby_was${key}1`}
                          >
                            {t("My Own Child")}
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-check">
                          <input
                            required
                            className="form-check-input"
                            type="radio"
                            value="Surrogate Birth"
                            name={`this_baby_was${key}`}
                            checked={
                              formData.child_details[key].this_baby_was ==
                              "Surrogate Birth"
                            }
                            onChange={(e) => {
                              setFormData((pre) => {
                                pre.child_details[key].this_baby_was =
                                  e.target.value;
                                return { ...pre };
                              });
                            }}
                            id={`this_baby_was${key}2`}
                          />
                          <label
                            className="form-check-label LabelSubHeading"
                            htmlFor={`this_baby_was${key}2`}
                          >
                            {t("Surrogate Birth")}
                          </label>
                        </div>
                      </div>
                    </div>
                    {key > 6?<div className="w-100 mb-4 text-end">
                      <button className="btn btn-sm btn-danger" type="button" onClick={()=>removeChild(key)}>{t('Remove child')}</button>
                    </div>:''}
                  </div>
                );
              })}

              {formData.no_child_deliverd > 7 && <div className="d-flex justify-content-end">
                <button
                  className="btn_fill"
                  type="button"
                  onClick={addMoreChild}
                >
                  + Add More Child
                </button>
              </div>}
            </div>

            <div className="Second_Section">
              <h1 className="SubHeading ">
                {t("Reproductive Information (Continued)")}
              </h1>
              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Do you have physical custody of all your children?")}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      checked={formData.physical_custody_children == true}
                      onChange={(e) => radioInputChange(e, true)}
                      name="physical_custody_children"
                      id="physical_custody_children1"
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="physical_custody_children1"
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  {" "}
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      checked={formData.physical_custody_children === false}
                      onChange={(e) => radioInputChange(e, false)}
                      name="physical_custody_children"
                      id="physical_custody_children2"
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="physical_custody_children2"
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Do any of your children have physical health problems?")}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => radioInputChange(e, true)}
                      name="child_physical_health_problem"
                      checked={formData.child_physical_health_problem == true}
                      id="child_physical_health_problem1"
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="child_physical_health_problem1"
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  {" "}
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData((prevState) => ({
                          ...prevState,
                          explain_child_physical_problem: "",
                        }));
                      }}
                      name="child_physical_health_problem"
                      checked={formData.child_physical_health_problem === false}
                      id="child_physical_health_problem2"
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="child_physical_health_problem2"
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row pb-4">
                {formData.child_physical_health_problem == true &&
                  <div className="col-md-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("Please Explain")}
                      <span className="text-danger">*</span>
                    </label>

                    <textarea
                    rows={5}
                      required
                      type="text"
                      className="form-control"
                      name="explain_child_physical_problem"
                      value={formData.explain_child_physical_problem}
                      onChange={handleInputChange}
                      placeholder=""
                    />
                  </div>}
              </div>
              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t(
                    "Do any of your children have psychological or behavioral problems?"
                  )}
                  <span className="text-danger">*</span>
                </label>

                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        radioInputChange(e, true)

                      }}
                      name="child_psychological_problem"
                      checked={formData.child_psychological_problem == true}
                      id="child_psychological_problem1"
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="child_psychological_problem1"
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  {" "}

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData((prevState) => ({
                          ...prevState,
                          explain_child_psychological_problem: "",
                        }));
                      }}
                      name="child_psychological_problem"
                      checked={formData.child_psychological_problem === false}
                      id="child_psychological_problem2"
                      required
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="child_psychological_problem2"
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row pb-4">
                {formData.child_psychological_problem == true &&
                  <div className="col-md-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("Please list date(s) and explain")}
                      <span className="text-danger">*</span>
                    </label>

                    <textarea
                    rows={5}
                      type="text"
                      className="form-control"
                      name="explain_child_psychological_problem"
                      value={formData.explain_child_psychological_problem}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                }
              </div>
              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Have you ever placed a child for adoption?")}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => radioInputChange(e, true)}
                      checked={formData.ever_placed_child_adoption == true}
                      name="ever_placed_child_adoption"
                      id="ever_placed_child_adoption1"
                      required
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="ever_placed_child_adoption1"
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData((prevState) => ({
                          ...prevState,
                          explain_placed_child_adoption: "",
                        }));

                      }}
                      name="ever_placed_child_adoption"
                      id="ever_placed_child_adoption2"
                      required
                      checked={formData.ever_placed_child_adoption === false}
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="ever_placed_child_adoption2"
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row pb-4">
                {formData.ever_placed_child_adoption === true &&
                  <div className="col-md-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("Please list date(s) of adoption and explain")}
                      <span className="text-danger">*</span>
                    </label>

                    <textarea
                    rows={5}
                      required
                      className="form-control"
                      name="explain_placed_child_adoption"
                      value={formData.explain_placed_child_adoption}
                      onChange={handleInputChange}
                    />
                  </div>}
              </div>

              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Do you have any deceased children?")}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => radioInputChange(e, true)}
                      checked={formData.deceased_child == true}
                      name="deceased_child"
                      id="deceased_child1"
                      value="true"
                    // checked={!formData.spouse_legal_cases}
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="deceased_child1"
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  {" "}
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData((prevState) => ({
                          ...prevState,
                          explain_deceased_child: "",
                        }));
                      }}
                      name="deceased_child"
                      checked={formData.deceased_child === false}
                      id="deceased_child2"
                      value="false"
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="deceased_child2"
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>
              {formData.deceased_child == true && (
                <div className="row pb-4">
                  <div className="col-md-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("Please list date(s) and explain")}
                      <span className="text-danger">*</span>
                    </label>

                    <textarea
                    rows={5}
                      required
                      className="form-control"
                      name="explain_deceased_child"
                      value={formData.explain_deceased_child}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              )}

              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Do you have any adopted or foster children?")}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => radioInputChange(e, true)}
                      checked={formData.any_child_adoption == true}
                      name="any_child_adoption"
                      id="any_child_adoption1"
                      value="true"
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="any_child_adoption1"
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  {" "}
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData((prevState) => ({
                          ...prevState,
                          explain_child_adoption: "",
                        }));
                      }}
                      name="any_child_adoption"
                      checked={formData.any_child_adoption === false}
                      id="any_child_adoption2"
                      value="false"
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="any_child_adoption2"
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>
              {formData.any_child_adoption == true && (
                <div className="row pb-4">
                  <div className="col-md-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("Please list date(s) and explain")}
                      <span className="text-danger">*</span>
                    </label>

                    <textarea
                    rows={5}
                      required
                      className="form-control"
                      name="explain_child_adoption"
                      value={formData.explain_child_adoption}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              )}

              <div className="CheckBoxParent row pb-4">
                <div className="col-md-6">
                  <label
                    className="form-label LabelHeading"
                    htmlFor="flexRadioDefault1"
                  >
                    {t("Are you currently breastfeeding?")}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          type="radio"
                          onChange={(e) => radioInputChange(e, true)}
                          name="breastfeeding"
                          checked={formData.breastfeeding == true}
                          id="breastfeeding1"
                          value="true"
                        />
                        <label
                          className="form-check-label LabelSubHeading"
                          htmlFor="breastfeeding1"
                        >
                          {t("Yes")}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      {" "}
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          type="radio"
                          onChange={(e) => {
                            radioInputChange(e, false)
                            setFormData((prevState) => ({
                              ...prevState,
                              breastfeeding_completion_date: "",
                            }));
                          }}
                          name="breastfeeding"
                          id="breastfeeding2"
                          checked={formData.breastfeeding === false}
                          value="false"
                        />
                        <label
                          className="form-check-label LabelSubHeading"
                          htmlFor="breastfeeding2"
                        >
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {formData.breastfeeding == true && (
                  <div className="col-md-6">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("Add Estimated completion date ")}
                      <span className="text-danger">*</span>
                    </label>

                    <input
                      required
                      type="Date"
                      name="breastfeeding_completion_date"
                      value={moment(formData.breastfeeding_completion_date).format("YYYY-MM-DD")}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                )}
              </div>

              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t(
                    "Have you ever had any form of fertility treatment (for ANY reason: surrogacy, egg donation, or personal)?"
                  )}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => radioInputChange(e, true)}
                      name="fertility_treatment"
                      checked={formData.fertility_treatment == true}
                      id="fertility_treatment1"
                      value="true"
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="fertility_treatment1"
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  {" "}
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData((prevState) => ({
                          ...prevState,
                          explain_fertility_treatment: "",
                        }));
                      }}
                      name="fertility_treatment"
                      id="fertility_treatment2"
                      checked={formData.fertility_treatment === false}
                      value="false"
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="fertility_treatment2"
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>
              {formData.fertility_treatment == true && (
                <div className="row pb-4">
                  <div className="col-md-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("Please list date(s) and explain")}
                      <span className="text-danger">*</span>
                    </label>

                    <textarea
                    rows={5}
                      required
                      name="explain_fertility_treatment"
                      value={formData.explain_fertility_treatment}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              )}
              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Have you ever delivered a premature baby?")}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => radioInputChange(e, true)}
                      name="delivered_premature_baby"
                      checked={formData.delivered_premature_baby == true}
                      id="delivered_premature_baby1"
                      value="true"
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="delivered_premature_baby1"
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      required
                      onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData((prevState) => ({
                          ...prevState,
                          explain_delivered_premature_baby: "",
                        }));
                      }}
                      name="delivered_premature_baby"
                      id="delivered_premature_baby2"
                      checked={formData.delivered_premature_baby === false}
                      value="false"
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="delivered_premature_baby2"
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>
              {formData.delivered_premature_baby == true && (
                <div className="row pb-4">
                  <div className="col-md-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("List date(s) and weeks gestation at delivery")}
                      <span className="text-danger">*</span>
                    </label>

                    <textarea
                    rows={5}
                      required
                      name="explain_delivered_premature_baby"
                      value={formData.explain_delivered_premature_baby}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              )}
              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Have you ever had a miscarriage?")}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => radioInputChange(e, true)}
                      checked={formData.miscarriage == true}
                      name="miscarriage"
                      id="miscarriage1"
                      value="true"
                      required
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="miscarriage1"
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData((prevState) => ({
                          ...prevState,
                          explain_miscarriage: "",
                        }));
                      }}
                      name="miscarriage"
                      id="miscarriage2"
                      checked={formData.miscarriage === false}
                      value="false"
                      required
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="miscarriage2"
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>

              {formData.miscarriage == true && (
                <div className="row pb-4">
                  <div className="col-md-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("List date(s) and weeks gestation at miscarriage")}
                      <span className="text-danger">*</span>
                    </label>

                    <textarea
                    rows={5}
                      name="explain_miscarriage"
                      value={formData.explain_miscarriage}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder=""
                      required
                    />
                  </div>
                </div>
              )}
              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Have you ever had a stillborn baby?")}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => radioInputChange(e, true)}
                      name="ever_stillborn_baby"
                      checked={formData.ever_stillborn_baby == true}
                      id="ever_stillborn_baby1"

                      required
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="ever_stillborn_baby1"
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData((prevState) => ({
                          ...prevState,
                          explain_stillborn_baby: "",
                        }));
                      }}
                      name="ever_stillborn_baby"
                      id="ever_stillborn_baby2"
                      checked={formData.ever_stillborn_baby === false}
                      required
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="ever_stillborn_baby2"
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>

              {formData.ever_stillborn_baby == true && (
                <div className="row pb-4">
                  <div className="col-md-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("List date(s) and weeks gestation at birth")}
                      <span className="text-danger">*</span>
                    </label>

                    <textarea
                    rows={5}
                      name="explain_stillborn_baby"
                      value={formData.explain_stillborn_baby}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder=""
                      required
                    />
                  </div>
                </div>
              )}
              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Have you ever had an abortion?")}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => radioInputChange(e, true)}
                      name="ever_abortion"
                      checked={formData.ever_abortion === true}
                      id="ever_abortion1"
                      required
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="ever_abortion1"
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData((prevState) => ({
                          ...prevState,
                          explain_date_abortion: "",
                        }));

                      }}
                      name="ever_abortion"
                      checked={formData.ever_abortion === false}
                      id="ever_abortion2"
                      required
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="ever_abortion2"
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row pb-4">
                {formData.ever_abortion === true &&
                  <div className="col-md-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("List date(s)")}
                      <span className="text-danger">*</span>
                    </label>

                    <textarea
                    rows={5}
                      name="explain_date_abortion"
                      value={formData.explain_date_abortion}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder=""
                      required
                    />
                  </div>}
              </div>

              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t(
                    "Have you ever had any pregnancy and/or delivery complications? Select all applicable:"
                  )}
                  <span className="text-danger">*</span>
                </label>

                {SURROGATE_PREGNANCY_COMPLICATIONS.map((res, key) => {
                  return (
                    <div key={key} className="col-md-3 pb-2">
                      <div className="form-check">
                        <input
                          checked={formData?.pregnancy_delivery_complications?.includes(res)}
                          className="form-check-input"
                          onChange={multipleCheckBoxChange}
                          value={res}
                          required={formData?.pregnancy_delivery_complications?.length == 0}
                          type="checkbox"
                          name="pregnancy_delivery_complications"
                          id={`pregnancy_delivery_complications${key}`}
                        />
                        <label
                          className="form-check-label LabelSubHeading"
                          htmlFor={`pregnancy_delivery_complications${key}`}
                        >
                          {t(res)}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Have you ever had a cesarean section?")}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => radioInputChange(e, true)}
                      name="ever_cesarean_section"
                      checked={formData.ever_cesarean_section == true}
                      id="ever_cesarean_section1"
                      value="true"
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="ever_cesarean_section1"
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData((prevState) => ({
                          ...prevState,
                          explain_cesarean_section: "",
                          reason_cSection: ""
                        }));
                      }}
                      name="ever_cesarean_section"
                      id="ever_cesarean_section2"
                      checked={formData.ever_cesarean_section === false}
                      required
                    />
                    <label
                      className="form-check-label LabelSubHeading"
                      htmlFor="ever_cesarean_section2"
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>
              {formData.ever_cesarean_section && (
                <>
                  <div className="row pb-4">
                    <div className="col-md-12">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label LabelHeading"
                      >
                        {t("List date(s)")}
                        <span className="text-danger">*</span>
                      </label>

                      <textarea
                      rows={5}
                        name="explain_cesarean_section"
                        value={formData.explain_cesarean_section}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                  <div className="row pb-4">
                    <div className="col-md-12">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label LabelHeading"
                      >
                        {t("What was the reason for your C-section(s)?")}
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        name="reason_cSection"
                        value={formData.reason_cSection}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="CheckBoxParent row pb-4">
                <div className="col-md-6">
                  <label
                    className="form-label LabelHeading"
                    htmlFor="flexRadioDefault1"
                  >
                    {t("Do you have regular menstrual cycles?")}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          onChange={(e) => radioInputChange(e, true)}
                          name="regular_menstrual_cycles"
                          checked={formData.regular_menstrual_cycles == true}
                          id="regular_menstrual_cycles1"
                          required
                        />
                        <label
                          className="form-check-label LabelSubHeading"
                          htmlFor="regular_menstrual_cycles1"
                        >
                          {t("Yes")}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      {" "}

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          onChange={(e) => {
                            radioInputChange(e, false)
                            setFormData((prevState) => ({
                              ...prevState,
                              menstrual_cycles_days: "",
                            }));
                          }}
                          name="regular_menstrual_cycles"
                          id="regular_menstrual_cycles2"
                          required
                          checked={formData.regular_menstrual_cycles === false}
                        />
                        <label
                          className="form-check-label LabelSubHeading"
                          htmlFor="regular_menstrual_cycles2"
                        >
                          {t("No")}
                        </label>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  {formData.regular_menstrual_cycles === true &&
                    <>
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label LabelHeading"
                      >
                        {t("How many days per cycle?")}
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        name="menstrual_cycles_days"
                        type="number"
                        required
                        value={formData.menstrual_cycles_days}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder=""
                      />
                    </>
                  }
                </div>
              </div>

              <div className="CheckBoxParent row pb-4">
                <label
                  className="form-label LabelHeading"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Current method(s) of birth control")}
                  <span className="text-danger">*</span>
                </label>

                {METHOD_OF_BIRTH_CONTROL.map((res, key) => {
                  return (
                    <div key={key} className="col-md-3 pb-2">
                      <div className="form-check">
                        <input
                          onChange={multipleCheckBoxChange}
                          name="birth_control_method"
                          className="form-check-input"
                          value={res}
                          type="checkbox"
                          required={formData?.birth_control_method?.length == 0}
                          checked={formData?.birth_control_method?.includes(res)}
                          id={`birth_control_method${key}`}
                        />
                        <label
                          className="form-check-label LabelSubHeading"
                          htmlFor={`birth_control_method${key}`}
                        >
                          {t(res)}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="row pb-4">
                <div className="col-md-6">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label LabelHeading"
                  >
                    {t("Date you began using method(s) of birth control")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    name="birth_control_method_begin"
                    type="Date"
                    required
                    className="form-control"
                    onChange={handleInputChange}
                    value={
                      formData.birth_control_method_begin &&
                      moment(formData.birth_control_method_begin).format(
                        "YYYY-MM-DD"
                      )
                    }
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            <div className="ButtonForm mt-3">
              <button
                className="btn_non_fill"
                type="button"
                onClick={navigation.previous}
              >
                {t("Previous")}
              </button>
              <button className="btn_fill" type="submit">
                {t("Next")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
