import React from 'react'
import style from "./InternalNews.module.css"
import { useTranslation } from "react-i18next";
import { useState } from 'react'
import { Link } from "react-router-dom"

const InternalNews = () => {


    let { t } = useTranslation();

    const [cards1, setCards1] = useState([
        {
            title:
                "Musk helped his female subordinate Zilis give birth to twins by sperm donation",
            image: "top_news_pic_one.png",
            source: "https://www.wenxuecity.com/news/2024/02/19/125428411.html",
            source_name: "wenxuecity.com",
            date: "feb 19,2024",
            isThirdPartyUrl: true,
        },
        {
            title:
                "Elon Musk's dad reveals he was asked to 'donate his sperm' as 'man who created Elon",
            image: "top_news_pic_two.png",
            source:
                "https://www.dailystar.co.uk/news/us-news/elon-musks-dad-reveals-asked-30818420",
            source_name: "dailystar.co",
            date: "aug 30,2023",
            isThirdPartyUrl: true,
        },
        {
            title: "Chinese rich guy makes 100 surrogate babies",
            image: "top_news_pic_three.png",
            source: "https://www.youtube.com/watch?v=HrmRgip-APQ",
            source_name: "youtube",
            date: "may 14,2022",
            isThirdPartyUrl: true,
        },
        {
            title: "How to Spend the Perfect Day on Croatia’s Most Magical Island",
            image: "top_news_pic_four.png",
            source: "https://www.opportunitiesforafricans.com/how-to-spend-the-perfect-day-on-croatias-most-magical-island/",
            source_name: "youtube",
            date: "may 14,2022",
            isThirdPartyUrl: true,
        },

        {
            title: "How to Spend the Perfect Day on Croatia’s Most Magical Island",
            image: "top_news_pic_five.png",
            source: "https://www.opportunitiesforafricans.com/how-to-spend-the-perfect-day-on-croatias-most-magical-island/",
            source_name: "youtube",
            date: "may 14,2022",
            isThirdPartyUrl: true,
        },
    ]);

    const [cards2, setCards2] = useState([
        {
            title:
                "Musk helped his female subordinate Zilis give birth to twins by sperm donation",
            image: "top_news_pic_one.png",
            source: "https://www.wenxuecity.com/news/2024/02/19/125428411.html",
            source_name: "wenxuecity.com",
            date: "feb 19,2024",
            isThirdPartyUrl: true,
        },
        {
            title:
                "Elon Musk's dad reveals he was asked to 'donate his sperm' as 'man who created Elon",
            image: "top_news_pic_two.png",
            source:
                "https://www.dailystar.co.uk/news/us-news/elon-musks-dad-reveals-asked-30818420",
            source_name: "dailystar.co",
            date: "aug 30,2023",
            isThirdPartyUrl: true,
        },
        {
            title: "Chinese rich guy makes 100 surrogate babies",
            image: "top_news_pic_three.png",
            source: "https://www.youtube.com/watch?v=HrmRgip-APQ",
            source_name: "youtube",
            date: "may 14,2022",
            isThirdPartyUrl: true,
        },
        {
            title: "How to Spend the Perfect Day on Croatia’s Most Magical Island",
            image: "top_news_pic_four.png",
            source: "https://www.opportunitiesforafricans.com/how-to-spend-the-perfect-day-on-croatias-most-magical-island/",
            source_name: "youtube",
            date: "may 14,2022",
            isThirdPartyUrl: true,
        },

        {
            title: "How to Spend the Perfect Day on Croatia’s Most Magical Island",
            image: "top_news_pic_five.png",
            source: "https://www.opportunitiesforafricans.com/how-to-spend-the-perfect-day-on-croatias-most-magical-island/",
            source_name: "youtube",
            date: "may 14,2022",
            isThirdPartyUrl: true,
        },
    ]);



    const [cards, setCards] = useState([
        {
            title:
                "Musk helped his female subordinate Zilis give birth to twins by sperm donation",
            image: "news_pic_one.png",
            source: "https://www.wenxuecity.com/news/2024/02/19/125428411.html",
            source_name: "wenxuecity.com",
            date: "feb 19,2024",
            isThirdPartyUrl: true,
        },
        {
            title:
                "Elon Musk's 77-Year-Old Dad Says He's Asked To Donate His Sperm...",
            image: "news_pic_two.png",
            source:
                "https://www.dailystar.co.uk/news/us-news/elon-musks-dad-reveals-asked-30818420",
            source_name: "dailystar.co",
            date: "aug 30,2023",
            isThirdPartyUrl: true,
        },
        {
            title: "Chinese rich guy make 100 Surrogate babies",
            image: "news_pic_three.png",
            source: "https://www.youtube.com/watch?v=HrmRgip-APQ",
            source_name: "youtube",
            date: "may 14,2022",
            isThirdPartyUrl: true,
        },
    ]);


    return (
        <div>

            <div className={`${style.main_container}`}>
                <div className={`${style.news_section}`}>
                    <div className='lufga_36px'>{t("Experienced Surrogates Can Earn Up to $104,000 in Their Second Surrogacy Journey")}</div>
                    <div className={`${style.image_container}`}>
                        <img src='/images/news_pic_four.png'></img>
                    </div>
                    <div className='lufga_16px' style={{ fontWeight: '600', margin: '1rem 0' }}>{t("Average Compensation for Surrogates")}</div>
                    <div className={`${style.news_content_section}`}>

                        <div className={`${style.lufga_16px}`}>{t("While surrogacy is a profoundly rewarding experience, it entails a multifaceted journey involving both medical and legal intricacies, demanding considerable time and effort. Prospective surrogates naturally seek insight into the average compensation they can expect. Across most surrogacy agencies in the U.S., intended parents typically provide compensation structured in three main forms, acknowledging the dedication, time, and sacrifices involved:")}</div>

                        <div>
                            <ol className={`${style.lufga_16px}`}>
                                <li>
                                    {t("Competitive base pay throughout the pregnancy.")}
                                </li>
                                <li>
                                {t("Additional stipends, varying based on different aspects of the surrogacy journey.")}                            </li>
                                <li>
                                    {t("Comprehensive coverage of all support and reimbursement for expenses incurred during the process.")}                                </li>
                            </ol>
                        </div>

                        <div className={`${style.lufga_16px}`}>
                        {t("Variations in Compensation Rates Based on Surrogacy Experience The average compensation for surrogates fluctuates depending on whether one is embarking on a repeat surrogacy journey or engaging as a first-time gestational surrogate. With each surrogacy agency adopting its unique approach, consulting a surrogacy professional remains the most reliable means to obtain accurate information tailored to individual circumstances.")}
                        </div>

                        <div className={`${style.lufga_16px}`}>
                        {t("Average Compensation for First-Time Gestational Surrogates Researching surrogate compensation often reveals promises of flat rates starting at $25,000 or higher for gestational carriers. However, caution is advised when interpreting such figures, as some agencies may present inflated numbers. It's essential to discern the competitive base pay compensations realistically offered to first-time surrogates. At Creative Family Connections, for instance, we provide transparent information about our compensation rates.")}
                        </div>

                        <div className={`${style.lufga_16px}`}>
                        {t("A guiding principle is to exercise skepticism if an offer appears too good to be true. Base pays typically range from $30,000 to $55,000, with additional reimbursement for expenses, contingent upon the specific details of the surrogacy journey.")}
                        </div>

                        <div className={`${style.lufga_16px}`}>
                        {t("Covering Surrogacy-Related Expenses Intended parents assume responsibility for covering various expenses incurred by the surrogate, encompassing medical procedures, screening costs, and legal fees. Additionally, surrogates receive a monthly allowance. This base compensation is supplemented by stipends for maternity clothing, transfer, and meal expenses during medical screenings and embryo transfers, among others.")}
                        </div>

                        <div className={`${style.lufga_16px}`}>{t("The details of surrogacy-related expenses covered by intended parents should be established early in the process and delineated within the surrogacy legal contract.")}</div>

                        <div className={`${style.lufga_16px}`}>{t("Monthly allowances during pregnancy typically encompass expenses such as mileage to and from medical appointments, telephone calls or shipping for legal documents, prenatal vitamins, and other health supplies. Furthermore, gestational surrogates receive compensation to offset lost income or limited paid time off due to surrogacy appointments. Depending on the contractual agreement, additional compensation may be provided for specific aspects of the surrogacy medical process, such as stipends for mock cycles.")}</div>

                        <div className={`${style.lufga_16px}`}>{t("It's crucial to note that while compensation is an essential aspect of surrogacy, the journey itself encompasses far more than just financial remuneration. Surrogates play a pivotal role in helping individuals or couples achieve their dreams of parenthood, fostering deep connections and leaving a lasting impact on both parties involved. Beyond the monetary aspects, the emotional fulfillment derived from knowing you've helped create a family is immeasurable. Surrogacy is a profoundly rewarding experience that transcends monetary value, offering surrogate mothers a sense of empowerment, pride, and joy that extends far beyond the duration of the pregnancy journey.")}
                        </div>
                    </div>
                </div>



                <div className={`${style.right_side_section}`}>
                    <div className={`${style.cards_container}`}>
                        <div className='lufga_18px'>Top Post</div>
                        {cards1?.map((card, index) => (
                            <Link style={{textDecoration:'none',color:'black'}} to={card.isThirdPartyUrl?card.source:""}
                            target={card.isThirdPartyUrl?"_blank":"_self"}>
                            <div key={index} className={`${style.card_one}`}>
                                <div
                                    className={`${style.card_img_container}`}>
                                    <img src={`/images/${card.image}`} alt='card_img'></img>
                                </div>
                                <div className={`${style.card_body} lufga_16px`}>
                                    {t(card.title)}
                                </div>
                            </div>
                            </Link>
                        ))}
                    </div>

                    <div className={`${style.cards_container}`}>
                        <div className='lufga_18px'>Latest Post</div>
                        {cards2?.map((card, index) => (
                            <Link style={{textDecoration:'none',color:'black'}} to={card.isThirdPartyUrl?card.source:""}
                            target={card.isThirdPartyUrl?"_blank":"_self"}>
                            <div key={index} className={`${style.card_one}`}>
                                <div
                                    className={`${style.card_img_container}`}>
                                    <img src={`/images/${card.image}`} alt='card_img'></img>
                                </div>
                                <div className={`${style.card_body} lufga_16px`}>
                                    {t(card.title)}
                                </div>
                            </div>
                            </Link>
                        ))}
                    </div>
                    
                </div>


            </div>

            <div>
                <div style={{ padding: '0 4rem' }} className='lufga_36px'>{t("Read News Article")}</div>
                <div style={{ padding: '0rem 6rem 6rem 6rem' }}>
                    <div className="row px-4 mt-5 justify-content-center">
                        {cards?.map((card, index) => (
                            <div
                                key={index}
                                className="col-12 col-sm-6 col-md-4 p-lg-2 p-md-2 p-sm-2 p-2"
                            >
                                <div className={`${style.card_wrapper} h-100`}>
                                    <Link
                                        style={{ height: '100%' }}
                                        to={card.isThirdPartyUrl ? card.source : "internalnews"}
                                        target={card.isThirdPartyUrl?'_blank':'_self'}
                                        className="d-flex align-items-end"
                                    >
                                        <div className={`card ${style.card}`}>
                                            <img
                                                className="card-img-top"
                                                src={`/images/${card.image}`}
                                                alt={t("Card image cap")}
                                            />
                                            <div className="card-body">
                                                <div className="lufga_18px px-2">{t(card.title)}</div>
                                                <div className="lufga_18px d-flex justify-content-between mt-3">
                                                    <div className="d-flex align-items-end">
                                                        <span className="custom_gray lufga_12px d-flex align-items-end">
                                                            {t(card.source_name)}
                                                        </span>
                                                    </div>
                                                    <div className="lufga_12px custom_gray d-flex align-items-end">
                                                        {t(card.date)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>



        </div>
    )
}

export default InternalNews    