export const userId=()=>{
    let userId=null;
    let userData=localStorage.getItem("userData");
    if(userData){
        userId=JSON.parse(userData).userId
    }
    return userId
}
export const userData=()=>{
    let userData=null;

    let user= localStorage.getItem("userData")
    if(user){
        userData=JSON.parse(user)
    }
    return userData
}