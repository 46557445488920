import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader/Loader";
import { forgetPasswordChange } from "../../../service/Apis";
import { useNavigate, useParams } from "react-router";
import style from "./IntendedParents.module.css";


export const EnterPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(true);
  let { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [matchError, setMatchError] = useState("");

  const [formData, setFormData] = useState({
    password: "",
  });

  const [passwordsMatch, setPasswordMatch] = useState(true);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!passwordsMatch) {
      setMatchError("Password doesn't match")
      return false;
    }
    setLoader(true);

    try {
      let res = await forgetPasswordChange(id, {
        newPassword: formData.password,
      });
      setLoader(false);
      setPasswordChanged(false);
    } catch (err) {
      console.log(err);
      alert("Error");
      setLoader(false);
    }
  }

  function inpChange(e) {
    let { name, value } = e.target;

    if (name === "password") {
      if (value.trim() !== "") {
        if (
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{":;'?/><,.\[\]\\]).{8,}$/.test(
            value
          )
        ) {
          setError2("");
          setError("");
          setError3("");
        } else {
          if (value.length < 8) {
            setError2(t("Password must have at least 8 characters"));
          } else {
            setError2("");
          }

          if (!/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
            setError(
              t("A mix of both uppercase and lowercase letters is required")
            );
          } else {
            setError("");
          }

          if (!/[!@#$%^&*()_+}{":;'?/><,.\[\]\\]/.test(value)) {
            setError3(
              t(
                "Inclusion of at least one special character (e.g., @ # ?) is required"
              )
            );
          } else {
            setError3("");
          }
        }
      } else {
        setError("");
        setError2("");
        setError3("");
      }
    } else if (name == "confirmPassword") {
      setMatchError("")
      if (formData.password === value) {
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
      }
    }

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }

  return (
    <>
      <Loader open={loader} />
      <Dialog
        closable={false}
        visible={true}
        onHide={() => { }}
        style={{ width: passwordChanged ? "60vw" : "45vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        {passwordChanged ? (
          <div className="DialogParent">
            <div className="gap-3">
              <form onSubmit={handleSubmit}>
                <h1 className="SignUphedaing text-center mb-5">
                  {t("Forgot Password")}
                </h1>

                <div className="row">
                  <div className=" col-md-6">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {t("Password")}
                    </label>
                    <div className="p-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        required
                        placeholder={t("Enter Password")}
                        name="password"
                        value={formData.password}
                        onChange={inpChange}
                      />
                      {!showPassword ? (
                        <i
                          class="bi bi-eye-slash View pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        ></i>
                      ) : (
                        <i
                          className="bi bi-eye View pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        ></i>
                      )}
                    </div>
                  </div>
                  <div className=" col-md-6">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {t("Confirm Password")}
                    </label>
                    <div className="p-relative">
                      <input
                        style={{
                          border: passwordsMatch ? "" : "1px solid red",
                        }}
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control"
                        required
                        value={formData.confirmPassword}
                        name="confirmPassword"
                        onChange={inpChange}
                        placeholder={t("Enter Password")}
                      />
                      {!showConfirmPassword ? (
                        <i
                          class="bi bi-eye-slash View pointer"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        ></i>
                      ) : (
                        <i
                          className="bi bi-eye View pointer"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        ></i>
                      )}
                    </div>
                  </div>
                </div>

                {matchError && <div className="text-danger mt-1 d-flex justify-content-start justify-content-md-end">
                  <li className={`${style.width_not_match}`}>{matchError}</li>
                </div>
                }
                {error && <li className="text-danger mt-1">{error}</li>}
                {error2 && <li className="text-danger mt-1">{error2}</li>}
                {error3 && <li className="text-danger mt-1">{error3}</li>}
                {/* <div className="d-flex justify-content-between mt-5">
                                <div className="BackLogin fs-5 pointer" onClick={() => navigate(`/signIn`,{state:"parents"})}>{t("Back to Login Screen")}</div>
                                <div>
                                    <span className="TextCustomBottom fs-5">{t("Don’t have any account?")}</span>&nbsp;
                                    <span className="Create fs-5 pointer" onClick={() => navigate(`/intendedparents/signUp/parents`)}>{t("Create New")}</span>
                                </div>
                            </div> */}

                <div className="ButtonForm mt-5">
                  <button
                    className="CencelButton"
                    type="button"
                    onClick={() => navigate(`/signIn`, { state: "parents" })}
                  >
                    {t("Cancel")}
                  </button>
                  <button className="SaveButton" type="submit"
                    disabled={error !== "" || error2 !== "" || error3 !== ""}
                  >
                    {t("Reset Password")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <>
            <div className="py-4">
              <div className={`d-flex justify-content-center`}>
                <img src="/images/Tick.png" style={{ width: "120px" }} alt="" />
              </div>
              <div className={`d-flex lufga_30px justify-content-center ${style.heading}`}>
                {t("Congratulations")}!
              </div>
              <div
                className={`d-flex justify-content-center mt-2 ${style.heading}`}
              >
                {t("Your password has been changed")}
              </div>

              <div className="mt-4 d-flex justify-content-center">
                <button
                  className={`SaveButton`}
                  onClick={() => navigate(`/signIn`, { state: "parents" })}
                >
                  {t("Back to Login")}
                </button>
              </div>
            </div>
          </>
        )}
      </Dialog>
    </>
  );
};
