import { Dialog } from 'primereact/dialog'
import React from 'react'
import { VscChromeClose } from "react-icons/vsc";
import { useNavigate } from 'react-router';
import { t } from 'i18next';


export default function AlertModalE() {
    const navigate = useNavigate();
    return (
        <>
            <Dialog
                closable={false}
                visible={true}
                onHide={() => { }}
                style={{ width: "40vw", border: '3px solid rgba(24, 124, 139, 1)', borderRadius: '25px' }}
                contentClassName={`p-md-4 p-sm-4 p-3`}
                breakpoints={{ "960px": "60vw", "641px": "95vw" }}
            >
                <div className='w-100 d-flex justify-content-end'><VscChromeClose className='fs-4 pointer' onClick={() => navigate('/embryodonor')} /></div>
                <div className='py-2'>
                    <div className={`d-flex justify-content-center`}>
                        <img src='/images/Tick.png' style={{ width: '100px' }} alt='' />
                    </div>
                    <div className={`mt-4 fs-5 text-black mb-2 text-center`}>{t('Please check and verify your email to proceed and complete your signup.')}</div>
                    {/* <div className='mt-4 d-flex justify-content-center'>
                        <button className={`btn_fill`} onClick={()=>navigate(`/embryodonor`)}>Back to Home</button>
                    </div> */}
                </div>


            </Dialog>
        </>
    )
}
