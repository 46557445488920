import { Dialog } from "primereact/dialog";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, useParams } from "react-router";
import {
  sendOptOnPhoneNumber,
  signUpUser,
  verifyEmailAddress,
  verifyOptByPhoneNumber,
  verifyPhoneNumber,
} from "../../../service/Apis";
import { LuCheckCircle } from "react-icons/lu";
import { FaCheck } from "react-icons/fa";
import { baseUrl } from "../../../service/Endpoint";
import { io } from "socket.io-client";
import PhoneInput from "react-phone-number-input";
import Loader from "../../../components/Loader/Loader";
const embryo_donor = "embryo_donor";
const socket = io(baseUrl);
const EmbryoDonorSignup = () => {
    // const role = useParams();
    // console.log(role)
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { role } = useParams();
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const formRef = useRef({
    fullName: "",
    mobile_No: "",
    email: "",
    password: "",
    role: "",
    agree_teams_policy: false,
  });
  const [codeValue, setCodeValue] = useState("");
  const [term, setTerm] = useState(false);
  const [optErr, setOptErr] = useState("");
  const [getCodeLoader, setGetCodeLoader] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [verify, setVerify] = useState(false);
  const [verifyNumber, setVerifyNumber] = useState(true);
  const [verifyBtn, setVerifyBtn] = useState(0);
  const [isCodeSend, setIsCodeSend] = useState(false);
  const [isContactVerified, setIsContactVerified] = useState(false);

  socket.on("emailVerfyDone", (res) => {
    if (formRef.current.email === res.email) {
      setVerify(res.verify);
    }
  });

  let { t } = useTranslation();
  const [formData, setFormData] = useState({
    fullName: "",
    mobile_No: "",
    email: "",
    password: "",
    role: embryo_donor,
    agree_teams_policy: true,
    confirmPassword: "",
  });

  let [captchaValue, setCaptchaValue] = useState(null);
  let intervel;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordMatch] = useState(true);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [phoneNumberErr, setPhoneNumberErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailerr, setEmailerr] = useState("");
  const [secCount, setSecCount] = useState(60);
  const [oterOption, setOtherOption] = useState(false);
  const [selectHere, setSelectHere] = useState("");

  function inpChange(e) {
    let { name, value } = e.target;

    if (name === "password") {
      if (value.trim() !== "") {
        if (
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{":;'?/><,.\[\]\\]).{8,}$/.test(
            value
          )
        ) {
          setError2("");
          setError("");
          setError3("");
        } else {
          if (value.length < 8) {
            setError2(t("Password must have at least 8 characters"));
          } else {
            setError2("");
          }

          if (!/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
            setError(
              t("A mix of both uppercase and lowercase letters is required")
            );
          } else {
            setError("");
          }

          if (!/[!@#$%^&*()_+}{":;'?/><,.\[\]\\]/.test(value)) {
            setError3(
              t("Inclusion of at least one special character (e.g., @ # ?) is required")
            );
          } else {
            setError3("");
          }
        }
      } else {
        setError("");
        setError2("");
        setError3("");
      }
      if (formData?.confirmPassword !== "") {
        if (formData.confirmPassword === value) {
          setPasswordMatch(true);
        } else {
          setPasswordMatch(false);
        }
      }
    } else if (name === "confirmPassword") {
      if (formData.password === value) {
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
      }
    }

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }

  const firstSubmit = async (e) => {
    e.preventDefault();
    let res = "";
    if (!captchaValue) {
      alert("Please complete the reCAPTCHA verification.");
      return;
    }

    if (!term) {
      alert(t("Please Read and Agree First"));
      return;
    }
    // if (!verify) {
    //   alert(t("Please verify email to continue"));
    //   return;
    // }
    if (!isContactVerified) {
      alert(t("Please verify your contact number"));
      return;
    }

    if (!passwordsMatch) {
      return;
    }

    if (formData.password.length < 8) {
      setError(t("Password must be at least 8 characters long."));
      return;
    }

    if (formRef.current.fullName == "") {
      alert(t("Please enter your name"));
      return;
    }

    if (formRef.current.email == "") {
      alert(t("Please enter your correct Email"));
      return;
    }

    if (formRef.current.email.includes("@") === false) {
      alert(t("Please enter your correct Email"));
      return;
    }

    let payload = {
      ...formRef.current,
      role: role,
      password: formData.password,
      agree_teams_policy: term,
      mobile_No: phoneNumber,
    };

    try {
      // setLoader(true);

      // let optVarification = await verifyOptByPhoneNumber({
      //   mobileNo: formRef.current.mobile_No,
      //   otp: codeValue,
      // });

      // if (optVarification.data.data == "Otp Expaired") {
      //   setOptErr(t("OTP Expired"));
      //   setLoader(false);
      //   return;
      // } else if (optVarification.data.data == "Enter correct OTP") {
      //   setOptErr(t('Enter correct OTP'));
      //   setLoader(false);
      //   return;
      // }

      setLoader(true);
      res = await signUpUser(payload);
      if (res.status == 201) {
        let emailPayload = {
          name: formRef.current.fullName,
          email: formRef.current.email.toLocaleLowerCase(),
        };
        await verifyEmailAddress(emailPayload);
        await verifyPhoneNumber(res.data.data._id, { phoneVerify: true });
        // setSignUpSuccess(true);
        if (res?.data?.data.role === "embryo_donor") {
          console.log('res',res)
          let object = {
            ...res.data.data,
            userId: res.data.data._id,
          };
          // localStorage.setItem("userData", JSON.stringify(object));
          navigate(`/embryodonor/success`);

          setLoader(false);
        }
        else if(res?.data?.data.role === "adopting_parent"){
          console.log('res',res)
          let object = {
            ...res.data.data,
            userId: res.data.data._id,
          };
          localStorage.setItem("userData", JSON.stringify(object));
          navigate(`/adoptingparent/success`);

          setLoader(false);
        }
      } else {
        setEmailerr(res.response.data.errormessage);
        console.log(res.response.data.errormessage);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  function captchaChange(e) {
    setCaptchaValue(e);
  }

  async function getCode() {
    setIsContactVerified(false);

    setOptErr("");
    if (formRef.current.mobile_No.length == 0) {
      setPhoneNumberErr(t("Please Enter Phone Number"));
      return;
    }
    setGetCodeLoader(1);
    setIsCodeSend(true);

    
    try {
      console.log(formRef.current.mobile_No.toString());
      // return      
      let res = await sendOptOnPhoneNumber({
        mobileNo: formRef.current.mobile_No.toString(),
      });
      setGetCodeLoader(2);
      setTimeout(() => {
        setGetCodeLoader(3);
        setSecCount(59);
        setTimeout(() => {
          clearInterval(intervel);
          setSecCount(0);
          setGetCodeLoader(4);
        }, 59000);
      }, 2000);
      setVerifyNumber(false);
    } catch (err) {
      console.log(err);
      setVerifyNumber(false);
      setGetCodeLoader(0);
    }
  }

  async function verifyMobileNumber() {
    if (verifyNumber) {
      setOptErr(t("Generate code first"));
      return;
    }
    setLoader(true);
    try {
      let optVarification = await verifyOptByPhoneNumber({
        mobileNo: formRef.current.mobile_No,
        otp: codeValue,
      });
      console.log(optVarification);

      setLoader(false);
      if (optVarification.data.data == "Otp Expaired") {
        setOptErr(t("OTP Expired"));
        return;
      } else if (optVarification.data.data == "Enter correct OTP") {
        setOptErr(t("Enter correct OTP"));
        return;
      }
      setIsContactVerified(true);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }

  useEffect(() => {
    intervel = setInterval(() => {
      if (secCount >= 0) {
        setSecCount((pre) => pre - 1);
      }
    }, 1000);

    return () => clearInterval(intervel);
  }, [getCode]);

  function switchLoader(res) {
    switch (res) {
      case 0:
        return (
          <div className="pointer" onClick={getCode}>
            {t("Get Code")}
          </div>
        );
        break;
      case 1:
        return (
          <div
            className="spinner-border"
            role="status"
            style={{ width: "1rem", height: "1rem" }}
          >
            <span className="visually-hidden">{t("Loading...")}</span>
          </div>
        );
        break;
      case 2:
        return <FaCheck />;
        break;
      case 3:
        return (
          <div>
            Resend code in - 00:{secCount < 10 ? "0" : ""}
            {secCount}
          </div>
        );
        break;
      case 4:
        return (
          <div className="pointer" onClick={getCode}>
            {t("Resend code")}
          </div>
        );
        break;

      default:
        break;
    }
  }

  function switchLoaderEmailBtn(res) {
    switch (res) {
      case 0:
        return (
          <div className="pointer" onClick={sendEmailVerification}>
            {t("Verify Email")}
          </div>
        );
        break;
      case 1:
        return (
          <div
            className="spinner-border"
            role="status"
            style={{ width: "1rem", height: "1rem" }}
          >
            <span className="visually-hidden">{t("Loading...")}</span>
          </div>
        );
        break;
      case 2:
        return switchLoaderEmail(verify);

        break;

      default:
        break;
    }
  }

  function setPhoneCodebyLanguage(key) {
    switch (key) {
      case "en":
        return "US";
        break;
      case "es":
        return "ES";
        break;
      case "zh":
        return "CN";
        break;
      case "en-US":
        return "US";
        break;
      default:
        return "US";
        break;
    }
  }

  async function sendEmailVerification() {
    if (formRef.current.fullName == "") {
      alert(t("Please enter your name"));
      return;
    }

    if (formRef.current.email == "") {
      alert(t("Please enter your correct Email"));
      return;
    }

    if (formRef.current.email.includes("@") === false) {
      alert(t("Please enter your correct Email"));
      return;
    }

    setVerifyBtn(1);
    try {
      let emailPayload = {
        name: formRef.current.fullName,
        email: formRef.current.email.toLocaleLowerCase(),
      };
      await verifyEmailAddress(emailPayload);
      setVerifyBtn(2);
    } catch (err) {
      console.log(err);
      setEmailerr(err.response.data.data?.trim());
      if (err.response.data.errormessage.details !== undefined) {
        setEmailerr(t("Please enter valid email"));
      }
      setVerifyBtn(0);
    }
  }

  function switchLoaderEmail(res) {
    switch (res) {
      case false:
        return <div className="pointer">{t("Check email")}</div>;
        break;
      case true:
        return (
          <span className="d-flex align-items-center">
            <FaCheck /> {t("Verified")}
          </span>
        );
        break;

      default:
        break;
    }
  }

  return (
    <>
      {/* <SignIn signIn={signIn} setSignIn={setSignIn} /> */}
      <Loader open={loader} />
      <Dialog
        closable={false}
        visible={true}
        onHide={() => {}}
        contentClassName="p-lg-5 p-md-4 p-sm-4 p-4 "
        style={{ width: signUpSuccess ? "55%" : "60%" }}
        breakpoints={{
          "1450px": signUpSuccess ? "55%" : "90%",
          "750px": signUpSuccess ? "70%" : "90%",
          "600px": "90%",
        }}
      >
        <div className="DialogParent">
          {signUpSuccess ? (
            <div className="">
              <div className="d-flex justify-content-center">
                <div>
                  <div className="d-flex justify-content-center">
                    <LuCheckCircle className="completeIcon fs-2" />
                  </div>
                  <h4 className="text-black fw-bold d-flex align-items-center completeIcon mt-2">
                    Success
                  </h4>
                </div>
              </div>
              <h6 className="text-center text-dark fs-5 mt-3">
                You have successfully created a new account
              </h6>
              <h6 className="text-center fs-6">
                Please check your email to verify your new account
              </h6>
              <div className="d-flex justify-content-center mt-4">
                <button
                  className="SaveButton"
                  onClick={() => navigate(`/signIn`,{state:role})}
                >
                  Back To Login
                </button>
              </div>
            </div>
          ) : (
            <div className="">
              <h1 className="SignUphedaing text-center mt-2">{t("Sign Up")}</h1>
              <form onSubmit={firstSubmit}>
                <div className="row mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                  <div className="col-md-12">
                    <label htmlFor="name" className="form-label">
                      {t("Full Name")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Enter Your Name")}
                      name="fullName"
                      required
                      defaultValue={formRef.current.fullName}
                      onChange={(e) => {
                        if (e.target.value == " ") {
                          e.target.value = "";
                        } else {
                          formRef.current.fullName = e.target.value;
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 mb-lg-0 mb-md-4 mb-sm-4 mb-3">
                    <label htmlFor="phone_number" className="form-label">
                      {t("Contact Number")}
                    </label>
                    <div className="p-relative">
                      <PhoneInput
                        international
                        defaultCountry={setPhoneCodebyLanguage(
                          localStorage.getItem("i18nextLng")
                        )}
                        withCountryCallingCode
                        required
                        defaultValue={phoneNumber}
                        onChange={(e) => {
                          if (e == " ") {
                            e = "";
                          } else {
                            setPhoneNumber(e);
                            formRef.current.mobile_No = e;
                            setPhoneNumberErr("");
                          }
                        }}
                      />
                      <div className="GetCode">
                        {switchLoader(getCodeLoader)}
                      </div>
                    </div>
                    <div className="text-danger">{phoneNumberErr}</div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label htmlFor="code" className="form-label">
                      {t("Code")}
                    </label>

                    <div className="p-relative">
                      <input
                        type="number"
                        className={`form-control ${
                          optErr.length !== 0 ? "border border-danger" : ""
                        }`}
                        placeholder={t("Enter Code")}
                        required
                        name="code"
                        disabled={verifyNumber}
                        defaultValue={codeValue}
                        onChange={(e) => {
                          if (e.target.value == " ") {
                            e.target.value = "";
                          } else {
                            setCodeValue(e.target.value);
                            setOptErr("");
                          }
                        }}
                      />

                      <div
                        className="phone_verified_text"
                        onClick={() => {
                          !isContactVerified &&
                            codeValue !== "" &&
                            formRef.current.mobile_No &&
                            verifyMobileNumber();
                        }}
                      >
                        {isContactVerified ? (
                          <div className="d-flex align-items-center">
                            <FaCheck /> {t("Verified")}
                          </div>
                        ) : (
                          <div className="pointer">{t("Verify Number")}</div>
                        )}
                      </div>
                    </div>

                    <div className="text-danger">{optErr}</div>
                  </div>
                </div>
                <div className="row mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                  <div className="col-md-6"> 
                    <label htmlFor="email" className="form-label">
                      {t("Email Address")}
                    </label>
                    <div className="p-relative">
                      <input
                        type="email"
                        className="form-control"
                        placeholder={t("Enter Email Address")}
                        name="email"
                        required
                        defaultValue={formRef.current.email}
                        onChange={(e) => {
                          if (e.target.value == " ") {
                            e.target.value = "";
                          } else {
                            formRef.current.email = e.target.value;
                            setEmailerr("");
                          }
                        }}
                      />
                      {/* <div className="GetCodeEmail">
                        {switchLoaderEmailBtn(verifyBtn)}
                      </div> */}
                    </div>

                    {emailerr && (
                      <span className="text-danger text-capitalize">
                        {t(emailerr)}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                  <label htmlFor="hear_about" className="form-label">
                      {t("Where did you hear about NHP?")}
                    </label>
                    {oterOption === true ? (
                      <>
                      <input
                    className="form-control"
                    placeholder={t("Please specify")}
                    onChange={(e) => {
                      setSelectHere(e.target.value);
                    }}
                    required
                  />
                      </>
                    ):(
                      <>
                        <select className="form-select" id="" required  onChange={(e) => {
                      setSelectHere(e.target.value);
                      if (e.target.value === "Others") {
                        setOtherOption(true);
                      } else {
                        setOtherOption(false);
                      }
                    }}>
                      <option value="" selected disabled>{t("Select an option")}</option>
                      <option value="Facebook">{t("Facebook")}</option>
                      <option value="Instagram">{t("Instagram")}</option>
                      <option value="Twitter">{t("Twitter")}</option>
                      <option value="Tiktok">{t("Tiktok")}</option>
                      <option value="Linkedin">{t("Linkedin")}</option>
                      <option value="Reddit">{t("Reddit")}</option>
                      <option value="Bilibili">{t("Bilibili")}</option>
                      <option value="Others">{t("Others")}</option>
                      
                    </select>
                      </>
                    )}
                  
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-3">
                    <label htmlFor="password" className="form-label">
                      {t("Password")}
                    </label>
                    <div className="p-relative">
                      <input
                        required
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder={t("Enter Password")}
                        name="password"
                        value={formData.password}
                        onChange={(e) => {
                          if (e.target.value == " ") {
                            e.target.value = "";
                          } else {
                            inpChange(e);
                          }
                        }}
                      />
                      {!showPassword ? (
                        <i
                          className="bi bi-eye-slash View pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        ></i>
                      ) : (
                        <i
                          className="bi bi-eye View pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        ></i>
                      )}
                      {/* <i className="bi bi-eye-slash"></i> */}
                    </div>
                  </div>

                  {/* Confirm Password input field */}
                  <div className="col-md-6">
                    <label htmlFor="confirmPassword" className="form-label">
                      {t("Confirm Password")}
                    </label>
                    <div className="p-relative">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control"
                        placeholder={t("Confirm Password")}
                        name="confirmPassword"
                        required
                        value={formData.confirmPassword}
                        onChange={(e) => {
                          if (e.target.value == " ") {
                            e.target.value = "";
                          } else {
                            inpChange(e);
                          }
                        }}
                        style={{
                          border: passwordsMatch ? "" : "1px solid red",
                        }}
                      />
                      {!showConfirmPassword ? (
                        <i
                          className="bi bi-eye-slash View pointer"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        ></i>
                      ) : (
                        <i
                          className="bi bi-eye View pointer"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        ></i>
                      )}
                    </div>
                    <div className="text-danger mt-1">
                      {passwordsMatch ? "" : t("Password doesn't match")}
                    </div>
                  </div>
                </div>
                {error && <li className="text-danger mt-1">{error}</li>}
                {error2 && <li className="text-danger mt-1">{error2}</li>}
                {error3 && <li className="text-danger mt-1">{error3}</li>}
                <div className="d-flex flex-wrap mt-4">
                  <div className="form-check me-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="agreedToTerms"
                      checked={term}
                      onChange={(e) => setTerm(e.target.checked)}
                    />
                    <label className="form-check-label">
                      {t("I’ve read and agree with")}
                    </label>
                  </div>
                  <div>
                    <span
                      className="TermColor me-1 pointer"
                      onClick={() => {
                        window.open("/termsAndConditions", "_blank");
                      }}
                    >
                      {t("Terms of service")}
                    </span>
                    <span className="form-check-label me-1">{t("and")}</span>
                    <span
                      className="TermColor me-1 pointer"
                      onClick={() => {
                        window.open("/privacyPolicy", "_blank");
                      }}
                    >
                      {t("our privacy policy")}
                    </span>
                  </div>
                </div>
                <div className="mt-4 d-flex">
                  <div>
                    <ReCAPTCHA
                      sitekey={"6LfNdI4pAAAAALEDG_OG1aDvRqK_f8_ye513OfRY"}
                      onChange={captchaChange}
                    />
                  </div>
                </div>
                <div className="ButtonForm mt-3">
                  <button
                    className="btn_non_fill"
                    type="button"
                    disabled={isLoading}
                    onClick={() => navigate(-1)}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    className="btn_fill"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div
                        className="spinner-border text-light"
                        role="status"
                        style={{ width: "1rem", height: "1rem" }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      t("Sign Up")
                    )}
                  </button>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  {" "}
                   <p className="fs-5">
                    <span className="font_web">
                      {t("Already have an account?")}
                    </span>{" "}
                    <span
                      className="TermColor pointer fs-5"
                      onClick={() =>
                        navigate(`/signIn`,{state:role})
                      

                      }
                    >
                      {t("Sign in")}
                    </span>
                  </p> 
                </div>
              </form>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default EmbryoDonorSignup;

