import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import "./SubscriptionDialog.css"
import { InputSwitch } from "primereact/inputswitch";
import { PaymentDialog } from '../PaymentDialog/PaymentDialog';
import { useTranslation } from 'react-i18next';
export const SubscriptionDialog = ({Subscription,setSubscription}) => {
  let {t}=useTranslation()
    const [checked, setChecked] = useState(false);
    const [payment, setPayment] = useState(false);

 function SelectPlan (){
  setPayment(true)
  setSubscription(false)
 }
 console.log("okok")
  return (

    <>
    <PaymentDialog payment={payment} setPayment={setPayment}/>
   <Dialog
    closable={false}
      visible={Subscription}
      onHide={() => setSubscription(false)}
      style={{ width: "60vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
    >
      <div className="SubsCription_con">
        <div className="mb-4">
        <h3 className="MAinheading text-center ">{t("Putting Together the Puzzle of Parenthood")}</h3>
        </div>
        <div>
            <p  className='textSubs mb-4'>
            {t("Choose the plan that’s right for your needs. Whether you’re just getting started with surrogate journey or well down the path to parenthood, we’re got you covered")}
            </p>
        </div>

        <div className="container">
        <div className="mb-4 mt-4 flex justify-content-center">
            <span className='MonthlyText me-2'>{t("Monthly")}</span>
            <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
            <span className='MonthlyText ms-2'>{t("Yearly")}</span>
            <span className='OffText ms-2'>{t("35% OFF")}</span>
        </div>
            <div className="row gap-2 d-flex justify-content-center">
                <div className="col-md-3 border p-0 borderShodowCard">
                  <div className='' >
                  <span className='PopularPlan3 text-center '>  </span>
                   <div className='CardCustom mb-3 px-3 pt-4'>
                    
                   <span className='FreeText pt-3'>{t("Free")}</span>
                        <span className='dollerText'>{t("$0")}</span>
                   
                        
                        <span className=''>{t("user/mo")}</span>
                        <hr />
                   </div>
                       <div className='CardCustom mb-4 px-3'>
                       <span className='CompleteFreeText '>{t("Completely free for your basic needs")}</span>
                       <hr />
                        <button className='CurrentPlantText mb-3' onClick={()=>setPayment(true)}>{t("Current Plan")}</button>
                        <span className='BasicPlanText'>Basic Plan 
                        <span className='SupportText '>"Essential Support"</span>
                        </span>
                       </div>
                       <div className='text-center FeaturesMain mb-3'>
                        <span className='FeaturesText'>
                            {t("All Features Options")}
                        </span>
                       </div>
                       <i class="bi bi-check-lg"></i>
                       <div className='CardCustom px-3'>
                        <span className='TextICon'>
                        <i class="bi bi-check2 me-1"></i>
                           {t("surrogacy advisor")}</span>
                        <span className='TextICon'> <i class="bi bi-check2 me-1"></i>{t("Surrogate matching")}</span>
                        <span className='TextICon'> <i class="bi bi-check2 me-1"></i>{t("Legal consultation")}</span>
                        <span className='TextICon'> <i class="bi bi-check2 me-1"></i>{t("Basic support")}</span>
                        <span className='TextICon'> <i class="bi bi-x me-1"></i>{t("Basic support")}</span>
                        <span className='TextICon'> <i class="bi bi-x me-1"></i>{t("Priority access")}</span>
                        <span className='TextICon'> <i class="bi bi-x me-1"></i>{t("personalized recommendations")}</span>
                       </div>
                
                  </div>
                </div>
                <div className="col-md-3  p-0 borderShodowCard">
                  <div className='' >
                
                   <div className='CardCustom mb-3 px-3 pt-4'>
                    
                   <span className='FreeText pt-3'>{t("Silver")}</span>
                        <span className='dollerText'>{t("$15")}</span>
                   
                        
                        <span className=''>{t("user/mo")}</span>
                        <hr />
                   </div>
                       <div className='CardCustom mb-4 px-3'>
                       <span className='CompleteFreeText '>{t("Everything in the Basic Plan, plus")}</span>
                       <hr />
                        <button className='SelectPlantText mb-3 ' onClick={SelectPlan}>{t("Select Plan")}</button>
                        <span className='BasicPlanText'>{t("Standard Plan")} 
                        <span className='SupportText '> {t("Comprehensive Care")}</span>
                        </span>
                       </div>
                       <div className='text-center FeaturesMain mb-3'>
                        <span className='FeaturesText'>
                        {t("All features options")}
                        </span>
                       </div>
                       <div className='CardCustom px-3'>
                        <span className='TextICon'><i class=" bi bi-check2 me-1 "></i> {t("surrogacy advisor")}</span>
                        <span className='TextICon'> <i class="bi bi-check2 me-1"></i>{t("Priority access")}</span>
                        <span className='TextICon'> <i class="bi bi-check2 me-1"></i>{t("medical evaluations")}</span>
                        <span className='TextICon'> <i class="bi bi-check2 me-1"></i>{t("Legal consultation")}</span>
                        <span className='TextICon'> <i class="bi bi-x me-1"></i>{t("Squarespace Extension")}</span>
                        <span className='TextICon'> <i class="bi bi-x me-1"></i>{t("Surrogate matching")}</span>
                        <span className='TextICon'> <i class="bi bi-x me-1"></i>{t("personalized recommendations")}</span>
                       </div>
                
                  </div>
                </div>
                <div className="col-md-3 p-0  borderShodowCard">
                  <div className='' >
                    <span className='PopularPlan2 text-center'>{t("Popular Plan")}</span>
                   <div className='CardCustom mb-3 pt-3'>
                   <span className='FreeText'>{t("Gold")}</span>
                        <span className='dollerText'>{t("$35")}</span>
                   
                        
                        <span className=''>{t("user/mo")}</span>
                        <hr />
                   </div>
                       <div className='CardCustom px-2 mb-4'>
                       <span className='CompleteFreeText '>{t("Everything in the Standard Plan, plus")}</span>
                       <hr />
                        <button className='SelectPlantText mb-3'onClick={()=>setPayment(true)}>{t("Select Plan")}</button>
                        <span className='BasicPlanText'>{t("Premium Plan")} 
                        <span className='SupportText '>- "{t("Full-Service Experience")}"</span>
                        </span>
                       </div>
                       <div className='text-center FeaturesMain mb-3'>
                        <span className='FeaturesText'>
                            {t("All Features Options")}
                        </span>
                       </div>
                       <div className='CardCustom px-2'>
                        <span className='TextICon'><i class="bi bi-check2 me-1 "></i> {t("surrogacy advisor")}</span>
                        <span className='TextICon'> <i class="bi bi-check2 me-1"></i>{t("Surrogate matching")}</span>
                        <span className='TextICon'> <i class="bi bi-check2 me-1"></i> {t("Contributors")}</span>
                        <span className='TextICon'> <i class="bi bi-check2 me-1"></i>{t("Basic Website Metrics")}</span>
                        <span className='TextICon'> <i class="bi bi-x me-1"></i>{t("medical evaluations")}</span>
                        <span className='TextICon'> <i class="bi bi-x me-1"></i>{t("Surrogate matching")}</span>
                        <span className='TextICon'> <i class="bi bi-x me-1"></i>{t("Surrogate matching")}</span>
                       </div>
                      
                  </div>
                </div>
             
            </div>
        </div>

      </div>
    </Dialog>
    </>
  )
}
