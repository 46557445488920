import { useTranslation } from "react-i18next";
import "./home.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const TestHome = () => {
  let { t } = useTranslation();

  const [cards,setCards] = useState([
    { title: "Intended Parents", image: "first_image_of_card.png", navlink: "intendedparenttest" },
    { title: "Eggs/Sperm Donor", image: "second_image_of_card.png", navlink: "underconstructiontest"},
    { title: "Surrogate Mother", image: "third_image_of_card.png", navlink: "surrogateMother" },
    { title: "Legal Firms", image: "forth_image_of_card.png" , navlink: "underconstruction"},
    { title: "IVF Clinic", image: "fifth_image_of_card.png" , navlink: "underconstruction"},
    { title: "Children Care Provider", image: "sixth_image_of_card.png" , navlink: "underconstruction"},
    { title: "Education Service Provider", image: "seventh_image_of_card.png" , navlink: "underconstruction"},
    { title: "Adoption Parent(s)", image: "eighth_image_of_card.png" , navlink: "underconstruction"},
    { title: "Broker/Agent", image: "ninth_image_of_card.png" , navlink: "underconstruction"},
    { title: "Child Care Agency", image: "tenth_image_of_card.png" , navlink: "underconstruction"},
    { title: "Driving Service Provider", image: "eleventh_image_of_card.png" , navlink: "underconstruction"},
    { title: "Confinement center", image: "twelth_image_of_card.png" , navlink: "underconstruction"}
  ]) 

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[t])

  return (

      <div className="main_div">
        <div className="upper_section container-fluid p-0">
          <div className="upper_section_content">
            <div className="lufga_20px mb-5">{t("One home extra")}</div>
            <div className="lufga_20px_bg_lightblue">
              {t("One-Stop Platform to make your babies becomes Supermen")}
            </div>
            <div className="volkhov_48px pt-3">
              {t("Empowering dreams,")} <br />{t("Embracing miracles")}
            </div>
            <div className="lufga_18px_bg_gray pt-2">
              {t("We help intended parents and adoption parents conceive, raise, and educate super children.")}
            </div>
            <div className=" pt-3">
              <button className="lufga_20px py-4 fw-500">{t("Find out more")}</button>
              <button className="lufga_20px py-4 fw-600">{t("Learn more")}</button>
            </div>
          </div>
          <div className="upper_section_image_couple">
            <img src="/images/couple&child.png"  />
          </div>
        </div>

        <div className="all_stories">
          <div className="lufga_36px my-md-5 my-3">{t("If you are the")}...</div>
            <div className="row">
              {cards?.map((card, index) => (
                <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 p-lg-2 p-md-2 p-sm-2 p-2">
                  <Link to={card.navlink} className={"navLink"}>
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={`/images/${card.image}`}
                        alt={t("Card image cap")}
                      />
                      <div className="card-body">
                        <div className="lufga_18px">{t(card.title)}</div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
        </div>

        <div className="choose_us_section">
          <div className="text_section_of_choose">
            <div className="text_content_of_choose">
              <div>
                <div className="lufga_36px">{t("Why Choose Us")}</div>
                <div className="lufga_20px">
                  {t("One-stop solution from an embryo to graduates of famous university – Start to make your dream to be true!")}
                </div>
              </div>
              <div>
                <div>
                  <div className="lufga_20px">{t("No Middle Man")}</div>
                  <div className="lufga_16px_bg_gray">
                    {t("Cost efficiently - We use AI Match Technology directly set up the bridge between parents and surrogate mother or donor")}
                  </div>
                </div>
                <div>
                  <div className="lufga_20px">
                    <span className="custom_color">{t("Earn More than before")}</span> {t("if you are a surrogate mother or donor.")}
                  </div>

                  <div className="lufga_16px_bg_gray">
                    {t("we have special bidding process to make it possible to earn more than expected")}
                  </div>
                </div>
                <div>
                  <div className="lufga_20px">
                    <span className="custom_color">{t("Residual Income")}</span> {t("can be earned for everyone by our unique referral programs.")}
                  </div>
                  <div className="lufga_16px_bg_gray">
                    {t("Competitive compensation packages, insurance, & financial protection, demonstrating a commitment to their well-being")}
                  </div>
                </div>
                <div>
                  <div className="lufga_20px">{t("Specialized Legal Expertise")}</div>
                  <div className="lufga_16px_bg_gray">
                    {t("Collaborate with experienced surrogacy attorneys to guide on legal matters, contracts, and any potential legal challenges")}
                  </div>
                </div>
                <div className="">
                  <div className="lufga_20px ps-2">{t("View our Services >")}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="image_section_of_choose">
            {localStorage.getItem('langCode') == 'es'?<img src="/images/spanishImg.png" alt={t("View our Services >")} />:''}
            {localStorage.getItem('langCode').includes('en')?<img src="/images/illus.png" alt={t("View our Services >")} />:''}
            {localStorage.getItem('langCode') == 'zh'?<img src="/images/chinesImg.png" alt={t("View our Services >")} />:''}
          </div>
        </div>
      </div>

  );
};

export default TestHome;
