
import React, { useState, useRef } from 'react';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import "./Resume.css"
import { useTranslation } from "react-i18next";
import { Outlet } from 'react-router';



export const TestResume = () => {
    let { t } = useTranslation();



    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef(null);
    const items = [
        {
            label: '',

        },
        {
            label: '',

        },
        {
            label: '',

        },
        {
            label: '',

        },
        {
            label: '',

        },
        {
            label: '',

        },
        {
            label: '',

        },
        {
            label: '',

        },
        {
            label: '',

        },
        {
            label: '',

        },
        {
            label: '',

        },

    ];


    // function fun() {
    //     switch (activeIndex) {
    //         case 0:
    //             return <ResumeStep1 />
    //             break;
    //         case 1:
    //             return <ResumeStep2 />
    //             break;
    //         case 2:
    //             return <ResumeStep3 />
    //             break;
    //         case 3:
    //             return <ResumeStep4 />
    //             break;
    //         case 4:
    //             return <ResumeStep5 />
    //             break;
    //         case 5:
    //             return <ResumeStep6 />
    //             break;
    //         case 6:
    //             return <ResumeStep7 />
    //             break;
    //         case 7:
    //             return <ResumeStep8 />
    //             break;
    //         case 8:
    //             return <ResumeStep9 />
    //             break;
    //         case 9:
    //             return <ResumeStep10 />
    //             break;
    //         case 10:
    //             return <ResumeStep11 />
    //             break;

    //         default:
    //             break;
    //     }
    // }
    const handlePrevious = () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const handleNext = () => {
        if (activeIndex < items.length - 1) {
            setActiveIndex(activeIndex + 1);
        }
    };

    const nextStep = () => {

    }

    return (
        <div className="Resume_con">
            <div className='mb-5'>
                <div className='MainHeading'><h1>{t("Surrogacy Mother")}</h1></div>
                <div className='SubHeading'>
                    <p>{t("Register to begin your journey with us!")}</p>
                </div>
            </div>
            <Toast ref={toast}></Toast>
            <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />

            <Outlet /> 



        </div>

    )
}



