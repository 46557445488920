import React from "react";
import { useTranslation } from "react-i18next";
import style from "./VideoLinks.module.css";
import "../../test/home.css";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";

const VideoLinks = () => {
    const [currentlang, setCureentLang] = useState([]);
    let te = localStorage.getItem("langCode");

    let currentLanguage = te === "en-US" ? "en" : te || "en";
    console.log(currentLanguage, "currentLanguage")



    const getLinkByLanguage = (language, links) => {
        switch (language) {
            case "en":
                return links[`youtube${language}`];
            case "es":
                return links[`youtube${language}`];
            case "zh":
                return links[`tiktok${language}`];
            default:
                return ""; // Default case if language is not supported
        }
    };

    

    
    let arr2 = [0, 1, 2, 3];

    switch (currentLanguage) {
        case "es":
            arr2 = [0, 1, 2, 3]
            break;
        case "zh":
            arr2 = [2, 0, 1, 3]
            break;

        default:
            break;
    }





    const [cards, setCards] = useState([
        {
            title:
                "Surrogate",
            image: "video_link_card_two.png",
            source:
                "https://www.dailystar.co.uk/news/us-news/elon-musks-dad-reveals-asked-30818420",
            source_name: "dailystar.co",
            date: "aug 30,2023",
            isThirdPartyUrl: true,
            links: {
                // youtubeen: "https://youtu.be/4vRiecER5IE?si=97cJa4QHIGgh2dlo",
                // youtubezh: "",
                // youtubees: "https://youtu.be/GzrAOLN7pDE?si=LvLGHOZ3wyXfi5ys",

                // facebooken: "https://www.facebook.com/reel/384232100904280",
                // facebookzh: "",
                // facebookes: "https://www.facebook.com/reel/1118658692637540",

                // tiktoken: "https://www.tiktok.com/t/ZPRTRxryw/",
                // tiktokzh: " ",
                // tiktokes: " ",

                // instagramen: "https://www.instagram.com/reel/C3z46B-NxHW/",
                // instagramzh: " ",
                // instagrames: "https://www.instagram.com/reel/C3pjf1BsKuA/",

                // twitteren: "https://x.com/NewhopePoints/status/1762094882410086834?s=20",
                // twitterzh: "",
                // twitteres: "//x.com/NewhopePoints/status/1760660210383745121?s=20",

                // bilibilien: "https://www.bilibili.tv/en/video/4790388882014720",
                // bilibilizh: "",
                // bilibilies: "https://www.bilibili.tv/en/video/4790343579993088",

                // linkedinen:
                //     "https://www.linkedin.com/feed/update/urn:li:activity:7167858562688397314",
                // linkedinzh: "",
                // linkedines:
                //     "https://www.linkedin.com/feed/update/urn:li:activity:7166406751418806272",

                // redditen: "",
                // redditzh: "",
                // reddites: "",

                youtubeen: "https://youtu.be/4vRiecER5IE",
                youtubezh: "https://youtu.be/4vRiecER5IE",
                youtubees: "https://youtu.be/GzrAOLN7pDE",

                facebooken: "https://www.facebook.com/reel/1584253702322566",
                facebookzh: "https://www.facebook.com/reel/384232100904280",
                facebookes: "https://www.facebook.com/reel/919627886512346",

                tiktoken: "https://www.tiktok.com/t/ZPRTRxryw/",
                tiktokzh: "https://www.tiktok.com/t/ZPRTRxryw/",
                tiktokes: "https://www.tiktok.com/t/ZPRTRxryw/",

                instagramen: "https://www.instagram.com/reel/C3z46B-NxHW/",
                instagramzh: "https://www.instagram.com/reel/C3z46B-NxHW/",
                instagrames: "https://www.instagram.com/reel/C3pjf1BsKuA/",

                twitteren: "https://x.com/NewhopePoints/status/1762094882410086834?s=20",
                twitterzh: "https://x.com/NewhopePoints/status/1762094882410086834?s=20",
                twitteres: "//x.com/NewhopePoints/status/1760660210383745121?s=20",

                bilibilien: "https://www.bilibili.tv/en/video/4790388882014720",
                bilibilizh: "https://www.bilibili.tv/en/video/4790388882014720",
                bilibilies: "https://www.bilibili.tv/en/video/4790343579993088",

                linkedinen:
                    "https://www.linkedin.com/feed/update/urn:li:activity:7167858562688397314",
                linkedinzh: "https://www.linkedin.com/feed/update/urn:li:activity:7167858562688397314",
                linkedines:
                    "https://www.linkedin.com/feed/update/urn:li:activity:7166406751418806272",

                redditen: "https://www.reddit.com/user/newhopepoints/comments/1c4lx9r/surrogacy_make_dreams_come_true_and_earn_money/",
                redditzh: "https://www.reddit.com/user/newhopepoints/comments/1b680sz/共育未来平台/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button",
                reddites: "https://www.reddit.com/user/newhopepoints/comments/1c4m8rd/gestaci%C3%B3n_subrogada_haz_realidad_los_sue%C3%B1os_y/",
            },
        },

        {
            title: "Adoption Parents",
            image: "video_link_card_four.png",
            source: "https://www.youtube.com/watch?v=HrmRgip-APQ",
            source_name: "youtube",
            date: "may 14,2022",
            isThirdPartyUrl: true,
            links: {
            
                youtubeen: "https://youtu.be/yuow5qBrVog",
                youtubezh: "https://youtu.be/yuow5qBrVog",
                youtubees: "https://youtu.be/yuow5qBrVog",

                facebooken: "https://www.facebook.com/61557619527385/videos/434075495920592/",
                facebookzh: "https://www.facebook.com/61557619527385/videos/434075495920592/",
                facebookes: "https://www.facebook.com/61557619527385/videos/434075495920592/",

                tiktoken: "https://www.tiktok.com/t/ZPRTRQKqM/",
                tiktokzh: "https://www.tiktok.com/t/ZPRTRQKqM/",
                tiktokes: "https://www.tiktok.com/t/ZPRTRQKqM/",

                instagramen: "https://www.instagram.com/p/C7UBfIbs5xt/",
                instagramzh: "https://www.instagram.com/p/C7UBfIbs5xt/",
                instagrames: "https://www.instagram.com/p/C7UBfIbs5xt/",

                twitteren: "https://x.com/NewhopePoints/status/1793636535599870399",
                twitterzh: "https://x.com/NewhopePoints/status/1793636535599870399",
                twitteres: "https://x.com/NewhopePoints/status/1793636535599870399",

                bilibilien: "https://www.bilibili.tv/en/video/4791374569931264",
                bilibilizh: "https://www.bilibili.tv/en/video/4791374569931264",
                bilibilies: "https://www.bilibili.tv/en/video/4791374569931264",

                linkedinen: "https://www.linkedin.com/feed/update/urn:li:ugcPost:7199402809463631883/?updateEntityUrn=urn%3Ali%3Afs_updateV2%3A%28urn%3Ali%3AugcPost%3A7199402809463631883%2CFEED_DETAIL%2CEMPTY%2CDEFAULT%2Cfalse%29",
                linkedinzh:
                    "https://www.linkedin.com/feed/update/urn:li:ugcPost:7199402809463631883/?updateEntityUrn=urn%3Ali%3Afs_updateV2%3A%28urn%3Ali%3AugcPost%3A7199402809463631883%2CFEED_DETAIL%2CEMPTY%2CDEFAULT%2Cfalse%29",
                linkedines: "https://www.linkedin.com/feed/update/urn:li:ugcPost:7199402809463631883/?updateEntityUrn=urn%3Ali%3Afs_updateV2%3A%28urn%3Ali%3AugcPost%3A7199402809463631883%2CFEED_DETAIL%2CEMPTY%2CDEFAULT%2Cfalse%29",

                redditen: "https://www.reddit.com/user/newhopepoints/comments/1cysz1o/embrace_a_new_era_of_adoption_with_newhopepoints/",
                redditzh: "https://www.reddit.com/user/newhopepoints/comments/1cysz1o/embrace_a_new_era_of_adoption_with_newhopepoints/",
                reddites: "https://www.reddit.com/user/newhopepoints/comments/1cysz1o/embrace_a_new_era_of_adoption_with_newhopepoints/",

            },
        },

        {
            title:
                "Child Care",
            image: "video_link_card_one.png",
            source: "https://www.wenxuecity.com/news/2024/02/19/125428411.html",
            source_name: "wenxuecity.com",
            date: "feb 19,2024",
            isThirdPartyUrl: true,
            links: {
                // youtubeen: "",
                // youtubezh: "https://youtu.be/121QYIdfLus?si=4A6CYM5ZnsQARnGj",
                // youtubees: "",

                // facebooken: "",
                // facebookzh: "https://fb.watch/qLXL2zJ5S4/",
                // facebookes: "",

                // tiktoken: " ",
                // tiktokzh: "https://www.tiktok.com/t/ZPRTR99a7/",
                // tiktokes: " ",

                // instagramen: "",
                // instagramzh: " https://www.instagram.com/reel/C3456CPtCrM/",
                // instagrames: "",

                // twitteren: "",
                // twitterzh: "https://x.com/NewhopePoints/status/1763188512264699947?s=20",
                // twitteres: "",

                // bilibilien: "",
                // bilibilizh: "https://www.bilibili.tv/en/video/4790411055858176",
                // bilibilies: "",

                // linkedinen: "",
                // linkedinzh:
                //     "https://www.linkedin.com/feed/update/urn:li:activity:7168570252266463232",
                // linkedines: "",

                // redditen: "",
                // redditzh:
                //     "https://www.reddit.com/user/newhopepoints/comments/1b680sz/共育未来平台/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button",
                // reddites: "",


                youtubeen: "https://youtu.be/qoV5Y-Q9Yk0",
                youtubezh: "https://youtu.be/121QYIdfLus",
                youtubees: "https://youtu.be/qoV5Y-Q9Yk0",

                facebooken: "https://fb.watch/rk-TFhpyYu/",
                facebookzh: "https://fb.watch/qLXL2zJ5S4/",
                facebookes: "https://fb.watch/qLXL2zJ5S4/",

                tiktoken: "https://www.tiktok.com/t/ZPRTR99a7/",
                tiktokzh: "https://www.tiktok.com/t/ZPRTR99a7/",
                tiktokes: "https://www.tiktok.com/t/ZPRTR99a7/",

                instagramen: "https://www.instagram.com/p/C5iuwlANett/",
                instagramzh: "https://www.instagram.com/reel/C3456CPtCrM/",
                instagrames: "https://www.instagram.com/reel/C3456CPtCrM/",

                twitteren: "https://twitter.com/NewhopePoints/status/1777692459889250636",
                twitterzh: "https://x.com/NewhopePoints/status/1763188512264699947?s=20",
                twitteres: "https://x.com/NewhopePoints/status/1763188512264699947?s=20",

                bilibilien: "https://www.bilibili.tv/en/video/4790876349799936",
                bilibilizh: "https://www.bilibili.tv/en/video/4790411055858176",
                bilibilies: "https://www.bilibili.tv/en/video/4790411055858176",

                linkedinen: "https://www.linkedin.com/feed/update/urn:li:activity:7183458140892323842",
                linkedinzh:
                    "https://www.linkedin.com/feed/update/urn:li:activity:7168570252266463232",
                linkedines: "https://www.linkedin.com/feed/update/urn:li:activity:7168570252266463232",

                redditen: "https://www.reddit.com/user/newhopepoints/comments/1bzshu8/join_the_new_hope_talent_cultivation_platform/",
                redditzh:
                    "https://www.reddit.com/user/newhopepoints/comments/1b680sz/共育未来平台/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button",
                reddites: "https://www.reddit.com/user/newhopepoints/comments/1b680sz/共育未来平台/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button",


            },

        },

        {
            title: "Intended Parents",
            image: "video_link_card_three.png",
            source: "https://www.youtube.com/watch?v=HrmRgip-APQ",
            source_name: "youtube",
            date: "may 14,2022",
            isThirdPartyUrl: true,
            links: {
                // youtubeen: "",
                // youtubezh: "https://youtu.be/jt6PHxF2FJE?si=zb8KySgBI0w-Mvfo",
                // youtubees: "",

                // facebooken: "",
                // facebookzh: "https://www.facebook.com/reel/1090113845367998",
                // facebookes: "",

                // tiktoken: "",
                // tiktokzh: "https://www.tiktok.com/t/ZPRTRQKqM/",
                // tiktokes: " ",

                // instagramen: "",
                // instagramzh: "https://www.instagram.com/reel/C3mzOLTNtCA/",
                // instagrames: "",

                // twitteren: "",
                // twitterzh: "https://x.com/NewhopePoints/status/1760251227240112173?s=20",
                // twitteres: "",

                // bilibilien: "",
                // bilibilizh: "https://www.bilibili.tv/en/video/4790331329479168",
                // bilibilies: "",

                // linkedinen: "",
                // linkedinzh:
                //     "https://www.linkedin.com/feed/update/urn:li:activity:7166017241128415232",
                // linkedines: "",

                // redditen: "",
                // redditzh: "",
                // reddites: "",


                youtubeen: "https://youtu.be/o_vjhtiAbkE?t=26",
                youtubezh: "https://youtu.be/jt6PHxF2FJE",
                youtubees: "https://youtu.be/o_vjhtiAbkE?t=26",

                facebooken: "https://www.facebook.com/reel/1090113845367998",
                facebookzh: "https://www.facebook.com/reel/434016005811089",
                facebookes: "https://www.facebook.com/reel/1090113845367998",

                tiktoken: "https://www.tiktok.com/t/ZPRTRQKqM/",
                tiktokzh: "https://www.tiktok.com/t/ZPRTRQKqM/",
                tiktokes: "https://www.tiktok.com/t/ZPRTRQKqM/",

                instagramen: "https://www.instagram.com/reel/C3mzOLTNtCA/",
                instagramzh: "https://www.instagram.com/reel/C3mzOLTNtCA/",
                instagrames: "https://www.instagram.com/reel/C3mzOLTNtCA/",

                twitteren: "https://x.com/NewhopePoints/status/1760251227240112173?s=20",
                twitterzh: "https://x.com/NewhopePoints/status/1760251227240112173?s=20",
                twitteres: "https://x.com/NewhopePoints/status/1760251227240112173?s=20",

                bilibilien: "https://www.bilibili.tv/en/video/4790331329479168",
                bilibilizh: "https://www.bilibili.tv/en/video/4790331329479168",
                bilibilies: "https://www.bilibili.tv/en/video/4790331329479168",

                linkedinen: "https://www.linkedin.com/feed/update/urn:li:activity:7166017241128415232",
                linkedinzh:
                    "https://www.linkedin.com/feed/update/urn:li:activity:7166017241128415232",
                linkedines: "https://www.linkedin.com/feed/update/urn:li:activity:7166017241128415232",

                redditen: "https://www.reddit.com/user/newhopepoints/comments/1b680sz/共育未来平台/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button",
                redditzh: "https://www.reddit.com/user/newhopepoints/comments/1b680sz/共育未来平台/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button",
                reddites: "https://www.reddit.com/user/newhopepoints/comments/1b680sz/共育未来平台/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button",

            },
        },

       


    ]);

    let { t } = useTranslation();


    return (
        <div>
            <div className={`container-fluid px-5 my-4`}>
                <div className={`row`} style={{ position: 'relative' }}>
                    <div className={`col rounded`}>
                        <img
                            className="w-100"
                            src="/images/video_links_upper_image.png"
                            alt="banner"
                        />
                    </div>
                    <div className={`d-flex justify-content-end ${style.heading}`}>
                        <div className={`d-flex flex-column gap-2 align-items-center ${style.banner_heading}`}>
                            <div className={`${style.banner_sub_heading_one} lufga_36px`}>
                                {t("Video Links")}
                            </div>
                            <div className={`${style.banner_sub_heading_two} lugfa_18px`}>
                                {t("Explore Our Video Library: Informative Resources on")}
                            </div>
                            <div className={`${style.banner_sub_heading_three} lugfa_18px`}>
                                {t("Surrogacy Journeys")}
                            </div>
                        </div>
                    </div>
                </div>



                <div className="row px-4 mt-5 justify-content-center">
                    {arr2?.map((res, index) => (
                        <div
                            key={index}
                            className="col-12 col-sm-6 col-md-3 p-lg-2 p-md-2 p-sm-1 p-2"
                        >
                            <div className={`${style.card_wrapper} h-100`}>
                                <Link
                                    style={{ height: '100%' }}
                                    // to={currentLanguage === "en" ? cards[res].links?.[`youtube${currentLanguage}`]:
                                    //     currentLanguage === "zh" ? cards[res].links?.[`tiktok${currentLanguage}`]:
                                    //     cards[res].links?.[`youtube${currentLanguage}`]
                                    //     }

                                        to={getLinkByLanguage(currentLanguage, cards[res].links)}


                                    target={res.isThirdPartyUrl ? '_blank' : '_self'}
                                    className="d-flex align-items-end"
                                >
                             
                                    <div className={`card ${style.card}`}>
                                        <div className={`${style.logo_of_card} d-flex gap-2`}>
                                            <img src="/images/logo_for_video_link.png"></img>


                                            <div className="lufga_16px">{t(cards[res].title)}</div>

                                        </div>

                                        <img
                                            className={`${style.card_img_top} card-img-top`}
                                            src={`/images/${cards[res].image}`}
                                            alt={t("Card image cap")}
                                        />
                                        <div className={`card-body ${style.card_body}`}>
                                            <div className="lufga_18px px-2 py-3">
                                                <div className={`${style.lower_lower}`}>
                                                    <a
                                                        onClick={() => { console.log(cards[res].links?.[`youtube${currentLanguage}`]) }}
                                                        href={cards[res].links?.[`youtube${currentLanguage}`]}
                                                        >
                                                        <img
                                                            src="/images/youtube_vl.png"
                                                            alt="social_media_links"
                                                        />
                                                    </a>

                                                    <a href={cards[res].links?.[`tiktok${currentLanguage}`]}>
                                                        <img
                                                            src="/images/tik_tok_vl.png"
                                                            alt="social_media_links"
                                                        />
                                                    </a>
                                                    <a href={cards[res].links?.[`facebook${currentLanguage}`]}>
                                                        <img
                                                            src="/images/facebook_vl.png"
                                                            alt="social_media_links"
                                                        />
                                                    </a>

                                                    <a href={cards[res].links?.[`instagram${currentLanguage}`]}>
                                                        <img
                                                            src="/images/insta_vl.png"
                                                            alt="social_media_links"
                                                        />
                                                    </a>

                                                    <a href={cards[res].links?.[`twitter${currentLanguage}`]}>
                                                        <img
                                                            src="/images/twitter_vl.png"
                                                            alt="social_media_links"
                                                        />
                                                    </a>

                                                    <a href={cards[res].links?.[`bilibili${currentLanguage}`]}>
                                                        <img
                                                            src="/images/television_vl.png"
                                                            alt="social_media_links"
                                                        />
                                                    </a>

                                                    <a href={cards[res].links?.[`linkedin${currentLanguage}`]}>
                                                        <img
                                                            src="/images/linkedin_vl.png"
                                                            alt="social_media_links"
                                                        />
                                                    </a>

                                                    <a href={cards[res].links?.[`reddit${currentLanguage}`]}>
                                                        <img
                                                            src="/images/reddit_vl.png"
                                                            alt="social_media_links"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="any_question_section">
                <div className="left_of_any_question">
                    <div>
                        <img src="/images/aq1.png" alt="images" />
                    </div>
                    <div>
                        <img src="/images/aq2.png" alt="images" />
                    </div>
                    <div>
                        <img src="/images/aq3.png" alt="images" />
                    </div>
                </div>
                <div className="content_of_any_question d-flex flex-column gap-3">
                    <div className="upper_section_of_ask_question">
                        <div className="lufga_36px text-white">
                            {t("Do you still have any questions?")}
                        </div>
                    </div>
                    <div className="middle_section_of_ask_question">
                        <div className="lufga_20px text-white">
                            {t(
                                "Don't hesitate to leave us your phone number. We will contact you"
                            )}
                        </div>
                        <div className="lufga_20px text-white">
                            {t("to discuss any questions you may have")}
                        </div>
                    </div>
                    <div className="lower_section_of_ask_question d-flex justify-content-center">
                        <div className="input_width">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control py-2"
                                    placeholder={t("Enter your phone number")}
                                    aria-label="Recipient's username"
                                ></input>

                                <button
                                    type="button"
                                    className="btn btn-dark"
                                    id="button-addon2"
                                >
                                    {t("Submit")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right_of_any_question">
                    <div>
                        <img src="/images/aq4.png" alt="images" />
                    </div>
                    <div>
                        <img src="/images/aq5.png" alt="images" />
                    </div>
                    <div>
                        <img src="/images/aq6.png" alt="images" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoLinks;
