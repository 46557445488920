import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useOutletContext } from 'react-router';
import { resumeForm8 } from '../../../../service/Apis';
import { userId } from '../../../../service/userData';
import { resumeAllKeys } from './resumeKeys';
import moment from 'moment';
import "./ResumeSteps.css"
import Loader from '../../../../components/Loader/Loader';

export const ResumeStep8 = () => {
  let { t } = useTranslation();

  let { navigation, data } = useOutletContext()
  let [loader, setLoader] = useState(false)
  let step8Keys = resumeAllKeys.step8;

  // useEffect(() => {
  //   let obj = {}
  //   for (let i in step8Keys) {
  //     if (data.resumeData[i]) {
  //       obj[i] = data?.resumeData?.[i] || step8Keys[i]
  //     }
  //   }
  //   console.log(obj)
  //   setFormData(pre => ({ ...pre, ...obj }));

  // }, [data.resumeData])

  useEffect(() => {
    let obj = {};
    for (let i in step8Keys) {
      if (data.resumeData.hasOwnProperty(i)) {
        obj[i] = data?.resumeData?.[i]
      }
      else {
        obj[i] = step8Keys[i]
      }
    }
    setFormData((pre) => ({ ...pre, ...obj }));
  }, [data.resumeData]);
  const [formData, setFormData] = useState(
    {
      recent_OB: "",
      recent_pop_smear: "",
      result_pop_smear: "",
      blood_type: "",
      have_allergies: '',
      allergies_to_what: "",
      any_precription_medication: '',
      list_all_medications: "",
      tested_HIV_AIDS: '',
      hepatitis_B: '',
      list_hiv_hepatitis_result: "",
      suffered_depression: '',
      describe_suffered_depression: [],
      depression_treatment: [],
      seen_psychologist: '',
      explain_seen_psychologish: "",
      psychiatric_medication: '',
      explain_psychiatric_medication: "",
      hospitalized_psychiatric_issue: '',
      explain_hospitalized_psychiatric: "",
      eating_habits_detail: "",
      your_exercise: '',
      frequency_exercise: "",
      eating_disorder: '',
      explain_eating_disorder: "",
      have_any_surgery: '',
      explain_surgery: "",
      hospitalized_major_illness: '',
      explain_hospitalized_major_illness: "",
      chronic_medical_conditions: '',
      explain_chronic_medical_conditions: "",
      smoke_cigarettes: '',
      smoke_cigarettes_Frequency: "",
      smoked_tobacco_past_year: '',
      date_smoked_tobacco: "",
      smoked_tobacco_inHome: '',
      explain_smoked_tobacco_inHome: "",
      drink_alcohol: '',
      drink_alcohol_often: "",
      ever_used_marijuana: '',
      explain_ever_used_marijuana: "",
      ever_used_illegal_drugs: '',
      explain_ever_used_illegal_drugs: "",
      spouse_ever_diagnosed_STDs: [],
      explain_spouse_diagnosed_STDs: "",
      diagnosed_any_illnesses: [],
      explain_diagnosed_any_illnesses: "",
    }
  )

  const handleInputChange2 = (e) => {

    const { name, value, type, checked } = e.target;

    if(value == " "){
      e.target.value = ''
    }else{
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

  };

  function radioInputChange(e, val) {
    let name = e.target.name

    setFormData((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };
  function multipleCheckBoxChange(e) {

    let { value, checked } = e.target;
    let datas = formData.describe_suffered_depression
    if (checked) {
      datas?.push(value);
      setFormData({ ...formData, ...{ describe_suffered_depression: datas } })
    }
    else {
      datas.splice(datas.indexOf(value), 1);

      setFormData({ ...formData, ...{ describe_suffered_depression: datas } })

    }
  }

  function multipleCheckBoxChange2(e) {

    let { value, checked } = e.target;
    let datas = formData.depression_treatment
    if (checked) {
      datas?.push(value);
      setFormData({ ...formData, ...{ depression_treatment: datas } })
    }
    else {
      datas.splice(datas.indexOf(value), 1);

      setFormData({ ...formData, ...{ depression_treatment: datas } })

    }


  }

  function multipleCheckBoxChange3(e) {

    let { value, checked } = e.target;
    let datas = formData.spouse_ever_diagnosed_STDs
    if (checked) {
      datas?.push(value);
      setFormData({ ...formData, ...{ spouse_ever_diagnosed_STDs: datas } })
    }
    else {
      datas.splice(datas.indexOf(value), 1);

      setFormData({ ...formData, ...{ spouse_ever_diagnosed_STDs: datas } })

    }

  }

  function multipleCheckBoxChange4(e) {

    let { value, checked } = e.target;
    let datas = formData.diagnosed_any_illnesses
    if (checked) {
      datas?.push(value);
      setFormData({ ...formData, ...{ diagnosed_any_illnesses: datas } })
    }
    else {
      datas.splice(datas.indexOf(value), 1);

      setFormData({ ...formData, ...{ diagnosed_any_illnesses: datas } })

    }


  }

  async function formsubmit8(e) {
    e.preventDefault()
    setLoader(true)
    let userID = userId();
    let data = { ...formData };

    await resumeForm8(userID, data).then((res) => {
      setLoader(false)
      navigation.next()

    }).catch((err) => {
      console.log(err)
      setLoader(false)
    })

  }
  return (
    <>
      <Loader open={loader} />
      <div className="ResumeStep_con">
        <div className='Spouse / Partner Information'>
          <form onSubmit={formsubmit8}>
            <div >
              <h1 className='SubHeading mb-lg-4 mb-md-4 mb-sm-3 mb-3' >{t("Personal Health and Medical Information")}</h1>

            </div>
            <div className="First_section">
              <div className="row pb-4">
                <div className="col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Date of most recent OB/GYN visit")}<span className='text-danger'>*</span></label>
                  <input type="Date" className="form-control" placeholder="" required name='recent_OB' value={formData.recent_OB && moment(formData.recent_OB).format("YYYY-MM-DD")} onChange={handleInputChange2} />
                </div>
                <div className="col-md-6">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Date of most recent pap smear")}<span className='text-danger'>*</span></label>
                  <input type="Date" className="form-control" placeholder="" required name='recent_pop_smear' value={formData.recent_pop_smear && moment(formData.recent_pop_smear).format("YYYY-MM-DD")} onChange={handleInputChange2} />
                </div>
              </div>


              <div className="row pb-4">
                <div className="col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5">{t("Results of most recent pap smear")}<span className='text-danger'>*</span></label>
                  <input type="text" className="form-control" placeholder="" required name='result_pop_smear' defaultValue={formData.result_pop_smear} onChange={handleInputChange2} />
                </div>
                <div className="col-md-6">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("What is your blood type?")}<span className='text-danger'>*</span></label>
                  <input type="text" className="form-control" placeholder="" required name='blood_type' defaultValue={formData.blood_type} onChange={handleInputChange2} />
                </div>
              </div>


              <div className='CheckBoxParent row pb-4'>
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Do you have any allergies?")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="have_allergies" checked={formData.have_allergies === true} onChange={(e) => { radioInputChange(e, true) }} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input required className="form-check-input" type="radio" name="have_allergies" checked={formData.have_allergies === false} onChange={(e) => {
                    radioInputChange(e, false)
                    setFormData(pre => {
                      return {
                        ...pre,
                        allergies_to_what: ''
                      }
                    })
                  }} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>


              {formData.have_allergies !== '' ? formData.have_allergies ?
                <div className="row pb-4">

                  <div className="col-md-12">
                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("To what?")}<span className='text-danger'>*</span></label>
                    <input required type="text" className="form-control" placeholder="" name='allergies_to_what' defaultValue={formData.allergies_to_what} onChange={handleInputChange2} />
                  </div>
                </div> : '' : ''}

              <div className='CheckBoxParent row pb-4'>
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Do you currently take any prescription or over-the-counter medications?")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="any_precription_medication" checked={formData.any_precription_medication == true} onChange={(e) => { radioInputChange(e, true) }} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input required className="form-check-input" type="radio" name="any_precription_medication" checked={formData.any_precription_medication === false} onChange={(e) => {
                    radioInputChange(e, false)
                    setFormData(pre => {
                      return {
                        ...pre,
                        list_all_medications: ''
                      }
                    })
                  }} />
                  <label className="form-check-label LabelSubHeading"  >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>



              {formData.any_precription_medication !== '' ? formData.any_precription_medication ? <div className="row pb-4">
                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list all medications and the reason you take them")}<span className='text-danger'>*</span></label>
                  <textarea type="text" className="form-control" placeholder="" required name="list_all_medications" rows={5} defaultValue={formData.list_all_medications} onChange={handleInputChange2} />
                </div>
              </div> : '' : ''}

              <div className="row pb-4">
                <div className='col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4'>
                  <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                    {t("Have you ever been tested for HIV/AIDS?")}<span className='text-danger'>*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" required name="tested_HIV_AIDS" checked={formData.tested_HIV_AIDS === true} onChange={(e) => { radioInputChange(e, true) }} />
                        <label className="form-check-label LabelSubHeading" >
                          {t('Yes')}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">  <div className="form-check">
                      <input className="form-check-input" type="radio" required name="tested_HIV_AIDS" checked={formData.tested_HIV_AIDS === false} onChange={(e) => {
                        radioInputChange(e, false)
                        if (formData.hepatitis_B === false) {
                          setFormData(pre => {
                            return {
                              ...pre,
                              list_hiv_hepatitis_result: ''
                            }
                          })
                        }
                      }} />
                      <label className="form-check-label LabelSubHeading" >
                        {t("No")}
                      </label>
                    </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                    {t("Do you have Hepatitis B?")}<span className='text-danger'>*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-check">
                        <input className="form-check-input" required type="radio" name="hepatitis_B" checked={formData.hepatitis_B === true} onChange={(e) => { radioInputChange(e, true) }} />
                        <label className="form-check-label LabelSubHeading" >
                          {t("Yes")}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">  <div className="form-check">
                      <input required className="form-check-input" type="radio" name="hepatitis_B" checked={formData.hepatitis_B === false} onChange={(e) => {
                        radioInputChange(e, false)
                        if (formData.tested_HIV_AIDS === false) {
                          setFormData(pre => {
                            return {
                              ...pre,
                              list_hiv_hepatitis_result: ''
                            }
                          })
                        }
                      }} />
                      <label className="form-check-label LabelSubHeading" >
                        {t("No")}
                      </label>
                    </div>
                    </div>
                  </div>
                </div>
              </div>


              {formData.hepatitis_B !== '' || formData.tested_HIV_AIDS !== '' ? formData.hepatitis_B || formData.tested_HIV_AIDS ? <div className="row pb-4">
                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("List date(s) and results")}<span className='text-danger'>*</span></label>
                  <textarea rows={5} type="text" required className="form-control" placeholder="" name='list_hiv_hepatitis_result' defaultValue={formData.list_hiv_hepatitis_result} onChange={handleInputChange2} />
                </div>
              </div> : '' : ''}


              <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Have you ever suffered from depression?")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="suffered_depression" checked={formData.suffered_depression === true} onChange={(e) => { radioInputChange(e, true) }} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input required className="form-check-input" type="radio" name="suffered_depression" checked={formData.suffered_depression === false} onChange={(e) => {
                    radioInputChange(e, false)
                    setFormData(pre => {
                      return {
                        ...pre,
                        describe_suffered_depression: [],
                        depression_treatment: []
                      }
                    })
                  }} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>

              {formData.suffered_depression !== '' ? formData.suffered_depression ? <><div className='CheckBoxParent row pb-4'>
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Please select the option(s) that best describe your depression")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">

                    <input className="form-check-input" type="checkbox" defaultValue value="Mild" name='describe_suffered_depression' checked={formData.describe_suffered_depression.includes("Mild")} onChange={multipleCheckBoxChange} required={formData?.describe_suffered_depression.length == 0} />
                    <label className="form-check-label LabelSubHeading" >

                      {t("Mild")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input className="form-check-input" type="checkbox" defaultValue value="Moderate" name='describe_suffered_depression' checked={formData.describe_suffered_depression.includes("Moderate")} onChange={multipleCheckBoxChange} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Moderate")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">
                  <input className="form-check-input" type="checkbox" defaultValue value="Severe" name='describe_suffered_depression' checked={formData.describe_suffered_depression.includes("Severe")} onChange={multipleCheckBoxChange} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Severe")}
                  </label>
                </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input className="form-check-input" type="checkbox" defaultValue value="Postpartum" name='describe_suffered_depression' checked={formData.describe_suffered_depression.includes("Postpartum")} onChange={multipleCheckBoxChange} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Postpartum")}
                  </label>
                </div>
                </div>


              </div>

                <div className='CheckBoxParent row pb-4'>
                  <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                    {t("Please select the option(s) that described your treatment")}<span className='text-danger'>*</span>
                  </label>
                  <div className="col-md-2">
                    <div className="form-check">

                      <input className="form-check-input" type="checkbox" defaultValue value="Medication" checked={formData.depression_treatment.includes("Medication")} name='depression_treatment'
                        required={formData?.depression_treatment?.length == 0}
                        onChange={multipleCheckBoxChange2} />
                      <label className="form-check-label LabelSubHeading" >


                        {t("Medication")}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-2">  <div className="form-check">
                    <input className="form-check-input" type="checkbox" defaultValue value="Counseling" checked={formData.depression_treatment.includes("Counseling")} name='depression_treatment' onChange={multipleCheckBoxChange2} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Counseling")}
                    </label>
                  </div>
                  </div>
                  <div className="col-md-2">  <div className="form-check">
                    <input className="form-check-input" type="checkbox" defaultValue value="Exercise" checked={formData.depression_treatment.includes("Exercise")} name='depression_treatment' onChange={multipleCheckBoxChange2} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Exercise")}
                    </label>
                  </div>
                  </div>

                  <div className="col-md-3">  <div className="form-check">
                    <input className="form-check-input" type="checkbox" defaultValue value="Lifestyle modification" checked={formData.depression_treatment.includes("Lifestyle modification")} name='depression_treatment' onChange={multipleCheckBoxChange2} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Lifestyle modification")}
                    </label>
                  </div>
                  </div>
                  <div className="col-md-2">  <div className="form-check">
                    <input className="form-check-input" type="checkbox" defaultValue value="Other" checked={formData.depression_treatment.includes("Other")} name='depression_treatment' onChange={multipleCheckBoxChange2} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Other")}
                    </label>
                  </div>
                  </div>

                </div></> : '' : ''}

              <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Have you ever seen a psychologist or any other mental health professional for any reason?")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="seen_psychologist" checked={formData.seen_psychologist === true} onChange={(e) => { radioInputChange(e, true) }} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input required className="form-check-input" type="radio" name="seen_psychologist" checked={formData.seen_psychologist === false} onChange={(e) => {
                    radioInputChange(e, false)
                    setFormData(pre => {
                      return {
                        ...pre,
                        explain_seen_psychologish: ''
                      }
                    })
                  }} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>

              {formData.seen_psychologist !== '' ? formData.seen_psychologist ? <div className="row pb-4">
                <div className="col-md-12 ">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list dates and explain")}<span className='text-danger'>*</span></label>
                  <textarea required rows={5} type="text" className="form-control" placeholder="" name='explain_seen_psychologish' defaultValue={formData.explain_seen_psychologish} onChange={handleInputChange2} />
                </div>
              </div> : '' : ''}


              <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Have you ever been hospitalized due to a psychiatric issue?")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="psychiatric_medication" checked={formData.psychiatric_medication === true} onChange={(e) => { radioInputChange(e, true) }} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input required className="form-check-input" type="radio" name="psychiatric_medication" checked={formData.psychiatric_medication === false} onChange={(e) => {
                    radioInputChange(e, false)
                    setFormData(pre => {
                      return {
                        ...pre,
                        explain_psychiatric_medication: ''
                      }
                    })
                  }} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>

              {formData.psychiatric_medication !== '' ? formData.psychiatric_medication ? <div className="row pb-4">
                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list dates and explain")}<span className='text-danger'>*</span></label>
                  <textarea rows={5} required type="text" className="form-control" placeholder="" name='explain_psychiatric_medication' defaultValue={formData.explain_psychiatric_medication} onChange={handleInputChange2} />
                </div>
              </div> : '' : ''}

              <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Have you ever been prescribed psychiatric medication(s) for any reason?")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="hospitalized_psychiatric_issue" checked={formData.hospitalized_psychiatric_issue === true} onChange={(e) => { radioInputChange(e, true) }} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input required className="form-check-input" type="radio" name="hospitalized_psychiatric_issue" checked={formData.hospitalized_psychiatric_issue === false} onChange={(e) => {
                    radioInputChange(e, false)
                    setFormData(pre => {
                      return {
                        ...pre,
                        explain_hospitalized_psychiatric: ''
                      }
                    })
                  }} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>

              {formData.hospitalized_psychiatric_issue !== '' ? formData.hospitalized_psychiatric_issue ? <div className="row pb-4">
                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list dates and explain")}<span className='text-danger'>*</span></label>
                  <textarea required rows={5} type="text" className="form-control" placeholder="" name='explain_hospitalized_psychiatric' defaultValue={formData.explain_hospitalized_psychiatric} onChange={handleInputChange2} />
                </div>
              </div> : '' : ''}

              {/* <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Have you ever been hospitalized due to a psychiatric issue?")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label LabelSubHeading" htmlFor="flexRadioDefault1">
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  <label className="form-check-label LabelSubHeading" htmlFor="flexRadioDefault1">
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>
              <div className="row pb-4">

                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list dates and explain")}<span className='text-danger'>*</span></label>
                  <input type="text" className="form-control" placeholder="" />
                </div>
              </div> */}
              <div className="row pb-4">

                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >
                    {t("Please describe your eating habits in detail. In example, specify if you are a vegetarian/vegan,how many meals you eat in a typical day, what types of food you eat on a regular basis, etc.")}
                    <span className='text-danger'>*</span></label>
                  <textarea rows={5} required type="text" className="form-control" placeholder="" name='eating_habits_detail' defaultValue={formData.eating_habits_detail} onChange={handleInputChange2} />
                </div>
              </div>

              <div className="row pb-4">
                <div className="col-md-6">
                  <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                    {t("Do you exercise?")}<span className='text-danger'>*</span>
                  </label>
                  <div className='row'>
                    <div className="col-md-6">
                      <div className="form-check">
                        <input required className="form-check-input" type="radio" name="your_exercise" checked={formData.your_exercise === true} onChange={(e) => { radioInputChange(e, true) }} />
                        <label className="form-check-label LabelSubHeading" >
                          {t("Yes")}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-check">
                        <input required className="form-check-input" type="radio" name="your_exercise" checked={formData.your_exercise === false} onChange={(e) => {
                          radioInputChange(e, false)
                          setFormData(pre => {
                            return {
                              ...pre,
                              frequency_exercise: ''
                            }
                          })
                        }} />
                        <label className="form-check-label LabelSubHeading" >
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              {formData.your_exercise !== '' ? formData.your_exercise ? <div className='row mb-4'>
                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >
                    {t("Frequency of exercise")}
                    <span className='text-danger'>*</span></label>
                  <textarea rows={5} required type="text" className="form-control" placeholder="" name='frequency_exercise' defaultValue={formData.frequency_exercise} onChange={handleInputChange2} />
                </div>
              </div> : '' : ''}

              <div className="row pb-4">
                <div className="col-md-6">
                  <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                    {t("Have you ever had an eating disorder?")}<span className='text-danger'>*</span>
                  </label>
                  <div className='row'>
                    <div className="col-md-6">
                      <div className="form-check">
                        <input required className="form-check-input" type="radio" name="eating_disorder" checked={formData.eating_disorder === true} onChange={(e) => { radioInputChange(e, true) }} />
                        <label className="form-check-label LabelSubHeading" >
                          {t("Yes")}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-check">
                        <input required className="form-check-input" type="radio" name="eating_disorder" checked={formData.eating_disorder === false} onChange={(e) => {
                          radioInputChange(e, false)
                          setFormData(pre => {
                            return {
                              ...pre,
                              explain_eating_disorder: ''
                            }
                          })
                        }} />
                        <label className="form-check-label LabelSubHeading" >
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              {formData.eating_disorder !== '' ? formData.eating_disorder ? <div className="row pb-4">
                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list dates and explain")}<span className='text-danger'>*</span></label>
                  <textarea rows={5} required type="text" className="form-control" placeholder="" name='explain_eating_disorder' value={formData.explain_eating_disorder} onChange={handleInputChange2} />
                </div>
              </div> : '' : ''}


              <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Have you ever had surgery (minor or major)?")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="have_any_surgery" checked={formData.have_any_surgery === true} onChange={(e) => { radioInputChange(e, true) }} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input required className="form-check-input" type="radio" name="have_any_surgery" checked={formData.have_any_surgery === false} onChange={(e) => {
                    radioInputChange(e, false)
                    setFormData(pre => {
                      return {
                        ...pre,
                        explain_surgery: ''
                      }
                    })
                  }} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>


              {formData.have_any_surgery !== '' ? formData.have_any_surgery ? <div className="row pb-4">
                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list date(s) and reason(s)")}<span className='text-danger'>*</span></label>
                  <textarea rows={5} required type="text" className="form-control" placeholder="" name='explain_surgery' defaultValue={formData.explain_surgery} onChange={handleInputChange2} />
                </div>
              </div> : '' : ''}


              <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Outside of childbirth, have you ever been hospitalized or had a major illness?")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="hospitalized_major_illness" checked={formData.hospitalized_major_illness === true} onChange={(e) => { radioInputChange(e, true) }} />
                    <label className="form-check-label LabelSubHeading">
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input required className="form-check-input" type="radio" name="hospitalized_major_illness" checked={formData.hospitalized_major_illness === false} onChange={(e) => {
                    radioInputChange(e, false)
                    setFormData(pre => {
                      return {
                        ...pre,
                        explain_hospitalized_major_illness: ''
                      }
                    })
                  }} />
                  <label className="form-check-label LabelSubHeading">
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>

              {formData.hospitalized_major_illness !== '' ? formData.hospitalized_major_illness ? <div className="row pb-4">

                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list date(s) and reasons(s)")}<span className='text-danger'>*</span></label>
                  <textarea rows={5} required type="text" className="form-control" placeholder="" name='explain_hospitalized_major_illness' defaultValue={formData.explain_hospitalized_major_illness} onChange={handleInputChange2} />
                </div>
              </div> : '' : ''}

            </div>
            <div className="Second_Section">

              <h1 className='SubHeading mb-lg-4 mb-md-4 mb-sm-3 mb-3'>{t("Personal Health and Medical Information (continued)")}</h1>


              <div className="row pb-4">
                <div className='col-md-6'>
                  <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                    {t("Do you have any chronic medical conditions/concerns?")}<span className='text-danger'>*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-check">
                        <input required className="form-check-input" type="radio" name="chronic_medical_conditions" checked={formData.chronic_medical_conditions === true} onChange={(e) => { radioInputChange(e, true) }} />
                        <label className="form-check-label LabelSubHeading" >
                          {t("Yes")}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">  <div className="form-check">
                      <input required className="form-check-input" type="radio" name="chronic_medical_conditions" checked={formData.chronic_medical_conditions === false} onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData(pre => {
                          return {
                            ...pre,
                            explain_chronic_medical_conditions: ''
                          }
                        })
                      }} />
                      <label className="form-check-label LabelSubHeading" >
                        {t("No")}
                      </label>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              {formData.chronic_medical_conditions !== '' ? formData.chronic_medical_conditions ? <div className="row pb-4">

                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list date(s) and condition(s)")}<span className='text-danger'>*</span></label>
                  <textarea rows={5} required type="text" className="form-control" placeholder="" name='explain_chronic_medical_conditions' defaultValue={formData.explain_chronic_medical_conditions} onChange={handleInputChange2} />
                </div>
              </div> : '' : ''}
              <div className="row pb-4">
                <div className='col-md-6'>
                  <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                    {t("Do you smoke cigarettes?")}<span className='text-danger'>*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-check">
                        <input required className="form-check-input" type="radio" name="smoke_cigarettes" checked={formData.smoke_cigarettes === true} onChange={(e) => { radioInputChange(e, true) }} />
                        <label className="form-check-label LabelSubHeading" >
                          {t("Yes")}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">  <div className="form-check">
                      <input required className="form-check-input" type="radio" name="smoke_cigarettes" checked={formData.smoke_cigarettes === false} onChange={(e) => {
                        radioInputChange(e, false)
                        setFormData(pre => {
                          return {
                            ...pre,
                            smoke_cigarettes_Frequency: ''
                          }
                        })
                      }} />
                      <label className="form-check-label LabelSubHeading" >
                        {t("No")}
                      </label>
                    </div>
                    </div>
                  </div>
                </div>

              </div>

              {formData.smoke_cigarettes !== '' ? formData.smoke_cigarettes ? <div className='row pb-4'>
                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("If yes, how often?")}<span className='text-danger'>*</span></label>
                  <input required type="text" className="form-control" placeholder="" name='smoke_cigarettes_Frequency' value={formData.smoke_cigarettes_Frequency} onChange={handleInputChange2} />
                </div>
              </div> : '' : ''}



              <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Have you smoked any form of tobacco within the past year?")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="smoked_tobacco_past_year" checked={formData.smoked_tobacco_past_year === true} onChange={(e) => { radioInputChange(e, true) }} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input required className="form-check-input" type="radio" name="smoked_tobacco_past_year" checked={formData.smoked_tobacco_past_year === false} onChange={(e) => {
                    radioInputChange(e, false)
                    setFormData(pre => {
                      return {
                        ...pre,
                        date_smoked_tobacco: ''
                      }
                    })
                  }} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>
              {formData.smoked_tobacco_past_year !== '' ? formData.smoked_tobacco_past_year ? <div className="row pb-4">

                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list date(s)")}<span className='text-danger'>*</span></label>
                  <textarea rows={5} required type="text" className="form-control" placeholder="" name='date_smoked_tobacco' defaultValue={formData.date_smoked_tobacco} onChange={handleInputChange2} />
                </div>
              </div> : '' : ''}



              <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Does anybody in your home smoke any tobacco products?")} <span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="smoked_tobacco_inHome" checked={formData.smoked_tobacco_inHome === true} onChange={(e) => { radioInputChange(e, true) }} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input required className="form-check-input" type="radio" name="smoked_tobacco_inHome" checked={formData.smoked_tobacco_inHome === false} onChange={(e) => {
                    radioInputChange(e, false)
                    setFormData(pre => {
                      return {
                        ...pre,
                        explain_smoked_tobacco_inHome: ''
                      }
                    })
                  }} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>
              {formData.smoked_tobacco_inHome !== '' ? formData.smoked_tobacco_inHome ? <div className="row pb-4">

                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please share who, which type of product, and frequency of use")}<span className='text-danger'>*</span></label>
                  <textarea rows={5} required type="text" className="form-control" placeholder="" name='explain_smoked_tobacco_inHome' defaultValue={formData.explain_smoked_tobacco_inHome} onChange={handleInputChange2} />
                </div></div> : '' : ''}


              {/* <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Does anybody in your home smoke any tobacco products?")} <span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label LabelSubHeading" htmlFor="flexRadioDefault1">
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  <label className="form-check-label LabelSubHeading" htmlFor="flexRadioDefault1">
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>

              <div className="row pb-4">

                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >Please share who, which type of product, and frequency of use<span className='text-danger'>*</span></label>
                  <input type="text" className="form-control" placeholder="" />
                </div>
              </div> */}
              <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Do you drink alcohol?")} <span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="drink_alcohol" checked={formData.drink_alcohol === true} onChange={(e) => { radioInputChange(e, true) }} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input required className="form-check-input" type="radio" name="drink_alcohol" checked={formData.drink_alcohol === false} onChange={(e) => {
                    radioInputChange(e, false)
                    setFormData(pre => {
                      return {
                        ...pre,
                        drink_alcohol_often: ''
                      }
                    })
                  }} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>
              {formData.drink_alcohol !== '' ? formData.drink_alcohol ? <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("How often?")} <span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="drink_alcohol_often" value="Daily" checked={formData.drink_alcohol_often === "Daily"} onChange={handleInputChange2} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Daily")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input required className="form-check-input" type="radio" name="drink_alcohol_often" value="Weekly" checked={formData.drink_alcohol_often === "Weekly"} onChange={handleInputChange2} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Weekly")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="drink_alcohol_often" value="Socially" checked={formData.drink_alcohol_often === "Socially"} onChange={handleInputChange2} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Socially")}
                    </label>
                  </div>
                </div>
              </div> : '' : ''}
              <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Have you ever used marijuana (including medicinally)?")} <span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="ever_used_marijuana" checked={formData.ever_used_marijuana === true} onChange={(e) => { radioInputChange(e, true) }} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input required className="form-check-input" type="radio" name="ever_used_marijuana" checked={formData.ever_used_marijuana === false} onChange={(e) => {
                    radioInputChange(e, false)
                    setFormData(pre => {
                      return {
                        ...pre,
                        explain_ever_used_marijuana: ''
                      }
                    })
                  }} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>


              {formData.ever_used_marijuana !== '' ? formData.ever_used_marijuana ? <div className="row pb-4">

                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list date(s) and reason used")}<span className='text-danger'>*</span></label>
                  <textarea rows={5} required type="text" className="form-control" placeholder="" name='explain_ever_used_marijuana' defaultValue={formData.explain_ever_used_marijuana} onChange={handleInputChange2} />
                </div>
              </div> : '' : ''}
              <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Have you ever used illegal drugs?")}<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">
                  <div className="form-check">
                    <input required className="form-check-input" type="radio" name="ever_used_illegal_drugs" checked={formData.ever_used_illegal_drugs === true} onChange={(e) => { radioInputChange(e, true) }} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Yes")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">
                  <input required className="form-check-input" type="radio" name="ever_used_illegal_drugs" checked={formData.ever_used_illegal_drugs === false} onChange={(e) => {
                    radioInputChange(e, false)
                    setFormData(pre => {
                      return {
                        ...pre,
                        explain_ever_used_illegal_drugs: ''
                      }
                    })
                  }} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("No")}
                  </label>
                </div>
                </div>
              </div>
              {formData.ever_used_illegal_drugs !== '' ? formData.ever_used_illegal_drugs ? <div className="row pb-4">

                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list date(s) and type of substance used")}<span className='text-danger'>*</span></label>
                  <textarea rows={5} required type="text" className="form-control" placeholder="" name='explain_ever_used_illegal_drugs' defaultValue={formData.explain_ever_used_illegal_drugs} onChange={handleInputChange2} />
                </div>
              </div> : '' : ''}


              <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Have either you or your spouse/partner ever been diagnosed with any of the following STDs?")}{ }<span className='text-danger'>*</span>
                </label>
                <div className="col-md-3">

                  <div className="form-check">

                    <input required={formData?.spouse_ever_diagnosed_STDs?.length == 0} className="form-check-input" type="checkbox" defaultValue value="Gonorrhea" name='spouse_ever_diagnosed_STDs' checked={formData.spouse_ever_diagnosed_STDs.includes("Gonorrhea")} onChange={multipleCheckBoxChange3} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Gonorrhea")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Hepatitis C" name='spouse_ever_diagnosed_STDs' checked={formData.spouse_ever_diagnosed_STDs.includes("Hepatitis C")} onChange={multipleCheckBoxChange3} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Hepatitis C")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="HIV" name='spouse_ever_diagnosed_STDs' checked={formData.spouse_ever_diagnosed_STDs.includes("HIV")} onChange={multipleCheckBoxChange3} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("HIV")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Syphilis" name='spouse_ever_diagnosed_STDs' checked={formData.spouse_ever_diagnosed_STDs.includes("Syphilis")} onChange={multipleCheckBoxChange3} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Syphilis")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Hepatitis B" name='spouse_ever_diagnosed_STDs' checked={formData.spouse_ever_diagnosed_STDs.includes("Hepatitis B")} onChange={multipleCheckBoxChange3} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Hepatitis B")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Herpes" name='spouse_ever_diagnosed_STDs' checked={formData.spouse_ever_diagnosed_STDs.includes("Herpes")} onChange={multipleCheckBoxChange3} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Herpes")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Genital warts/sores" name='spouse_ever_diagnosed_STDs' checked={formData.spouse_ever_diagnosed_STDs.includes("Genital warts/sores")} onChange={multipleCheckBoxChange3} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Genital warts/sores")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="None of the above" name='spouse_ever_diagnosed_STDs' checked={formData.spouse_ever_diagnosed_STDs.includes("None of the above")} onChange={multipleCheckBoxChange3} />
                  <label className="form-check-label LabelSubHeading" >
                    {t(" None of the above")}
                  </label>
                </div>
                </div>

              </div>

              <div className="row pb-4">
                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please explain in detail, including date(s)")} - {t('(If any)')}</label>
                  <textarea rows={5} type="text" className="form-control" placeholder="" name='explain_spouse_diagnosed_STDs' defaultValue={formData.explain_spouse_diagnosed_STDs} onChange={handleInputChange2} />
                </div>
              </div>


              <div className="row pb-4">
                <label className="form-label fs-5" htmlFor="flexRadioDefault1">
                  {t("Have you ever been diagnosed with any of the following conditions/illnesses (check any that apply)")}<span className='text-danger'>*</span>:
                </label>
                <div className="col-md-3">

                  <div className="form-check">

                    <input required={formData?.diagnosed_any_illnesses?.length == 0} className="form-check-input" type="checkbox" defaultValue value="Anemia" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Anemia")} onChange={multipleCheckBoxChange4} />
                    <label className="form-check-label LabelSubHeading" >
                      {t("Anemia")}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Asthma" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Asthma")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Asthma")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Back or neck problems" checked={formData.diagnosed_any_illnesses.includes("Back or neck problems")} name='diagnosed_any_illnesses' onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {("Back or neck problems")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Beta Strep" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Beta Strep")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Beta Strep")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Cancer" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Cancer")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Cancer")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Diabetes" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Diabetes")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Diabetes")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Head injury" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Head injury")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Head injury")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Heart problems" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Heart problems")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t('Heart problems')}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Hemorrhoids" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Hemorrhoids")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Hemorrhoids")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="High blood pressure" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("High blood pressure")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("High blood pressure")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Kidney disease/infection" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Kidney disease/infection")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Kidney disease/infection")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Irregular heartbeat" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Irregular heartbeat")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Irregular heartbeat")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Kidney stones" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Kidney stones")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Kidney stones")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Liver disease/infection" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Liver disease/infection")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Liver disease/infection")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Migraine" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Migraine")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Migraine")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Ovarian cysts" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Ovarian cysts")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Ovarian cysts")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Pelvic Inflammatory Disease (PID)" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Pelvic Inflammatory Disease (PID)")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Pelvic Inflammatory Disease (PID)")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Seizures" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Seizures")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Seizures")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Thyroid problem" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Thyroid problem")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Thyroid problem")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Tuberculosis" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Tuberculosis")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Tuberculosis")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Uterine fibroid" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Uterine fibroid")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Uterine fibroid")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Vaginal discharge" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Vaginal discharge")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Vaginal discharge")}
                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="Varicose veins" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("Varicose veins")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("Varicose veins")}

                  </label>
                </div>
                </div>
                <div className="col-md-3">  <div className="form-check">

                  <input className="form-check-input" type="checkbox" defaultValue value="None of the above" name='diagnosed_any_illnesses' checked={formData.diagnosed_any_illnesses.includes("None of the above")} onChange={multipleCheckBoxChange4} />
                  <label className="form-check-label LabelSubHeading" >
                    {t("None of the above")}
                  </label>
                </div>
                </div>



              </div>
              <div className="row pb-4">

                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please explain in detail, including date(s)")} - {t('(If any)')}</label>
                  <textarea rows={5} type="text" className="form-control" placeholder="" name='explain_diagnosed_any_illnesses' defaultValue={formData.explain_diagnosed_any_illnesses} onChange={handleInputChange2} />
                </div>
              </div>

            </div>

            <div className="ButtonForm mt-3">
              <button className="btn_non_fill" type="button" onClick={navigation.previous}>{t('Previous')}</button>
              <button className="btn_fill" type="submit">{t('Next')}</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
