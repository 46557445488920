import { Dialog } from 'primereact/dialog';
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha'
import { useNavigate, useParams } from "react-router";
import { sendOptOnPhoneNumber, signUpUser, verifyOptByPhoneNumber, verifyPhoneNumber } from '../../../service/Apis';
import { LuCheckCircle } from "react-icons/lu";
import { FaCheck } from "react-icons/fa";
import Loader from '../../../components/Loader/Loader';
const surrogate_donor = 'surrogate_donor';
export const DialogSignUp = ({ visible, setVisible }) => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const { role } = useParams()
  const [signUpSuccess, setSignUpSuccess] = useState(false)
  const formRef = useRef({
    fullName: "",
    mobile_No: "",
    email: "",
    password: "",
    role: "",
    agree_teams_policy: false
  });
  const [codeValue, setCodeValue] = useState('')
  const [term, setTerm] = useState(false)
  const [optErr, setOptErr] = useState('')
  const [getCodeLoader, setGetCodeLoader] = useState(0)








  let { t } = useTranslation()
  const [formData, setFormData] = useState({
    fullName: '',
    mobile_No: '',
    email: '',
    password: '',
    role: surrogate_donor,
    agree_teams_policy: true

  });

  let [captchaValue, setCaptchaValue] = useState(null)

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordMatch] = useState(true)
  const [error, setError] = useState('');
  const [error2, setError2] = useState('');
  const [error3, setError3] = useState('');
  const [phoneNumberErr, setPhoneNumberErr] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [emailerr, setEmailerr] = useState('')


  function inpChange(e) {
    let { name, value } = e.target;

    if (name === 'password') {
      if (value.trim() !== '') {
        if (
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{":;'?/><,.\[\]\\]).{8,}$/.test(
            value
          )
        ) {
          setError2('');
          setError('');
          setError3('');
        } else {
          if (value.length < 8) {
            setError2('Password must have at least 8 characters');
          } else {
            setError2('');
          }

          if (!/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
            setError('A mix of both uppercase and lowercase letters is required');
          } else {
            setError('');
          }

          if (!/[!@#$%^&*()_+}{":;'?/><,.\[\]\\]/.test(value)) {
            setError3('Inclusion of at least one special character (e.g., @ # ?) is required');
          } else {
            setError3('');
          }
        }
      } else {
        setError('');
        setError2('');
        setError3('');
      }
    } else if (name === 'confirmPassword') {
      if (formData.password === value) {
        setPasswordMatch(true)
      } else {
        setPasswordMatch(false)
      }
    }


    setFormData(prevState => ({ ...prevState, [name]: value }));

  }


  const firstSubmit = async (e) => {
    e.preventDefault();
    let res = ''
    // if (!captchaValue) {
    //   alert("Please complete the reCAPTCHA verification.")
    //   return
    // }

    if (!term) {
      alert('Please Read and Agree First')
      return
    }

    if (!passwordsMatch) {
      return
    }



    if (formData.password.length < 8) {
      setError('Password must be at least 8 characters long.');
      return;
    }

    let payload = {
      ...formRef.current,
      role: role,
      password: formData.password,
      agree_teams_policy: term
    }
    try {
      setLoader(true)
      let optVarification = await verifyOptByPhoneNumber({ mobileNo: formRef.current.mobile_No, otp: codeValue })

      if (optVarification.data.data == 'Otp Expaired') {
        setOptErr(optVarification.data.data)
        setLoader(false)
        return
      } else if (optVarification.data.data == 'Enter correct OTP') {
        setOptErr(optVarification.data.data)
        setLoader(false)
        return
      }
      setLoader(true)
      res = await signUpUser(payload)
      if (res.status == 201) {
        await verifyPhoneNumber(res.data.data._id, { phoneVerify: true })
        setSignUpSuccess(true)
        if (res?.data?.data.role === 'surrogate_donor') {
          localStorage.setItem("userData", JSON.stringify(res.data.data))
          navigate("resume/step-1")
          setLoader(false)
        }

      } else {
        setEmailerr(res.response.data.errormessage)
        console.log(res.response.data.errormessage)
      }
      setLoader(false)

    } catch (err) {
      setLoader(false)
      console.log(err)

    }


  };



  function captchaChange(e) {
    setCaptchaValue(e)
  }


  async function getCode() {
    setOptErr('')
    setGetCodeLoader(1)
    if (formRef.current.mobile_No.length == 0) {
      setPhoneNumberErr('Please Enter Phone Number')
      return
    }

    try {
      let res = await sendOptOnPhoneNumber({ mobileNo: formRef.current.mobile_No.toString() })
      setGetCodeLoader(2)
    } catch (err) {
      console.log(err)
      setGetCodeLoader(0)
    }
  }


  function switchLoader(res) {
    switch (res) {
      case 0:
        return <div className='pointer' onClick={getCode}>{t("Get Code")}</div>
        break;
      case 1:
        return <div class="spinner-border" role="status" style={{ width: '1rem', height: '1rem' }}>
          <span class="visually-hidden">Loading...</span>
        </div>
        break;
      case 2:
        return <FaCheck />
        break;

      default:
        break;
    }
  }




  return (
    <>
      {/* <SignIn signIn={signIn} setSignIn={setSignIn} /> */}
      <Loader open={loader} />
      <Dialog
        closable={false}
        visible={visible}
        onHide={() => { }}
        style={{ width: signUpSuccess ? "55vh" : "60vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="DialogParent">
          {signUpSuccess ? (
            <div className="">
              <div className='d-flex justify-content-center'>
                <div>
                  <div className='d-flex justify-content-center'>
                    <LuCheckCircle className='completeIcon fs-2' />
                  </div>
                  <h4 className='text-black fw-bold d-flex align-items-center completeIcon mt-2'>Success</h4>
                </div>
              </div>
              <h6 className='text-center text-dark fs-5 mt-3'>You have successfully created a new account</h6>
              <h6 className='text-center fs-6'>Please check your email to verify your new account</h6>
              <div className='d-flex justify-content-center mt-4'>
                <button className='SaveButton' onClick={() => navigate(`/signIn`,{state:role})}>Back To Login</button>
              </div>
            </div>
          ) : (
            <div className="">
              <h1 className="SignUphedaing text-center ">{t("Sign Up")}</h1>
              <form onSubmit={firstSubmit}>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <label htmlFor="name" className="form-label">{t("FullName")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Enter Your Name")}
                      name="fullName"
                      required
                      defaultValue={formRef.current.fullName}
                      onChange={(e) => {
                        if (e.target.value == ' ') {
                          e.target.value = ''
                        } else {
                          formRef.current.fullName = e.target.value
                        }
                      }}
                    />
                  </div>

                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="phone_number" className="form-label">{t("Contact Number")}</label>
                    <div className='p-relative'>
                      <input
                        type="number" // Changed to text for phone number input
                        className={`form-control ${phoneNumberErr.length !== 0 ? 'border border-danger' : ''}`}
                        placeholder={t("Enter Number")}
                        name="phone_number"
                        required
                        defaultValue={formRef.current.mobile_No}
                        onChange={(e) => {
                          if (e.target.value == ' ') {
                            e.target.value = ''
                          } else {
                            formRef.current.mobile_No = e.target.value
                            setPhoneNumberErr('')
                          }
                        }}
                      />
                      <div className="GetCode" >
                        {switchLoader(getCodeLoader)}
                      </div>
                    </div>
                    <div className='text-danger'>{phoneNumberErr}</div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="code" className="form-label">{t("Code")}</label>
                    <input
                      type="number"
                      className={`form-control ${optErr.length !== 0 ? 'border border-danger' : ''}`}
                      placeholder={t('Enter Code')}
                      required
                      name="code"
                      defaultValue={codeValue}
                      onChange={(e) => {
                        if (e.target.value == ' ') {
                          e.target.value = ''
                        } else {
                          setCodeValue(e.target.value)
                          setOptErr('')
                        }
                      }}
                    />
                    <div className='text-danger'>{optErr}</div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <label htmlFor="email" className="form-label">{t("Email Address")}</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder={t("Enter Email Address")}
                      name="email"
                      required
                      defaultValue={formRef.current.email}
                      onChange={(e) => {
                        if (e.target.value == ' ') {
                          e.target.value = ''
                        } else {
                          formRef.current.email = e.target.value
                          setEmailerr('')
                        }
                      }}
                    />

                    {emailerr && <span className="text-danger text-capitalize">{emailerr}</span>}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label htmlFor="password" className="form-label">{t("Password")}</label>
                    <div className='p-relative'>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder={t("Enter Password")}
                        name="password"
                        value={formData.password}
                        onChange={inpChange}
                      />
                      {!showPassword ? <i class="bi bi-eye-slash View pointer" onClick={() => setShowPassword(!showPassword)}></i> : <i className="bi bi-eye View pointer" onClick={() => setShowPassword(!showPassword)}></i>}
                      {/* <i class="bi bi-eye-slash"></i> */}

                    </div>
                  </div>

                  {/* Confirm Password input field */}
                  <div className="col-md-6">
                    <label htmlFor="confirmPassword" className="form-label">{t("Confirm Password")}</label>
                    <div className='p-relative'>
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control"
                        placeholder={t("Confirm Password")}
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={inpChange}
                        style={{ border: passwordsMatch ? "" : "1px solid red" }}
                      />
                      {!showConfirmPassword ? <i class="bi bi-eye-slash View pointer" onClick={() => setShowConfirmPassword(!showConfirmPassword)}></i> : <i className="bi bi-eye View pointer" onClick={() => setShowConfirmPassword(!showConfirmPassword)}></i>}

                    </div>
                  </div>
                </div>
                {error && <li className="text-danger mt-1">{error}</li>}
                {error2 && <li className="text-danger mt-1">{error2}</li>}
                {error3 && <li className="text-danger mt-1">{error3}</li>}
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="agreedToTerms"
                    name="agreedToTerms"
                    checked={term}
                    onChange={(e) => setTerm(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="agreedToTerms">
                    {t("I’ve read and agree with")}
                    <span className="TermColor"> {t("Terms of service and our privacy policy")}</span>
                  </label>
                </div>
                <div className="mt-4 d-flex">
                  <div>
                    <ReCAPTCHA sitekey={"6LfNdI4pAAAAALEDG_OG1aDvRqK_f8_ye513OfRY"}
                      onChange={captchaChange} />
                  </div>
                </div>
                <div className="ButtonForm mt-3">
                  <button className="CencelButton" type="button" disabled={isLoading} onClick={() => setVisible(false)}>{t("Cancel")}</button>
                  <button className="SaveButton" type="submit" disabled={isLoading}>{isLoading ? <div class="spinner-border text-light" role="status" style={{ width: '1rem', height: '1rem' }}>
                    <span class="visually-hidden">Loading...</span>
                  </div> : t("Save")}</button>
                </div>
                <div className="d-flex justify-content-center">  <p>{t("Already have an account?")} <span className='TermColor pointer' onClick={() => navigate(`/signIn`,{state:role})}>{t("Sign in")}</span></p></div>

              </form>


            </div>
          )}
        </div>
      </Dialog>


    </>
  )
}








// import { Dialog } from 'primereact/dialog';
// import React, { useEffect, useState, useRef } from 'react'
// import { addDocument, createDocument } from '../../../Firebase/cloudFirestore/setData';
// import { useTranslation } from 'react-i18next';
// import emailjs from "@emailjs/browser";
// import ReCAPTCHA from 'react-google-recaptcha'
// import { emailPasswordSignUp, signUpWithEmailPassword } from '../../../Firebase/firebaseAuth/userSignUp';
// import { SignIn } from './SignIn';
// import { signUpUser } from '../../../service/Apis';
// import { ProgressSpinner } from 'primereact/progressspinner';
// import { useNavigate } from 'react-router';
// import Loader from '../../../components/Loader/Loader';
// const surrogate_donor = 'surrogate_donor';
// export const DialogSignUp = ({ visible, setVisible }) => {


//   const [email, setEmail] = useState("")
//   const [loader, setLoader] = useState(false)
//   const formRef = useRef(null);
//   useEffect(() => emailjs.init("T91Mgsjn5mGkqRYy7"), []);
//   const
//     handleSubmit = async () => {

//       const serviceId = "service_hxv722e";
//       const templateId = "template_lgsysqo";
//       try {
//         let res = await emailjs.sendForm(serviceId, templateId, formRef.current);
//         console.log(res)

//         setEmail(""); // Clear the email input after sending the email
//       } catch (error) {
//         console.error(error);
//       }
//     };


//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };


//   function Signtoclick() {
//     setSignIn(true)
//     setVisible(false)
//   }

//   let { t } = useTranslation()
//   const [signIn, setSignIn] = useState(false)
//   const [formData, setFormData] = useState({
//     fullName: '',
//     mobile_No: '',
//     email: '',
//     password: '',
//     role: surrogate_donor,
//     agree_teams_policy: true

//   });

//   let [captchaValue, setCaptchaValue] = useState(null)

//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const [passwordsMatch, setPasswordMatch] = useState(true)
//   const [error, setError] = useState('');
//   const [error2, setError2] = useState('');
//   const [error3, setError3] = useState('');
//   const [isLoading, setIsLoading] = useState(false)
//   const [error1, setError1] = useState()
//   const [emailerr, setEmailerr] = useState()
//   const formIsValid = passwordsMatch && formData.name && formData.phone_number && formData.email && formData.code && formData.agreedToTerms && formData.notARobot;

//   let navigate = useNavigate()

//   function inpChange(e) {
//     let { name, value } = e.target;
//     if (name === 'password') {
//       if (value.trim() !== '') {
//         if (
//           /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{":;'?/><,.\[\]\\]).{8,}$/.test(
//             value
//           )
//         ) {
//           setError2('');
//           setError('');
//           setError3('');
//         } else {
//           if (value.length < 8) {
//             setError2('Password must have at least 8 characters');
//           } else {
//             setError2('');
//           }

//           if (!/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
//             setError('A mix of both uppercase and lowercase letters is required');
//           } else {
//             setError('');
//           }

//           if (!/[!@#$%^&*()_+}{":;'?/><,.\[\]\\]/.test(value)) {
//             setError3('Inclusion of at least one special character (e.g., @ # ?) is required');
//           } else {
//             setError3('');
//           }
//         }
//       } else {
//         setError('');
//         setError2('');
//         setError3('');
//       }
//     } else if (name == 'confirmPassword') {
//       setFormData(prevState => ({ ...prevState, [name]: value }));
//       console.log(formData.password)
//       console.log(formData.confirmPassword)
//       if (formData.password === value) {
//         setPasswordMatch(true)
//       } else {
//         setPasswordMatch(false)
//       }
//     }



//     setFormData(prevState => ({ ...prevState, [name]: value }));

//   }


//   const firstSubmit = async (e) => {
//     e.preventDefault();
//     setLoader(true)
//     try {

//       // if (!captchaValue) {
//       //   alert("Please complete the reCAPTCHA verification.")
//       //   return
//       // }

//       // let res = await signUpWithEmailPassword(formData.name, formData.email, formData.password)

//       if (formData.password.length < 8) {
//         setError('Password must be at least 8 characters long.');
//         return;
//       }

//       let data = {
//         fullName: formData.name,
//         mobile_No: formData.phone_number,
//         email: formData.email,
//         password: formData.confirmPassword,
//         role: surrogate_donor,
//         agree_teams_policy: formData.agree_teams_policy
//       }

//       let res2 = await signUpUser(data)

//       if (res2?.data?.data.role === 'surrogate_donor') {
//         localStorage.setItem("userData", JSON.stringify(res2.data.data))
//         navigate("resume/step-1")
//         setLoader(false)
//         return
//       }

//       localStorage.setItem("userData", JSON.stringify(res2.data.data))
//       navigate("resume/step-1")
//       // handleSubmit();



//       // alert("Verification Link sent to your email");

//       // window.location.href = 'https://ivf-dashboard.d1i3emxiss1log.amplifyapp.com/dashboard';

//       // setIsLoading(false);


//       setCaptchaValue(null)
//       setFormData({
//         fullName: '',
//         mobile_No: '',
//         email: '',
//         password: '',
//         role: surrogate_donor,
//         agree_teams_policy: false
//       })

//     }
//     catch (err) {
//       console.log(err);
//       if (err?.response?.data.errormessage.includes("email already exists")) {
//         setEmailerr("this email already exists")
//       }
//       setLoader(false)
//     }
//     // setVisible(false)

//   };

//   const toggleShowPassword = () => {
//     if (formData.password.length > 0) {
//       setShowPassword(!showPassword);
//     }
//   };

//   const toggleShowConfirmPassword = () => {
//     const isPasswordEntered = formData.password.length > 0;
//     const isConfirmPasswordEntered = formData.confirmPassword.length > 0;

//     if (isPasswordEntered && isConfirmPasswordEntered) {
//       setShowConfirmPassword(!showConfirmPassword);
//     } else {
//     }
//   };

//   function captchaChange(e) {
//     console.log(e)
//     setCaptchaValue(e)
//   }




//   return (
//     <>
//       <SignIn signIn={signIn} setSignIn={setSignIn} />
//       <Loader open={loader} />
//       <Dialog
//         closable={false}
//         visible={visible}
//         onHide={() => setVisible(false)}
//         style={{ width: "60vw" }}
//         breakpoints={{ "960px": "75vw", "641px": "100vw" }}
//       >
//         <div className="DialogParent">
//           {/* {isLoading ? (
//             <div className=" flex justify-content-center">
//               <ProgressSpinner />
//             </div>
//           ) : ( */}
//           <div className="">
//             <h1 className="SignUphedaing text-center ">{t("Sign Up")}</h1>
//             <form ref={formRef}>
//               <div className="row mb-3">
//                 <div className="col-md-12">
//                   <label htmlFor="name" className="form-label">{t("FullName")}</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     placeholder={t("Enter Your Name")}
//                     name="name"
//                     required
//                     value={formData.name}
//                     onChange={inpChange}
//                   />
//                 </div>

//               </div>
//               <div className="row mb-3">
//                 <div className="col-md-6">
//                   <label htmlFor="phone_number" className="form-label">{t("Contact Number")}</label>
//                   <input
//                     type="number" // Changed to text for phone number input
//                     className="form-control"
//                     placeholder={t("Enter Number")}
//                     name="phone_number"
//                     required
//                     value={formData.phone_number}
//                     onChange={inpChange}
//                   />
//                 </div>

//                 <div className="col-md-6">
//                   <label htmlFor="email" className="form-label">{t("Email Address")}</label>
//                   <input
//                     type="email"
//                     className="form-control"
//                     placeholder={t("Enter Email Address")}
//                     name="email"
//                     required
//                     value={formData.email}
//                     onChange={inpChange}
//                   />

//                   {emailerr && <span className="text-danger">{emailerr}</span>}
//                 </div>
//               </div>
//               <div className="row mb-2">
//                 <div className="col-md-6">
//                   <label htmlFor="password" className="form-label">{t("Password")}</label>
//                   <div className='p-relative'>
//                     <input
//                       type={showPassword ? "text" : "password"}
//                       className="form-control"
//                       placeholder={t("Enter Password")}
//                       name="password"
//                       value={formData.password}
//                       onChange={inpChange}
//                     />
//                     <i className="bi bi-eye View pointer" onClick={toggleShowPassword}></i>
//                   </div>

//                 </div>

//                 {/* Confirm Password input field */}
//                 <div className="col-md-6">
//                   <label htmlFor="confirmPassword" className="form-label">{t("Confirm Password")}</label>
//                   <div className='p-relative'>
//                     <input
//                       type={showConfirmPassword ? "text" : "password"}
//                       className="form-control"
//                       placeholder={t("Confirm Password")}
//                       name="confirmPassword"
//                       value={formData.confirmPassword}
//                       onChange={inpChange}
//                       style={{ border: passwordsMatch ? "" : "2px solid red" }} // Conditional styling
//                     />
//                     <i className="bi bi-eye View pointer " onClick={toggleShowConfirmPassword}></i>
//                   </div>
//                 </div>
//               </div>
//               {error && <li className="text-danger mt-1">.{error}</li>}
//               {error2 && <li className="text-danger mt-1">.{error2}</li>}
//               {error3 && <li className="text-danger mt-1">.{error3}</li>}
//               <div className="form-check">
//                 <input
//                   type="checkbox"
//                   className="form-check-input"
//                   id="agreedToTerms"
//                   name="agreedToTerms"
//                   checked={formData.agreedToTerms}
//                   onChange={inpChange}
//                 />
//                 <label className="form-check-label" htmlFor="agreedToTerms">
//                   {t("I’ve read and agree with")}
//                   <span className="TermColor"> {t("Terms of service and our privacy policy")}</span>
//                 </label>
//               </div>
//               <div className="mt-4 d-flex">
//                 <div>
//                   <ReCAPTCHA sitekey={"6LfNdI4pAAAAALEDG_OG1aDvRqK_f8_ye513OfRY"}
//                     onChange={captchaChange}
//                   />
//                 </div>
//               </div>
//               <div className="ButtonForm mt-3">
//                 <button className="CencelButton" type="button" onClick={() => setVisible(false)}>{t("Cancel")}</button>
//                 <button className="SaveButton" type="submit" onClick={firstSubmit}>{t("Save")}</button>
//               </div>
//               <div className="d-flex justify-content-center">  <p>{t("Already have an account?")} <span className='TermColor pointer' onClick={Signtoclick}>{t("Sign in")}</span></p></div>
//             </form>
//           </div>
//           {/* )}   */}
//         </div>
//       </Dialog>


//     </>
//   )
// }
