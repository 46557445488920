import React from 'react'

export const TermsAndConditions = () => {


    let data = [
        {
            heading: "Introduction",
            items: [
                "These terms and conditions govern the provision by New Hope Points.",
                "By engaging in our services, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please do not proceed with our services."
            ]
        },
        {
            heading: "Services",
            items: [
                "The Company offers a range of services including but not limited to consultation, fertility assessment, egg retrieval, sperm analysis, embryo culture, embryo transfer, and related services.",
                "The Company reserves the right to refuse service to anyone at its discretion."
            ]
        },
        {
            heading: "Eligibility",
            items: [
                "Clients must meet the eligibility criteria set forth by the Company to avail services.",
                "Clients must provide accurate and complete medical history and undergo necessary medical assessments as required by the Company."
            ]
        },
        {
            heading: "Consent",
            items: [
                "Clients must provide informed consent for all medical procedures, treatments, and interventions offered by the Company.",
                "The Company will provide clients with detailed information about the risks, benefits, and alternatives of each procedure or treatment."
            ]
        },
        {
            heading: "Confidentiality",
            items: [
                "The Company will maintain the confidentiality of all client information and medical records in accordance with applicable laws and regulations.",
                "Clients agree to the use of their anonymized data for research and statistical purposes, with their explicit consent."
            ]
        },
        {
            heading: "Fees and Payments",
            items: [
                "Clients agree to pay the fees for services rendered by the Company as per the agreed-upon payment schedule.",
                "All fees are non-refundable once services have been rendered, except as required by law."
            ]
        },
        {
            heading: "Risks and Disclaimers",
            items: [
                "The Company will make reasonable efforts to achieve successful outcomes but does not guarantee success in any particular case.",
                "Clients acknowledge that procedures carry certain risks and uncertainties, including but not limited to medical complications, side effects, and failure to achieve pregnancy."
            ]
        },
        {
            heading: "Termination of Services",
            items: [
                "The Company reserves the right to terminate services if clients fail to comply with these terms and conditions or if continuation of services is deemed medically or ethically inappropriate."
            ]
        },
        {
            heading: "Governing Law",
            items: [
                "These terms and conditions shall be governed by and construed in accordance with the laws."
            ]
        },
        {
            heading: "Amendments",
            items: [
                "The Company reserves the right to modify these terms and conditions at any time. Any such changes will be communicated to clients in writing."
            ]
        },
        {
            heading: "Contact Information ",
            items: [
                "For inquiries or concerns regarding these terms and conditions, please contact email info@newhopepoints.org"
            ]
        },
    ]

    return (
        <div className=' p-5'>

            <div className='text-center lufga_20px_bg_lightblue'>Terms And Conditions</div>

            {data.map((res, key) => {
                return (
                    <div>
                        <div className='lufga_20px_bg_lightblue'>{res.heading}</div>
                       
                            {res.items.map((res2, key2) => {
                                return (
                                    <div className='lufga_14px'>{res2}</div>
                                )
                            })}
                       <br/>
                    </div>
                )
            })}



        </div>
    )
}
