

module.exports = {
    baseUrl: "https://newhopepoints-backend-5c94ad9d7e3b.herokuapp.com",
    // baseUrl: "http://localhost:3003",


    signUp: "/userDashboard/adduserDashboard",
    logInDashboard: "/userDashboard/logInDashboard",
    forgetPasswordSentMail:'/userDashboard/sendforgotEmail',
    forgetPassword:'/userDashboard/forgotPassword/',
    getUser:'/userDashboard/getUserDashboardById/',

    surrogate: {
        getResumeData: "/surrogate/getAllData/",
        addForm1: "/surrogate/addForm1/",
        addForm2: "/surrogate/addForm2/",
        addForm3: "/surrogate/addForm3/",
        addForm4:"/surrogate/addForm4/",
        addForm5:"/surrogate/addForm5/",
        addForm6:"/surrogate/addForm6/",
        addForm7:"/surrogate/addForm7/",
        addForm8:"/surrogate/addForm8/",
        addForm9:"/surrogate/addForm9/",
        addForm10:"/surrogate/addForm10/",
        addForm11:"/surrogate/addForm11/",
        conciseFormOne:'/surrogate/AddFormConcise1/',
        conciseFormtwo:'/surrogate/AddFormConcise2/',
        conciseFormData:'/surrogate/getFormConcise/',
        getAllLifestyle:"/lifestyle/getAllLifestyle",
        getAllHabits:"/habit/getAllHabits",

    },

    services:{
        sendOtp:'/service/sendOtp',
        verifyOtp:'/service/verifyOtp',
        verifyPhoneNumber:'/userDashboard/phoneVerify/',
        uploadDocs:"/service/uploadDoc",
        uploadMultiDocs:"/service/uploadMultiDocs",
        verifyEmail:'/userDashboard/sendEmailverify',
        deleteDocs:'/service/deleteDocsApi',
        emailVerify:'/userDashboard/verifyEmailTrue'
    },

    admin:{
        getSocialPage: '/socialPage/getSocialPage',
        getAllLifestyle:"/lifestyle/getAllLifestyle",
        getAllHabits:"/habit/getAllHabits",
    },

    intendedParent:{
        
        getIntendedParentById : "/intended/getIntendedParentById/",
        addIntendedParent : "/intended/addIntendedParent/",
    },

    embryoDonor : {
        addEmbryoMother : "/embryo/addEmbryoDonorMother/",
        addEmbryoFather : "/embryo/addEmbryoDonorFather/",
        embryoDonorFormData : "/embryo/getEmbryoDonorById/",
        getEmbryoDonorFormDataByUserId:"/embryo/getEmbryoDonorByUserId/"
    },

    adoptingParent : {
        addAdoptingParent : "/adopting/addAdoptingParent/",
        getAdoptingParentById : "/adopting/getAdoptingParentById/",

    },

    news:{
        getNews: "/news/getNews",
    }
}