import React, { useEffect, useState } from 'react'
import './ResumeSteps.css'
import { useTranslation } from "react-i18next";
import { useOutletContext } from 'react-router';
import { resumeForm4 } from '../../../../service/Apis';
import { userId } from '../../../../service/userData';
import { resumeAllKeys } from './resumeKeys';
import Loader from '../../../../components/Loader/Loader';

const ResumeStep4 = () => {
    let { t } = useTranslation();
    let { navigation, data } = useOutletContext()
    let step4Keys = resumeAllKeys.step4;
    const [loader, setLoader] = useState(false)

    const [formData, setFormData] = useState(
        {
            willing_to_carry_for: [],
            top_choice_intended_parent: "",
            second_choice_intended_parent: "",
            third_choice_intended_parent: "",
            describe_the_ideal_intended_Parent: "",
            NOT_want_work_with_intended_parents: "",
            note_future_intended_Parent: "",
            do_you_want: "",
            diagoned_condition_terminate: null,
            multiple_pergancy_reduce_request: null,
            diagoned_downSyndrom_terminate: null,
            wlling_amniocentesis: null,
            type_contect_desire_with_ip: "",
            interest_continuing_contect_post_birth: null,
            comfortable_vist_during_pregnancy: null,
            comfortable_join_doctor_appointment: null,
            comfortable_taking_photos: null,
            comfortable_being_delivery_room: null,
            birth_experience: [],
            specific_envision_birth_experience: "",
            lived_another_country: null
        }
    )

    // useEffect(() => {
    //     let obj = {}
    //     // console.log(data)
    //     // for (let i in formData) {
    //     //     if (data.resumeData[i]) {
    //     //         obj[i] = data?.resumeData?.[i] || step4Keys[i]
    //     //     }
    //     // }
    //     // console.log(obj)
    //     setFormData(pre => ({ ...pre, ...data.resumeData }));

    // }, [data.resumeData])


    useEffect(() => {
        let obj = {};
        for (let i in step4Keys) {
            if (data.resumeData.hasOwnProperty(i)) {
                obj[i] = data?.resumeData?.[i]
            }
            else {
                obj[i] = step4Keys[i]
            }
        }

        setFormData((pre) => ({ ...pre, ...obj }));
    }, [data.resumeData]);


    const handleInputChange2 = (e) => {

        const { name, value, type, checked } = e.target;
        if (value == ' ') {
            e.target.value = ''
        } else {

            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }

    };

    function radioInputChange(e, val) {
        let name = e.target.name

        setFormData((prevState) => ({
            ...prevState,
            [name]: val,
        }));
    };

    function multipleCheckBoxChange(e) {

        let { value, checked } = e.target;
        let datas = formData.willing_to_carry_for
        if (checked) {
            datas?.push(value);
            setFormData({ ...formData, ...{ willing_to_carry_for: datas } })
        }
        else {
            datas.splice(datas.indexOf(value), 1);
            setFormData({ ...formData, ...{ willing_to_carry_for: datas } })

        }
    }
    function multipleCheckBoxChange2(e) {

        let { value, checked } = e.target;
        let datas = formData.birth_experience
        if (checked) {
            datas?.push(value);
            setFormData({ ...formData, ...{ birth_experience: datas } })
        }
        else {
            datas.splice(datas.indexOf(value), 1);
            setFormData({ ...formData, ...{ birth_experience: datas } })

        }

    }

    async function formsubmit4(e) {
        e.preventDefault()

        setLoader(true)
        let userID = userId();
        let data = { ...formData };

        await resumeForm4(userID, data).then((res) => {
            setLoader(false)
            navigation.next()

        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })

    }




    return (
        <>
            <Loader open={loader} />
            <div className='ResumeStep_con'>
                    <div className='SubHeading mb-lg-4 mb-md-4 mb-sm-3 mb-3'>{t("Important Matching Details")}</div>
              

                <form onSubmit={formsubmit4}>
                    <div className='row mb-2'>
                        <div className='mb-4'> <label className="form-label fs-5" >
                            {t("Would you be willing to carry for (please check ALL that apply)")}<span className='text-danger'> *</span>
                        </label>
                        </div>

                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" required={formData?.willing_to_carry_for.length == 0} name='willing_to_carry_for' type="checkbox" defaultValue value="A single intended parent" checked={formData.willing_to_carry_for.includes("A single intended parent")} onChange={multipleCheckBoxChange} />
                                <label className="form-check-label LabelSubHeading"  >
                                    {t("A single intended parent")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="checkbox" name='willing_to_carry_for' defaultValue value="A single female intended parent" checked={formData.willing_to_carry_for.includes("A single female intended parent")} onChange={multipleCheckBoxChange} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("A single FEMALE intended parent")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="checkbox" name='willing_to_carry_for' defaultValue value="A heterosexual couple" checked={formData.willing_to_carry_for.includes("A heterosexual couple")} onChange={multipleCheckBoxChange} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("A heterosexual couple")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="checkbox" name='willing_to_carry_for' defaultValue value="A male same-sex couple" checked={formData.willing_to_carry_for.includes("A male same-sex couple")} onChange={multipleCheckBoxChange} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("A MALE same-sex couple")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="checkbox" name='willing_to_carry_for' defaultValue value="A female same-sex couple" checked={formData.willing_to_carry_for.includes("A female same-sex couple")} onChange={multipleCheckBoxChange} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("A FEMALE same-sex couple")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="checkbox" name='willing_to_carry_for' defaultValue value="Intended parent(s) over 55 years old" checked={formData.willing_to_carry_for.includes("Intended parent(s) over 55 years old")} onChange={multipleCheckBoxChange} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Intended parent(s) over 55 years old")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-8 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="checkbox" name='willing_to_carry_for' defaultValue value="Intended parent(s) going through a sibling journey simultaneously with another surrogate" checked={formData.willing_to_carry_for.includes("Intended parent(s) going through a sibling journey simultaneously with another surrogate")} onChange={multipleCheckBoxChange} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Intended parent(s) going through a sibling journey simultaneously with another surrogate")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="checkbox" name='willing_to_carry_for' defaultValue value="HIV+ intended parent(s)" checked={formData.willing_to_carry_for.includes("HIV+ intended parent(s)")} onChange={multipleCheckBoxChange} />
                                <label className="form-check-label LabelSubHeading" >

                                    {t("HIV+ intended parent(s)")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="checkbox" name='willing_to_carry_for' defaultValue value="Hepatitis B carrier" checked={formData.willing_to_carry_for.includes("Hepatitis B carrier")} onChange={multipleCheckBoxChange} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Hepatitis B carrier")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="checkbox" name='willing_to_carry_for' defaultValue value="International intended parent(s)" checked={formData.willing_to_carry_for.includes("International intended parent(s)")} onChange={multipleCheckBoxChange} />
                                <label className="form-check-label LabelSubHeading" >

                                    {t("International intended parent(s)")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="checkbox" name='willing_to_carry_for' defaultValue value="Non-English speaking intended parent(s)" checked={formData.willing_to_carry_for.includes("Non-English speaking intended parent(s)")} onChange={multipleCheckBoxChange} />
                                <label className="form-check-label LabelSubHeading" >

                                    {t("Non-English speaking intended parent(s)")}
                                </label>
                            </div>

                        </div>



                    </div>
                    <div className='mb-4 LabelSubHeading'>
                        *Please note that we have translators available to help with translation, and many of our matches with non-English speaking IPs have had incredible journeys with the assistance of translation software, such as WeChat!
                    </div>


                    <div className='row mb-4'>
                        <div className='mb-4'> <label className="form-label fs-5 " >
                            {t("Please select your TOP choice for the type of intended parent(s) you would prefer to carry for.")}<span className='text-danger'> *</span>
                        </label>
                        </div>

                        <div className="col-md-4 pb-3">
                            <div class="form-check">
                                <input className="form-check-input" type="radio" name="top_choice_intended_parent" required value="A single intended parent" checked={formData.top_choice_intended_parent === "A single intended parent"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("A single intended parent")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="top_choice_intended_parent" required value="A heterosexual couple" checked={formData.top_choice_intended_parent === "A heterosexual couple"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("A heterosexual couple")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="top_choice_intended_parent" required value="A same-sex couple" checked={formData.top_choice_intended_parent === "A same-sex couple"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("A same-sex couple")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="top_choice_intended_parent" required value="Intended parent(s) over 55 years old" checked={formData.top_choice_intended_parent === "Intended parent(s) over 55 years old"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Intended parent(s) over 55 years old")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-8">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="top_choice_intended_parent" required value="Intended parent(s) going through a sibling journey simultaneously with another surrogate" checked={formData.top_choice_intended_parent === "Intended parent(s) going through a sibling journey simultaneously with another surrogate"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Intended parent(s) going through a sibling journey simultaneously with another surrogate")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="top_choice_intended_parent" required value="HIV+ intended parent(s)" checked={formData.top_choice_intended_parent === "HIV+ intended parent(s)"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >

                                    {t("HIV+ intended parent(s)")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="top_choice_intended_parent" required value="Hepatitis B carrier" checked={formData.top_choice_intended_parent === "Hepatitis B carrier"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >

                                    {t("Hepatitis B carrier")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="top_choice_intended_parent" required value="Non-English speaking intended parent(s)" checked={formData.top_choice_intended_parent === "Non-English speaking intended parent(s)"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >

                                    {t("Non-English speaking intended parent(s)")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="top_choice_intended_parent" required value="International intended parent(s)" checked={formData.top_choice_intended_parent === "International intended parent(s)"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >

                                    {t("International intended parent(s)")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="top_choice_intended_parent" required value="No Preference" checked={formData.top_choice_intended_parent === "No Preference"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >

                                    {t("No Preference")}
                                </label>
                            </div>

                        </div>



                    </div>



                    <div className='row mb-4'>
                        <div className='mb-4'> <label className="form-label fs-5" >
                            {t("If your first preference were unavailable, what type of parent(s) would be your SECOND choice?")}<span className='text-danger'> *</span>
                        </label>
                        </div>

                        <div className="col-md-4 pb-3">
                            <div class="form-check">
                                <input className="form-check-input" type="radio" name="second_choice_intended_parent" required value="A single intended parent" checked={formData.second_choice_intended_parent === "A single intended parent"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("A single intended parent")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="second_choice_intended_parent" required value="A heterosexual couple" checked={formData.second_choice_intended_parent === "A heterosexual couple"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("A heterosexual couple")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="second_choice_intended_parent" required value="A same-sex couple" checked={formData.second_choice_intended_parent === "A same-sex couple"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("A same-sex couple")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="second_choice_intended_parent" required value="Intended parent(s) over 55 years old" checked={formData.second_choice_intended_parent === "Intended parent(s) over 55 years old"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Intended parent(s) over 55 years old")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-8">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="second_choice_intended_parent" required value="Intended parent(s) going through a sibling journey simultaneously with another surrogate" checked={formData.second_choice_intended_parent === "Intended parent(s) going through a sibling journey simultaneously with another surrogate"} onChange={handleInputChange2} />
                                <label className="form-label LabelSubHeading" >
                                    {t("Intended parent(s) going through a sibling journey simultaneously with another surrogate")}
                                </label>
                            </div>

                        </div>

                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="second_choice_intended_parent" required value="HIV+ intended parent(s)" checked={formData.second_choice_intended_parent === "HIV+ intended parent(s)"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >

                                    {t("HIV+ intended parent(s)")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="second_choice_intended_parent" required value="Hepatitis B carrier" checked={formData.second_choice_intended_parent === "Hepatitis B carrier"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >

                                    {t("Hepatitis B carrier")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="second_choice_intended_parent" required value="Non-English speaking intended parent(s)" checked={formData.second_choice_intended_parent === "Non-English speaking intended parent(s)"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >

                                    {t("Non-English speaking intended parent(s)")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="second_choice_intended_parent" required value="International intended parent(s)" checked={formData.second_choice_intended_parent === "International intended parent(s)"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("International intended parent(s)")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="second_choice_intended_parent" required value="No Preference" checked={formData.second_choice_intended_parent === "No Preference"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("No Preference")}
                                </label>
                            </div>

                        </div>


                    </div>
                    <div className='row mb-4'>
                        <div className='mb-4'> <label className="form-label fs-5" >
                            {t("If your first and second preferences were not available, what type of parent(s) would be your THIRD choice?")}<span className='text-danger'> *</span>
                        </label>
                        </div>

                        <div className="col-md-4 pb-3">
                            <div class="form-check">
                                <input className="form-check-input" type="radio" name="third_choice_intended_parent" required value="A single intended parent" checked={formData.third_choice_intended_parent === "A single intended parent"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("A single intended parent")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="third_choice_intended_parent" required value="A heterosexual couple" checked={formData.third_choice_intended_parent === "A heterosexual couple"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("A heterosexual couple")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="third_choice_intended_parent" value="A same-sex couple" checked={formData.third_choice_intended_parent === "A same-sex couple"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("A same-sex couple")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="third_choice_intended_parent" required value="Intended parent(s) over 55 years old" checked={formData.third_choice_intended_parent === "Intended parent(s) over 55 years old"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Intended parent(s) over 55 years old")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-8">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="third_choice_intended_parent" required value="Intended parent(s) going through a sibling journey simultaneously with another surrogate" checked={formData.third_choice_intended_parent === "Intended parent(s) going through a sibling journey simultaneously with another surrogate"} onChange={handleInputChange2} />
                                <label className="form-label LabelSubHeading" >
                                    {t("Intended parent(s) going through a sibling journey simultaneously with another surrogate")}
                                </label>
                            </div>

                        </div>

                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="third_choice_intended_parent" required value="HIV+ intended parent(s)" checked={formData.third_choice_intended_parent === "HIV+ intended parent(s)"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >

                                    {t("HIV+ intended parent(s)")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="third_choice_intended_parent" required value="Hepatitis B carrier" checked={formData.third_choice_intended_parent === "Hepatitis B carrier"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >

                                    {t("Hepatitis B carrier")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="third_choice_intended_parent" required value="Non-English speaking intended parent(s)" checked={formData.third_choice_intended_parent === "Non-English speaking intended parent(s)"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >

                                    {t("Non-English speaking intended parent(s)")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="third_choice_intended_parent" required value="International intended parent(s)" checked={formData.third_choice_intended_parent === "International intended parent(s)"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("International intended parent(s)")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="third_choice_intended_parent" required value="No Preference" checked={formData.third_choice_intended_parent === "No Preference"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("No Preference")}
                                </label>
                            </div>

                        </div>


                    </div>
                    <div className='Upload_resume row mb-4'>
                        <div className=" col-md-12 ">
                            <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please describe the “ideal” Intended Parent(s) with whom you’d like to work")}<span className='text-danger'>*</span></label>
                            <textarea rows={5} type="text" className="form-control" required name='describe_the_ideal_intended_Parent' defaultValue={formData.describe_the_ideal_intended_Parent} onChange={handleInputChange2} />

                        </div>
                    </div>
                    <div className='Upload_resume row mb-4'>
                        <div className=" col-md-12 ">
                            <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("List any circumstances which would cause you to NOT want to work with specific Intended Parents")}<span className='text-danger'>*</span></label>
                            <textarea rows={5} type="text" className="form-control" required name='NOT_want_work_with_intended_parents' defaultValue={formData.NOT_want_work_with_intended_parents} onChange={handleInputChange2} />

                        </div>
                    </div>
                    <div className='Upload_resume row mb-4'>
                        <div className=" col-md-12 ">
                            <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("If you’d like to, please include a note to your future Intended Parent(s)")}<span className='text-danger'>*</span></label>
                            <textarea rows={5} type="text" className="form-control" required name='note_future_intended_Parent' defaultValue={formData.note_future_intended_Parent} onChange={handleInputChange2} />

                        </div>
                    </div>

                    <div className='SubHeading mb-lg-4 mb-md-4 mb-sm-3 mb-3'>{t("Important Matching Details (Continued)")}</div>
                    <div className="row mb-4">
                        <label className="form-label fs-5" >
                            {t("Do you want a")} <span className='text-danger'>*</span>
                        </label>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">
                                <input className="form-check-input" type="radio" name="do_you_want" required value="Single Embryo Transfer" checked={formData.do_you_want === "Single Embryo Transfer"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Single Embryo Transfer")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4 pb-3">
                            <div class="form-check">
                                <input className="form-check-input" type="radio" name="do_you_want" required value="Double Embryo Transfer" checked={formData.do_you_want === "Double Embryo Transfer"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Double Embryo Transfer")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="do_you_want" required value="Either Single or Double Embryo Transfer" checked={formData.do_you_want === "Either Single or Double Embryo Transfer"} onChange={handleInputChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Either Single or Double Embryo Transfer")}
                                </label>
                            </div>

                        </div>
                    </div>
                    <div className="row mb-4">
                        <label className="form-label fs-5" >
                            {t("In the event of a multiple pregnancy, will you reduce at the request of the Intended Parent(s)?")} <span className='text-danger'>*</span>
                        </label>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="multiple_pergancy_reduce_request" required checked={formData.multiple_pergancy_reduce_request === true} onChange={(e) => { radioInputChange(e, true) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Yes")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="multiple_pergancy_reduce_request" required checked={formData.multiple_pergancy_reduce_request === false} onChange={(e) => { radioInputChange(e, false) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("No")}
                                </label>
                            </div>

                        </div>

                    </div>

                    <div className="row mb-4">
                        <label className="form-label fs-5" >
                            {t("If the baby is diagnosed with a condition that is incompatible with life, will you terminate at the request of the Intended Parent(s)?")} <span className='text-danger'>*</span>
                        </label>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="diagoned_condition_terminate" required checked={formData.diagoned_condition_terminate === true} onChange={(e) => { radioInputChange(e, true) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Yes")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="diagoned_condition_terminate" required checked={formData.diagoned_condition_terminate === false} onChange={(e) => { radioInputChange(e, false) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("No")}
                                </label>
                            </div>

                        </div>

                    </div>

                    <div className="row mb-4">
                        <label className="form-label fs-5" >
                            {t("If the baby is diagnosed with Down Syndrome, will you terminate at the request of the Intended Parent(s)?")} <span className='text-danger'>*</span>
                        </label>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="diagoned_downSyndrom_terminate" required checked={formData.diagoned_downSyndrom_terminate === true} onChange={(e) => { radioInputChange(e, true) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Yes")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="diagoned_downSyndrom_terminate" required checked={formData.diagoned_downSyndrom_terminate === false} onChange={(e) => { radioInputChange(e, false) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("No")}
                                </label>
                            </div>

                        </div>

                    </div>

                    <div className="row mb-4">
                        <label className="form-label fs-5" >
                            {t("Would you be willing to have an amniocentesis, if requested by the Intended Parent(s) (and recommended by a physician)?")} <span className='text-danger'>*</span>
                        </label>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="wlling_amniocentesis" required checked={formData.wlling_amniocentesis === true} onChange={(e) => { radioInputChange(e, true) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Yes")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="wlling_amniocentesis" required checked={formData.wlling_amniocentesis === false} onChange={(e) => { radioInputChange(e, false) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("No")}
                                </label>
                            </div>

                        </div>

                    </div>
                    <div className='Upload_resume row mb-4'>
                        <div className=" col-md-12 ">
                            <label htmlFor="exampleInputEmail1" className="form-label fs-5" >
                                {t("What type of contact do you desire to have with your Intended Parent(s) throughout the pregnancy? (Please be specific as we aim to make the best match for you and your Intended Parents!)")}
                                <span className='text-danger'>*</span></label>
                            <textarea rows={5} type="text" className="form-control" value={formData.type_contect_desire_with_ip} name='type_contect_desire_with_ip' required onChange={handleInputChange2} />

                        </div>
                    </div>

                    <div className="row mb-4">
                        <label className="form-label fs-5" >
                            {t("Are you interested in continuing contact post birth?")} <span className='text-danger'>*</span>
                        </label>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="interest_continuing_contect_post_birth" required checked={formData.interest_continuing_contect_post_birth === true} onChange={(e) => { radioInputChange(e, true) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Yes")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="interest_continuing_contect_post_birth" required checked={formData.interest_continuing_contect_post_birth === false} onChange={(e) => { radioInputChange(e, false) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("No")}
                                </label>
                            </div>

                        </div>

                    </div>
                    <div className="row mb-4">
                        <label className="form-label fs-5" >
                            {t("Are you comfortable with your Intended Parent(s) visit you during pregnancy?")} <span className='text-danger'>*</span>
                        </label>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="comfortable_vist_during_pregnancy" required checked={formData.comfortable_vist_during_pregnancy === true} onChange={(e) => { radioInputChange(e, true) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Yes")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="comfortable_vist_during_pregnancy" required checked={formData.comfortable_vist_during_pregnancy === false} onChange={(e) => { radioInputChange(e, false) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("No")}
                                </label>
                            </div>

                        </div>

                    </div>
                    <div className="row mb-4">
                        <label className="form-label fs-5" >
                            {t("Are you comfortable with your Intended Parent(s) joining you during pregnancy?")} <span className='text-danger'>*</span>
                        </label>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="comfortable_join_doctor_appointment" required checked={formData.comfortable_join_doctor_appointment === true} onChange={(e) => { radioInputChange(e, true) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Yes")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="comfortable_join_doctor_appointment" required checked={formData.comfortable_join_doctor_appointment === false} onChange={(e) => { radioInputChange(e, false) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("No")}
                                </label>
                            </div>

                        </div>

                    </div>
                    <div className="row mb-4">
                        <label className="form-label fs-5" >
                            {t("Are you comfortable with your Intended Parent(s) taking pictures during this journey?")} <span className='text-danger'>*</span>
                        </label>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="comfortable_taking_photos" required checked={formData.comfortable_taking_photos === true} onChange={(e) => { radioInputChange(e, true) }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Yes")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="comfortable_taking_photos" required checked={formData.comfortable_taking_photos === false} onChange={(e) => { radioInputChange(e, false) }} />
                                <label className="form-check-label LabelSubheading" >
                                    {t("No")}
                                </label>
                            </div>

                        </div>

                    </div>
                    <div className="row mb-4">
                        <label className="form-label fs-5" >
                            {t("Are you comfortable with your Intended Parent(s) being in the delivery room?")} <span className='text-danger'>*</span>
                        </label>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="comfortable_being_delivery_room" required checked={formData.comfortable_being_delivery_room === true} onChange={(e) => { radioInputChange(e, true) }} />
                                <label className="form-check-label LabelSubheading" >
                                    {t("Yes")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div class="form-check">

                                <input className="form-check-input" type="radio" name="comfortable_being_delivery_room" required checked={formData.comfortable_being_delivery_room === false} onChange={(e) => { radioInputChange(e, false) }} />
                                <label className="form-check-label LabelSubheading" >
                                    {t("No")}
                                </label>
                            </div>

                        </div>

                    </div>
                    <div className="row mb-4">
                        <label className="form-label fs-5" >
                            {t("What type of birth experience do you want?")} <span className='text-danger'>*</span>
                        </label>
                        <div className="col-md-3">
                            <div class="form-check">

                                <input className="form-check-input" type="checkbox" required={formData?.birth_experience.length == 0} value="Hospital / OB" name="birth_experience" checked={formData.birth_experience.includes("Hospital / OB")} onChange={multipleCheckBoxChange2} />
                                <label className="form-check-label LabelSubHeading"   >
                                    {t("Hospital / OB")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-3">
                            <div class="form-check">

                                <input className="form-check-input" type="checkbox" value="Hospital / Midwife" name="birth_experience" checked={formData.birth_experience.includes("Hospital / Midwife")} onChange={multipleCheckBoxChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Hospital / Midwife")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-3">
                            <div class="form-check">

                                <input className="form-check-input" type="checkbox" value="Birth Center / Midwife" name="birth_experience" checked={formData.birth_experience.includes("Birth Center / Midwife")} onChange={multipleCheckBoxChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Birth Center / Midwife")}
                                </label>
                            </div>

                        </div>
                        <div className="col-md-3">
                            <div class="form-check">
                                <input className="form-check-input" type="checkbox" value="Home Delivery / Midwife" name="birth_experience" checked={formData.birth_experience.includes("Home Delivery / Midwife")} onChange={multipleCheckBoxChange2} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("Home Delivery / Midwife")}
                                </label>
                            </div>

                        </div>

                    </div>
                    <div className='Upload_resume row mb-4'>
                        <div className=" col-md-12 ">
                            <label htmlFor="exampleInputEmail1" className="form-label fs-5" >
                                {t("Please expand, if you wish, on anything specific you envision during the birth experience")}
                            </label>
                            <textarea rows={5} type="text" className="form-control" value={formData.specific_envision_birth_experience} name='specific_envision_birth_experience'  onChange={handleInputChange2} />

                        </div>
                    </div>
                    <div className="ButtonForm mt-3">
                        <button className="btn_non_fill" type="button" onClick={navigation.previous}>{t('Previous')}</button>
                        <button className="btn_fill" type="submit" >{t('Next')}</button>
                    </div>
                </form >
            </div >
        </>
    )
}

export default ResumeStep4