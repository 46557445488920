import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { addDocument, createDocument } from '../../../Firebase/cloudFirestore/setData';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha'
import { emailPasswordSignUp, signUpWithEmailPassword } from '../../../Firebase/firebaseAuth/userSignUp';

export const DialogSignUp = ({ visible, setVisible }) => {
  let { t } = useTranslation()
  const [formData, setFormData] = useState({
    name: '',
    phone_number: '',
    email: '',
    password: '',
    confirmPassword: '',
    // notARobot: false,
    agreedToTerms: false,
  });

  let [captchaValue, setCaptchaValue] = useState(null)


  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const passwordsMatch = formData.password === formData.confirmPassword;
  const formIsValid = passwordsMatch && formData.name && formData.phone_number && formData.email && formData.code && formData.agreedToTerms && formData.notARobot;


  function inpChange(e) {
    let { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
  }

  const firstSubmit = async (e) => {
    e.preventDefault();
    try {

      // if (!captchaValue) {
      //   alert("Please complete the reCAPTCHA verification.")
      //   return
      // }

      let res = await signUpWithEmailPassword(formData.name, formData.email, formData.password)

     
      let data = {
        name: formData.name,
        email: formData.email,
        mobile: formData.phone_number,
        agreedToTerms: formData.agreedToTerms,
        userId: res.uid,
        created_at:new Date()
      }
      

      console.log(data)

      let res2 = await createDocument("users", res.uid, data)

      console.log(res)
      alert("Verification Link sent to your email")
      setVisible(false)
      setCaptchaValue(null)
      setFormData({
        name: '',
        phone_number: '',
        email: '',
        gender: '',
        password: '',
        confirmPassword: '',
        notARobot: false,
        agreedToTerms: false,
      })

    } catch (err) {
      console.log(err)
      alert(err?.message)
      
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  function captchaChange(e) {
    console.log(e)
    setCaptchaValue(e)
  }




  return (
    <>
      <Dialog
        closable={false}
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: "60vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="DialogParent">
          <div className="">
            <h1 className="SignUphedaing text-center ">{t("Sign Up")}</h1>


            <form onSubmit={firstSubmit}>
              <div className="row mb-2">
                <div className="col-md-12">
                  <label htmlFor="name" className="form-label">{t("FullName")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Name"
                    name="name"
                    required
                    value={formData.name}
                    onChange={inpChange}
                  />
                </div>

              </div>
              <div className="row mb-2">
                <div className="col-md-6 Codeparent">
                  <label htmlFor="phone_number" className="form-label">{t("Contact Number")}</label>
                  <input
                    type="text" // Changed to text for phone number input
                    className="form-control"
                    placeholder="Enter Number"
                    name="phone_number"
                    required
                    value={formData.phone_number}
                    onChange={inpChange}
                  />
                  {/* <div className="GetCode">{t("Get Code")}</div> */}
                </div>
                {/* <div className="col-md-6">
          <label htmlFor="code" className="form-label">{t("Code")}</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Code"
            name="code"
            value={formData.code}
            onChange={inpChange}
          />
        </div> */}
              </div>
              <div className="row mb-2">
                <div className="col-md-12 Codeparent">
                  <label htmlFor="email" className="form-label">{t("Email Address")}</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address"
                    name="email"
                    required
                    value={formData.email}
                    onChange={inpChange}
                  />
                  {/* <div className="GetCode">{t("Verify")}</div> */}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6 EyeView">
                  <label htmlFor="password" className="form-label">{t("Password")}</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter Password"
                    name="password"
                    value={formData.password}
                    onChange={inpChange}
                  />
                  <i className="bi bi-eye View pointer" onClick={toggleShowPassword}></i>
                </div>

                {/* Confirm Password input field */}
                <div className="col-md-6 EyeView">
                  <label htmlFor="confirmPassword" className="form-label">{t("Confirm Password")}</label>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={inpChange}
                    style={{ border: passwordsMatch ? "" : "2px solid red" }} // Conditional styling
                  />

                  <i className="bi bi-eye View pointer " onClick={toggleShowConfirmPassword}></i>
                </div>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="agreedToTerms"
                  name="agreedToTerms"
                  checked={formData.agreedToTerms}
                  onChange={inpChange}
                />
                <label className="form-check-label" htmlFor="agreedToTerms">
                  {t("I’ve read and agree with")}
                  <span className="TermColor">{t("Terms of service and our privacy policy")}</span>
                </label>
              </div>
              <div className="mt-3 form-check d-flex">
                <div>
                  {/* <input
          type="checkbox"
          className="form-check-input"
          id="notARobot"
          name="notARobot"
          checked={formData.notARobot}
          onChange={inpChange}
        /> */}
                  {/* <label className="form-check-label" htmlFor="notARobot">
        {t("I'm not a robot")}
        </label> */}

                  <ReCAPTCHA sitekey={"6LfNdI4pAAAAALEDG_OG1aDvRqK_f8_ye513OfRY"}
                    onChange={captchaChange}
                  />


                </div>
                {/* <div>
        <img src='/images/download 2.png'/>
        </div> */}
              </div>
              <div className="ButtonForm mt-3">
                <button className="CencelButton" type="button" onClick={() => setVisible(false)}>{t("Cancel")}</button>
                <button className="SaveButton" type="submit">{t("Save")}</button>
              </div>
            </form>


          </div>

        </div>
      </Dialog>
    </>
  )
}
