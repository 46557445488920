import React, { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";

export default function Toaster({ event,show, severity,setToaster }) {
  const toastBottomRight = useRef(null);

  const showMessage = (event, ref, severity) => {
    const label = event;

    ref.current.show({
      severity: severity,
      summary: label,
      // detail: label,
      life: 3000,
    });
    setTimeout(()=>{
      setToaster({show:false, message:""})
    },1500)
  };

  useEffect(() => {
    if(show){
      showMessage(event, toastBottomRight, severity);
    }
  }, [event, severity, toastBottomRight,show]); // Make sure to include all dependencies

  return (

      <Toast ref={toastBottomRight} position="top-right" />

  );
}
