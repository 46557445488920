import { Dialog } from 'primereact/dialog'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const ForgettDialog = ({forget,setForget}) => {

  let {t}=useTranslation()

  return (
   <>
    <Dialog
      closable={false}
      visible={forget}
      onHide={() => setForget(false)}
      style={{ width: "60vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
    >
      <div className="DialogParent">
        <div className="gap-3">
          <form >
        <h1 className="SignUphedaing text-center mb-5">{t("Forget Password")}</h1>
           <div className="row">
           <div className=" col-md-6 Codeparent">
              <label htmlFor="exampleInputEmail1" className="form-label">{t("Email Address")}</label>
              <input type="email" className="form-control " placeholder={t("Enter Email")}/>
              <div className="GetCode">{t("Get OTP")}</div>
            </div>
            <div className=" col-md-6">
              <label htmlFor="exampleInputEmail1" className="form-label">{t("OTP")}</label>
              <input type="tex" className="form-control " placeholder={t("Enter Your otp")} />
           
            </div>
        
           </div>
           <div className="row">
           <div className=" col-md-6 EyeView">
              <label htmlFor="exampleInputEmail1" className="form-label" >{t("Password")}</label>
              <input type="password" className="form-control"  placeholder={t("Enter  Password")} />
              <i className="bi bi-eye View"></i>
            </div>
            <div className=" col-md-6 EyeView">
              <label htmlFor="exampleInputEmail1" className="form-label" >{t("Confirm Password")}</label>
              <input type="password" className="form-control"  placeholder={t("Enter Password")}/>
              <i className="bi bi-eye View"></i>
            </div>
          
           
         
           </div>
           <div className="d-flex justify-content-between ">
            <div className="BackLogin">{t("Back to Login Screen")}</div>
            <div>
              <span className="TextCustomBottom">{t("Don’t have any account?")}</span>
              <span className="Create">{t("Create New")}</span>
            </div>
           </div>
           <div className="mt-3 form-check">
              <input type="checkbox" className="form-check-input" id="exampleCheck1" />
              <label className="form-check-label" htmlFor="exampleCheck1">
              {t("I'm not a robot")}
              </label>
            </div>
            <div className="ButtonForm mt-3">
              <button className="CencelButton"  type="button" onClick={()=>setForget(false)}>{t("Cancel")}</button>
              <button className="SaveButton"  type="submit" >{t("Reset Password")}</button>
            </div>
          </form>

        </div>

      </div>
    </Dialog>
   </>
  )
}
