import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdAdd } from "react-icons/io";
import { FaMinus } from "react-icons/fa6";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { MultipleImageUpload } from "../../../components/Inputs/MultipleImageUpload";
import Toaster from "../../../components/Toaster";

import { City, Country, State } from "country-state-city";



import Loader from "../../../components/Loader/Loader";
import { addFormConcise1, deleteDocs, uploadMultipleFiles } from "../../../service/Apis";


export const Embryodonorstep1 = () => {
  const { allData, getData } = useOutletContext();
  console.log(allData)
  let allCountries = Country.getAllCountries();
  let [countryCode, setCountryCode] = useState("");
  let [stateCode, setStateCode] = useState("");
  let [error, setError] = useState(0);
  const [imgForRemove, setImgForRemove] = useState([])
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });

  let { t } = useTranslation();
  let [loader, setLoader] = useState(false);
  let navigate = useNavigate();
  let [images, setImages] = useState([]);
  let { id } = useParams();
  let [formData, setFormData] = useState({
    photos: [],
    firstName: "",
    middleName: "",
    lastName: "",
    not_show_fullName: false,
    country: "",
    state: "",
    dob: "",
    current_address: "",
    not_show_current_address: false,
    city: "",
    zipCode: "",
    no_of_kids: "",
    no_of_vaginal_deliveries: "",
    no_of_c_section_deliveries: "",
    medical_insurance: "",
    terminate_when_need: "",
    willing_to_work_with_hiv_positive_ip: "",
    willing_to_work_with_hep_b_positive_ip: "",
    currently_employed: "",
    occupation: "",
    child_details: [],
  });

  function addChild() {
    setFormData((pre) => ({
      ...pre,
      child_details: [
        ...pre.child_details,
        {
          gender: "",
          dob: "",
          weeks_at_delivery: "",
          body_weight: {
            lbs: "",
            oz: "",
          },
          surrogacy: "",
          singleton_or_multiple: "",
        },
      ],
    }));
  }
  useEffect(() => {
    let obj = {};
    for (let i in formData) {
      if (allData?.hasOwnProperty(i)) {
        obj[i] = allData?.[i];
      } else {
        obj[i] = formData[i];
      }
    }
    setFormData((pre) => ({ ...pre, ...obj }));
    if (allData?.photos) {
      let photoArr = allData?.photos?.map((res, index) => {
        return {
          ...res,
          fileType: 'server'
        }
      })
      setImagesValue(photoArr);
    }
  }, [allData]);
  useEffect(() => {
    if (formData.country && formData.city) {
      let country = Country.getAllCountries().find(
        (d) => d.name == formData.country
      );
      setCountryCode(country.isoCode);
      let state = State.getStatesOfCountry(country.isoCode).find(
        (d) => d.name == formData.state
      );
      setStateCode(state.isoCode);
    }
  }, [formData]);

  let [imagesValue, setImagesValue] = useState([]);

  useEffect(() => {
    setDefaultCountryStateCode();
  }, []);

  function setDefaultCountryStateCode() {
    let countryCode = allCountries.find(
      (e) => e.name == formData.country
    )?.isoCode;
    let stateCode = State.getStatesOfCountry(countryCode)?.find(
      (e) => e.name == formData.state
    )?.isoCode;
    setCountryCode(countryCode);
    setStateCode(stateCode);
  }

  function multipleImageInputChange(e) {
    setImagesValue(e);
  }

  function handleInputChange(e) {
    let { name, value, type } = e.target;
    setError(0);
    if (value === " ") {
      e.target.value = "";
    } else {
      setFormData((pre) => {
        pre[name] = value;
        return { ...pre };
      });
    }
  }
  function sumofKid(ndata, sdata) {
    setFormData((pre) => {
      pre.no_of_kids = Number(ndata) + Number(sdata);
      return { ...pre };
    });
  }
  function deletechild(ind) {
    setFormData((pre) => {
      let dd = { ...pre };
      let ddd = [...dd.child_details];

      ddd.splice(ind, 1);
      dd.child_details = [...ddd];

      return { ...dd };
    });
  }

  function countryStateCityInpChange(e, input) {
    let data = JSON.parse(e.target.value);
    let obj = {};
    switch (input) {
      case "country":
        setCountryCode(data.isoCode);
        obj.country = data.name;
        obj.state = null;
        obj.city = null;
        break;
      case "state":
        setStateCode(data.isoCode);
        obj.state = data.name;
        obj.city = null;
        break;
      case "city":
        obj.city = data.name;
        break;
    }
    setFormData((pre) => ({ ...pre, ...obj }));
  }
  function radioButtonChange(e, val) {
    let { name } = e.target;
    setFormData((pre) => {
      pre[name] = val;
      return { ...pre };
    });
  }
  async function handleSubmit(e) {
    e.preventDefault();
    setLoader(true);
    try {
      let img = imagesValue;
      if (imagesValue.length == 0) {

        setToaster({
          show: true,
          message: "please select images",
        });
        setLoader(false);
        return;
      }
      if (imgForRemove.length > 0) {
        let imagesData = await Promise.all(
          imgForRemove.map(async (e) => await deleteDocs({ fileName: e.fileName }))
        );
      }

      let httpimage = [];
      let formData2 = new FormData();
      let nonhttpImage = [];
      img.forEach((e) => {
        if (e.fileUrl.includes("blob")) {
          nonhttpImage.push(e);
        } else {
          httpimage.push({
            fileName: e.fileName,
            fileUrl: e.fileUrl,
          });
        }
      });
      for (const item of nonhttpImage.map((e) => e.file)) {
        formData2.append("uploadMultiDocs", item);
      }
      let uploadResponse = await uploadMultipleFiles(formData2);
      let res2 = {
        ...formData,
        photos: [...httpimage, ...uploadResponse.data.data],
      };
      let res = await addFormConcise1(id, res2);
      getData();
      navigate(`/surrogateMother/primaryResume/${id}/step2`);
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }
  return (
    <>
      <Loader open={loader} />
      <Toaster
        event={toaster.message}
        show={toaster.show}
        severity={"error"}
        setToaster={setToaster}
      />
      <div className="text-center second_heading mt-4 mb-2">
        {t("General Information")}
      </div>
      <section>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <MultipleImageUpload
              value={imagesValue}
              onChange={multipleImageInputChange}
              maximg={5}
              setDeletedImage={setImgForRemove}
            />

            <div className="col-md-12 mt-4 p-2 input-text-main rounded-pill">
              <div className="input-text lufga_16px">
                <img className="" src="/images/bulb_icon.png" alt="" />{t("Good photos help to catch good intended parent(s)' eyes")}

              </div>
            </div>

            <div className="col-md-4 mt-4">
              <label className="form-label fs-5">
                {t("First Name")}
                <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                className="form-control"
                placeholder={t("First Name")}
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-4 mt-4 md-sm-4">
              <label className="form-label fs-5">{t("Middle Initial")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Middle Initial")}
                name="middleName"
                value={formData.middleName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-4 mt-4 md-sm-4">
              <label className="form-label fs-5">
                {t("Last Name")}
                <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                className="form-control"
                placeholder={t("Last Name")}
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-12 mt-2">
              <input
                onChange={(e) => {
                  setFormData((pre) => {
                    pre.not_show_fullName = e.target.checked;
                    return { ...pre };
                  });
                }}
                name="not_show_fullName"
                type="checkbox"
                checked={formData?.not_show_fullName}
              />
              <label className="ms-2 lufga">
                {t("Not Shown on Public Profile")}
              </label>
            </div>

            <div className="col-md-6 mt-4">
              <label className="form-label fs-5">
                {t("Date of Birth")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="date"
                className="form-control"
                placeholder={t("Date of Birth")}
                name="dob"
                required
                max={moment().format("YYYY-MM-DD")}
                value={
                  formData.dob && moment(formData.dob).format("YYYY-MM-DD")
                }
                onChange={(e) => {
                  handleInputChange(e);
                  // ageCalculate(e.target.value)
                }}
              />
            </div>

            <div className="col-md-12 mt-4">
              <label className="form-label fs-5">
                {t("Current Address")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                required
                className="form-control"
                placeholder={t("Current Addresss")}
                name="current_address"
                value={formData.current_address}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-12 mt-2">
              <input
                onChange={(e) => {
                  setFormData((pre) => {
                    pre.not_show_current_address = e.target.checked;
                    return { ...pre };
                  });
                }}
                name="not_show_current_address"
                type="checkbox"
                checked={formData?.not_show_current_address}
              />
              <label className="ms-2 lufga">
                {t("Not Shown on Public Profile")}
              </label>
            </div>

            <div className="col-md-4 mt-4">
              <label className="form-label fs-5">
                {t("Country")} <span className="text-danger">*</span>
              </label>
              <select
                required
                onChange={(e) => countryStateCityInpChange(e, "country")}
                value={JSON.stringify(
                  allCountries.find((e) => e.name == formData.country)
                )}
                className="form-select"
              >
                <option value={""}>Select</option>
                {allCountries.map((res, key) => {
                  return (
                    <option value={JSON.stringify(res)}>{res.name}</option>
                  );
                })}
              </select>
            </div>

            <div className="col-md-4 mt-4">
              <label className="form-label fs-5">
                {t("State of Residence")} <span className="text-danger">*</span>
              </label>
              <select
                value={JSON.stringify(
                  State.getStatesOfCountry(countryCode).find(
                    (e) => e.name == formData.state
                  )
                )}
                onChange={(e) => countryStateCityInpChange(e, "state")}
                className={`form-select`}
                required
              >
                <option value={""}>{t("Select")}</option>
                {State.getStatesOfCountry(countryCode).map((res, key) => {
                  return (
                    <option value={JSON.stringify(res)}>{res.name}</option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-4 mt-4">
              <label className="form-label fs-5">
                {t("City")} <span className="text-danger">*</span>
              </label>
              <select
                value={JSON.stringify(
                  City.getCitiesOfState(countryCode, stateCode).find(
                    (e) => e.name == formData.city
                  )
                )}
                onChange={(e) => countryStateCityInpChange(e, "city")}
                className={`form-select`}
                required
              >
                <option value={""}>{t("Select")}</option>
                {City.getCitiesOfState(countryCode, stateCode).map(
                  (res, key) => {
                    return (
                      <option value={JSON.stringify(res)}>{res.name}</option>
                    );
                  }
                )}
              </select>
            </div>

            <div className="col-md-4 mt-4">
              <label className="form-label fs-5">
                {t("Zip Code")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                required
                className="form-control"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleInputChange}
              />
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <label className="form-label fs-5">
                  {t("No of Vaginal Deliveries")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  required
                  className="form-control"
                  name="no_of_vaginal_deliveries"
                  defaultValue={formData.no_of_vaginal_deliveries}
                  onChange={(e) => {
                    handleInputChange(e);
                    sumofKid(
                      e.target.value,
                      formData.no_of_c_section_deliveries
                    );
                  }}
                />
              </div>
              <div className="col-md-4 mt-4">
                <label className="form-label fs-5">
                  {t("No. of C-Section Deliveries")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  required
                  className="form-control"
                  name="no_of_c_section_deliveries"
                  defaultValue={formData.no_of_c_section_deliveries}
                  onChange={(e) => {
                    console.log(formData.no_of_vaginal_deliveries);

                    handleInputChange(e);

                    sumofKid(e.target.value, formData.no_of_vaginal_deliveries);
                  }}
                />
              </div>
              <div className="col-md-4 mt-4">
                <label className="form-label fs-5">
                  {t("No of Kids")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  required
                  className="form-control"
                  name="no_of_kids"
                  value={formData.no_of_kids}
                  onChange={handleInputChange}
                  disabled
                />
              </div>
            </div>

            <div className="col-md-6 mt-4">
              <label className="form-label fs-5">
                {t("Do you have Insurance")}?
              </label>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      id="medical_insuranceId1"
                      type="radio"
                      onChange={(e) => radioButtonChange(e, true)}
                      checked={formData.medical_insurance === true}
                      name="medical_insurance"
                    />
                    <label
                      htmlFor="medical_insuranceId1"
                      className="form-check-label LabeSubHeading "
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      id="medical_insuranceId2"
                      type="radio"
                      onChange={(e) => radioButtonChange(e, false)}
                      checked={formData.medical_insurance === false}
                      name="medical_insurance"
                    />
                    <label
                      htmlFor="medical_insuranceId2"
                      className="form-check-label LabeSubHeading "
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-4">
              <label className="form-label fs-5">
                {t("Will you terminate when needed")} ?
              </label>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      id="terminate_when_need1"
                      type="radio"
                      onChange={(e) => radioButtonChange(e, true)}
                      checked={formData.terminate_when_need === true}
                      name="terminate_when_need"
                    />
                    <label
                      htmlFor="terminate_when_need1"
                      className="form-check-label LabeSubHeading "
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      id="terminate_when_need2"
                      type="radio"
                      onChange={(e) => radioButtonChange(e, false)}
                      checked={formData.terminate_when_need === false}
                      name="terminate_when_need"
                    />
                    <label
                      htmlFor="terminate_when_need2"
                      className="form-check-label LabeSubHeading "
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-4">
              <label className="form-label fs-5">
                {t("Are you willing to work with HIV positive intended parent")}
                ?
              </label>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      id="willing_to_work_with_hiv_positive_ip1"
                      type="radio"
                      onChange={(e) => radioButtonChange(e, true)}
                      checked={
                        formData.willing_to_work_with_hiv_positive_ip === true
                      }
                      name="willing_to_work_with_hiv_positive_ip"
                    />
                    <label
                      htmlFor="willing_to_work_with_hiv_positive_ip1"
                      className="form-check-label LabeSubHeading "
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      id="willing_to_work_with_hiv_positive_ip2"
                      type="radio"
                      onChange={(e) => radioButtonChange(e, false)}
                      checked={
                        formData.willing_to_work_with_hiv_positive_ip === false
                      }
                      name="willing_to_work_with_hiv_positive_ip"
                    />
                    <label
                      htmlFor="willing_to_work_with_hiv_positive_ip2"
                      className="form-check-label LabeSubHeading "
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 mt-4">
                  <label className="form-label fs-5">
                    {t(
                      "Are you willing to work with HEP B positive intended parent"
                    )}
                    ?
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          id="willing_to_work_with_hep_b_positive_ip1"
                          type="radio"
                          onChange={(e) => radioButtonChange(e, true)}
                          checked={
                            formData.willing_to_work_with_hep_b_positive_ip === true
                          }
                          name="willing_to_work_with_hep_b_positive_ip"
                        />
                        <label
                          htmlFor="willing_to_work_with_hep_b_positive_ip1"
                          className="form-check-label LabeSubHeading "
                        >
                          {t("Yes")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          id="willing_to_work_with_hep_b_positive_ip2"
                          type="radio"
                          onChange={(e) => radioButtonChange(e, false)}
                          checked={
                            formData.willing_to_work_with_hep_b_positive_ip === false
                          }
                          name="willing_to_work_with_hep_b_positive_ip"
                        />
                        <label
                          htmlFor="willing_to_work_with_hep_b_positive_ip2"
                          className="form-check-label LabeSubHeading "
                        >
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-4">
              <label className="form-label fs-5">
                {t("Are you Employed")}?
              </label>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      id="currently_employed1"
                      type="radio"
                      name="currently_employed"
                      onChange={(e) => radioButtonChange(e, true)}
                      checked={formData.currently_employed === true}
                    />
                    <label
                      htmlFor="currently_employed1"
                      className="form-check-label LabeSubHeading "
                    >
                      {t("Yes")}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-check">
                    <input
                      required
                      className="form-check-input"
                      id="currently_employed2"
                      type="radio"
                      onChange={(e) => {
                        radioButtonChange(e, false);
                        setFormData((pre) => {
                          pre.occupation = "";
                          return { ...pre };
                        });
                      }}
                      checked={formData.currently_employed === false}
                      name="currently_employed"
                    />
                    <label
                      htmlFor="currently_employed2"
                      className="form-check-label LabeSubHeading "
                    >
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {formData.currently_employed ? (
              <div className="col-md-6 mt-4">
                <label className="form-label fs-5">
                  {t("Job Role")}?<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  required
                  className="form-control"
                  name="occupation"
                  value={formData.occupation}
                  onChange={handleInputChange}
                />
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="second_heading text-center mt-5 mb-4">
            {t("Reproductive Information")}
          </div>

          <div className="complete_text mb-md-4 mb-sm-4 mb-4">
            {t("Complete the following for each child you have delivered")}:
          </div>

          {formData?.child_details?.map((res, key) => {
            return (
              <div key={key} className="mb-3">
                <div className="complete_text d-flex align-items-center">
                  <button
                    className="addChild_btn me-2"
                    type="button"
                    onClick={() => deletechild(key)}
                  >
                    <FaMinus />
                  </button>
                  {t("Child")} #{key + 1}
                </div>

                <div className="row">
                  <div className="col-md-4 mt-3">
                    <label className="form-label fs-5">
                      {t("Date of Delivery")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      required
                      className="form-control"
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) => {
                        setFormData((pre) => {
                          pre.child_details[key].dob = e.target.value;
                          return { ...pre };
                        });
                      }}
                      value={moment(res?.dob).format("YYYY-MM-DD")}
                    />
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="form-label fs-5">
                      {t("Gender")}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      required
                      name="gender"
                      onChange={(e) => {
                        setFormData((pre) => {
                          pre.child_details[key].gender = e.target.value;
                          return { ...pre };
                        });
                      }}
                      className="form-select"
                      value={res.gender}
                    >
                      <option value={""}>Select Gender</option>
                      <option value={"Male"}>Male</option>
                      <option value={"Female"}>Female</option>
                    </select>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="form-label fs-5">
                      {t("Weeks at Delivery")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      required
                      value={res?.weeks_at_delivery}
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value == " ") {
                          e.target.value = "";
                        } else {
                          setFormData((pre) => {
                            pre.child_details[key].weeks_at_delivery =
                              e.target.value;
                            return { ...pre };
                          });
                        }
                      }}
                    />
                  </div>

                  <div className="row m-0 p-0 col-md-6 mt-3">
                    <label className="form-label fs-5">
                      {t("Baby’s Weight")}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-6 d-flex align-items-center">
                      <input
                        required
                        type="number"
                        onChange={(e) => {
                          setFormData((pre) => {
                            pre.child_details[key].body_weight.lbs =
                              e.target.value;
                            return { ...pre };
                          });
                        }}
                        value={res?.body_weight?.lbs}
                        className="form-control"
                      />
                      <span className="ms-2">{t("Lbs")}</span>
                    </div>
                    <div className="col-6 d-flex align-items-center">
                      <input
                        required
                        type="number"
                        onChange={(e) => {
                          setFormData((pre) => {
                            pre.child_details[key].body_weight.oz =
                              e.target.value;
                            return { ...pre };
                          });
                        }}
                        value={res?.body_weight?.oz}
                        className="form-control"
                      />
                      <span className="ms-2">{t("Oz")}</span>
                    </div>
                  </div>

                  <div className="mt-3 col-md-6">
                    <label className="form-label fs-5">
                      {t("Delivery Type")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      required
                      className="form-control"
                      value={res.delivery_type}
                      onChange={(e) => {
                        if (e.target.value == " ") {
                          e.target.value = "";
                        } else {
                          setFormData((pre) => {
                            pre.child_details[key].delivery_type =
                              e.target.value;
                            return { ...pre };
                          });
                        }
                      }}
                    />
                  </div>

                  <div className="row col-6 mt-3">
                    <label className="form-label fs-5">{t("Surrogacy")}?
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          onChange={(e) => {
                            setFormData((pre) => {
                              pre.child_details[key].surrogacy = true;
                              return { ...pre };
                            });
                          }}
                          type="radio"
                          name={"surrogacy" + key}
                          checked={res.surrogacy == true}
                        />
                        <label className="form-check-label LabeSubHeading ">
                          {t("Yes")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          onChange={(e) => {
                            setFormData((pre) => {
                              pre.child_details[key].surrogacy = false;
                              return { ...pre };
                            });
                          }}
                          checked={res.surrogacy === false}
                          type="radio"
                          name={"surrogacy" + key}
                        />
                        <label className="form-check-label LabeSubHeading ">
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row col-md-6 mt-3">
                    <label className="form-label fs-5">
                      {t("Singleton or Multiple")}?
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          onChange={(e) => {
                            setFormData((pre) => {
                              pre.child_details[key].singleton_or_multiple =
                                "Singleton";
                              return { ...pre };
                            });
                          }}
                          checked={res.singleton_or_multiple == "Singleton"}
                          type="radio"
                          name={"singleton_or_multiple" + key}
                        />
                        <label className="form-check-label LabeSubHeading ">
                          {t("Singleton")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          onChange={(e) => {
                            setFormData((pre) => {
                              pre.child_details[key].singleton_or_multiple =
                                "Multiple";
                              return { ...pre };
                            });
                          }}
                          checked={res.singleton_or_multiple == "Multiple"}
                          type="radio"
                          name={"singleton_or_multiple" + key}
                        />
                        <label className="form-check-label LabeSubHeading ">
                          {t("Multiple")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="col-12 d-flex w-100 justify-content-center mt-4">
            <button className="addChild_btn" type="button" onClick={addChild}>
              <IoMdAdd /> {t("Add Child")}
            </button>
          </div>
          <div className="text-end mt-4">
            <button className="btn-primary px-5" type="submit">
              {t("Next")}
            </button>
          </div>
        </form>
      </section>
    </>
  );
};
