import React, { useEffect, useState } from "react";
import "./surrogateMother.css";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'

import { addDocument } from "../../../Firebase/cloudFirestore/setData";
import { DialogSignUp } from "../DialogSignUp/DialogSignUp";


const SurrogateMotherTest = () => {
  const [visible, setVisible] = useState(false);
  const [signIn, setSignIn] = useState(false);
  // const [visible, setVisible] = useState(false);
  const [forget, setForget] = useState(false);

  const navigate =useNavigate()
  let { t } = useTranslation();

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  
  let cardsArr = [
    {
      name: "Become a Surrogate",
      imgUrl: "/images/surrogate_card_image_one.png",
    },
    {
      name: "Find an Intended Parent",
      imgUrl: "/images/surrogate_card_image_three.png",
    },
    {
      name: "Find an Attorney",
      imgUrl: "/images/surrogate_card_image_four.png",
    },
    { name: "Need an Agent", imgUrl: "/images/surrogate_card_image_five.png" },
    {
      name: "Referral to others",
      imgUrl: "/images/surrogate_card_image_six.png",
    },
  ];






  function SecondSubmit(e){
    e.preventDefault()
    setForget(true)
    setSignIn(false)

  }
    
  function ForgetPassword(e){
    e.preventDefault()
    setForget(true)
    setSignIn(false)

  }
function HowDoesSubmit (){
  navigate('approvedtest')
}

  return (
    <>
    <div className="english_page">
      <div className="main_div">
        <div className="upper_section">
          <div className="upper_section_content py-md-2 py-5">
            <div className="lufga_20px_bg_lightblue">
            {t("BEST CHILD BIRTH, CHILD CARE, CHILD EDUCATION PLATFORM")}
            </div>
            <div className="volkhov_48px pt-3">{t("Surrogate Mother")}</div>
            <div className="lufga_18px_bg_gray pt-2">
            {t("People respect Surrogate Mothers because they helping people dream to be true. To be a Surrogate Mother can be a full time or part time job which not difficult for a health woman. Good extra income!NewHopePoints can give you extra bonus through BIDDING process or REFERRAL program.You can approach Intended Parents on this platform if you wish")}
            </div>
          <div >
            <button className="mt-3"
             style={{background:"#187C8B",
             padding:"21px 26px",
             border:"None",
             borderRadius:"12px",
             color:"white",
             fontFamily:"Lufga",
             fontSize:"20",
             fontWeight:"500"
          
          
          }} onClick={HowDoesSubmit}>{t("How Does it Works ?")}</button>
          </div>
          </div>
          <div className="upper_section_image_couple">
            <img
              src="/images/surrogate_mother_upper_right_image.png"
              alt="couple image"
            />
          </div>
        </div>
        <div className="all_stories">
          <div className="card_container">
            <div className="card_padding">
              <div className="row row_section">
                {cardsArr?.map((res) => {
                  return (
                    <div className="col-12 col-md-4 col-sm-6 col-lg-4 p-2" onClick={() => setVisible(true)} >
                      <div className="card">

                        <img
                          className="card-img-top"
                          src={res.imgUrl}
                          alt="Card image cap"
                        />

                        <div className="card-body">
                          <div className="lufga_14px">{t(res.name)}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}


              </div>
            </div>
          </div>
        </div>

        <div className="any_question_section">
          <div className="left_of_any_question">
            <div>
              <img src="/images/aq1.png" alt="images" />
            </div>
            <div>
              <img src="/images/aq2.png" alt="images" />
            </div>
            <div>
              <img src="/images/aq3.png" alt="images" />
            </div>
          </div>
          <div className="content_of_any_question d-flex flex-column gap-3">
            <div className="upper_section_of_ask_question">
              <div className="lufga_36px text-white">
              {t("Do you still have any questions?")}
              </div>
            </div>
            <div className="middle_section_of_ask_question">
              <div className="lufga_20px text-white">
              {t("Don't hesitate to leave us your phone number. We will contact you")}
              </div>
              <div className="lufga_20px text-white">
              {t("to discuss any questions you may have")}
              </div>
            </div>
            <div className="lower_section_of_ask_question d-flex justify-content-center">
              <div className="input_width">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control py-2"
                    placeholder={t('Enter your phone number')}
                    aria-label="Recipient's username"
                  ></input>

                  <button
                    type="button"
                    className="btn btn-dark"
                    id="button-addon2"
                  >
                    {t("Submit")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="right_of_any_question">
            <div>
              <img src="/images/aq4.png" alt="images" />
            </div>
            <div>
              <img src="/images/aq5.png" alt="images" />
            </div>
            <div>
              <img src="/images/aq6.png" alt="images" />
            </div>
          </div>
        </div>
      </div>
    </div>

  <DialogSignUp visible={visible} setVisible={setVisible} />


    <Dialog
      closable={false}
      visible={signIn}
      onHide={() => setSignIn(false)}
      style={{ width: "60vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
    >
      <div className="DialogParent">
        <div className="" style={{overflow:"hidden"}}>
          <form onSubmit={SecondSubmit} >
        <span className="SignUphedaing text-center  ">Sign in</span>
           <div className="row mb-3">
           <div className=" col-md-12">
              <label htmlFor="exampleInputEmail1" className="form-label">Email Address</label>
              <input type="email" className="form-control " placeholder="Enter Your Name" />
           
            </div>
        
           </div>
           <div className="row mb-3">
           <div className=" col-md-12 EyeView">
              <label htmlFor="exampleInputEmail1" className="form-label" >Password</label>
              <input type="password" className="form-control"  placeholder="Enter  Password" />
              <i className="bi bi-eye View"></i>
            </div>
           
         
           </div>
           <div className="d-flex justify-content-between">
            <div className="ForgetPassword pointer" onClick={ForgetPassword}>Forget Password</div>
            <div>
              <span className="spanaPara">Don’t have any account? </span>
              <span className="Create">Create New</span>
            </div>
           </div>
           <div className="mt-3 form-check">
              <input type="checkbox" className="form-check-input" id="exampleCheck1" />
              <label className="form-check-label" htmlFor="exampleCheck1">
              I'm not a robot
              </label>
            </div>
            <div className="ButtonForm mt-3">
              <button className="CencelButton" type="button"  onClick={()=> setSignIn(false)}>Cancel</button>
              <button className="SaveButton" type="submit" >Submit</button>
            </div>
          </form>

        </div>

      </div>
    </Dialog>




    <Dialog
      closable={false}
      visible={forget}
      onHide={() => setForget(false)}
      style={{ width: "60vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
    >
      <div className="DialogParent">
        <div className="gap-3">
          <form >
        <h1 className="SignUphedaing text-center mb-5">Forget Password</h1>
           <div className="row">
           <div className=" col-md-6 Codeparent">
              <label htmlFor="exampleInputEmail1" className="form-label">Email Address</label>
              <input type="email" className="form-control " placeholder="Enter Email" />
              <div className="GetCode">Get OTP</div>
            </div>
            <div className=" col-md-6">
              <label htmlFor="exampleInputEmail1" className="form-label">OTP</label>
              <input type="tex" className="form-control " placeholder="Enter Your Name" />
           
            </div>
        
           </div>
           <div className="row">
           <div className=" col-md-6 EyeView">
              <label htmlFor="exampleInputEmail1" className="form-label" >Password</label>
              <input type="password" className="form-control"  placeholder="Enter  Password" />
              <i className="bi bi-eye View"></i>
            </div>
            <div className=" col-md-6 EyeView">
              <label htmlFor="exampleInputEmail1" className="form-label" >Confirm Password</label>
              <input type="password" className="form-control"  placeholder="Enter  Password" />
              <i className="bi bi-eye View"></i>
            </div>
          
           
         
           </div>
           <div className="d-flex justify-content-between ">
            <div className="BackLogin">Back to Login Screen</div>
            <div>
              <span className="TextCustomBottom">Don’t have any account? </span>
              <span className="Create">Create New</span>
            </div>
           </div>
           <div className="mt-3 form-check">
              <input type="checkbox" className="form-check-input" id="exampleCheck1" />
              <label className="form-check-label" htmlFor="exampleCheck1">
              I'm not a robot
              </label>
            </div>
            <div className="ButtonForm mt-3">
              <button className="CencelButton"  type="button" onClick={()=>setForget(false)}>Cancel</button>
              <button className="SaveButton"  type="submit" >Reset Password</button>
            </div>
          </form>

        </div>

      </div>
    </Dialog>


  </>
  );
};


export default SurrogateMotherTest
