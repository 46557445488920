
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";


  // your firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyB7AlJeiLg5akHciOzD47431WtXQcElBR4",
    authDomain: "ivf-project-65157.firebaseapp.com",
    projectId: "ivf-project-65157",
    storageBucket: "ivf-project-65157.appspot.com",
    messagingSenderId: "514863985904",
    appId: "1:514863985904:web:f0017d4d1e7bd3245dbe35"};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
// export const auth = getAuth(app);
export const storage = getStorage(app);



const auth = getAuth(app);

export { auth };



