import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import style from './dialog.module.css'
import "./ResumeSteps.css";
import {
  resumeForm11,
} from "../../../../service/Apis";

import { userId } from "../../../../service/userData";
import { resumeAllKeys } from "./resumeKeys";
import PhoneInput from 'react-phone-number-input'
import Loader from "../../../../components/Loader/Loader";
import { Dialog } from "primereact/dialog";

export const ResumeStep11 = () => {

  const [dialogOpen, setDialogOpen] = useState(false);
  let { data } = useOutletContext();
  const [submitData, setSubmitData] = useState(null)
  let [loader, setLoader] = useState(false)
  const [inputValue, setInputValue] = useState("");
  let step11Keys = resumeAllKeys.step11;

  // useEffect(() => {
  //   let obj = {};
  //   for (let i in step11Keys) {
  //     if (data.resumeData[i]) {
  //       obj[i] = data?.resumeData?.[i] || step11Keys[i];
  //     }
  //   }
  //   setAllData((pre) => ({ ...pre, ...obj }));
  // }, [data.resumeData]);

  useEffect(() => {
    let obj = {};
    for (let i in step11Keys) {
      if (data.resumeData.hasOwnProperty(i)) {
        obj[i] = data?.resumeData?.[i]
      }
      else {
        obj[i] = step11Keys[i]
      }
    }
    setAllData((pre) => ({ ...pre, ...obj }));
  }, [data.resumeData]);

  const [allData, setAllData] = useState({
    reference_name1: "",
    relationship1: "",
    phone_no1: "",
    reference_name2: "",
    relationship2: "",
    phone_no2: "",
    photos: [],
    agreement_name: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (value == ' ') {
      e.target.value = ''
    } else {
      setAllData((preData) => ({
        ...preData,
        [name]: value,
      }));
    }
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    let userID = userId();
    setLoader(true)

    try {

      let data = {
        ...allData
      };

      let res = await resumeForm11(userID, data);
      setSubmitData(res.data.data)
      setDialogOpen(true)


    } catch (error) {
      console.log(error);
    }
    finally {
      setLoader(false);
    }
  };

  function navigateToDashBoard(res) {
    window.location.href = `https://dashboard.newhopepoints.org/surrogate/dashboard_?token=${encodeURIComponent(res?.token)}&email=${encodeURIComponent(res?.fullData?.email)}&id=${encodeURIComponent(res?.fullData?.userId)}`
  }

  let { t } = useTranslation();
  let { navigation } = useOutletContext();


  function setPhoneCodebyLanguage(key) {
    switch (key) {
      case 'en':
        return 'US'
        break;
      case 'es':
        return 'ES'
        break;
      case 'zh':
        return 'CN'
        break;
      case 'en-US':
        return 'US'
        break;
      default:
        return 'US'
        break;
    }
  }



  return (
    <>
      <Loader open={loader} />
      <Dialog
        closable={false}
        visible={dialogOpen}
        onHide={() => { }}
        style={{ width: "60vw", border: '3px solid rgba(24, 124, 139, 1)', borderRadius: '25px' }}
        contentClassName={`${style.bgImg}`}
        breakpoints={{ "960px": "75vw", "641px": "98vw" }}
      >
        {/* <div className='w-100 d-flex justify-content-end'><VscChromeClose className={style.closeIcon} onClick={() =>{}} /></div> */}
        <div className='py-4'>
          <div className={`d-flex justify-content-center`}>
            <img src='/images/cele.png' style={{ width: '120px' }} alt='' />
          </div>
          <div className={`mt-4 ${style.heading} mb-4`}>
            {t('Congratulations!')}
            <br />
            {t('Your advance resume has been generated')}
          </div>
          <div className='mt-4 d-flex justify-content-center'>
            <button className={`${style.SaveButton}`} onClick={() => navigateToDashBoard(submitData)}>{t('Explore Now')}</button>
          </div>
        </div>


      </Dialog>
      <div className="ResumeStep_con">
        <div className="Spouse / Partner Information">
          <form onSubmit={handleSubmit}>
            <div className="First_section">

              <div className="SubHeading mb-lg-4 mb-md-4 mb-sm-3 mb-2">{t("References")}</div>

              <p className="text-center mb-lg-5 mb-md-5 mb-sm-4 mb-4">
                {t(
                  "Please list two (2) references (only one of which may be a family member)"
                )}
              </p>

              <div className="row mb-4">
                <div className="col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fs-5"
                  >
                    {t("Reference #1 Name")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    name="reference_name1"
                    value={allData.reference_name1}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fs-5"
                  >
                    {t("Relationship")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    name="relationship1"
                    value={allData?.relationship1}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fs-5"
                  >
                    {t("Phone")}
                    <span className="text-danger">*</span>
                  </label>
                  <PhoneInput
                    international
                    defaultCountry={setPhoneCodebyLanguage(localStorage.getItem('i18nextLng'))}
                    withCountryCallingCode
                    value={allData?.phone_no1}
                    onChange={(e) => {
                      if (e == " ") {
                        e = "";
                      } else {
                        setAllData((preData) => ({
                          ...preData,
                          phone_no1: e,
                        }));
                      }
                    }}
                  />
                  {/* <input
                    type="number"
                    className="form-control"
                    required
                    name="phone_no1"
                    value={allData.phone_no1}
                    onChange={handleChange}
                  /> */}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fs-5"
                  >
                    {t("Reference #2 Name")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    name="reference_name2"
                    value={allData.reference_name2}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fs-5"
                  >
                    {t("Relationship")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    name="relationship2"
                    value={allData.relationship2}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fs-5"
                  >
                    {t("Phone")}
                    <span className="text-danger">*</span>
                  </label>
                  <PhoneInput
                    international
                    defaultCountry={setPhoneCodebyLanguage(localStorage.getItem('i18nextLng'))}
                    withCountryCallingCode
                    value={allData?.phone_no2}
                    onChange={(e) => {
                      if (e == " ") {
                        e = "";
                      } else {
                        setAllData((preData) => ({
                          ...preData,
                          phone_no2: e,
                        }));
                      }
                    }}
                  />
                  {/* <input
                    type="number"
                    className="form-control"
                    required
                    name="phone_no2"
                    value={allData.phone_no2}
                    onChange={handleChange}
                  /> */}
                </div>
              </div>

              {/* <MultiSelectImage files={file} setFiles={(setFile)} url={url} submit={submit} setSubmit={setSubmit} />  */}
            </div>
            <div className="Second_Section">
              <h1 className="SubHeading mb-lg-4 mb-md-4 mb-sm-3 mb-3">{t("Agreement")}</h1>
              <p className="text-center">
                {t(
                  "By entering my name below, I/we agree that the responses on this application are true and accurate.If any false information has been intentionally provided, or if any pertinent information has beendeliberately omitted, this agreement may be considered null and void."
                )}
              </p>

              <div className="row mb-4">
                <div className="MiddleSubHeading mb-4">
                  {t("Agree e-sign")}{" "}
                  <span style={{ color: "#187C8B", fontStyle: "oblique" }}>
                    {/* {inputValue} */}
                  </span>
                </div>
                <div className="col-md-2">
                  <label className=" fs-5 d-flex align-items-center pt-1">
                    {t("I AGREE")}
                  </label>
                </div>
                <div className="col-md-10">
                  <input
                    type="text"
                    className="form-control"
                    required
                    name="agreement_name"
                    value={allData.agreement_name}
                    onChange={(e) => {
                      setInputValue(e.target.value);
                      handleChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="ButtonForm mt-3">
              <button
                className="btn_non_fill"
                type="button"
                onClick={navigation.previous}
              >
                {t('Previous')}
              </button>
              <button className="btn_fill" type="submit">
                {t('Submit')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
