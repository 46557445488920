import jsPDF from 'jspdf';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import Loader from '../../../../components/Loader/Loader';
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import * as htmlToImage from 'html-to-image';
import './Exploreresume.css';
import { getEmbryoDonorFormDataByUserId } from '../../../../service/Apis';
import { EmbryoDonorCongratsDialog } from '../Embryodonors_form/EmbryoDonorCongratsDialog';
import moment from 'moment';



const EmbryoResume = () => {

    const userData = JSON.parse(localStorage.getItem('userData'))

    useEffect(() => {
        const idToGetFormData = userData?.userId;
        if (idToGetFormData) {
            getFormdata(idToGetFormData)
        }
    }, [])

    async function getFormdata(id) {
        try {
            let res = await getEmbryoDonorFormDataByUserId(id);

            setMotherData(res.data.data.bio_mother)
            setFatherData(res.data.data.bio_father);

            setmemberId(res.data.data.memberId)
            setFormData({ ...formData, ...res.data.data.bio_father })
        } catch (error) {
            console.log(error)
        }
    }





    const navigate = useNavigate()
    const [Congratulation, setCongratulation] = useState(true);
    const [formData, setFormData] = useState({})

    let { t } = useTranslation();
    const [loader, setLoader] = useState(false)
    const { id } = useParams()

    const [motherData, setMotherData] = useState([])
    const [fatherData, setFatherData] = useState({})
    const [memberId, setmemberId] = useState("")

    const resumeRef1 = useRef(null);
    const resumeRef2 = useRef(null);
    const resumeRef3 = useRef(null);
    const resumeRef4 = useRef(null);
    const resumeRef5 = useRef(null);
    const resumeRef6 = useRef(null);



    async function convertToImg() {
        setLoader(true);
        let arr = [resumeRef1.current, resumeRef2.current, resumeRef3.current, resumeRef4.current, resumeRef5.current, resumeRef6.current];
        let photoArr = [];
        const pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        console.log(pdfWidth,"this is width", pdfHeight ,"this is pdf height")

        for (let index = 0; index < arr.length; index++) {
            const res = arr[index];
            await htmlToImage.toPng(res, { quality: 0.5 }) // Reduced quality to 0.5
                .then(function (dataUrl) {
                    console.log(dataUrl,"this is dataurl")
                    photoArr.push(dataUrl);
                    const imgProps = pdf.getImageProperties(dataUrl);
                    console.log(imgProps,"this is image props")
                    const imgWidth = pdfWidth;
                    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

                    // Scale image to fit within PDF dimensions
                    const scaleFactor = Math.min(pdfWidth / imgProps.width, pdfHeight / imgProps.height);
                    const scaledWidth = imgProps.width * scaleFactor;
                    const scaledHeight = imgProps.height * scaleFactor;

                    console.log(scaleFactor,"this is scaleFactor",scaledWidth,"this is scale width", scaledHeight,"this is scale height")
                    
                    pdf.addImage(dataUrl, 'PNG', 0, 0, scaledWidth, scaledHeight, undefined, 'FAST'); // Added compression option
                    if (index !== arr.length - 1) {
                        pdf.addPage();
                    }
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                })
                .finally(() => {
                    if (index === arr.length - 1) {
                        setLoader(false);
                    }
                });
        }


        pdf.save('Resume.pdf');
    }




    function navigateTodashboard() {
        window.location.href = `https://dashboard.newhopepoints.org/embryo_donor/dashboard?token=${encodeURIComponent(userData?.token)}&email=${encodeURIComponent(userData?.email)}&id=${encodeURIComponent(userData?.userId)}&getDataId=${encodeURIComponent(userData.idToGetFormData)}
    `}


    return (
        <>
            <Loader open={loader} />
            <div>
                <EmbryoDonorCongratsDialog Congratulation={Congratulation} setCongratulation={setCongratulation} />
            </div>
            <div className='height_for_scroll'>

                <div className='w-100 d-flex justify-content-end pt-4 pe-4 pb-2'>
                    <button className='btn btn_non_fill text-dark bg-light d-flex align-items-center fs-6  download_button_for_pdf'
                        onClick={convertToImg}
                    ><MdOutlineFileDownload className='fs-5 me-2' /> Download Profile</button>
                </div>
                <div className='scroll_clild_wrapper'>
                    <div className='scroll_child p-2'>


                        <div
                            // className='p-2' 
                            style={{ width: '1000px'}}
                            className='v_resume_scalling'
                        >

                            <div className='row m-5 align-center mx-0' >

                                <div className='col-12 mb-4 mt-4 maincontainer' ref={resumeRef1}>
                                    <div className='topBox' />
                                    <div className='leftBox' />
                                    <div className='flex gap-1'>
                                        <div className='col-5'>
                                            {console.log(motherData?.mother_photos?.[motherData?.photo_resume_index]?.fileUrl, "ratatetetasdigjdwitjweksdiuwe")}
                                            <img
                                                crossOrigin='anonymous'
                                                src={`${motherData?.mother_photos?.[motherData?.photo_resume_index]?.fileUrl}?${moment().format('mm:ss')}`} style={{ width: '90%', height: '90%' }} alt='' />
                                        </div>
                                        <div className='col-7'>
                                            <div className='d-flex justify-content-between topRight'>
                                                <div className="">
                                                    <div><span className="topId"> {memberId}</span></div>
                                                    <div><span className="topText">Embryo Donor</span></div>

                                                </div>
                                                <div>
                                                    <img src='/images/bigLogo.png' alt='' style={{ width: '140px' }} />
                                                </div>
                                            </div>
                                            <div className='mt-5'>
                                                <span className='heading'>Bio Mother Information</span>
                                            </div>
                                            <div className='d-flex gap-3 mt-2'>
                                                <div className='topRightList'>
                                                    <div>
                                                        <span className="listHeading" >Date Of Birth:</span>
                                                        <span className="listText"> {motherData?.date_of_birth} </span>
                                                    </div>
                                                    <div>
                                                        <span className="listHeading" >Height: </span>
                                                        <span className="listText"> {motherData?.height?.ft}ft {motherData?.height?.in}in</span></div>
                                                    <div>
                                                        <span className="listHeading" >Weight:</span>
                                                        <span className="listText"> {motherData?.weight?.lbs} lbs</span>
                                                    </div>
                                                    <div>
                                                        <span className="listHeading" >Hair Color:</span>
                                                        <span className="listText"> {motherData?.hair_color}</span>
                                                    </div>
                                                    <div>
                                                        <span className="listHeading" >Eye Color:</span>
                                                        <span className="listText"> {motherData?.eye_color}</span>
                                                    </div>
                                                    <div>
                                                        <span className="listHeading" >Blood Group:</span>
                                                        <span className="listText"> {motherData?.blood_type}</span>
                                                    </div>
                                                </div>
                                                <div className='topRightList'>
                                                    <div>
                                                        <span className="listHeading">Ethnicity:</span>
                                                        <span className="listText"> {motherData?.race_ethnic_origin}</span></div>
                                                    <div>
                                                        <span className="listHeading">Ethnicity (Mother):</span>
                                                        <span className="listText"> {motherData?.race_ethnic_of_mother}</span></div>
                                                    <div>
                                                        <span className="listHeading">Ethnicity (Father):</span>
                                                        <span className="listText"> {motherData?.race_ethnic_of_father}</span></div>
                                                    <div>
                                                        <span className="listHeading">Highest Education:</span>
                                                        <span className="listText"> {motherData?.your_highest_education}</span></div>
                                                    <div>
                                                        <span className="listHeading">Degree Major:</span>
                                                        <span className="listText"> {motherData?.your_highest_degree_major}</span></div>

                                                    <div><span className="listHeading">College:</span>
                                                        <span className="listText"> {motherData?.highest_degree_major_college_name}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-2'>
                                        <label className='containerHeading'>
                                            Details About Family
                                        </label>
                                    </div>

                                    <div className='row border rounded-5 mt-2 p-3 mx-0'>
                                        <table className="tableStyle">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <td scope="col">Height</td>
                                                    <td scope="col">Age</td>
                                                    <td scope="col">Is he still alive?</td>
                                                    <td scope="col">Profession</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="styleFather">Father</td>
                                                    <td className="heightStyle">
                                                        <select disabled className="selectStyleFt">
                                                            <option> {motherData?.write_details_about_your_family?.father?.height?.ft}</option>
                                                        </select>
                                                        <span>Ft</span>
                                                        <select disabled className="selectStyleIn">
                                                            <option> {motherData?.write_details_about_your_family?.father?.height?.in}</option>
                                                        </select>
                                                        <span>In</span>
                                                    </td>
                                                    <td className="styleAge">
                                                        <input disabled type='text' className='form-text inputAge' value={motherData?.write_details_about_your_family?.father?.age} />
                                                    </td>
                                                    <td className="styleAlive" >
                                                        <input type='radio' className='form-check-input inputRadio' value="true" checked={motherData?.write_details_about_your_family?.father?.is_he_still_alive === true} disabled /><span>Yes</span>
                                                        <input type='radio' className='form-check-input inputRadio' value="false" checked={motherData?.write_details_about_your_family?.father?.is_he_still_alive === false} disabled /><span>No</span>
                                                    </td>
                                                    <td className="styleProfession">
                                                        <input disabled type='text' className='form-text inputProfession' value={motherData?.write_details_about_your_family?.father?.profession} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="styleFather">Mother</td>
                                                    <td className="heightStyle">
                                                        <select disabled className="selectStyleFt">
                                                            <option> {motherData?.write_details_about_your_family?.mother?.height?.ft}</option>
                                                        </select>
                                                        <span>Ft</span>
                                                        <select disabled className="selectStyleIn">
                                                            <option> {motherData?.write_details_about_your_family?.mother?.height?.in}</option>
                                                        </select>
                                                        <span>In</span>
                                                    </td>
                                                    <td className="styleAge">
                                                        <input disabled type='text' className='form-text inputAge' value={motherData?.write_details_about_your_family?.mother?.age} />
                                                    </td>
                                                    <td className="styleAlive" >
                                                        <input type='radio' className='form-check-input inputRadio' value="true" checked={motherData?.write_details_about_your_family?.mother?.is_she_still_alive === true} disabled /><span>Yes</span>
                                                        <input type='radio' className='form-check-input inputRadio' value="false" checked={motherData?.write_details_about_your_family?.mother?.is_she_still_alive === false} disabled /><span>No</span>
                                                    </td>
                                                    <td className="styleProfession">
                                                        <input disabled type='text' className='form-text inputProfession' value={motherData?.write_details_about_your_family?.mother?.profession} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="styleFather">GrandFather</td>
                                                    <td className="heightStyle">
                                                        <select disabled className="selectStyleFt">
                                                            <option> {motherData?.write_details_about_your_family?.grandfather?.height?.ft}</option>
                                                        </select>
                                                        <span>Ft</span>
                                                        <select disabled className="selectStyleIn">
                                                            <option> {motherData?.write_details_about_your_family?.grandfather?.height?.in}</option>
                                                        </select>
                                                        <span>In</span>
                                                    </td>
                                                    <td className="styleAge">
                                                        <input disabled type='text' className='form-text inputAge' value={motherData?.write_details_about_your_family?.grandfather?.age} />
                                                    </td>
                                                    <td className="styleAlive" >
                                                        <input type='radio' className='form-check-input inputRadio' value="true" checked={motherData?.write_details_about_your_family?.grandfather?.is_he_still_alive === true} disabled /><span>Yes</span>
                                                        <input type='radio' className='form-check-input inputRadio' value="false" checked={motherData?.write_details_about_your_family?.grandfather?.is_he_still_alive === false} disabled /><span>No</span>
                                                    </td>
                                                    <td className="styleProfession">
                                                        <input disabled type='text' className='form-text inputProfession' value={motherData?.write_details_about_your_family?.grandfather?.profession} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="styleFather">GrandMother</td>
                                                    <td className="heightStyle">
                                                        <select disabled className="selectStyleFt">
                                                            <option> {motherData?.write_details_about_your_family?.grandmother?.height?.ft}</option>
                                                        </select>
                                                        <span>Ft</span>
                                                        <select disabled className="selectStyleIn">
                                                            <option> {motherData?.write_details_about_your_family?.grandmother?.height?.in}</option>
                                                        </select>
                                                        <span>In</span>
                                                    </td>
                                                    <td className="styleAge">
                                                        <input disabled type='text' className='form-text inputAge' value={motherData?.write_details_about_your_family?.grandmother?.age} />
                                                    </td>
                                                    <td className="styleAlive" >
                                                        <input type='radio' className='form-check-input inputRadio' value="true" checked={motherData?.write_details_about_your_family?.grandmother?.is_she_still_alive === true} disabled /><span>Yes</span>
                                                        <input type='radio' className='form-check-input inputRadio' value="false" checked={motherData?.write_details_about_your_family?.grandmother?.is_she_still_alive === false} disabled /><span>No</span>
                                                    </td>
                                                    <td className="styleProfession">
                                                        <input disabled type='text' className='form-text inputProfession' value={motherData?.write_details_about_your_family?.grandmother?.profession} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='mt-4'>
                                        <label className='containerHeading'>
                                            Details About Brother & Sister
                                        </label>
                                    </div>

                                    <div className='row border rounded-5 mt-4 p-3 mx-0'>
                                        {motherData?.write_details_about_your_brother_and_sister?.length <= 0 ?
                                            <div className='d-flex justify-content-center p-4'>
                                                <div className='lufga_18px align-center'>No data Available For Siblings</div>
                                            </div>
                                            :

                                            <table className="tableStyle">
                                                <thead>
                                                    <tr>
                                                        <td cope="col">Brother or Sister</td>
                                                        <td scope="col">Height</td>
                                                        <td scope="col">Age</td>
                                                        <td scope="col">Profession</td>
                                                    </tr>
                                                </thead>
                                                {motherData?.write_details_about_your_brother_and_sister?.map((res) => {
                                                    return (
                                                        <tbody>
                                                            <tr>
                                                                <td className="nameStyleBrother" >
                                                                    <input type='text' className='form-text inputBrother' value={res?.brother_or_sister} disabled />
                                                                </td>
                                                                <td className="heightStyleBrother">
                                                                    <select disabled className="selectStyleFt">
                                                                        <option>{res?.height?.ft}</option>
                                                                    </select>
                                                                    <span>Ft</span>
                                                                    <select disabled className="selectStyleIn">
                                                                        <option>{res?.height?.in}</option>
                                                                    </select>
                                                                    <span>In</span>
                                                                </td>
                                                                <td className="ageStyleBrother">
                                                                    <input disabled type='text' value={res?.age} className='form-text inputAge' />
                                                                </td>
                                                                <td className="professionStyleBrother">
                                                                    <input disabled type='text' value={res?.profession} className='form-text inputProfession' placeholder='' />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                })}


                                            </table>
                                        }

                                    </div>


                                    <div class="row mt-2 p-2 mx-0">
                                        <table class="table table-bordered border-dark">
                                            <tbody className="styleBody">
                                                <tr>
                                                    <td className="styleLeft">Current Address (City, State & Country)</td>
                                                    <td valign='middle' className="styleRight"> {motherData?.current_address}</td>
                                                </tr>
                                                <tr>
                                                    <td>Current Working Profession</td>
                                                    <td valign='middle' className="styleRight"> {motherData?.current_working_profession}</td>
                                                </tr>
                                                <tr>
                                                    <td>Where were your born?</td>
                                                    <td valign='middle' className="styleRight"> {motherData?.where_were_you_born}</td>
                                                </tr>
                                                <tr>
                                                    <td>Current Marital Status</td>
                                                    <td valign='middle' className="styleRight"> {motherData?.current_marital_status}</td>
                                                </tr>
                                                <tr>
                                                    <td>Do you have ever wore glasses due to vision defect?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.do_you_have_ever_wore_glasses_due_to_vision_defect ? "Yes" : "No"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='rightBox' />
                                    <div className='bottomBox' />
                                </div>

                                <div className='col-12 mb-4 mt-4 maincontainer' ref={resumeRef2}>
                                    <div className='topBox' />
                                    <div className='leftBox' />
                                    <div class="row mt-2 p-2 mx-0" >
                                        <table class="table table-bordered border-dark">
                                            <tbody className="styleBody">

                                                <tr>
                                                    <td className="styleLeft">Please Describe your Personality Traits</td>
                                                    <td valign='middle' className="styleRight"> {motherData?.please_describe_your_personality_traits}</td>
                                                </tr>
                                                <tr>
                                                    <td>Please Provide reasons why you want to donate your embryo</td>
                                                    <td valign='middle' className="styleRight"> {motherData?.please_provide_reasons_why_you_want_to_donate_your_embryo}</td>
                                                </tr>
                                                <tr>
                                                    <td>Donating embryo is a significant commitment involving multiple doctors' appointments, injections, and small outpatient clinic visits. Are you prepared to bear this responsibility?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.donating_embryo_is_a_significant_commitment ? "Yes" : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Are you willing to be matched with different families regardless of their marital status, sexual orientation, sex, or race?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.are_you_ready_to_match_with_different_families ? "Yes" : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>If the intended parents express a desire to meet you in person or connect with you, would you be open to that?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.if_the_intended_parent_express_a_desire_to_meet_you ? "Yes" : "No"}
                                                    </td>
                                                </tr><tr>
                                                    <td className="styleLeft">If in the future the children want to meet you, would you be willing?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.if_in_the_future_the_children_want_to_meet_you ? "Yes" : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Do you have children of your own? If so, please provide their names and ages.</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.do_you_have_children_of_you_own}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>What activities bring you joy and happiness during your leisure time?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.what_activities_bring_you_joy_and_happiness}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >Do you love to travel? If so, which places do you prefer to go?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.do_you_love_to_travel}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>What is your favourite joyful color?</td>
                                                    <td valign='middle' className="styleRight" >
                                                        {motherData?.what_is_your_favorite_joyful_color}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Do you have any past or current medical conditions, such as major surgeries, congenital defects, or mental health conditions? If so, please provide detailed information about them.</td>
                                                    <td valign='middle' className="styleRight" >
                                                        {motherData?.do_you_have_any_past_or_current_medical_conditions}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="styleLeft">Do you know that genetic diseases can run in families?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.do_you_know_that_genetic_diseases_can_run_in_families ? "Yes" : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Do you smoke?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.do_you_smoke ? "Yes" : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Do you consume liquor</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.do_you_consume_liquor ? "Yes" : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Are you currently taking any medication, including over-the-counter or prescription drugs, for physical or mental health conditions? If so, please list all medications, their active substances, and the reasons for taking them.</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.are_you_currently_taking_any_medication}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Are you using medications or substances for sexual enhancement or entertainment? If so, please provide details about your condition or situation.</td>
                                                    <td valign='middle' className="styleRight" >
                                                        {motherData?.are_you_using_medications_or_substances_for_sexual_enhancement}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='rightBox' />
                                    <div className='bottomBox' />
                                </div>

                                <div className='col-12 mb-4 mt-4 maincontainer' ref={resumeRef3}>
                                    <div className='topBox' />
                                    <div className='leftBox' />
                                    <div className='row mt-2 p-2 mx-0'>
                                        <table className='table table-bordered border-dark'>
                                            <tbody className='styleBody'>
                                                <tr>
                                                    <td className="styleLeft">Does anyone in your family have a history of: cancer, mental retardation, autoimmune diseases, body dysmorphia, cystic fibrosis, Lyme disease, hypertension, memory loss, depression, kidney disease, bipolar disorder, heart disease, ADHD, anemia, birth defects, vision loss, bleeding disorders, or cardiovascular conditions? If so, please list them.</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.does_anyone_in_your_family_have_history_of_cancer}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>How often do you engage in physical activity?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.how_often_do_you_engage_in_physical_activity}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Are you having a special talent in art?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.are_you_having_a_special_talent_in_art}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Have you ever donated eggs before? If so, could you provide details about the situation?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {motherData?.have_you_ever_donated_egg_before}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='row mx-0'>
                                        <div className='d-flex flex-wrap p-2'>

                                            {motherData?.mother_photos?.map((item, index) => (
                                                <div className="m-3 imgWidth">
                                                    <img
                                                        crossOrigin='anonymous'
                                                        src={`${item?.fileUrl}?${moment().format('mm:ss')}`} style={{ width: '100%', height: '100%' }} alt='' />
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                    <div className='rightBox' />
                                    <div className='bottomBox' />
                                </div>

                                <div className='col-12 mb-4 mt-4 maincontainer' ref={resumeRef4}>
                                    <div className='topBox' />
                                    <div className='leftBox' />
                                    <div className='flex gap-1'>
                                        <div className='col-5'>
                                            <img
                                                crossOrigin='anonymous'
                                                src={`${fatherData?.father_photos?.[fatherData?.photo_resume_index]?.fileUrl}?${moment().format('mm:ss')}`}
                                                style={{ width: '90%', height: '90%' }} alt='' />
                                        </div>
                                        <div className='col-7'>
                                            <div className='d-flex justify-content-between topRight'>
                                                <div className="">
                                                    <div><span className="topId">{memberId}</span></div>
                                                    <div><span className="topText">Embryo Donor</span></div>

                                                </div>
                                                <div>
                                                    <img src='/images/bigLogo.png' alt='' style={{ width: '140px' }} />
                                                </div>
                                            </div>
                                            <div className='mt-5'>
                                                <span className='heading'>Bio Father Information</span>
                                            </div>
                                            <div className='d-flex gap-3 mt-2'>
                                                <div className='topRightList'>
                                                    <div>
                                                        <span className="listHeading" >Date Of Birth:</span>
                                                        <span className="listText"> {fatherData?.date_of_birth} </span>
                                                    </div>
                                                    <div>
                                                        <span className="listHeading" >Height: </span>
                                                        <span className="listText"> {fatherData?.height?.ft}ft {fatherData?.height?.in}in</span></div>
                                                    <div>
                                                        <span className="listHeading" >Weight:</span>
                                                        <span className="listText"> {fatherData?.weight?.lbs} lbs</span>
                                                    </div>
                                                    <div>
                                                        <span className="listHeading" >Hair Color:</span>
                                                        <span className="listText"> {fatherData?.hair_color}</span>
                                                    </div>
                                                    <div>
                                                        <span className="listHeading" >Eye Color:</span>
                                                        <span className="listText"> {fatherData?.eye_color}</span>
                                                    </div>
                                                    <div>
                                                        <span className="listHeading" >Blood Group:</span>
                                                        <span className="listText"> {fatherData?.blood_type}</span>
                                                    </div>
                                                </div>
                                                <div className='topRightList'>
                                                    <div>
                                                        <span className="listHeading">Ethnicity:</span>
                                                        <span className="listText"> {fatherData?.race_ethnic_origin}</span></div>
                                                    <div>
                                                        <span className="listHeading">Ethnicity (Mother):</span>
                                                        <span className="listText"> {fatherData?.race_ethnic_of_mother}</span></div>
                                                    <div>
                                                        <span className="listHeading">Ethnicity (Father):</span>
                                                        <span className="listText"> {fatherData?.race_ethnic_of_father}</span></div>
                                                    <div>
                                                        <span className="listHeading">Highest Education:</span>
                                                        <span className="listText"> {fatherData?.your_highest_education}</span></div>
                                                    <div>
                                                        <span className="listHeading">Degree Major:</span>
                                                        <span className="listText"> {fatherData?.your_highest_degree_major}</span></div>

                                                    <div><span className="listHeading">College:</span>
                                                        <span className="listText"> {fatherData?.highest_degree_major_college_name}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-2'>
                                        <label className='containerHeading'>
                                            Details About Family
                                        </label>
                                    </div>

                                    <div className='row border rounded-5 mt-2 p-3 mx-0'>
                                        <table className="tableStyle">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <td scope="col">Height</td>
                                                    <td scope="col">Age</td>
                                                    <td scope="col">Is he still alive?</td>
                                                    <td scope="col">Profession</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="styleFather">Father</td>
                                                    <td className="heightStyle">
                                                        <select disabled className="selectStyleFt">
                                                            <option> {fatherData?.write_details_about_your_family?.father?.height?.ft}</option>
                                                        </select>
                                                        <span>Ft</span>
                                                        <select disabled className="selectStyleIn">
                                                            <option> {fatherData?.write_details_about_your_family?.father?.height?.in}</option>
                                                        </select>
                                                        <span>In</span>
                                                    </td>
                                                    <td className="styleAge">
                                                        <input disabled type='text' className='form-text inputAge' value={fatherData?.write_details_about_your_family?.father?.age} />
                                                    </td>
                                                    <td className="styleAlive" >
                                                        <input type='radio' className='form-check-input inputRadio' value="true" checked={fatherData?.write_details_about_your_family?.father?.is_he_still_alive === true} disabled /><span>Yes</span>
                                                        <input type='radio' className='form-check-input inputRadio' value="false" checked={fatherData?.write_details_about_your_family?.father?.is_he_still_alive === false} disabled /><span>No</span>
                                                    </td>
                                                    <td className="styleProfession">
                                                        <input disabled type='text' className='form-text inputProfession' value={fatherData?.write_details_about_your_family?.father?.profession} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="styleFather">Mother</td>
                                                    <td className="heightStyle">
                                                        <select disabled className="selectStyleFt">
                                                            <option> {fatherData?.write_details_about_your_family?.mother?.height?.ft}</option>
                                                        </select>
                                                        <span>Ft</span>
                                                        <select disabled className="selectStyleIn">
                                                            <option> {fatherData?.write_details_about_your_family?.mother?.height?.in}</option>
                                                        </select>
                                                        <span>In</span>
                                                    </td>
                                                    <td className="styleAge">
                                                        <input disabled type='text' className='form-text inputAge' value={fatherData?.write_details_about_your_family?.mother?.age} />
                                                    </td>
                                                    <td className="styleAlive" >
                                                        <input type='radio' className='form-check-input inputRadio' value="true" checked={fatherData?.write_details_about_your_family?.mother?.is_she_still_alive === true} disabled /><span>Yes</span>
                                                        <input type='radio' className='form-check-input inputRadio' value="false" checked={fatherData?.write_details_about_your_family?.mother?.is_she_still_alive === false} disabled /><span>No</span>
                                                    </td>
                                                    <td className="styleProfession">
                                                        <input disabled type='text' className='form-text inputProfession' value={fatherData?.write_details_about_your_family?.mother?.profession} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="styleFather">GrandFather</td>
                                                    <td className="heightStyle">
                                                        <select disabled className="selectStyleFt">
                                                            <option> {fatherData?.write_details_about_your_family?.grandfather?.height?.ft}</option>
                                                        </select>
                                                        <span>Ft</span>
                                                        <select disabled className="selectStyleIn">
                                                            <option> {fatherData?.write_details_about_your_family?.grandfather?.height?.in}</option>
                                                        </select>
                                                        <span>In</span>
                                                    </td>
                                                    <td className="styleAge">
                                                        <input disabled type='text' className='form-text inputAge' value={fatherData?.write_details_about_your_family?.grandfather?.age} />
                                                    </td>
                                                    <td className="styleAlive" >
                                                        <input type='radio' className='form-check-input inputRadio' value="true" checked={fatherData?.write_details_about_your_family?.grandfather?.is_he_still_alive === true} disabled /><span>Yes</span>
                                                        <input type='radio' className='form-check-input inputRadio' value="false" checked={fatherData?.write_details_about_your_family?.grandfather?.is_he_still_alive === false} disabled /><span>No</span>
                                                    </td>
                                                    <td className="styleProfession">
                                                        <input disabled type='text' className='form-text inputProfession' value={fatherData?.write_details_about_your_family?.grandfather?.profession} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="styleFather">GrandMother</td>
                                                    <td className="heightStyle">
                                                        <select disabled className="selectStyleFt">
                                                            <option> {fatherData?.write_details_about_your_family?.grandmother?.height?.ft}</option>
                                                        </select>
                                                        <span>Ft</span>
                                                        <select disabled className="selectStyleIn">
                                                            <option> {fatherData?.write_details_about_your_family?.grandmother?.height?.in}</option>
                                                        </select>
                                                        <span>In</span>
                                                    </td>
                                                    <td className="styleAge">
                                                        <input disabled type='text' className='form-text inputAge' value={fatherData?.write_details_about_your_family?.grandmother?.age} />
                                                    </td>
                                                    <td className="styleAlive" >
                                                        <input type='radio' className='form-check-input inputRadio' value="true" checked={fatherData?.write_details_about_your_family?.grandmother?.is_she_still_alive === true} disabled /><span>Yes</span>
                                                        <input type='radio' className='form-check-input inputRadio' value="false" checked={fatherData?.write_details_about_your_family?.grandmother?.is_she_still_alive === false} disabled /><span>No</span>
                                                    </td>
                                                    <td className="styleProfession">
                                                        <input disabled type='text' className='form-text inputProfession' value={fatherData?.write_details_about_your_family?.grandmother?.profession} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='mt-4'>
                                        <label className='containerHeading'>
                                            Details About Brother & Sister
                                        </label>
                                    </div>

                                    <div className='row border rounded-5 mt-4 p-3 mx-0'>
                                        {fatherData?.write_details_about_your_brother_and_sister?.length <= 0 ?
                                            <div className='d-flex justify-content-center p-4'>
                                                <div className='lufga_22px align-center'>No data Available For Siblings</div>
                                            </div>
                                            :

                                            <table className="tableStyle">
                                                <thead>
                                                    <tr>
                                                        <td cope="col">Brother or Sister</td>
                                                        <td scope="col">Height</td>
                                                        <td scope="col">Age</td>
                                                        <td scope="col">Profession</td>
                                                    </tr>
                                                </thead>
                                                {fatherData?.write_details_about_your_brother_and_sister?.map((res) => {
                                                    return (
                                                        <tbody>
                                                            <tr>
                                                                <td className="nameStyleBrother" >
                                                                    <input type='text' className='form-text inputBrother' value={res?.brother_or_sister} disabled />
                                                                </td>
                                                                <td className="heightStyleBrother">
                                                                    <select disabled className="selectStyleFt">
                                                                        <option>{res?.height?.ft}</option>
                                                                    </select>
                                                                    <span>Ft</span>
                                                                    <select disabled className="selectStyleIn">
                                                                        <option>{res?.height?.in}</option>
                                                                    </select>
                                                                    <span>In</span>
                                                                </td>
                                                                <td className="ageStyleBrother">
                                                                    <input disabled type='text' value={res?.age} className='form-text inputAge' />
                                                                </td>
                                                                <td className="professionStyleBrother">
                                                                    <input disabled type='text' value={res?.profession} className='form-text inputProfession' placeholder='' />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                })}


                                            </table>
                                        }
                                    </div>


                                    <div class="row mt-2 p-2 mx-0">
                                        <table class="table table-bordered border-dark">
                                            <tbody className="styleBody">
                                                <tr>
                                                    <td className="styleLeft">Current Address (City, State & Country)</td>
                                                    <td valign='middle' className="styleRight"> {fatherData?.current_address}</td>
                                                </tr>
                                                <tr>
                                                    <td>Current Working Profession</td>
                                                    <td valign='middle' className="styleRight"> {fatherData?.current_working_profession}</td>
                                                </tr>
                                                <tr>
                                                    <td>Where were your born?</td>
                                                    <td valign='middle' className="styleRight"> {fatherData?.where_were_you_born}</td>
                                                </tr>
                                                <tr>
                                                    <td>Current Marital Status</td>
                                                    <td valign='middle' className="styleRight"> {fatherData?.current_marital_status}</td>
                                                </tr>
                                                <tr>
                                                    <td>Do you have ever wore glasses due to vision defect?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {fatherData?.do_you_have_ever_wore_glasses_due_to_vision_defect ? "Yes" : "No"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='rightBox' />
                                    <div className='bottomBox' />
                                </div>

                                <div className='col-12 mb-4 mt-4 maincontainer' ref={resumeRef5}>
                                    <div className='topBox' />
                                    <div className='leftBox' />
                                    <div class="row mt-2 p-2 mx-0" >
                                        <table class="table table-bordered border-dark">
                                            <tbody className="styleBody">

                                                <tr>
                                                    <td className="styleLeft">Please Describe your Personality Traits</td>
                                                    <td valign='middle' className="styleRight"> {fatherData?.please_describe_your_personality_traits}</td>
                                                </tr>
                                                <tr>
                                                    <td>Do you have children of your own? If so, please provide their names and ages.</td>
                                                    <td valign='middle' className="styleRight">
                                                        {fatherData?.do_you_have_children_of_you_own}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>What activities bring you joy and happiness during your leisure time?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {fatherData?.what_activities_bring_you_joy_and_happiness}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >Do you love to travel? If so, which places do you prefer to go?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {fatherData?.do_you_love_to_travel}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>What is your favourite joyful color?</td>
                                                    <td valign='middle' className="styleRight" >
                                                        {fatherData?.what_is_your_favorite_joyful_color}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Do you have any past or current medical conditions, such as major surgeries, congenital defects, or mental health conditions? If so, please provide detailed information about them.</td>
                                                    <td valign='middle' className="styleRight" >
                                                        {fatherData?.do_you_have_any_past_or_current_medical_conditions}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="styleLeft">Do you know that genetic diseases can run in families?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {fatherData?.do_you_know_that_genetic_diseases_can_run_in_families ? "Yes" : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Do you smoke?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {fatherData?.do_you_smoke ? "Yes" : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Do you consume liquor</td>
                                                    <td valign='middle' className="styleRight">
                                                        {fatherData?.do_you_consume_liquor ? "Yes" : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Are you currently taking any medication, including over-the-counter or prescription drugs, for physical or mental health conditions? If so, please list all medications, their active substances, and the reasons for taking them.</td>
                                                    <td valign='middle' className="styleRight">
                                                        {fatherData?.are_you_currently_taking_any_medication}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Are you using medications or substances for sexual enhancement or entertainment? If so, please provide details about your condition or situation.</td>
                                                    <td valign='middle' className="styleRight" >
                                                        {fatherData?.are_you_using_medications_or_substances_for_sexual_enhancement}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="styleLeft">Does anyone in your family have a history of: cancer, mental retardation, autoimmune diseases, body dysmorphia, cystic fibrosis, Lyme disease, hypertension, memory loss, depression, kidney disease, bipolar disorder, heart disease, ADHD, anemia, birth defects, vision loss, bleeding disorders, or cardiovascular conditions? If so, please list them.</td>
                                                    <td valign='middle' className="styleRight">
                                                        {fatherData?.does_anyone_in_your_family_have_history_of_cancer}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>How often do you engage in physical activity?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {fatherData?.how_often_do_you_engage_in_physical_activity}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Are you having a special talent in art?</td>
                                                    <td valign='middle' className="styleRight">
                                                        {fatherData?.are_you_having_a_special_talent_in_art}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='rightBox' />
                                    <div className='bottomBox' />
                                </div>

                                <div className='col-12 mb-4 mt-4 maincontainer' ref={resumeRef6}>
                                    <div className='topBox' />
                                    <div className='leftBox' />
                                    <div className='row mt-2 p-2 mx-0'>
                                        <table className='table table-bordered border-dark'>
                                            <tbody className='styleBody'>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='row mx-0'>
                                        <div className='d-flex flex-wrap p-2'>
                                            {fatherData?.father_photos?.map((item, index) => (
                                                <div className="m-3 imgWidth">
                                                    <img
                                                        crossOrigin='anonymous'
                                                        src={`${item?.fileUrl}?${moment().format('mm:ss')}`} style={{ width: '100%', height: '100%' }} alt='' />
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                    <div className='rightBox' />
                                    <div className='bottomBox' />
                                </div>

                            </div>

                        </div>


                    </div>
                </div>

                <div className='d-flex justify-content-center flex-md-row flex-column gap-md-5 gap-3 m-3 mx-0'>
                    <button
                        className='btn_non_fill mw_400px text-dark bg-light d-flex align-items-center w-100 w-md-25 download_button_for_pdf'
                        onClick={() => navigate(`/embryodonor/bioMother/${id}`)}
                    >
                        <span className='mx-auto'>Make Changes</span>
                    </button>
                    <button
                        className='btn_fill mw_400px w-100 w-md-25'
                        onClick={navigateTodashboard}
                    >
                        Go to Dashboard
                    </button>
                </div>
            </div>
        </>
    )
}

export default EmbryoResume