import React, { useState } from 'react'
import "./StartHelpingNow.module.css"
import { BsDownload } from "react-icons/bs";
import { useNavigate } from 'react-router';
import jsPDF from 'jspdf';
import { useTranslation } from 'react-i18next';
import Loader from '../../../components/Loader/Loader';

const EmbryoDonorSuccess = () => {

  const navigate = useNavigate()
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [loader,setLoader] = useState(false)
  async function addImageToPdf(pdf, imageUrl) {
      const dataUrl = await loadImageAsDataUrl(imageUrl);
      pdf.addImage(dataUrl, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
  }
const {t}= useTranslation()

  function loadImageAsDataUrl(imageUrl) {
      return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = function () {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);
              resolve(canvas.toDataURL('image/jpeg'));
          };
          img.onerror = function (error) {
              reject(error);
          };
          img.src = imageUrl;
      });
  }


  async function convertToPdf() {
      setLoader(true)
      const imageUrls = ["/images/resume_img.png", "/images/resume_img2.png"];
      const pdf = new jsPDF();
      for (let i = 0; i < imageUrls.length; i++) {
          const imageUrl = imageUrls[i];

          await addImageToPdf(pdf, imageUrl);

          if (i !== imageUrls.length - 1) {
              pdf.addPage();
          }
      }

      pdf.save('Sample Resume');
      setLoader(false)
  }

  return (
    <div className="py-3 px-lg-5 px-md-5 px-sm-4 px-3">
  <Loader open={loader}/>
            <div className='surrogateSuccess_main_con'>

                <section className="section_con">
                    <div className='primary_text'>{t('Congratulations!')}</div>
                    <div className='my-md-3 my-sm-2 my-2 success_line_2'>{t('on signing up with')} <span className='primary_text'>{t('New Hope Points')}</span></div>
                    <button className='btn-primary' onClick={() => navigate(`/embryodonor/bioMother/${userData?.userId}`)}>{t('Start Helping Now!')}</button>
                </section>

                <section className='section_two'>

                    <div className='left_con d-flex flex-column justify-content-between'>
                        <div>
                            <div className='text_1'>{t('Give the Gift of Life: Donate Your Embryos and Create Miracles')}</div>
                            <br />
                            <div className='text_2'>
                                {t('Donate your embryos to spread your genetic legacy worldwide, impacting families and creating lasting connections across generations.')}
                            </div>
                        </div>
                        <div className='text-center left_img_con h-100'>
                            <button className='btn-primary py-2' onClick={() => navigate(`/embryodonor/bioMother/${userData?.userId}`)}>{t('Start Helping People')}</button>
                        </div>
                    </div>
                    <div className='right_con'>
                        <div className='sample_profile_text'>{t('Sample Profile')}</div>
                        <div className='my-3'>
                            <img className='w-100' src='/images/resume_img.png' />
                        </div>
                        <div className='download_sample_text pointer' onClick={convertToPdf}>
                            <span className='me-2'><BsDownload /></span>
                           {t('Download Sample Profile')}
                        </div>
                    </div>


                </section>

            </div>
        </div>


  )
}

export default EmbryoDonorSuccess
