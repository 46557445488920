import React from 'react'
import style from './UnderConstructionTest.module.css'


const UnderConstructionTest = () => {

  return (
    <div className='d-flex flex-column'>
      <div className={`${style.image_section}`}>
        <img src='/images/undercons.png' alt='Under Construction Image' />
      </div>
        <div className={`${style.content_section}`}>
          <div className='lufga_20px'>Under Construction</div>
          <div className='lufga_20px custom_color'>Coming Soon</div>
          <div className='lufga_20px'>Check Back Later</div>
        </div>
      </div>
  )
}

export default UnderConstructionTest;