import React, { useState } from 'react'
import "./HowItWorks.css"
import { useTranslation } from 'react-i18next';
import { SubscriptionDialog } from '../../../test/SubscriptionDialog/SubscriptionDialog';


const HowItWorksForIp = () => {
  const [Subscription ,setSubscription]=useState(false)
  let { t } = useTranslation();
  return (
    <>
    <SubscriptionDialog Subscription={Subscription} setSubscription={setSubscription}/>
     <div className='IntendedParents_con'>
   <div className='text-center mb-3 mt-5'>
     <h1 className='Mainheading'>{t("How does it works ?:")} <span className='SurrogateColor'>{t("Intended Parents ")}</span></h1>
   </div>

   <div className='BoxColor px-3'>
    
   <div className="row ">
     <div className="col ">
        <div className="row">
        <div className='ImagesComun mb-3'>
          <div className='ImagesBackground d-flex '>
             <img src="/images/Group 1171274839 (1).png" className='ImagesCustom' alt="" />

       
          </div>
          <div className='textcustom'>      
          <span className='BulidText'>{t("Build Your Profile")}</span></div>
          </div>
          <div className='ImagesComun mb-3'>
          <div className='ImagesBackground d-flex '>
             <img src="/images/Group 1171274839 (2).png" className='ImagesCustom' alt="" />

       
          </div>
          <div className='textcustom'>    
          <span className='BulidText'>{t("Upload or Generate your resume")}</span>
          </div>
          </div>
          <div className='ImagesComun mb-3'>
          <div className='ImagesBackground d-flex '>
          <img src="/images/Group 1171274839 (3).png" className='ImagesCustom' alt="" />

       
          </div>
          <div className='textcustom'>     
          <span className='BulidText'>{t("Sign the contract with the agent")}</span>

           </div>
          </div>
          <div className='ImagesComun mb-3'>
          <div className='ImagesBackground d-flex '>
          <img src="/images/Group 1171274839 (4).png" className='ImagesCustom' alt="" />

       
          </div>
          <div className='textcustom'>  
          <span className='BulidText'>{t("Wait auto match or donors/Surrogate Mother to pick up you (Silver member above)")}</span>
              </div>
          </div>
            <div className='ImagesComun mb-3'>
          <div className='ImagesBackground d-flex '>
          <img src="/images/Group 1171274839.png" className='ImagesCustom' alt="" />

       
          </div>
          <div className='textcustom'>     
          <span className='BulidText'>{t("System can send you an auto match results if any (Silver member above)")}</span>
           </div>
          </div>
    
        </div>
      
         
     </div>
     <div className="col-md d-flex justify-content-center">
       <div className='ImagesMain'>
         <img src="/images/Group 1000001021.png" alt="" />
       </div>
     </div>
   </div>
   </div>


   <div className='BoxColor2 px-3'>
   <div className="row">

   <div className="col-md d-flex justify-content-center">
       <div className='ImagesMain'>
         <img src="/images/image 235.png" alt="" />
       </div>
     </div>

     <div className="col mt-5">
      <div className="row">
      <div className='ImagesComun mb-3 col-md-12'>
          <div className='ImagesBackground d-flex '>
             <img src="/images/Group 1171274839 (6).png" className='ImagesCustom' alt="" />

       
          </div>
          <div className='textcustom'>      
          <span className='BulidText'>{t("You can search Intended parents by your self (Silver member above)")}</span></div>
          </div>
          <div className='ImagesComun mb-3 col-md-12'>
          <div className='ImagesBackground d-flex '>
             <img src="/images/Group 1171274839 (7).png" className='ImagesCustom' alt="" />

       
          </div>
          <div className='textcustom'>    
          <span className='BulidText'>{t("Zoom interview with the IPs and the agents")}</span>
          </div>
          </div>
          <div className='ImagesComun mb-3 col-md-12'>
          <div className='ImagesBackground d-flex '>
          <img src="/images/Group 1171274839 (8).png" className='ImagesCustom' alt="" />

       
          </div>
          <div className='textcustom col-md-12'>     
          <span className='BulidText'>{t("Select your attorney")} </span>

           </div>
          </div>
          <div className='ImagesComun mb-3 col-md-12'>
          <div className='ImagesBackground d-flex '>
          <img src="/images/Group 1171274839 (9).png" className='ImagesCustom' alt="" />

       
          </div>
          <div className='textcustom'>  
          <span className='BulidText'>{t("Sign the contract with intended parents")}</span>
              </div>
          </div>
            <div className='ImagesComun mb-3 col-md-12'>
          <div className='ImagesBackground d-flex '>
          <img src="/images/Group 1171274839 (10).png" className='ImagesCustom' alt="" />

       
          </div>
          <div className='textcustom'>     
          <span className='BulidText'>{t("Select IVF clinic")}</span>
           </div>
          </div>
    
      </div>
      
         
     </div>
   
   </div>
   </div>


   <div className='boxContent'>
   <div className='Modaldiv'>
   
     
   <div className='UnLockBox'>
           <div className='LockImages'> 
             <img src="/images/golden-lock-and-copper-key-space-isolated-concept-3d-illustration-or-3d-render-png 1.png" alt="" />
           </div>
        <span className='text-center SubsCriptiontext mb-3'>
        {t("Upgrade to Silver membership to view all content")}
        </span>
                <div className='d-flex justify-content-center'>
             <button className='ViewSubscription' onClick={()=>{setSubscription(true)}}>
             {t("View Subscription Plans")}
             </button>
           </div>
         </div>
      </div>
  
   <div className='BoxColortwo px-3'>
    
    <div className="row ">
      {/* <div className="col ">
      <div className="row">
            <div className='ImagesComun mb-3 '>
              <div className='ImagesBackground d-flex '>
                 <img src="/images/Group 1171274839 (11).png" className='ImagesCustom2' alt="" />
 
           
              </div>
              <div className='textcustom'>      
              <span className='BulidText'>{t("Go to IVF clinic to do your medical test")}</span></div>
              </div>
              <div className='ImagesComun  mb-3'>
              <div className='ImagesBackground d-flex '>
                 <img src="/images/Group 1171274839 (12).png" className='ImagesCustom2' alt="" />
 
           
              </div>
              <div className='textcustom'>    
              <span className='BulidText'>{t("Follow doctors instruction to prepare your sperms and eggs")}</span>
              </div>
              </div>
              <div className='ImagesComun mb-3'>
              <div className='ImagesBackground d-flex '>
              <img src="/images/Group 1171274839 (13).png" className='ImagesCustom2' alt="" />
 
           
              </div>
              <div className='textcustom'>     
              <span className='BulidText'>{t("Inspect your sperms and eggs to make sure the quality are good")}</span>
 
               </div>
              </div>
              <div className='ImagesComun mb-3'>
              <div className='ImagesBackground d-flex '>
              <img src="/images/Group 1171274839 (14).png" className='ImagesCustom2' alt="" />
 
           
              </div>
              <div className='textcustom'>  
              <span className='BulidText'>{t("back to Clinic to inspect to make sure the embryo grow properly")}</span>
                  </div>
              </div>
                <div className='ImagesComun mb-3'>
              <div className='ImagesBackground d-flex '>
              <img src="/images/Group 1171274839 (15).png" className='ImagesCustom2' alt="" />
 
           
              </div>
              <div className='textcustom'>     
              <span className='BulidText'>{t("Deliver the baby (babies)")}</span>
               </div>
              </div>
 
              <div className='ImagesComun mb-3'>
              <div className='ImagesBackground d-flex '>
              <img src="/images/Group 1171274839 (16).png" className='ImagesCustom2' alt="" />
 
           
              </div>
              <div className='textcustom'>     
              <span className='BulidText'>{t("To make embryos and let you know how may boys and/or girls")}</span>
               </div>
              </div>
        
            </div>
       
          
      </div> */}


<div className="col ">
      <div className="row">
            <div className='ImagesComun mb-3 '>
              <div className='ImagesBackground d-flex '>
                 <img src="/images/Group 1171274839 (11).png" className='ImagesCustom2' alt="" />
 
           
              </div>
              <div className='textcustom'>      
              <span className='BulidText'>{t("")}</span></div>
              </div>
              <div className='ImagesComun  mb-3'>
              <div className='ImagesBackground d-flex '>
                 <img src="/images/Group 1171274839 (12).png" className='ImagesCustom2' alt="" />
 
           
              </div>
              <div className='textcustom'>    
              <span className='BulidText'>{t("")}</span>
              </div>
              </div>
              <div className='ImagesComun mb-3'>
              <div className='ImagesBackground d-flex '>
              <img src="/images/Group 1171274839 (13).png" className='ImagesCustom2' alt="" />
 
           
              </div>
              <div className='textcustom'>     
              <span className='BulidText'>{t("")}</span>
 
               </div>
              </div>
              <div className='ImagesComun mb-3'>
              <div className='ImagesBackground d-flex '>
              <img src="/images/Group 1171274839 (14).png" className='ImagesCustom2' alt="" />
 
           
              </div>
              <div className='textcustom'>  
              <span className='BulidText'>{t("")}</span>
                  </div>
              </div>
                <div className='ImagesComun mb-3'>
              <div className='ImagesBackground d-flex '>
              <img src="/images/Group 1171274839 (15).png" className='ImagesCustom2' alt="" />
 
           
              </div>
              <div className='textcustom'>     
              <span className='BulidText'>{t("")}</span>
               </div>
              </div>
 
              <div className='ImagesComun mb-3'>
              <div className='ImagesBackground d-flex '>
              <img src="/images/Group 1171274839 (16).png" className='ImagesCustom2' alt="" />
 
           
              </div>
              <div className='textcustom'>     
              <span className='BulidText'>{t("")}</span>
               </div>
              </div>
        
            </div>
       
          
      </div>


      <div className="col d-flex justify-content-center">
        <div className='ImagesMain'>
          <img src="/images/imageSurro.png" className='ImagesCustom2' alt="" />
        </div>
      </div>
    </div>
    </div>
   </div>




 </div>
 </>
  )
}

export default HowItWorksForIp;
