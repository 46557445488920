import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Router } from "./routes/Router";
import './I18n';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import DisableScrollNumberValueChange from "./components/DisableScrollNumberValueChange";


function App() {
  DisableScrollNumberValueChange()
  return <Router />;
};

export default App;
