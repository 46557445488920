import "./IntendedParents.css";
import React, { useEffect, useState } from "react";
import style from "./IntendedParents.module.css";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router";
import AskAnyQuestion from "../CommonComponents/AskAnyQuestion/AskAnyQuestion";

const IntendedParents = () => {
  const navigate = useNavigate();
  let { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let cardsArr = [
    {
      name: "Become a Parent",
      role: "parents",
      imgUrl: "/images/ip_card_one.png",
    },
    {
      name: "Find an Eggs/Sperms donor",
      role: "",
      imgUrl: "/images/ip_card_two.png",
    },
    {
      name: "Find Surrogate Mothers",
      role: "",
      imgUrl: "/images/ip_card_three.png",
    },
    {
      name: "Find an Agent",
      role: "",
      imgUrl: "/images/ip_card_four.png",
    },
    {
      name: "Find your Attorney",
      role: "",
      imgUrl: "/images/ip_card_five.png",
    },
    {
      name: "Find Adoption Parents",
      role: "",
      imgUrl: "/images/ip_card_six.png",
    },
    {
      name: "Find 0-2 Child Care (Day and Night)",
      role: "",
      imgUrl: "/images/ip_card_seven.png",
    },
    {
      name: "Find 3-6 Child Care (Day and Night)",
      role: "",
      imgUrl: "/images/ip_card_eight.png",
    },
    {
      name: "School Finding",
      role: "",
      imgUrl: "/images/ip_card_nine.png",
    },
    {
      name: "Find Education Professionals",
      role: "",
      imgUrl: "/images/ip_card_ten.png",
    },
  ];

  function HowDoesSubmit() {
    navigate("howitworks");
  }

  return (
    <div>
      <div className="english_page">
        <div className="main_div">
          <div className="upper_section">
            <div className="upper_section_content py-md-2 py-5">
              <div className="lufga_20px_bg_lightblue pe-4">
                {t(
                  "A one-stop fertility, childcare, and education resource platform, the birthplace of superheroes."
                )}
              </div>
              <div className="volkhov_48px pt-3">{t("Intended Parent")}</div>
              <div className="lufga_18px_bg_gray pt-2 pe-4">
                {t(
                  "The New Hope Points platform utilizes IVF technology that can screen for genetic defects and select the gender of the baby. Additionally, it can optimize genes by selecting sperm or eggs from others. We not only assist you in conceiving healthy and intelligent children but also provide education experts to discover their talents, teach according to their aptitude, and provide personalized training until they graduate from top universities (Ivy League)."
                )}
              </div>
              <div>
                <button
                  className="mt-3"
                  style={{
                    background: "#187C8B",
                    padding: "21px 26px",
                    border: "None",
                    borderRadius: "12px",
                    color: "white",
                    fontFamily: "Lufga",
                    fontSize: "20",
                    fontWeight: "500",
                  }}
                  onClick={HowDoesSubmit}
                >
                  {t("How Does it Works ?")}
                </button>
              </div>
            </div>
            <div className="upper_section_image_couple">
              <img
                src="/images/intended_parents_upper_image.png"
                alt="couple image"
              />
            </div>
          </div>
          <div className="all_stories">
            <div className="card_container">
              <div className="card_padding">
                <div className="row row_section">
                  {cardsArr?.map((res) => {
                    return (
                      <div
                        className="col-12 col-md-4 col-sm-6 col-lg-4 p-2"
                        onClick={() =>
                          res.role !== "" ? navigate(`signUp/${res.role}`) : {}
                        }
                      >
                        <div className="card">
                          <img
                            className="card-img-top"
                            src={res.imgUrl}
                            alt="Card image cap"
                          />

                          <div className="card-body">
                            <div className="lufga_14px">{t(res.name)}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <DialogSignUp visible={visible} setVisible={setVisible} /> */}

      <div
        className={`${style.comparision_container}`}
        style={{ marginBottom: "7rem" }}
      >
        <div
          className={`d-flex justify-content-center volkhov_48px custom_color ${style.comparision_container_v_1}`}
        >
          {t("In vitro fertilization (IVF) vs. natural conception:")}
        </div>

        <div className={`${style.v_2}`}>
          <img src="/images/ivf_vs_conception.png" />
          <div className={`${style.v_2_1}`}>
            <div
              style={{ fontWeight: "600" }}
              className={`clinic_green lufga_18px`}
            >
              {t(
                "Since people are capable of natural conception, why choose IVF? IVF offers several advantages:"
              )}
            </div>
            <div className={`clinic_green lufga_18px`}>
              {t(
                "Prior to embryo creation, it allows for pre-screening of sperm and egg quality to detect any genetic defects. If present, treatment can be sought, and conception attempted again. This is not possible with natural conception."
              )}
            </div>
            <div className={`clinic_green lufga_18px`}>
              {t(
                "After embryo creation, it enables pre-screening of embryo quality, allowing for selection of high-quality embryos for implantation."
              )}
            </div>
            <div className={`clinic_green lufga_18px`}>
              {t(
                "Choice of desired gender: Some families may already have several boys and desire a girl, or vice versa. They may be concerned about the possibility of having another child of the same gender. IVF allows for the selection of the desired gender."
              )}
            </div>
          </div>
        </div>

        <div className={`${style.v_3}`}>
          <div className={`${style.v_3_1}`}>
            <div
              className={`clinic_green lufga_18px`}
              style={{ lineHeight: "26px" }}
            >
              {t(
                "Preservation of sperm, eggs, and embryos when both partners are in good health, to be used for conception when economic or other conditions are favorable. Health conditions can change unexpectedly, and sometimes age is not a determining factor. Even from health to illness is just a step away. Life is unpredictable; someone who was lively and healthy yesterday could be bedridden today. Taking advantage of modern medical technology to freeze sperm, eggs, and embryos while in good health can secure the future of the next generation and mitigate the uncertainties of health and aging."
              )}
            </div>
            <div
              className={`clinic_green lufga_18px`}
              style={{ lineHeight: "26px" }}
            >
              {t(
                "IVF allows for the involvement of altruistic surrogate mothers, freeing and preserving the youth of young mothers. If young mothers were to conceive every 2 to 3 years on their own, raising 5 children would take 10 to 15 years. Additionally, raising these children to the age of 13 would take another 23 to 28 years. Much of the mother's life would be consumed by childbearing and child-rearing. With multiple surrogate mothers helping simultaneously, the biological parents can save significantly on the costs of conception and upbringing. The cost of raising multiple children is similar to that of raising one child, and the children can learn from each other. This maximizes the efficiency of the parenting cycle, allowing biological parents to have more time to enjoy their lives."
              )}
            </div>
          </div>

          <img src="/images/ivf_vs_conception_one.png" />
        </div>
      </div>

      <AskAnyQuestion />

      <Outlet></Outlet>
    </div>
  );
};

export default IntendedParents;
