import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router";
import style from "./AdoptingParent.module.css";
import moment from "moment/moment";
import {
  addAdoptingParent,
  addIntendedParentFormData,
  deleteDocs,
  getAdoptingParentFormData,
  getIntendedParentsFormData,
  uploadMultipleFiles,
} from "../../../../service/Apis";
import { useTranslation } from "react-i18next";
import { MultipleImageUpload } from "../../../../components/Inputs/MultipleImageUpload";
import { MultiSelect } from "primereact/multiselect";
import Loader from "../../../../components/Loader/Loader";
import { Country, State, City } from "country-state-city";
import Toaster from "../../../../components/Toaster";

const AdoptingParentForm = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const countries = Country.getAllCountries();
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [statesListing, setStatesListing] = useState([]);
  const [citiesListing, setCitiesListing] = useState([]);
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });

  let [stateCode, setStateCode] = useState("");

  const [parentData, setParentData] = useState([]);

  const [formData, setFormData] = useState({
    parent_photos: [
      {
        fileName: "",
        fileUrl: "",
      },
    ],
    photo_resume_index: 0,
    full_name: "",
    not_show_on_public_profile_full_name: false,
    date_of_birth: "",
    gender: "",
    race: [],
    spouse_name: "",
    not_show_on_public_profile_spouse_name: false,
    spouse_date_of_birth: "",
    spouse_gender: "",
    spouse_race: [],
    street_address: "",
    not_show_on_public_profile_street_address: false,
    city: "",
    state_of_residence: "",
    country: "",
    zip_code: "",
    date_of_legal_marriage: "",
    your_highest_degree_of_education: "",
    your_spouse_highest_degree_of_education: "",
    religion: "",
    your_occupation: "",
    spouse_occupation: "",
    total_income: "",
    briefly_describe_your_reason_for_wanting_to_adopt: "",
    tell_us_about_any_children_you_currently_have: "",
    kid_birth_status: "",
    tell_us_about_your_self: "",
    tell_us_about_your_spouse: "",
    tell_us_about_your_relationship: "",
    tell_us_about_your_extended_family: "",
    tell_us_about_adventure_you_do_in_your_free_time: "",
    tell_us_about_tradition: "",
    tell_us_about_home: "",
    tell_us_about_your_and_your_spouse_hobbies: "",
    your_favorites: {
      hobby: "",
      movie: "",
      sport: "",
      holiday: "",
      book: "",
      subject: "",
      tradition: "",
      destination: "",
      job: "",
      food: "",
      music_type: "",
      song: "",
      celebrity: "",
      moment: "",
      author: "",
    },
    your_spouse_favorites: {
      hobby: "",
      movie: "",
      sport: "",
      holiday: "",
      book: "",
      subject: "",
      tradition: "",
      destination: "",
      job: "",
      food: "",
      music_type: "",
      song: "",
      celebrity: "",
      moment: "",
      author: "",
    },
    child_preferences: {
      age_range: {
        min: "",
        max: "",
      },
      gender: "",
      special_need: "",
      race_of_child: "",
    },
  });

  const [selectedRaces, setSelectedRaces] = useState([]);
  const [selectedSpouseRaces, setSelectedSpouseRaces] = useState([]);
  const [selectedChildRaces, setSelectedChildRaces] = useState([]);
  const races = [
    { name: "White", code: "W" },
    { name: "Black or African American", code: "B" },
    { name: "American Indian or Alaska Native", code: "AI" },
    { name: "Asian", code: "A" },
    { name: "Native Hawaiian", code: "NH" },
    { name: "Other", code: "O" },
  ];

  useEffect(() => {
    const idToGetFormData = userData?.userId;
    if (idToGetFormData) {
      getFormdata(idToGetFormData);
    }
  }, []);

  useEffect(() => {
    if (parentData?.parent_photos) {
      let photoArr = parentData?.parent_photos?.map((res, index) => {
        return {
          ...res,
          fileType: "server",
        };
      });
      console.log(photoArr, "lllllllllllllllllllllllll");
      setImagesValue(photoArr);
    }
  }, [parentData]);

  async function getFormdata(id) {
    try {
      let res = await (userData.role === "parents"
        ? getIntendedParentsFormData(id)
        : getAdoptingParentFormData(id));

      console.log("this is res", res);

      const { race, spouse_race, child_preferences } = res.data.data;
      const { race_of_child } = child_preferences;
      setSelectedRaces(race);
      setSelectedSpouseRaces(spouse_race);
      setSelectedChildRaces(race_of_child);
      setParentData(res.data.data);
      setFormData({ ...formData, ...res.data.data });

      if (res.data.data.country !== undefined) {
        let cRes = countries?.find(
          (res2) => res2?.name == res.data.data.country
        );
        setCountry(`${cRes.name}&&${cRes.isoCode}`);
        getStates(`${cRes.name}&&${cRes.isoCode}`);
      }
      if (res.data.data.city !== undefined) {
        setCity(res.data.data.city);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (statesListing.length !== 0) {
      let sRes = statesListing?.find(
        (res2) => res2?.name == formData.state_of_residence
      );
      if (sRes !== undefined) {
        setState(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`);
        getCities(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`);
      }
    }
  }, [statesListing]);

  function getStates(res) {
    let isoCode = res.split("&&")[1];
    setCountry(res);
    let states = State.getStatesOfCountry(isoCode);
    setStatesListing(states);
  }

  function getCities(res) {
    let isoCode = res.split("&&")[1];
    let cCode = res.split("&&")[2];
    setState(res);
    let cities = City.getCitiesOfState(cCode, isoCode);
    setCitiesListing(cities);
  }

  const { id } = useParams();
  let { t } = useTranslation();
  let navigate = useNavigate();

  let [loader, setLoader] = useState(false);
  const [imgForRemove, setImgForRemove] = useState([]);
  let [imagesValue, setImagesValue] = useState([]);

  function multipleImageInputChange(e) {
    console.log(e, "aaaaaaa");
    setImagesValue(e);
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleFavoritesChange = (e, type) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [type]: {
        ...prevFormData[type],
        [name]: value,
      },
    }));
  };

  function radioButtonChange(e, val) {
    let { name } = e.target;
    setFormData((pre) => {
      pre[name] = val;
      return { ...pre };
    });
  }

  const handleChildChange = (e) => {
    const { name, value } = e.target;
    console.log("target", e.target);
    const nameParts = name.split(".");
    console.log("nameparts", nameParts);

    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      let current = newFormData;

      for (let i = 0; i < nameParts.length - 1; i++) {
        if (!current[nameParts[i]]) {
          current[nameParts[i]] = {}; // Initialize the object if it does not exist
        }
        current = current[nameParts[i]];
      }

      current[nameParts[nameParts.length - 1]] = value;
      return newFormData;
    });
  };

  async function handleSubmit(e) {
    e.preventDefault();

    setLoader(true);
    try {
      let img = imagesValue;
      if (imagesValue.length == 0) {

        setToaster({
          show: true,
          message: "please select images",
        });
        setLoader(false);
        return;
      }
      if (imgForRemove.length > 0) {
        let imagesData = await Promise.all(
          imgForRemove.map(
            async (e) => await deleteDocs({ fileName: e.fileName })
          )
        );
      }

      let httpimage = [];
      let formData2 = new FormData();
      let nonhttpImage = [];
      img.forEach((e) => {
        if (e?.fileUrl?.includes("blob")) {
          nonhttpImage.push(e);
        } else {
          httpimage.push({
            fileName: e.fileName,
            fileUrl: e.fileUrl,
          });
        }
      });
      for (const item of nonhttpImage.map((e) => e.file)) {
        formData2.append("uploadMultiDocs", item);
      }
      let uploadResponse = await uploadMultipleFiles(formData2);
      let res2 = {
        ...formData,
        parent_photos: [...httpimage, ...uploadResponse.data.data],
        race: selectedRaces,
        spouse_race: selectedSpouseRaces,
        child_preferences: {
          ...formData.child_preferences,
          race_of_child: selectedChildRaces,
        },
        city: city.split("&&")[0],
        state_of_residence: state.split("&&")[0],
        country: country.split("&&")[0],
      };

      console.log("api hit start");
      let res;
      if (userData.role === "parents") {
        res = await addIntendedParentFormData(id, res2);
      } else {
        res = await addAdoptingParent(id, res2);
      }

      console.log("api hit end");

      setLoader(false);

      navigate(
        userData.role === "parents"
          ? `/intendedparents/profile/${id}`
          : `/adoptingparent/profile/${id}`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  }

  const [imageSrc, setImageSrc] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImageSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const formRef = useRef(null);

  useEffect(() => {
    if (!formRef.current) {
      console.warn("Form reference is not set yet.");
      return;
    }

    // Get all textarea elements within the form
    const textareas = formRef.current.querySelectorAll("textarea");
    textareas.forEach((textarea) => {
      adjustTextareaHeight(textarea);
    });
  }, [formData]);

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = "auto"; // Reset the height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight
  };

  return (
    <>
      <Loader open={loader} />
      <Toaster
        event={toaster.message}
        show={toaster.show}
        severity={"error"}
        setToaster={setToaster}
      />
      <div
        className={`p-lg-5 p-md-5 p-sm-4 p-3 ${style.wrapper_for_Addopting_parent}`}
        ref={formRef}
      >
        <form onSubmit={handleSubmit}>
          <div className="primary_resume_main_con">
            <div className="p-md-4 p-sm-3 p-2">
              <div className="text-center main_heading">
                {userData?.role === "parents" ? (
                  <>Intended Parent</>
                ) : (
                  <>Adopting Parent</>
                )}
              </div>
              <div className="text-center second_heading">
                Register to begin your journey with us!
              </div>
            </div>
            <div className="text-center mb-3 second_heading mt-5">
              General information
            </div>

            <MultipleImageUpload
              value={imagesValue}
              onChange={multipleImageInputChange}
              maximg={5}
              setDeletedImage={setImgForRemove}
            />

            <div className="Form2Parent row">
              <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Full Name")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  required
                  className="form-control mt-auto"
                  placeholder="Enter Your Name"
                  name="full_name"
                  value={formData?.full_name}
                  onChange={handleChange}
                />
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                <label className="form-label labelHeadin">
                  {t("Date of Birth")}:<span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="date"
                  className="form-control mt-auto"
                  placeholder="Enter Your DOB"
                  max={moment().format("YYYY-MM-DD")}
                  name="date_of_birth"
                  value={formData?.date_of_birth}
                  onChange={handleChange}
                />
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Gender")}
                  <span className="text-danger">*</span>
                </label>

                <div className="mt-auto">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          required
                          // name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="Male"
                          name="gender"
                          checked={formData?.gender === "Male"}
                          onChange={(e) => radioButtonChange(e, e.target.value)}
                        />
                        <label
                          className="form-check-label LabeSubHeading "
                          htmlFor="flexRadioDefault1"
                        >
                          {t("Male")}
                        </label>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          required
                          // name="flexRadioDefault"
                          id="flexRadioDefault1"
                          name="gender"
                          value="Female"
                          checked={formData?.gender === "Female"}
                          onChange={(e) => radioButtonChange(e, e.target.value)}
                        />
                        <label
                          className="form-check-label LabeSubHeading "
                          htmlFor="flexRadioDefault1"
                        >
                          {t("Female")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Race")} <span className="text-danger">*</span>
                </label>

                <MultiSelect
                  value={selectedRaces}
                  required
                  onChange={(e) => {
                    setSelectedRaces(e.value);
                    // console.log(e.value);
                  }}
                  options={races}
                  optionLabel="name"
                  display="chip"
                  showSelectAll={false}
                  closeIcon={false}
                  placeholder="Select Your Race"
                  maxSelectedLabels={5}
                  className="multiSelectDropDown ps-2"
                  name="race"
                />
              </div>

              <div className="col-md-12 mt-4">
                <div className=" form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    name="not_show_on_public_profile_full_name"
                    value={formData?.not_show_on_public_profile_full_name}
                    checked={
                      formData?.not_show_on_public_profile_full_name === true
                    }
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label LabeSubHeading"
                    htmlFor="exampleCheck1"
                  >
                    {t(" Not Shown on Public Profile")}
                  </label>
                </div>
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Spouse Name")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  required
                  className="form-control mt-auto"
                  placeholder="Enter Your Name"
                  name="spouse_name"
                  value={formData?.spouse_name}
                  onChange={handleChange}
                />
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                <label className="form-label labelHeadin">
                  {t("Spouse Date of Birth")}:
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="date"
                  className="form-control mt-auto"
                  placeholder="Enter Your DOB"
                  max={moment().format("YYYY-MM-DD")}
                  name="spouse_date_of_birth"
                  // value={moment().format("YYYY-MM-DD")}
                  value={formData?.spouse_date_of_birth}
                  onChange={handleChange}
                />
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Spouse Gender")}
                  <span className="text-danger">*</span>
                </label>

                <div className="mt-auto">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          required
                          // name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="Male"
                          name="spouse_gender"
                          checked={formData?.spouse_gender === "Male"}
                          onChange={(e) => radioButtonChange(e, e.target.value)}
                        />
                        <label
                          className="form-check-label LabeSubHeading "
                          htmlFor="flexRadioDefault1"
                        >
                          {t("Male")}
                        </label>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          required
                          // name="flexRadioDefault"
                          id="flexRadioDefault1"
                          name="spouse_gender"
                          value="Female"
                          checked={formData?.spouse_gender === "Female"}
                          onChange={(e) => radioButtonChange(e, e.target.value)}
                        />
                        <label
                          className="form-check-label LabeSubHeading "
                          htmlFor="flexRadioDefault1"
                        >
                          {t("Female")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Spouse Race")} <span className="text-danger">*</span>
                </label>

                <MultiSelect
                  value={selectedSpouseRaces}
                  required
                  onChange={(e) => {
                    setSelectedSpouseRaces(e.value);
                    // console.log(e.value);
                  }}
                  options={races}
                  optionLabel="name"
                  display="chip"
                  showSelectAll={false}
                  closeIcon={false}
                  placeholder="Select Spouse Race"
                  maxSelectedLabels={5}
                  className="multiSelectDropDown ps-2"
                  name="race"
                />
              </div>

              <div className="col-md-12 mt-4">
                <div className=" form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    name="not_show_on_public_profile_spouse_name"
                    value={formData?.not_show_on_public_profile_spouse_name}
                    checked={
                      formData?.not_show_on_public_profile_spouse_name === true
                    }
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label LabeSubHeading"
                    htmlFor="exampleCheck1"
                  >
                    {t(" Not Shown on Public Profile")}
                  </label>
                </div>
              </div>

              {/* Third Row - Street Address */}

              <div className="col-md-12 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label ${style.LabelHeading}`}
                >
                  {t("Street Address")}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  className={`form-control`}
                  placeholder="Enter your address"
                  required
                  value={formData?.street_address}
                  name="street_address"
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-12 mt-4">
                <div className=" form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    name="not_show_on_public_profile_street_address"
                    value={formData?.not_show_on_public_profile_street_address}
                    checked={
                      formData?.not_show_on_public_profile_street_address ===
                      true
                    }
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label LabeSubHeading"
                    htmlFor="exampleCheck1"
                  >
                    {t(" Not Shown on Public Profile")}
                  </label>
                </div>
              </div>

              {/* Fourth Row - City , State of Residence, Country, Zip Code */}

              <div className="col-sm-6 col-xxl-3 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Country")}
                  <span className="text-danger">*</span>{" "}
                </label>
                <select
                  className={`form-select`}
                  value={country}
                  required
                  onChange={(e) => {
                    getStates(e.target.value);
                    setState("");
                    setCity("");
                  }}
                >
                  <option selected disabled value={""}>
                    {t("select")}
                  </option>
                  {countries.map((res, index) => {
                    return (
                      <option key={index} value={`${res.name}&&${res.isoCode}`}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("State of Residence")}
                </label>
                <select
                  className={`form-select`}
                  value={state}
                  onChange={(e) => {
                    getCities(e.target.value);
                    setCity("");
                  }}
                >
                  <option selected disabled value={""}>
                    {t("select")}
                  </option>
                  {statesListing?.map((res, index) => {
                    return (
                      <option
                        key={index}
                        value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}
                      >
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("City")}
                </label>
                <select
                  className={`form-select`}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                >
                  <option selected disabled value={""}>
                    {t("select")}
                  </option>
                  {citiesListing?.map((res, index) => {
                    return (
                      <option key={index} value={`${res.name}`}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label ${style.LabelHeading}`}
                >
                  {t("Zip Code")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className={`form-control`}
                  required
                  placeholder={t("")}
                  // // // disabled={editable}
                  value={formData?.zip_code}
                  name="zip_code"
                  onChange={handleChange}
                />
              </div>

              {/* 5th - Date of legal Marriage,....... */}
              <div className="col-sm-6 col-xxl-3 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Date of legal Marriage")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className={`form-control`}
                  placeholder="Enter Date"
                  max={moment().format("YYYY-MM-DD")}
                  value={moment(formData?.date_of_legal_marriage).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={handleChange}
                  required
                  name="date_of_legal_marriage"
                />
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Your Highest Degree of Education")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  required
                  className={`form-control`}
                  placeholder="Enter Highest Degree"
                  name="your_highest_degree_of_education"
                  value={formData?.your_highest_degree_of_education}
                  onChange={handleChange}
                />
              </div>

              <div className="col-sm-8 col-xxl-4 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Your Spouse's Highest Degree of Qualification")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  required
                  className={`form-control`}
                  placeholder="Enter Spouse Highest Degree"
                  name="your_spouse_highest_degree_of_education"
                  value={formData?.your_spouse_highest_degree_of_education}
                  onChange={handleChange}
                />
              </div>

              <div className="col-sm-4 col-xxl-2 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Religion")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  required
                  className={`form-control`}
                  name="religion"
                  placeholder="Enter Your Religion"
                  value={formData?.religion}
                  onChange={handleChange}
                />
              </div>

              {/* 6th Row - Your Occupation , Spouse Occuptaion , Total Income */}

              <div className="col-md-4 mt-4">
                <label className={`form-label  ${style.LabelHeading}`}>
                  {t("Your Occupation")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  required
                  placeholder="Enter Occupation"
                  className="form-control"
                  name="your_occupation"
                  value={formData?.your_occupation}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-4 mt-4">
                <label className={`form-label  ${style.LabelHeading}`}>
                  {t("Spouse Occupation")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  required
                  placeholder={t("Enter Spouse Occupation")}
                  className="form-control"
                  name="spouse_occupation"
                  value={formData?.spouse_occupation}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-4 mt-4">
                <label className={`form-label  ${style.LabelHeading}`}>
                  {t("Total Income($)")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  required
                  placeholder={t("Enter Your Income")}
                  className="form-control"
                  name="total_income"
                  value={formData?.total_income}
                  onChange={handleChange}
                />
              </div>

              {/* 7th Row - Breifly describe the reasons for wanting to adapt */}

              <div className="col-md-12 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Breifly describe the reasons for wanting to adopt")}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  type="text"
                  rows={1}
                  className={`form-control mt-auto`}
                  placeholder={t("Breifly describe the reasons for wanting to adopt")}
                  required
                  value={formData?.briefly_describe_your_reason_for_wanting_to_adopt}
                  name="briefly_describe_your_reason_for_wanting_to_adopt"
                  onChange={handleChange}
                />
              </div>

              {/* 8th Row - Tell us about any children .......... */}

              <div className="col-md-8 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t(
                    "Tell us about any children you currently have, including their age"
                  )}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  className={`form-control`}
                  placeholder={t("Enter the Name and Age of your children")}
                  required
                  value={
                    formData?.tell_us_about_any_children_you_currently_have
                  }
                  name="tell_us_about_any_children_you_currently_have"
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-4 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Kids Birth Status")}
                  <span className="text-danger">*</span>
                </label>
                <select
                  className={`form-select`}
                  value={formData?.kid_birth_status}
                  name="kid_birth_status"
                  required
                  onChange={handleChange}
                >
                  <option selected disabled value={""}>
                    {t("Select")}
                  </option>
                  <option value="Biological Children">
                    Biological Children
                  </option>
                  <option value="Adopted Children">Adopted Children</option>
                  <option value="Both Biological and Adopted Children">
                    Both Biological and Adopted Children
                  </option>
                  <option value="No Children">No Children</option>
                </select>
              </div>

              {/* 9th Row - Tell us about yourself */}

              <div className="col-md-12 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Tell about yourself")}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  type="text"
                  rows={1}
                  className={`form-control mt-auto`}
                  placeholder={t("Enter details about yourself")}
                  required
                  value={formData?.tell_us_about_your_self}
                  name="tell_us_about_your_self"
                  onChange={handleChange}
                />
              </div>

              {/* 10th Row */}

              <div className="col-md-12 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Tell us about your spouse")}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  type="text"
                  rows={1}
                  className={`form-control`}
                  placeholder={t("Enter details about your spouse")}
                  required
                  value={formData?.tell_us_about_your_spouse}
                  name="tell_us_about_your_spouse"
                  onChange={handleChange}
                />
              </div>

              {/* 11th Row */}

              <div className="col-md-12 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Tell us about your relationship")}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  type="text"
                  rows={1}
                  className={`form-control mt-auto`}
                  placeholder={t("Enter details about your relationship")}
                  required
                  value={formData?.tell_us_about_your_relationship}
                  name="tell_us_about_your_relationship"
                  onChange={handleChange}
                />
              </div>

              {/* 12th Row */}

              <div className="col-md-12 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Tell us about your extended family")}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  type="text"
                  rows={1}
                  className={`form-control mt-auto`}
                  placeholder={t("Enter details about your extended family")}
                  required
                  value={formData?.tell_us_about_your_extended_family}
                  name="tell_us_about_your_extended_family"
                  onChange={handleChange}
                />
              </div>

              {/* 13th Row */}

              <div className="col-md-12 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Tell us about your adventures you do in your free time")}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  type="text"
                  rows={1}
                  className={`form-control mt-auto`}
                  placeholder={t("Enter details about your adventures")}
                  required
                  value={
                    formData?.tell_us_about_adventure_you_do_in_your_free_time
                  }
                  name="tell_us_about_adventure_you_do_in_your_free_time"
                  onChange={handleChange}
                />
              </div>

              {/* 14th Row */}

              <div className="col-md-12 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Tell us about tradition, holidays and celebrations")}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  type="text"
                  rows={1}
                  className={`form-control mt-auto`}
                  placeholder={t(
                    "Enter details about your tradition, holidays and celebrations"
                  )}
                  required
                  value={formData?.tell_us_about_tradition}
                  name="tell_us_about_tradition"
                  onChange={handleChange}
                />
              </div>

              {/* 15th Row */}

              <div className="col-md-12 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Tell us about your home")}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  type="text"
                  rows={1}
                  className={`form-control mt-auto`}
                  placeholder={t("Enter details about your home")}
                  required
                  value={formData?.tell_us_about_home}
                  name="tell_us_about_home"
                  onChange={handleChange}
                />
              </div>

              {/* 16th Row */}

              <div className="col-md-12 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t(
                    "Tell us about your and your spouse's hobby and lifestyle"
                  )}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  type="text"
                  rows={1}
                  className={`form-control mt-auto`}
                  placeholder={t(
                    "Enter details about your and your spouse's hobby and lifestyle"
                  )}
                  required
                  value={formData?.tell_us_about_your_and_your_spouse_hobbies}
                  name="tell_us_about_your_and_your_spouse_hobbies"
                  onChange={handleChange}
                />
              </div>

              {/* 17th Row */}
              <div className="row mt-4">
                <span className={`${style.subHeading}`}>Your Favorites</span>
              </div>

              {/* 17th -> 1row */}
              <div className={`mt-4 ${style.favoriteContainer}`}>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Hobby")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="Enter Your Favorite Hobby"
                    required
                    value={formData?.your_favorites?.hobby}
                    name="hobby"
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Movie")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Your Favorite Movie")}
                    required
                    name="movie"
                    value={formData?.your_favorites?.movie}
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Sport")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Your Favorite Sport")}
                    required
                    value={formData?.your_favorites?.sport}
                    name="sport"
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Holiday")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Your Favorite Holiday")}
                    required
                    value={formData?.your_favorites?.holiday}
                    name="holiday"
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Book")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Your Favorite Book")}
                    required
                    value={formData?.your_favorites?.book}
                    name="book"
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Subject")}

                    <span className="text-danger">*</span>
                  </label>

                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Your Favorite Subject")}
                    required
                    value={formData?.your_favorites?.subject}
                    name="subject"
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Tradition")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Your Favorite Tradition")}
                    required
                    value={formData?.your_favorites?.tradition}
                    name="tradition"
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Destination")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Your Favorite Destination")}
                    required
                    value={formData?.your_favorites?.destination}
                    name="destination"
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Job")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Your Favorite Job")}
                    required
                    value={formData?.your_favorites?.job}
                    name="job"
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Food")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Your Favorite Food")}
                    required
                    value={formData?.your_favorites?.food}
                    name="food"
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Music Type")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Your Favorite Music Type")}
                    required
                    value={formData?.your_favorites?.music_type}
                    name="music_type"
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Song")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Your Favorite Song")}
                    required
                    value={formData?.your_favorites?.song}
                    name="song"
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Celebrity")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Your Favorite Celebrity")}
                    required
                    value={formData?.your_favorites?.celebrity}
                    name="celebrity"
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Moment")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Your Favorite Moment")}
                    required
                    value={formData?.your_favorites?.moment}
                    name="moment"
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Author")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Your Favorite Author")}
                    required
                    value={formData?.your_favorites?.author}
                    name="author"
                    onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                  />
                </div>
              </div>

              {/* 18th Row */}

              <div className="row mt-4">
                <span className={`${style.subHeading}`}>
                  Your Spouse Favorites
                </span>
              </div>

              <div className={`mt-4 ${style.favoriteContainer}`}>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Hobby")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Spouse's Favorite Hobby")}
                    required
                    value={formData?.your_spouse_favorites?.hobby}
                    name="hobby"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Movie")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Spouse's Favorite Movie")}
                    required
                    value={formData?.your_spouse_favorites?.movie}
                    name="movie"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Sport")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Spouse's Favorite Sport")}
                    required
                    value={formData?.your_spouse_favorites?.sport}
                    name="sport"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Holiday")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Spouse's Favorite Holiday")}
                    required
                    value={formData?.your_spouse_favorites?.holiday}
                    name="holiday"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Book")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Spouse's Favorite Book")}
                    required
                    value={formData?.your_spouse_favorites?.book}
                    name="book"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Subject")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Spouse's Favorite Subject")}
                    required
                    value={formData?.your_spouse_favorites?.subject}
                    name="subject"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Tradition")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Spouse's Favorite Tradition")}
                    required
                    value={formData?.your_spouse_favorites?.tradition}
                    name="tradition"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Destination")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Spouse's Favorite Destination")}
                    required
                    value={formData?.your_spouse_favorites?.destination}
                    name="destination"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Job")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Spouse's Favorite Job")}
                    required
                    value={formData?.your_spouse_favorites?.job}
                    name="job"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Food")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Spouse's Favorite Food")}
                    required
                    value={formData?.your_spouse_favorites?.food}
                    name="food"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Music Type")}

                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Spouse's Favorite Music Type")}
                    required
                    value={formData?.your_spouse_favorites?.music_type}
                    name="music_type"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Song")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Spouse's Favorite Song")}
                    required
                    value={formData?.your_spouse_favorites?.song}
                    name="song"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Celebrity")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter Spouse's Favorite Celebrity")}
                    required
                    value={formData?.your_spouse_favorites?.celebrity}
                    name="celebrity"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Moment")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="Enter Spouse's Favorite Moment"
                    required
                    value={formData?.your_spouse_favorites?.moment}
                    name="moment"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className={`form-label ${style.LabelHeading}`}>
                    {t("Favorite Author")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="Enter Spouse's Favorite Author"
                    required
                    value={formData?.your_spouse_favorites?.author}
                    name="author"
                    onChange={(e) =>
                      handleFavoritesChange(e, "your_spouse_favorites")
                    }
                  />
                </div>
              </div>

              {/* 19th row */}
              <div className="row mt-4">
                <span className={`${style.subHeading}`}>Child Prefrences</span>
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Age Range")}
                  <span className="text-danger">*</span>
                </label>
                <div className="row">
                  <div className="col-6">
                    <input
                      type="number"
                      className={`form-control`}
                      placeholder="Min age"
                      value={formData?.child_preferences?.age_range?.min}
                      name="child_preferences.age_range.min"
                      onChange={handleChildChange}
                      required
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="number"
                      className={`form-control`}
                      placeholder="Max age"
                      required
                      value={formData?.child_preferences?.age_range?.max}
                      name="child_preferences.age_range.max"
                      onChange={handleChildChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Gender")}
                  <span className="text-danger">*</span>
                </label>
                <select
                  className={`form-select`}
                  name="child_preferences.gender"
                  value={formData?.child_preferences?.gender}
                  required
                  onChange={handleChildChange}
                >
                  <option selected disabled value={""}>
                    {t("Select")}
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="No Preference">No Preference</option>
                </select>
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Special Needs")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="Enter Special Needs"
                  required
                  value={formData?.child_preferences?.special_need}
                  name="child_preferences.special_need"
                  onChange={handleChildChange}
                />
              </div>

              <div className="col-sm-6 col-xxl-3 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Race of child")}
                  <span className="text-danger">*</span>
                </label>
                <MultiSelect
                  value={selectedChildRaces}
                  required
                  onChange={(e) => {
                    setSelectedChildRaces(e.value);
                    // console.log(e.value);
                  }}
                  options={races}
                  optionLabel="name"
                  display="chip"
                  showSelectAll={false}
                  closeIcon={false}
                  placeholder="Select Race"
                  maxSelectedLabels={5}
                  className="multiSelectDropDown  ps-2"
                  name="race"
                />
              </div>
            </div>

            <div className="button_section_for_upper_section justify-content-end">
              <button type="submit" className="btn_non_fill">
                {t("Submit")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AdoptingParentForm;
