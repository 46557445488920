import React, { useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router'
import "./primaryResume.css"
import { getConciseFormData } from '../../../../service/Apis'
import { useTranslation } from 'react-i18next'

export const PrimaryResumeForm = () => {
    const {id} = useParams()
    const [formData,setFormData] = useState({})
    let {t}=useTranslation()

    async function getFormdata(){
        try{
            let res = await getConciseFormData(id)
            setFormData(res.data.data)

        }catch(err){
            console.log(err)
        }
    }

    useEffect(()=>{
        getFormdata()
    },[])
    return (
        <div className="p-lg-5 p-md-5 p-sm-4 p-3">
            <div className="primary_resume_main_con">
                <div className='p-md-4 p-sm-3 p-2'>
                    <div className='text-center main_heading'>{t("Surrogacy Mother")}</div>
                    <div className='text-center second_heading'>{t("Complete your profile and be ready to earn")}.</div>
                </div>
                <Outlet 
                context={{
                    allData:formData,
                    getData:getFormdata
                }}
                />
            </div>
        </div>
    )
}
