import React, { useEffect, useState } from 'react'
import "./ResumeSteps.css"
import { useTranslation } from "react-i18next";
import { useOutletContext } from 'react-router';
import { resumeForm5 } from "../../../../service/Apis"
import { userId } from '../../../../service/userData';
import axios from 'axios';
import { resumeAllKeys } from './resumeKeys';
import { SURROGATE_INSURANCE_THROUGH } from '../../../../constant';
import moment from 'moment';
import Loader from '../../../../components/Loader/Loader';


const ResumeStep5 = () => {


    let { data } = useOutletContext()
    const [loader,setLoader] = useState(false)
    const [formData, setFormData] = useState(
        {
            medical_insurance: '',
            insurance_through: [],
            health_insurance_company: '',
            date_expired: "",
            maternity_coverage: '',
            yearly_deductible: "",
            coverage_percentage: "",
            Copay_amount: "",
            monthly_premium_payment: "",
            yearly_maximum_out_of_pocket_expense: "",
            currently_employed: '',
            occupation: '',
            joining_date: "",
            working_hours_per_week: "",
            annual_salary: "",
            employer_offer_short_term_disability_plan: '',
            currently_enrolled_plan: '',
            high_school_name_location: '',
            graduation_date: "",
            top_choice_education_ip: '',
            attend_college: '',
            pursuing_degree_list: '',
            other_certificates_training: ''
        }
    )

    let step5Keys = resumeAllKeys.step5;
    // useEffect(() => {
    //     let obj = {}
    //     for (let i in step5Keys) {
    //         if (data.resumeData[i]) {
    //             obj[i] = data?.resumeData?.[i] || step5Keys[i]
    //         }
    //     }
       
    //     setFormData(pre => ({ ...pre, ...data.resumeData }));
    // }, [data.resumeData])
    useEffect(() => {
        let obj = {};
        for (let i in step5Keys) {
          if (data.resumeData.hasOwnProperty(i)) {
            obj[i] = data?.resumeData?.[i]
          }
           else {
            obj[i] = step5Keys[i]
          }
        }
       
        setFormData((pre) => ({ ...pre, ...obj }));
      }, [data.resumeData]);




    const radioInputChange = (e, val) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: val,
        }));

    }
    const handleChange = (e) => {
        let val = e.target.value
        // console.log(val)
        const { name, value, type, checked } = e.target;
        if(value == ' '){
            e.target.value = ''
        }else{

            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }


    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoader(true)

        let userID = userId();
        // let formdata 
        let formdata = { ...formData }
        // if(formData.medical_insurance === false){
        //     formdata = {
        //         medical_insurance:formData.medical_insurance,
        //         top_choice_education_ip:formData.top_choice_education_ip,
        //         high_school_name_location:formData.high_school_name_location,
        //         graduation_date:formData.graduation_date,
        //         attend_college:formData.attend_college,
        //         pursuing_degree_list:formData.pursuing_degree_list,
        //         other_certificates_training:formData.other_certificates_training
        //     }
        //     if(formData.currently_employed === true){
        //         formdata = {
        //             ...formdata,
        //             occupation:formData.occupation,
        //             joining_date:formData.joining_date,
        //             working_hours_per_week:formData,
        //             annual_salary:formData.annual_salary,
        //             employer_offer_short_term_disability_plan:formData.employer_offer_short_term_disability_plan,
        //             currently_enrolled_plan:formData.currently_enrolled_plan
        //         }
        //     }else{
        //         formdata = {
        //             ...formdata,
        //             currently_employed:formData.currently_employed
        //         }
        //     }
        // }else {
        //     formdata = {
        //         medical_insurance:formData.medical_insurance,
        //         insurance_through:formData.insurance_through,
        //         health_insurance_company:formData.health_insurance_company,
        //         date_expired:formData.date_expired,
        //         maternity_coverage:formData.maternity_coverage,
        //         yearly_deductible:formData.yearly_deductible,
        //         coverage_percentage:formData.coverage_percentage,
        //         Copay_amount:formData.Copay_amount,
        //         monthly_premium_payment:formData.monthly_premium_payment,
        //         yearly_maximum_out_of_pocket_expense:formData.yearly_maximum_out_of_pocket_expense,
        //         top_choice_education_ip:formData.top_choice_education_ip,
        //         high_school_name_location:formData.high_school_name_location,
        //         graduation_date:formData.graduation_date,
        //         attend_college:formData.attend_college,
        //         pursuing_degree_list:formData.pursuing_degree_list,
        //         other_certificates_training:formData.other_certificates_training
        //     }
        //     if(formData.currently_employed === true){
        //         formdata = {
        //             ...formdata,
        //             occupation:formData.occupation,
        //             joining_date:formData.joining_date,
        //             working_hours_per_week:formData,
        //             annual_salary:formData.annual_salary,
        //             employer_offer_short_term_disability_plan:formData.employer_offer_short_term_disability_plan,
        //             currently_enrolled_plan:formData.currently_enrolled_plan
        //         }
        //     }else{
        //         formdata = {
        //             ...formdata,
        //             currently_employed:formData.currently_employed
        //         }
        //     }
        // }

        if(formdata.maternity_coverage == ''){
            formdata.maternity_coverage = false
        }
        if(formdata.employer_offer_short_term_disability_plan == ''){
            formdata.employer_offer_short_term_disability_plan = false
        }
        if(formdata.employer_offer_short_term_disability_plan === false){
            delete formdata.currently_enrolled_plan
        }else{

            if(formdata.currently_enrolled_plan == ''){
                formdata.currently_enrolled_plan = false
            }
        }
        try {
            let res = await resumeForm5(userID, formdata);
            setLoader(false)
            navigation.next()
        }
        catch (error) {
            console.log(error)
            setLoader(false)
        }

    }

    function multipleCheckBoxChange(e) {

        let { value, checked } = e.target;
        let datas = formData.insurance_through
        if (checked) {
            datas?.push(value);
            setFormData({ ...formData, ...{ insurance_through: datas } })
        }
        else {
            datas.splice(datas.indexOf(value), 1);
            setFormData({ ...formData, ...{ insurance_through: datas } })

        }
    }

    let { t } = useTranslation();
    let { navigation } = useOutletContext()
    return (
        <>
        <Loader open={loader}/>
            <div className='ResumeStep_con'>
                <div className='FormInsurance'>
                    <div >
                        <h1 className='SubHeading mb-lg-4 mb-md-4 mb-sm-3 mb-3' >{t("Insurance Information")}</h1>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='CheckBoxParent row mb-4'>
                            <label className="form-label fs-5" >
                                {t("Do you have medical insurance?")}<span className='text-danger'>*</span>
                            </label>
                            <div className="col-md-3">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio" name="medical_insurance" required
                                        
                                        checked={formData.medical_insurance === true}
                                        onChange={(e) => radioInputChange(e, true)}
                                    />
                                    <label className="form-check-label LabelSubHeading" >
                                        {t("Yes")}
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-3">  <div className="form-check">
                                <input checked={formData.medical_insurance === false} className="form-check-input" type="radio" name="medical_insurance" required  onChange={(e) =>{
                                     radioInputChange(e, false)
                                     setFormData(pre=>{
                                        return{
                                            ...pre,
                                                insurance_through: [],
                                                health_insurance_company: '',
                                                date_expired: "",
                                                maternity_coverage: '',
                                                yearly_deductible: "",
                                                coverage_percentage: "",
                                                Copay_amount: "",
                                                monthly_premium_payment: "",
                                                yearly_maximum_out_of_pocket_expense: ""
                                        }
                                     })
                                }} />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("No")}
                                </label>
                            </div></div>

                        </div>
                       {formData.medical_insurance !== ''?formData.medical_insurance?<>
                        <div className='CheckBoxParent row mb-4'>
                            <label className="form-label fs-5" >
                                {t("Is your insurance through")}?<span className='text-danger'>*</span>
                            </label>


                            {SURROGATE_INSURANCE_THROUGH.map((res, key) => {
                                return (

                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                                        <div className="form-check">
                                            <input type="checkbox"
                                                class="form-check-input"
                                                name="insurance_through"
                                                
                                                value={res}
                                                required={formData?.insurance_through?.length==0}
                                                checked={formData.insurance_through.includes(res)}
                                                onChange={multipleCheckBoxChange} />
                                            <label className="form-check-label LabelSubHeading"  >
                                                {t(res)}
                                            </label>
                                        </div>
                                    </div>
                                )
                            })}



                        </div>
                        <div className='row mb-4'>
                            <div className=" col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Name of Health Insurance Company")} <span className='text-danger'>*</span></label>

                                <input type="text" value={formData.health_insurance_company} required className="form-control" placeholder="" name='health_insurance_company' onChange={handleChange} />

                            </div>
                            <div className=" col-md-6 ">
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Expiry date of Insurance")} <span className='text-danger'>*</span></label>

                                <input type="Date"
                                    className="form-control"
                                    name='date_expired'
                                    value={formData.date_expired && moment(formData.date_expired).format("YYYY-MM-DD")}
                                    placeholder=""
                                    required
                                    onChange={handleChange} />

                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <label className="form-label fs-5" >
                                    {t("Does your medical insurance include maternity coverage?")}<span className='text-danger'>*</span>
                                </label>
                                <div className='row mb-4'>
                                    <div className="col-md-6">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio" name="maternity_coverage" required
                                                 onChange={(e) => radioInputChange(e, true)}
                                                checked={formData.maternity_coverage === true}
                                            />
                                            <label className="form-check-label LabelSubHeading" >
                                                {t("Yes")}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-6">  <div className="form-check">
                                        <input
                                            className="form-check-input" type="radio" name="maternity_coverage" required  onChange={(e) => radioInputChange(e, false)}
                                            checked={formData.maternity_coverage === false}

                                        />
                                        <label className="form-check-label LabelSubHeading" htmlFor="flexRadioDefault2">
                                            {t("No")}
                                        </label>
                                    </div></div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Yearly deductible?")}{t('(In $)')} <span className='text-danger'>*</span></label>

                                <input type="number" required value={formData.yearly_deductible} className="form-control" placeholder="" name='yearly_deductible' onChange={handleChange} />
                            </div>
                            <div className="col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Coverage percentage?")} <span className='text-danger'>*</span></label>

                                <input type="number" required value={formData.coverage_percentage} className="form-control" placeholder="" name='coverage_percentage' onChange={handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Co-pay amount per office visit?")}{t('(In $)')} <span className='text-danger'>*</span></label>

                                <input type="number" required
                                    value={formData.Copay_amount}
                                    className="form-control" placeholder="" name='Copay_amount' onChange={handleChange} />
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className=" col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Monthly premium payment?")}{t('(In $)')}<span className='text-danger'>*</span></label>

                                <input type="number" required className="form-control" placeholder="" name='monthly_premium_payment' onChange={handleChange}
                                    value={formData.monthly_premium_payment}
                                />

                            </div>
                            <div className=" col-md-6 ">
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Yearly maximum out-of-pocket expense?")}{t('(In $)')}<span className='text-danger'>*</span></label>

                                <input type="number"
                                    required
                                    value={formData.yearly_maximum_out_of_pocket_expense}
                                    className="form-control" placeholder="" name='yearly_maximum_out_of_pocket_expense' onChange={handleChange} />

                            </div>
                        </div>

                        </>:'':''}
                        <div className='Section3 '>
                            <h1 className='SubHeading mb-lg-4 mb-md-4 mb-sm-3 mb-3'>{t("Employment Information")}</h1>
                            <div className='CheckBoxParent row mb-4'>
                                <label className="form-label fs-5" >
                                    {t("Are you currently employed?")}<span className='text-danger'>*</span>
                                </label>


                                <div className="col-md-3">  <div className="form-check">
                                    <input className="form-check-input" type="radio" name="currently_employed" required 
                                        checked={formData.currently_employed === true}
                                        onChange={(e) => {
                                            radioInputChange(e, true)
                                            setFormData(pre=>{
                                                return {
                                                    ...pre,
                                                    occupation:'',
                                                    joining_date:'',
                                                    working_hours_per_week:'',
                                                    annual_salary:'',
                                                    employer_offer_short_term_disability_plan:'',
                                                    currently_enrolled_plan:''
                                                }
                                            })
                                        }}
                                    />
                                    <label className="form-check-label LabelSubHeading" >
                                        {t("Yes")}
                                    </label>
                                </div>
                                </div>
                                <div className="col-md-3">  <div className="form-check">
                                    <input className="form-check-input" type="radio" name="currently_employed" required 
                                        checked={formData.currently_employed === false}

                                        onChange={(e) => {
                                            radioInputChange(e, false)
                                            setFormData(pre=>{
                                                return {
                                                    ...pre,
                                                    occupation:'',
                                                    joining_date:'',
                                                    working_hours_per_week:'',
                                                    annual_salary:'',
                                                    employer_offer_short_term_disability_plan:'',
                                                    currently_enrolled_plan:''
                                                }
                                            })
                                        }} />
                                    <label className="form-check-label LabelSubHeading" >
                                        {t("No")}
                                    </label>
                                </div>
                                </div>

                            </div>



                            {formData.currently_employed?<>
                                <div className='row mb-4'>
                                <div className=" col-md-6 mb-4">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Current occupation/position?")}<span className='text-danger'>*</span></label>

                                    <input type="text"
                                        required
                                        className="form-control" placeholder=""
                                        name='occupation' onChange={handleChange}
                                        value={formData.occupation}
                                    />

                                </div>
                                <div className=" col-md-6 mb-4">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Start date with current employer")}<span className='text-danger'>*</span></label>

                                    <input type="date"
                                        required
                                        value={formData.joining_date && moment(formData.joining_date).format('YYYY-MM-DD')}
                                        className="form-control" placeholder="" name='joining_date' onChange={handleChange} />

                                </div>
                                <div className=" col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Average number hours worked per week")}<span className='text-danger'>*</span></label>

                                    <input type="number" className="form-control" placeholder=""
                                        required
                                        name='working_hours_per_week'
                                        value={formData.working_hours_per_week}
                                        onChange={handleChange} />

                                </div>
                                <div className=" col-md-6">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Hourly wage or annual salary")}{t('(In $)')}<span className='text-danger'>*</span></label>

                                    <input type="number" required className="form-control" placeholder="" name='annual_salary'
                                        value={formData.annual_salary}
                                        onChange={handleChange} />

                                </div>
                            </div>


                            <div className='row mb-4'>
                                <div className="col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                    <label className="form-label fs-5" >
                                        {t("Does your employer offer a short term disability plan?")}<span className='text-danger'>*</span>
                                    </label>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="employer_offer_short_term_disability_plan" required  checked={formData.employer_offer_short_term_disability_plan === true} onChange={(e) => {
                                                    radioInputChange(e, true)
                                                    setFormData(pre=>{
                                                        return {
                                                            ...pre,
                                                            currently_enrolled_plan:''
                                                        }
                                                    })
                                                }} />
                                                <label className="form-check-label LabelSubHeading" >
                                                    {t("Yes")}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-6">  <div className="form-check">
                                            <input className="form-check-input" type="radio" name="employer_offer_short_term_disability_plan" required  checked={formData.employer_offer_short_term_disability_plan === false} onChange={(e) => {
                                                radioInputChange(e, false)
                                                setFormData(pre=>{
                                                    return {
                                                        ...pre,
                                                        currently_enrolled_plan:''
                                                    }
                                                })
                                            }} />
                                            <label className="form-check-label LabelSubHeading" >
                                                {t("No")}
                                            </label>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                               {formData.employer_offer_short_term_disability_plan === true ? <div className="col-md-6">
                                    <label className="form-label fs-5" >
                                        {t("Are you currently enrolled in the plan?")}<span className='text-danger'>*</span>
                                    </label>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input" type="radio" name="currently_enrolled_plan" required  onChange={(e) => radioInputChange(e, true)}
                                                    checked={formData.currently_enrolled_plan === true}
                                                />
                                                <label className="form-check-label LabelSubHeading" >
                                                    {t("Yes")}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-6">  <div className="form-check">
                                            <input className="form-check-input" type="radio" name="currently_enrolled_plan" required 
                                                checked={formData.currently_enrolled_plan === false}
                                                onChange={(e) => radioInputChange(e, false)} />
                                            <label className="form-check-label LabelSubHeading" >
                                                {t("No")}
                                            </label>
                                        </div>
                                        </div>
                                    </div>
                                </div>:''}
                            </div>
                            </>:''}
                        </div>

                        <div className='Section2 '>
                            <h1 className='SubHeading mb-lg-4 mb-md-4 mb-sm-3 mb-3'>{t("Educational Information")}</h1>
                            <div className='CheckBoxParent row mb-4'>
                                <label className="form-label fs-5 mb-3" >
                                    {t("Please select your TOP choice for the type of intended parent(s) you would prefer to carry for.")}<span className='text-danger'>*</span>
                                </label>
                                <div className="col-md-4">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="top_choice_education_ip"
                                            required
                                            
                                            checked={formData.top_choice_education_ip === "High School Diploma"}
                                            onChange={handleChange}
                                            value={"High School Diploma"}
                                        />

                                        <label className="form-check-label LabelSubHeading" >
                                            {t("High School Diploma")}
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-4">  <div className="form-check">
                                    <input
                                        className="form-check-input" type="radio"
                                        name="top_choice_education_ip"
                                        required
                                        
                                        value={"GED"}
                                        checked={formData.top_choice_education_ip === "GED"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label LabelSubHeading" >
                                        {t("GED")}
                                    </label>
                                </div>
                                </div>
                                <div className="col-md-4">  <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="top_choice_education_ip"
                                        required
                                        value={"I do not have a Diploma or GED"}
                                        checked={formData.top_choice_education_ip === "I do not have a Diploma or GED"}
                                        
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label LabelSubHeading" >
                                        {t("I do not have a Diploma or GED")}
                                    </label>
                                </div>
                                </div>

                            </div>



                            <div className='row mb-4'>
                                <div className=" col-md-12 ">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Name and location of high school")}<span className='text-danger'>*</span></label>

                                    <input type="text" className="form-control" placeholder="" name="high_school_name_location"
                                        value={formData.high_school_name_location}
                                        onChange={handleChange} required />

                                </div>
                            </div>


                            <div className='row mb-4'>
                                <div className=" col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Graduation date")}<span className='text-danger'>*</span></label>

                                    <input type="Date" className="form-control" placeholder="" name='graduation_date' required onChange={handleChange}
                                        value={formData.graduation_date && moment(formData.graduation_date).format("YYYY-MM-DD")}
                                    />

                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fs-5" >
                                        {t("Did you attend college/university?")}<span className='text-danger'>*</span>
                                    </label>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    required
                                                    name="attend_college"
                                                    
                                                    checked={formData.attend_college === true}
                                                    onChange={(e) => radioInputChange(e, true)} />
                                                <label className="form-check-label LabelSubHeading" >
                                                    {t("Yes")}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    required
                                                    name="attend_college"
                                                    
                                                    onChange={(e) => radioInputChange(e, false)}
                                                    checked={formData.attend_college === false}
                                                />
                                                <label className="form-check-label LabelSubHeading" >
                                                    {t("No")}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-4'>
                                <div className=" col-md-12 ">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >
                                        {t("Please list any degrees you are currently pursuing")}
                                    </label>

                                    <textarea rows={5}
                                        value={formData.pursuing_degree_list}
                                        type="text"
                                        
                                        className="form-control"
                                        placeholder=""
                                        name='pursuing_degree_list'
                                        onChange={handleChange} />

                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className=" col-md-12 ">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >
                                        {t("Other licenses/certificates/areas of training, etc")}
                                    </label>

                                    <textarea rows={5} type="text"
                                     className="form-control" placeholder="" name='other_certificates_training'
                                        value={formData.other_certificates_training}
                                        onChange={handleChange} />
                                </div>
                            </div>

                        </div>
                       
                        <div className="ButtonForm mt-3">
                            <button className="btn_non_fill" type="button" onClick={navigation.previous}>{t('Previous')}</button>
                            <button className="btn_fill" type="submit" >{t('Next')}</button>
                        </div>


                    </form>
                </div>
            </div>
        </>
    )
}

export default ResumeStep5