import React from "react";
import style from "./ContactUs.module.css";
import { useTranslation } from "react-i18next";


const ContactUs = () => {
  let { t } = useTranslation();
  return (
    <div>
      <div className={`${style.banner}`}>
        <img src="/images/contactus.png" className="w-100" alt="" />
        <div className={`${style.banner_abolute}`}>
          <div className={`${style.lufga_36px_style}`}>
              {t("Contact Us")}
          </div>
          <div className={`${style.lufga_18px_style}`}>
          {t("Committed to serve you better in your parenthood journey")}
          </div>
        </div>
      </div>

      <div className={style.main_content}>
        <div className={style.main_img}>
          <img
            className={style.main_img_tag}
            src="/images/contact_us.png"
            alt="couple and child"
          />
        </div>
        <div className={style.right_container}>
          <div className={style.right_inner}>
            <div className={style.input_form_main}>
              <div className={style.input_form}>
                <label
                  for="fname"
                  className={`lufga_20px ${style.label_input}`}
                >
                  {t("Full Name")}
                </label>
                <input className="mt-4" type="text" id="fname" name="fname" />
                <label
                  for="lname"
                  className={`lufga_20px mt-4 ${style.label_input}`}
                >
                  {t("Email")}
                </label>
                <input className="mt-4" type="text" id="lname" name="lname" />
                <label
                  for="msg"
                  className={`lufga_20px mt-4 ${style.label_input}`}
                >
                  {t("Message")}
                </label>
                <input className="mt-4" type="text" id="msg" name="msg" />
              </div>
              <button className={style.btn}>{t("Contact Us")}</button>
            </div>
            <div className={style.right_div} >
              <div className={`${style.right_one}`}>
                <div className={`lufga_24px fw-600`}>{t("Contact")}</div>
                <div className={`lufga_18px d-flex`}>
                  info@newhopepoints.org newhopepoints@gmail.com
                </div>

                <div className={`lufga_24px fw-600`}>{t("Based in")}</div>

                <div className={`lufga_18px`}>
                  {t("2469 FM 359 Rd. S, Ste X Brookshire, TX 77423, USA")}
                  
                </div>
                <div className={`pt-5 mt-5 ${style.link_section}`}>
                  <img src="/images/facebool_black.png" alt="facebook logo" />
                  <img src="/images/instagram_black.png" alt="facebook logo" />
                  <img src="/images/twitter_black.png" alt="facebook logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
