import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useOutletContext } from 'react-router';
import { userId } from '../../../../service/userData';
import { resumeForm6 } from '../../../../service/Apis';
import { resumeAllKeys } from './resumeKeys';
import moment from 'moment';
import "./ResumeSteps.css"
import Loader from '../../../../components/Loader/Loader';
export const ResumeStep6 = () => {

    let { t } = useTranslation();
    let { navigation, data } = useOutletContext()
    let [loader, setLoader] = useState(false);
    const [filledData, setFilledData] = useState({})

    let step6Keys = resumeAllKeys.step6;

    // useEffect(() => {
    //     let obj = {}
    //     console.log(data)
    //     for (let i in step6Keys) {
    //         if (data.resumeData[i]) {
    //             obj[i] = data?.resumeData?.[i] || step6Keys[i]
    //         }
    //     }
    //     console.log(obj)
    //     setFormData(pre => ({ ...pre, ...obj }));
    // }, [data.resumeData])


    useEffect(() => {
        let obj = {};
        for (let i in step6Keys) {
            if (data.resumeData.hasOwnProperty(i)) {
                obj[i] = data?.resumeData?.[i]
            }
            else {
                obj[i] = step6Keys[i]
            }
        }

        setFormData((pre) => ({ ...pre, ...obj }));
    }, [data.resumeData]);

    const [formData, setFormData] = useState(
        {
            spouse_dob: '',
            spouse_name: '',
            spouse_email: '',
            spouse_occupation: '',
            spouse_employed: "",
            spouse_marriage_date: '',
            spouse_startDate_employer: '',
            hourly_wages_annual_salary: "",
            spouse_ever_hospitalized: "",
            spouse_working_hours_per_week: "",
            spouse_ever_meet_psychologist: "",
            spouse_hospitalized_circumstances: '',
            spouse_ever_prescribed_psychiatric: "",
            spouse_meet_psychologist_circumstances: '',
            spouse_prescribed_psychiatric_circumstances: '',
        }
    )
    async function formsubmit6(e) {
        e.preventDefault()
        setLoader(true)
        try {
            e.preventDefault();
            let userID = userId();
            let data = { ...filledData };
            // if(data?.spouse_employed === ''){
            //     data.spouse_employed = false
            // }
            // if(data.spouse_ever_meet_psychologist === ''){
            //     data.spouse_ever_meet_psychologist = false
            // }
            // if(data.spouse_ever_prescribed_psychiatric === ''){
            //     data.spouse_ever_prescribed_psychiatric = false
            // }
            // if(data.spouse_ever_hospitalized === ''){
            //     data.spouse_ever_hospitalized = false
            // }
            // let res = await resumeForm6(userID, {spouse_name:'varun'});
            let res = await resumeForm6(userID, data);
            navigation.next()
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoader(false)
        }
    }
    function radioInputChange(e, val) {
        let name = e.target.name

        setFilledData((prevState) => ({
            ...prevState,
            [name]: val,
        }));
        setFormData((prevState) => ({
            ...prevState,
            [name]: val,
        }));
    };
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;


        if (type === 'checkbox') {
            setFilledData((prevState) => ({
                ...prevState,
                [name]: checked,
            }));
            setFormData((prevState) => ({
                ...prevState,
                [name]: checked,
            }));
        } else {

            if (value == ' ') {
                e.target.value = ''
            } else {
                setFilledData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
            }
        }
    };

    return (
        <>
            <Loader open={loader} />
            <div className="ResumeStep_con">
                <div className='Spouse / Partner Information'>
                   
                        <div className='SubHeading mb-lg-4 mb-md-4 mb-sm-3 mb-3' >{t("Spouse/Partner Information")}</div>
                  
                    <form onSubmit={formsubmit6}>
                        <div className='row mb-4 '>
                            <div className=" col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Spouse/partner's name")}</label>

                                <input

                                    type='text'
                                    className='form-control'
                                    placeholder=''
                                    name='spouse_name'
                                    value={formData.spouse_name}
                                    onChange={handleInputChange}
                                />

                            </div>
                            <div className=" col-md-6 ">
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Spouse/partner's Birth Date")} </label>

                                <input

                                    type='Date'
                                    className='form-control'
                                    placeholder=''
                                    max={moment().format('YYYY-MM-DD')}
                                    name='spouse_dob'
                                    value={formData.spouse_dob && moment(formData.spouse_dob).format("YYYY-MM-DD")}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className=" col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Spouse/partner's email address")} </label>

                                <input

                                    type='email'
                                    className='form-control'
                                    placeholder=''
                                    name='spouse_email'
                                    value={formData.spouse_email}
                                    onChange={handleInputChange}
                                />

                            </div>
                            <div className=" col-md-6 ">
                                <label htmlFor="exampleInputEmail1"
                                    className="form-label fs-5" >{t("Date of your marriage")} </label>

                                <input

                                    type='date'
                                    className='form-control'
                                    name='spouse_marriage_date'
                                    value={formData.spouse_marriage_date && moment(formData.spouse_marriage_date).format("YYYY-MM-DD")}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                />

                            </div>
                        </div>
                        <div>
                            <div className='CheckBoxParent row mb-4'>
                                <label className="form-label fs-5" >
                                    {t("Is your spouse/partner employed?")}
                                </label>
                                <div className="col-md-3">
                                    <div className="form-check">
                                        <input onChange={(e) => radioInputChange(e, true)}
                                            className="form-check-input"
                                            type="radio"

                                            checked={formData.spouse_employed == true}
                                            name="spouse_employed"


                                        />
                                        <label className="form-check-label LabelSubHeading" >
                                            {t("Yes")}
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-3">  <div className="form-check">
                                    <input onChange={(e) => {
                                        radioInputChange(e, false)
                                        setFormData(pre => {
                                            return {
                                                ...pre,
                                                spouse_occupation: '',
                                                spouse_startDate_employer: '',
                                                spouse_working_hours_per_week: '',
                                                hourly_wages_annual_salary: ''
                                            }
                                        })
                                    }}
                                        className="form-check-input" type="radio"
                                        name="spouse_employed"

                                        checked={formData.spouse_employed === false} />
                                    <label className="form-check-label LabelSubHeading" >
                                        {t("No")}
                                    </label>
                                </div></div>

                            </div>
                            {formData.spouse_employed !== '' ? formData.spouse_employed ? (
                                <div>
                                    <div className='row mb-4'>
                                        <div className=" col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                            <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Spouse/partner’s current occupation/position")} </label>

                                            <input

                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                name='spouse_occupation'
                                                value={formData.spouse_occupation}
                                                onChange={handleInputChange}
                                            />

                                        </div>
                                        <div className=" col-md-6 ">
                                            <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Spouse/partner's start date with current employer")} </label>

                                            <input

                                                type='Date'
                                                className='form-control'
                                                placeholder=''
                                                name='spouse_startDate_employer'
                                                value={formData.spouse_startDate_employer && moment(formData.spouse_startDate_employer).format("YYYY-MM-DD")}
                                                onChange={handleInputChange}
                                            />

                                        </div>
                                    </div>
                                    <div className='row mb-4'>
                                        <div className=" col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                            <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Average number hours worked per week")}</label>

                                            <input

                                                type='number'
                                                className='form-control'
                                                placeholder=''
                                                name='spouse_working_hours_per_week'
                                                value={formData.spouse_working_hours_per_week}
                                                onChange={handleInputChange}
                                            />

                                        </div>
                                        <div className=" col-md-6 ">
                                            <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Hourly wage or annual salary")} </label>

                                            <input

                                                type='number'
                                                className='form-control'
                                                placeholder=''
                                                name='hourly_wages_annual_salary'
                                                value={formData.hourly_wages_annual_salary}
                                                onChange={handleInputChange}
                                            />

                                        </div>
                                    </div>
                                </div>
                            ) : '' : ''}

                        </div>
                        <div className='CheckBoxParent row mb-4'>
                            <label className="form-label fs-5" >
                                {t("Has spouse/partner ever seen a psychologist or any other mental health professional?")}
                            </label>
                            <div className="col-md-3">
                                <div className="form-check">
                                    <input onChange={(e) => radioInputChange(e, true)}
                                        className="form-check-input" type="radio"
                                        name="spouse_ever_meet_psychologist"


                                        checked={formData.spouse_ever_meet_psychologist == true}
                                    />

                                    <label className="form-check-label LabelSubHeading" >
                                        {t("Yes")}
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-3">  <div className="form-check">
                                <input onChange={(e) => {
                                    radioInputChange(e, false)
                                    setFormData(pre => {
                                        return {
                                            ...pre,
                                            spouse_meet_psychologist_circumstances: ''
                                        }
                                    })
                                }}
                                    className="form-check-input" type="radio"
                                    name="spouse_ever_meet_psychologist"


                                    checked={formData.spouse_ever_meet_psychologist === false}
                                />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("No")}
                                </label>
                            </div>
                            </div>
                        </div>
                        {formData.spouse_ever_meet_psychologist !== '' ? formData.spouse_ever_meet_psychologist ? (

                            <div className='row mb-4'>
                                <div className=" col-md-12 ">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list dates and circumstances")}</label>

                                    <textarea
                                        rows={5}

                                        className='form-control'
                                        placeholder=''
                                        name='spouse_meet_psychologist_circumstances'
                                        value={formData?.spouse_meet_psychologist_circumstances}
                                        onChange={handleInputChange}
                                    />

                                </div>

                            </div>
                        ) : '' : ''}
                        <div className='CheckBoxParent row mb-4'>
                            <label className="form-label fs-5" >
                                {t("Has spouse/partner ever been prescribed psychiatric medication(s)?")}
                            </label>
                            <div className="col-md-3">
                                <div className="form-check">
                                    <input onChange={(e) => radioInputChange(e, true)}
                                        className="form-check-input" type="radio"
                                        name="spouse_ever_prescribed_psychiatric"


                                        checked={formData.spouse_ever_prescribed_psychiatric == true}
                                    />
                                    <label className="form-check-label LabelSubHeading" >
                                        {t("Yes")}
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-3">  <div className="form-check">
                                <input

                                    onChange={(e) => {
                                        setFormData(pre => {
                                            radioInputChange(e, false)
                                            return {
                                                ...pre,
                                                spouse_prescribed_psychiatric_circumstances: ''
                                            }
                                        })
                                    }}
                                    className="form-check-input"
                                    type="radio" name="spouse_ever_prescribed_psychiatric"

                                    checked={formData.spouse_ever_prescribed_psychiatric === false}
                                />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("No")}
                                </label>
                            </div>
                            </div>
                        </div>
                        {formData.spouse_ever_prescribed_psychiatric !== '' ? formData.spouse_ever_prescribed_psychiatric ? (

                            <div className='row mb-4'>
                                <div className=" col-md-12 ">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list dates and circumstances")}</label>

                                    <textarea
                                        rows={5}

                                        className='form-control'
                                        placeholder=''
                                        name='spouse_prescribed_psychiatric_circumstances'
                                        value={formData.spouse_prescribed_psychiatric_circumstances}
                                        onChange={handleInputChange}
                                    />

                                </div>

                            </div>
                        ) : '' : ''}
                        <div className='CheckBoxParent row mb-4'>
                            <label className="form-label fs-5" >
                                {t("Has spouse/partner ever been hospitalized due to a psychiatric issue?")}
                            </label>
                            <div className="col-md-3">
                                <div className="form-check">
                                    <input onChange={(e) => radioInputChange(e, true)}
                                        className="form-check-input" type="radio"
                                        name="spouse_ever_hospitalized"
                                        requierd
                                        checked={formData.spouse_ever_hospitalized == true}
                                    />
                                    <label className="form-check-label LabelSubHeading" >
                                        {t("Yes")}
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-3">  <div className="form-check">
                                <input onChange={(e) => {
                                    setFormData(pre => {
                                        radioInputChange(e, false)
                                        return {
                                            ...pre,
                                            spouse_hospitalized_circumstances: ''
                                        }
                                    })
                                }}
                                    className="form-check-input" type="radio"
                                    name="spouse_ever_hospitalized"


                                    checked={formData.spouse_ever_hospitalized === false}
                                />
                                <label className="form-check-label LabelSubHeading" >
                                    {t("No")}
                                </label>
                            </div>
                            </div>
                        </div>
                        {formData.spouse_ever_hospitalized !== '' ? formData.spouse_ever_hospitalized ? (

                            <div className='row mb-4'>
                                <div className=" col-md-12 ">
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-5" >{t("Please list dates and circumstances")}</label>

                                    <textarea
                                        rows={5}

                                        className='form-control'
                                        placeholder=''
                                        name='spouse_hospitalized_circumstances'
                                        value={formData.spouse_hospitalized_circumstances}
                                        onChange={handleInputChange}
                                    />

                                </div>

                            </div>
                        ) : '' : ''}
                        <div className="ButtonForm mt-3">
                            <button className="btn_non_fill" type="button" onClick={navigation.previous}>{t('Previous')}</button>
                            <button className="btn_fill" type="submit" >{t('Next')}</button>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}
