import React, { useState } from 'react'
import style from './Loader.module.css'
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';

const Loader = ({ open }) => {
  return (
    <>
      <Dialog closable={false} headerClassName='d-none' contentStyle={{ backgroundColor: "transparent" }} className='p-0 shadow-none' visible={open}>
        <ProgressSpinner strokeWidth="3" />
      </Dialog>

    </>
  )
}

export default Loader