import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
// import { ForgettDialog } from './ForgettDialog'
import "./surrogateMother.css";
import { useTranslation } from "react-i18next";
import { LogInUser } from "../../../service/Apis";
import { useLocation, useNavigate, useParams } from "react-router";
import Loader from "../../../components/Loader/Loader";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { PrimeIcons } from "primereact/api";
import Toaster from "../../../components/Toaster";

export const SurrogateSignIn = () => {
  let { t } = useTranslation();
  const navigate = useNavigate();
  const role = useLocation().state;
  const [loginData, setLogInData] = useState({
    email: "",
    password: "",
  });

  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });

  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  let [captchaValue, setCaptchaValue] = useState(true); //please set this value to null after testing the code on local
  const [isLoading, setIsLoading] = useState(false);
  const [emailerr1, setEmailerr1] = useState();
  const [emailerr, setEmailerr] = useState();
  const [visible, setVisible] = useState(false);
  const [openDialog, setOpenDialog] = useState(true);

  let languageCode = localStorage.getItem("en");
  const [cards, setCards] = useState([
    {
      title: "Surrogate Mother",
      image: "third_image_of_card.png",
      navlink: "/surrogateMother/signUp/surrogate_donor",
    },
    {
      title: "Intended Parents",
      image: "first_image_of_card.png",
      navlink: "/intendedparents/signUp/parents",
    }, {
      title: "Embryo Donor",
      image: "surrogate_card_image_five.png",
      navlink: "/embryodonor/signUp/embryo_donor",
    }, {
      title: "Adopting Parents",
      image: "Adopting_Parent(role).png",
      navlink: "/adoptingparent/signUp/adopting_parent"
    }
  ]);
  let arr2 = [0, 1, 2, 3];
  function captchaChange(e) {
    setCaptchaValue(e);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (!captchaValue) {
      // alert(t("Please complete the reCAPTCHA verification."));
      setToaster({
        show: true,
        message: "Please complete the reCAPTCHA verification.",
      });
      return;
    }
    let payload = {
      email: loginData.email.toLocaleLowerCase(),
      password: loginData.password,
    };
    setLoader(true);
    try {
      const res = await LogInUser(payload);
      console.log("this is the response catch from login ", res)
      // return
      if (res?.response?.data) {
        if (
          res?.response?.data.errormessage.message.includes(
            "enter correct password"
          )
        ) {
          setEmailerr(t("Please enter correct password"));
          setLoader(false);
        }
        if (
          res?.response?.data.errormessage.message.includes(
            "enter correct email id"
          )
        ) {
          setEmailerr1(t("Please enter correct email id"));
          setLoader(false);
        }
        if (
          res?.response?.data.errormessage.message.includes(
            "Please Verify your email"
          )
        ) {
          // alert(t("Please Verify your email"));
          setToaster({
            show: true,
            message: "Please Verify your email",
          });
          setLoader(false);
        }
        if (
          res?.response?.data.errormessage.message.includes(
            "Your account is blocked. Please contact the help center for assistance."
          )
        ) {
          // alert(t("Please Verify your email"));
          setToaster({
            show: true,
            message: "Your account is blocked. Please contact the help center for assistance.",
          });
          setLoader(false);
        }

        return;
      }


      localStorage.setItem("userData", JSON.stringify(res.data.data));


      if (res?.data?.data.role === "parents") {

        if (res.data.data.resuma_complete) {
          window.location.href = `https://dashboard.newhopepoints.org/intendedParent/dashboard?token=${encodeURIComponent(
            res.data.data.token
          )}&id=${encodeURIComponent(res.data.data.userId)}&role=${encodeURIComponent(res.data.data.role)}`;

        } else {
          navigate(`/intendedparents/signUp/success`);
        } 
        return;
      }

      if (res?.data?.data.role === "surrogate_donor") {
        if (res.data.data.concise_resuma_complete) {
          // window.location.href = `http://localhost:3000/surrogate/dashboard_?token=${encodeURIComponent(res.data.data.token)}&email=${encodeURIComponent(res.data.data.email)}&id=${encodeURIComponent(res.data.data.userId)}`
          window.location.href = `https://dashboard.newhopepoints.org/surrogate/dashboard_?token=${encodeURIComponent(
            res.data.data.token
          )}&email=${encodeURIComponent(
            res.data.data.email
          )}&id=${encodeURIComponent(res.data.data.userId)}`;
        } else {
          if (+res.data.data.concise_resuma_step == 1) {
            navigate(`/surrogateMother/signUp/success`);
          } else {
            navigate(
              `/surrogateMother/primaryResume/${res.data.data.userId}/step${res.data.data.concise_resuma_step}`
            );
          }
        }
        return;
      }

      if (res?.data?.data.role === "lawyer") {
        window.location.href = `https://dashboard.newhopepoints.org/lawyer/dashboard?token=${encodeURIComponent(
          res.data.data.token
        )}&id=${encodeURIComponent(res.data.data.userId)}`;
        return;
      }

      if (res?.data?.data.role === "clinic") {
        window.location.href = `https://dashboard.newhopepoints.org/ivfclinic/dashboard?token=${encodeURIComponent(
          res.data.data.token
        )}&id=${encodeURIComponent(res.data.data.userId)}`;
        return;
      }

      if (res?.data?.data.role === "embryo_donor") {

        if (res.data.data.resuma_complete) {
          window.location.href = `https://dashboard.newhopepoints.org/embryo_donor/dashboard?token=${encodeURIComponent(
            res.data.data.token
          )}&id=${encodeURIComponent(res.data.data.userId)}`;

        } else {
          if (+res.data.data.resuma_step == 1) {
            navigate(`/embryodonor/signUp/success`);
          } else {
            navigate(
              `/embryodonor/bioFather/${res.data.data.userId}`
            );
          }
        }

        return;
      }

      if (res?.data?.data.role === "adopting_parent") {

        if (res.data.data.resuma_complete) {
          window.location.href = `https://dashboard.newhopepoints.org/intendedParent/dashboard?token=${encodeURIComponent(
            res.data.data.token
          )}&id=${encodeURIComponent(res.data.data.userId)}&role=${encodeURIComponent(res.data.data.role)}`;

        } else {
          navigate(`/adoptingparent/signUp/success`);
        }
        return;
      }


      setLogInData({
        email: "",
        password: "",
      });
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  }
  const backLink = () => {
    if (role === "parents") {
      return "/intendedparents";
    } else if (role === "surrogate_donor") {
      return "/surrogateMother";
    } else if (role === "lawyer") {
      return;
    } else if (role === "clinic") {
      return "/ivfclinic";
    } else if (role === "adopting_parent") {
      return "/adoptingparent";
    } else if (role === "embryo_donor") {
      return "/embryodonor";
    }
    else if (role === "all") {
      return -1;
    } else {
      return "/";
    }
  };
  return (
    <>
      <Toaster
        event={toaster.message}
        show={toaster.show}
        severity={"error"}
        setToaster={setToaster}
      />

      <Loader open={loader} />
      <Dialog
        visible={visible}
        contentClassName="p-lg-5 p-md-4 p-sm-4 p-3 "
        style={{ width: "750px" }}
        breakpoints={{ "960px": "700", "790px": "90vw" }}
        onHide={() => {
          setVisible(false);
        }}
        closable={false}
        headerClassName=""
      >
        <div className="DialogParent">
          <div className="d-flex justify-content-between align-items-center">
            <span className="SignUphedaing">{t("Choose Your Role")}</span>
            <span
              onClick={() => {
                setVisible(false);
                setOpenDialog(true);
              }}
              className="pointer"
            >
              <i class="bi bi-x" style={{ fontSize: "40px" }}></i>
            </span>
          </div>

          <div className="row mt-4">
            {arr2?.map((res, index) => (
              <div
                key={index}
                className="col-12 col-sm-6 p-lg-2 p-md-2 p-sm-2 p-2"
              >
                <Link to={cards[res].navlink} className={"navLink"}>
                  <div className="card">
                    <img
                      className="card-img-top"
                      src={`/images/${cards[res].image}`}
                      alt={t("Card image cap")}
                    />
                    <div className="card-body">
                      <div className="lufga_18px">{t(cards[res].title)}</div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center mt-4">
            <p className="fs-5">
              <span className="font_web">{t("Already have an account?")}</span>{" "}
              <span
                className="TermColor pointer fs-5"
                onClick={() => {
                  setOpenDialog(true);
                  setVisible(false);
                }}
              >
                {t("Sign in")}
              </span>
            </p>
          </div>
        </div>
      </Dialog>


      <Dialog
        closable={false}
        visible={openDialog}
        onHide={() => {
          setOpenDialog(false);
        }}
        contentClassName="p-md-4 p-sm-4 p-3"
        style={{ width: "750px", background: "red" }}
        breakpoints={{ "960px": "700", "641px": "90vw" }}
      >
        <div className="DialogParent">
          {/* {isLoading ? (
            <div className="bg-none flex justify-content-center">
              <ProgressSpinner />
            </div>
          ) : ( */}
          <div className="" style={{ overflow: "hidden" }}>
            <form onSubmit={handleSubmit}>
              <div className="d-flex justify-content-center pb-2">
                <span className="SignUphedaing">{t("Sign in")}</span>
              </div>
              <div className="row mb-3 px-1">
                <div className="col-md-12">
                  <label htmlFor="email" className="form-label fs-5">
                    {t("Email Address")}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder={t("Enter Your Email")}
                    value={loginData.email}
                    onChange={(e) => {
                      if (e.target.value == " ") {
                        e.target.value = "";
                      } else {
                        setLogInData((pre) => {
                          return { ...pre, [e.target.name]: e.target.value };
                        });
                        setEmailerr("");
                        setEmailerr1("");
                      }
                    }}
                    required
                  />
                  {emailerr1 && <div className="text-danger">{emailerr1}</div>}
                </div>
              </div>
              <div className="row mb-md-3 mb-sm-2 mb-1 px-1">
                <div className=" col-md-12">
                  <label htmlFor="password" className="form-label fs-5">
                    {t("Password")}
                  </label>
                  <div className="p-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      name="password"
                      placeholder={t("Enter Password")}
                      value={loginData.password}
                      onChange={(e) => {
                        if (e.target.value == " ") {
                          e.target.value = "";
                        } else {
                          setLogInData((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                          setEmailerr("");
                          setEmailerr1("");
                        }
                      }}
                      required
                    />
                    {!showPassword ? (
                      <i
                        className="bi bi-eye-slash View pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-eye View pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      ></i>
                    )}
                  </div>
                  {emailerr && <div className="text-danger">{emailerr}</div>}
                </div>
              </div>

              <div className="d-flex justify-content-between flex-lg-row flex-md-row flex-sm-row flex-column px-1">
                <div
                  className="ForgetPassword fs-5 d-flex justify-content-lg-start justify-content-md-start justify-content-sm-start justify-content-end pointer"
                  onClick={() =>
                    navigate(`/surrogateMother/forgetPassword/${role}`)
                  }
                >
                  {t("Forgot Password")}
                </div>
                {console.log(role)}
                {(role === "surrogate_donor" || role === "parents") && (
                  <>
                    <div className="d-flex justify-content-between align-items-center flex-lg-row flex-md-row flex-sm-row flex-column">
                      <span className="text-black fs-5">
                        {t("Don't have any account?")}
                      </span>
                      <span
                        className="Create fs-5 pointer"
                        onClick={() =>
                          role === "surrogate_donor"
                            ? navigate(
                              `/surrogateMother/signUp/surrogate_donor`
                            )
                            : navigate(`/intendedparents/signUp/parents`)
                        }
                      >
                        {t("Create New")}
                      </span>
                    </div>
                  </>
                )}

                {role === "all" && (
                  <>
                    <div className="d-flex justify-content-between align-items-center flex-lg-row flex-md-row flex-sm-row flex-column">
                      <span className="text-black fs-5">
                        {t("Don't have any account?")}
                      </span>
                      <span
                        className="Create fs-5 pointer"
                        onClick={
                          () => {
                            setOpenDialog(false);
                            setVisible(true);
                          }
                          // navigate(`/surrogateMother/signUp/${role}`)
                        }
                      >
                        {t("Create New")}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="mt-3 d-flex px-1">
                <div>
                  <ReCAPTCHA
                    sitekey={"6LfNdI4pAAAAALEDG_OG1aDvRqK_f8_ye513OfRY"}
                    onChange={captchaChange}
                  />
                </div>
              </div>
              <div className="ButtonForm mt-5 px-1">
                <button
                  className="btn_non_fill"
                  type="button"
                  disabled={isLoading}
                  onClick={() => navigate(backLink())}
                >
                  {t("Cancel")}
                </button>
                <button className="btn_fill" type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <div
                      className="spinner-border text-light"
                      role="status"
                      style={{ width: "1rem", height: "1rem" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    t("Submit")
                  )}
                </button>
              </div>
            </form>
          </div>
          {/* )} */}
        </div>
      </Dialog>
    </>
  );
};
