import React from 'react'
import style from './IntendedParents.module.css'
import { Dialog } from 'primereact/dialog'
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export default function EmailSentSucces() {
    const navigate = useNavigate()
    let { t } = useTranslation();

    return (
        <>
            <Dialog
                closable={false}
                visible={true}
                onHide={() => { }}
                style={{ width: "60vw", border: '3px solid rgba(24, 124, 139, 1)' }}
                contentClassName={`${style.bgImg}`}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div className='py-4'>
                    <div className={`d-flex justify-content-center`}>
                       <img src='/images/Tick.png' style={{width:'120px'}} alt=''/>
                    </div>
                    <div className={`mt-5 d-flex justify-content-center ${style.heading}`}>{t("Congratulations! Check your email to update password")}</div>
                    <div className='mt-4 d-flex justify-content-center'>
                        <button className={`SaveButton`} onClick={()=>navigate(`/signIn`,{state:"parents"})}>{t("Back to Login")}</button>
                    </div>
                </div>


            </Dialog>
        </>
    )
}
