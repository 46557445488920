import React, { useEffect, useState } from 'react'
import { t } from 'i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import moment from 'moment/moment';
import { addFormConcise2 } from '../../../../service/Apis';
import Loader from '../../../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';

export const PrimaryResumeStep2 = () => {
  const navigate = useNavigate()
  let {t}=useTranslation()
  const [loader, setLoader] = useState(false)
  const {id} = useParams()
  const { allData,getData } = useOutletContext()
  const [formData, setFormdata] = useState({
    desired_compensaton: '',
    embryo_willing_to_transfer: '',
    no_of_embryo_transfer_attempts: '',
    want_to_become_surrogate: '',
    what_surrogate_means_to_you: '',
    any_concern_about_surrogacy: '',
    willing_to_work_with_non_english_speaker_ip: '',
    willing_to_reduce_caffeine: '',
    willing_to_eat_organic_food: '',
    type_of_family_want_to_work_with: '',
    NOT_want_work_with_intended_parents: '',
    describe_the_ideal_intended_Parent: '',
    note_future_intended_Parent: '',
    presence_of_ip_during_appointment_and_delivery: '',
    anyone_you_want_during_delivery: '',
    fequency_of_contact_with_ip_after_pregnancy: '',
    contact_with_ip_during_pregnancy: '',
    mode_of_communication_with_ip: '',
    willing_to_pump_breast_milk: '',
    leave_termnation_choice_on_ip_for_birth_defect: '',
    leave_termnation_choice_on_ip_for_threatening_condition: '',
    willing_to_be_placed_on_bedrest: '',
    reassurances_for_ip: '',
    describe_support_system: '',
    child_care_available: '',
    ready_to_begin: ''
  })


  useEffect(() => {
    let obj = {};
    for (let i in formData) {
        if (allData?.hasOwnProperty(i)) {
            obj[i] = allData?.[i]
        }
        else {
            obj[i] = formData[i]
        }
    }
    setFormdata((pre) => ({ ...pre, ...obj }));
  }, [allData])


  function onInputHandleChange(e) {
    let { value, name, type } = e.target
    if (type === 'radio') {
      if (value == 'true' || value == 'false') {
        setFormdata(pre => {
          return {
            ...pre,
            [name]: JSON.parse(value)
          }
        })
      } else {
        setFormdata(pre => {
          return {
            ...pre,
            [name]: value
          }
        })
      }


    } else {
      if (value === ' ') {
        value = ''
      } else {
        if (type === 'number') {
          setFormdata(pre => {
            return {
              ...pre,
              [name]: parseInt(value)
            }
          })
        } else {
          setFormdata(pre => {
            return {
              ...pre,
              [name]: value
            }
          })
        }
      }
    }
  }

  async function submitData(e) {
    e.preventDefault()
    setLoader(true)
    let payload = { ...formData }
    try {
      let res = await addFormConcise2(id,payload)
      console.log(res)
      setLoader(false)
      getData()
      navigate(`/surrogateMother/primaryResume/${id}/profile`)
    } catch (err) {
      setLoader(false)
      console.log(err)
    }
  }




  return (
    <>
      <Loader open={loader} />
      <div className='text-center second_heading mt-lg-4 mt-md-4 mt-sm-4 mt-3'>{t("Surrogacy Information")}</div>

      <form onSubmit={submitData}>
        <div className="row">
          <div className="col-md-6 mt-4 md-md-4 mb">
            <label className="form-label fs-5" >{t("Desired Compensation")} {t('(In $)')}<span className='text-danger'>*</span></label>
            <input
              required
              type='number'
              className='form-control'
              name='desired_compensaton'
              value={formData.desired_compensaton}
              onChange={onInputHandleChange}
            />
          </div>
          <div className="col-md-6 mt-4 md-md-4">
            <label className="form-label fs-5" >{t("Embryos willing to transfer")}<span className='text-danger'>*</span></label>
            <input
              required
              type='number'
              className='form-control'
              name='embryo_willing_to_transfer'
              value={formData.embryo_willing_to_transfer}
              onChange={onInputHandleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <label className="form-label fs-5" >{t("Number of Embryo Transfer Attempts")}<span className='text-danger'>*</span></label>
            <input
              required
              type='number'
              className='form-control'
              name='no_of_embryo_transfer_attempts'
              value={formData.no_of_embryo_transfer_attempts}
              onChange={onInputHandleChange}
            />
          </div>

          <div className="col-md-12 mt-4">
            <label className="form-label fs-5" >{t("Why do you want to become a surrogate? What first interested you in surrogacy")}?<span className='text-danger'>*</span></label>
            <textarea
              rows={5}
              required
              type='text'
              className='form-control'
              name='want_to_become_surrogate'
              value={formData.want_to_become_surrogate}
              onChange={onInputHandleChange}
            />
          </div>
          <div className="col-md-12 mt-4">
            <label className="form-label fs-5" >{t("What does being a surrogate mean to you")}?<span className='text-danger'>*</span></label>
            <textarea
              rows={5}
              required
              type='text'
              className='form-control'
              name='what_surrogate_means_to_you'
              value={formData.what_surrogate_means_to_you}
              onChange={onInputHandleChange}
            />
          </div>

          <div className="col-md-12 mt-4">
            <label className="form-label fs-5" >{t("Do you have any concerns about surrogacy")}?<span className='text-danger'>*</span></label>
            <textarea
              rows={5}
              required
              type='text'
              className='form-control'
              name='any_concern_about_surrogacy'
              value={formData.any_concern_about_surrogacy}
              onChange={onInputHandleChange}
            />
          </div>
          <div className="col-md-12 mt-4">
            <label className="form-label fs-5" >{t("Are you willing to work with Intended Parent(s) that does not speak English (translation provided)")}?<span className='text-danger'>*</span></label>
            <textarea
              rows={5}
              required
              type='text'
              className='form-control'
              name='willing_to_work_with_non_english_speaker_ip'
              value={formData.willing_to_work_with_non_english_speaker_ip}
              onChange={onInputHandleChange}
            />
          </div>


          <div className="row col-12 mt-4">
            <label className="form-label fs-5">{t("Are you willing to reduce caffeine during pregnancy")}?</label>

            <div className="col-md-6">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  checked={formData.willing_to_reduce_caffeine === true}
                  name='willing_to_reduce_caffeine'
                  value='true'
                  onChange={onInputHandleChange}
                />
                <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  checked={formData.willing_to_reduce_caffeine === false}
                  name='willing_to_reduce_caffeine'
                  value='false'
                  onChange={onInputHandleChange}
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
              </div>
            </div>
          </div>
          <div className="row col-12 mt-4">
            <label className="form-label fs-5">{t("Are you willing to eat organic foods, if requested (and paid for) by the Intended Parent(s)")}?</label>

            <div className="col-md-6">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  checked={formData.willing_to_eat_organic_food === true}
                  name='willing_to_eat_organic_food'
                  onChange={onInputHandleChange}
                  value='true'
                />
                <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='willing_to_eat_organic_food'
                  checked={formData.willing_to_eat_organic_food === false}
                  onChange={onInputHandleChange}
                  value='false'
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
              </div>
            </div>
          </div>


          <div className="col-md-12 mt-4">
            <label className="form-label fs-5" >{t("What type of family would you like to work with")}?<span className='text-danger'>*</span></label>
            <textarea
              rows={5}
              required
              type='text'
              className='form-control'
              name='type_of_family_want_to_work_with'
              value={formData.type_of_family_want_to_work_with}
              onChange={onInputHandleChange}
            />
          </div>
          <div className="col-md-12 mt-4">
            <label className="form-label fs-5" >{t("Is there anyone you are not comfortable working with")}?<span className='text-danger'>*</span></label>
            <textarea
              rows={5}
              required
              type='text'
              className='form-control'
              name='NOT_want_work_with_intended_parents'
              value={formData.NOT_want_work_with_intended_parents}
              onChange={onInputHandleChange}
            />
          </div>
          <div className="col-md-12 mt-4">
            <label className="form-label fs-5" >{t("Describe your ideal Intended Parent(s)")}<span className='text-danger'>*</span></label>
            <textarea
              rows={5}
              required
              type='text'
              className='form-control'
              name='describe_the_ideal_intended_Parent'
              value={formData.describe_the_ideal_intended_Parent}
              onChange={onInputHandleChange}
            />
          </div>
          <div className="col-md-12 mt-4">
            <label className="form-label fs-5" >{t("What would you like your future Intended Parent(s) to know about you")}?<span className='text-danger'>*</span></label>
            <textarea
              rows={5}
              required
              type='text'
              className='form-control'
              name='note_future_intended_Parent'
              value={formData.note_future_intended_Parent}
              onChange={onInputHandleChange}
            />
          </div>


          <div className="row col-12 mt-4">
            <label className="form-label fs-5">{t("Are you willing to have the Intended Parent(s) present during doctors appointments and the delivery")}?<span className='text-danger'>*</span></label>

            <div className="col-md-6">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  name='presence_of_ip_during_appointment_and_delivery'
                  type="radio"
                  checked={formData.presence_of_ip_during_appointment_and_delivery === true}
                  onChange={onInputHandleChange}
                  value='true'
                />
                <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='presence_of_ip_during_appointment_and_delivery'
                  checked={formData.presence_of_ip_during_appointment_and_delivery === false}
                  onChange={onInputHandleChange}
                  value='false'
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-4">
            <label className="form-label fs-5" >{t("Is there anyone you would like present during the delivery (example: Spouse/Partner, Mother, Sister or Friend)")}?<span className='text-danger'>*</span></label>
            <textarea
              rows={5}
              required
              type='text'
              className='form-control'
              name='anyone_you_want_during_delivery'
              value={formData.anyone_you_want_during_delivery}
              onChange={onInputHandleChange}
            />
          </div>

          <div className="row col-12 mt-4">
            <label className="form-label fs-5">{t("How often would you like to be in contact with the Intended Parent(s) during the pregnancy")}?<span className='text-danger'>*</span></label>

            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  name='contact_with_ip_during_pregnancy'
                  type="radio"
                  value='Couple times a year'
                  checked={formData.contact_with_ip_during_pregnancy === 'Couple times a year'}
                  onChange={onInputHandleChange}
                />
                <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Couple times a year")}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='contact_with_ip_during_pregnancy'
                  value='Once a month'
                  checked={formData.contact_with_ip_during_pregnancy === 'Once a month'}
                  onChange={onInputHandleChange}
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("Once a month")}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='contact_with_ip_during_pregnancy'
                  value='Not allowed'
                  checked={formData.contact_with_ip_during_pregnancy === 'Not allowed'}
                  onChange={onInputHandleChange}
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("Not allowed")}</label>
              </div>
            </div>
          </div>






          <div className="row col-12 mt-4">
            <label className="form-label fs-5">{t("How often would you like to be in contact with the Intended Parent(s) after the delivery")}?<span className='text-danger'>*</span></label>

            <div className="col-md-3">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='fequency_of_contact_with_ip_after_pregnancy'
                  value='As needed'
                  checked={formData.fequency_of_contact_with_ip_after_pregnancy === 'As needed'}
                  onChange={onInputHandleChange}
                />
                <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("As needed")}</label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='fequency_of_contact_with_ip_after_pregnancy'
                  value='Couple times a year'
                  checked={formData.fequency_of_contact_with_ip_after_pregnancy === 'Couple times a year'}
                  onChange={onInputHandleChange}
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("Couple times a year")}</label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='fequency_of_contact_with_ip_after_pregnancy'
                  value='Once a month'
                  checked={formData.fequency_of_contact_with_ip_after_pregnancy === 'Once a month'}
                  onChange={onInputHandleChange}
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("Once a month")}</label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='fequency_of_contact_with_ip_after_pregnancy'
                  value='Not willing to'
                  checked={formData.fequency_of_contact_with_ip_after_pregnancy === 'Not willing to'}
                  onChange={onInputHandleChange}
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("Not willing to")}</label>
              </div>
            </div>
          </div>


          <div className="row col-12 mt-4">
            <label className="form-label fs-5">{t("How would you like to be in contact with the Intended Parent(s)")}?<span className='text-danger'>*</span></label>

            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  name='mode_of_communication_with_ip'
                  type="radio"
                  value='Online meeting only'
                  checked={formData.mode_of_communication_with_ip === 'Online meeting only'}
                  onChange={onInputHandleChange}

                />
                <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Online meeting only")}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='mode_of_communication_with_ip'
                  value='Face to face'
                  checked={formData.mode_of_communication_with_ip === 'Face to face'}
                  onChange={onInputHandleChange}
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("Face to face")}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='mode_of_communication_with_ip'
                  value='Phone call only'
                  checked={formData.mode_of_communication_with_ip === 'Phone call only'}
                  onChange={onInputHandleChange}
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("Phone call only")}</label>
              </div>
            </div>
          </div>


          <div className="row col-12 mt-4">
            <label className="form-label fs-5">{t("Are you willing to pump breast milk (compensation available)")}?<span className='text-danger'>*</span></label>

            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  name='willing_to_pump_breast_milk'
                  type="radio"
                  checked={formData.willing_to_pump_breast_milk === true}
                  onChange={onInputHandleChange}
                  value='true'
                />
                <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='willing_to_pump_breast_milk'
                  checked={formData.willing_to_pump_breast_milk === false}
                  onChange={onInputHandleChange}
                  value='false'
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
              </div>
            </div>

          </div>





          <div className="row col-12 mt-4">
            <label className="form-label fs-5">{t("Are you willing to leave termination choices up to the Intended Parent(s) in the case of a serious birth defect")}?<span className='text-danger'>*</span></label>

            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  name='leave_termnation_choice_on_ip_for_birth_defect'
                  type="radio"
                  checked={formData.leave_termnation_choice_on_ip_for_birth_defect === true}
                  onChange={onInputHandleChange}
                  value='true'
                />
                <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='leave_termnation_choice_on_ip_for_birth_defect'
                  checked={formData.leave_termnation_choice_on_ip_for_birth_defect === false}
                  onChange={onInputHandleChange}
                  value='false'
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
              </div>
            </div>

          </div>




          <div className="row col-12 mt-4">
            <label className="form-label fs-5">{t("Are you willing to leave termination choices up to the Intended Parent(s) in the case of a non-life threatening condition")}?<span className='text-danger'>*</span></label>

            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  name='leave_termnation_choice_on_ip_for_threatening_condition'
                  type="radio"
                  checked={formData.leave_termnation_choice_on_ip_for_threatening_condition === true}
                  onChange={onInputHandleChange}
                  value='true'
                />
                <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='leave_termnation_choice_on_ip_for_threatening_condition'
                  checked={formData.leave_termnation_choice_on_ip_for_threatening_condition === false}
                  onChange={onInputHandleChange}
                  value='false'
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
              </div>
            </div>

          </div>



          <div className="row col-12 mt-4">
            <label className="form-label fs-5">{t("If recommended by a doctor are you willing to be placed on bedrest")}?<span className='text-danger'>*</span></label>

            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  name='willing_to_be_placed_on_bedrest'
                  type="radio"
                  checked={formData.willing_to_be_placed_on_bedrest === true}
                  onChange={onInputHandleChange}
                  value='true'
                />
                <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='willing_to_be_placed_on_bedrest'
                  checked={formData.willing_to_be_placed_on_bedrest === false}
                  onChange={onInputHandleChange}
                  value='false'
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
              </div>
            </div>

          </div>


          <div className="col-md-12 mt-4 md-sm-4">
            <label className="form-label fs-5" >{t("What reassurance can you give your Intended Parent(s) that you will not change your mind")}<span className='text-danger'>*</span></label>
            <textarea
              rows={5}
              required
              type='text'
              className='form-control'
              name='reassurances_for_ip'
              value={formData.reassurances_for_ip}
              onChange={onInputHandleChange}
            />
          </div>
          <div className="col-md-12 mt-4 md-sm-4">
            <label className="form-label fs-5" >{t("Please describe your support system and who your support system is")}:<span className='text-danger'>*</span></label>
            <textarea
              rows={5}
              required
              type='text'
              className='form-control'
              name='describe_support_system'
              value={formData.describe_support_system}
              onChange={onInputHandleChange}
            />
          </div>






          <div className="row col-12 mt-4">
            <label className="form-label fs-5">{t("Do you have child care available")}?<span className='text-danger'>*</span></label>

            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  name='child_care_available'
                  type="radio"
                  checked={formData.child_care_available === true}
                  onChange={onInputHandleChange}
                  value='true'
                />
                <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-check">
                <input
                  required
                  className="form-check-input"
                  type="radio"
                  name='child_care_available'
                  checked={formData.child_care_available === false}
                  onChange={onInputHandleChange}
                  value='false'
                />
                <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
              </div>
            </div>

          </div>



          <div className="col-md-12 mt-4 md-sm-4">
            <label className="form-label fs-5" >{t("When are you ready to begin")}:<span className='text-danger'>*</span></label>
            <input
              required
              type='date'
              className='form-control'
              min={moment().format('YYYY-MM-DD')}
              name='ready_to_begin'
              value={moment(formData.ready_to_begin).format('YYYY-MM-DD')}
              onChange={onInputHandleChange}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end flex-md-row flex-sm-row flex-column-reverse mt-5 align-items-center gap-md-0 gap-sm-0 gap-3">
          <button className='btn_non_fill' type='button' onClick={() => navigate(`/surrogateMother/primaryResume/${id}/step1`)}>{t("Previous")}</button>
          <button className='btn_fill' type='submit' >{t("Generate Profile with Photos")}</button>
        </div>
      </form>
    </>
  )
}
