import React, { useEffect, useState } from 'react'
import './ResumeSteps.css'
import { useTranslation } from "react-i18next";
import { useOutletContext } from 'react-router';
import { resumeForm3 } from '../../../../service/Apis';
import { userId } from '../../../../service/userData';
import { resumeAllKeys } from './resumeKeys';
import Loader from '../../../../components/Loader/Loader';
export default function ResumeStep3() {

    let { t } = useTranslation();
    let { navigation, data } = useOutletContext()
    const [loader, setLoader] = useState(false)
    let step3Keys = resumeAllKeys.step3;
    // useEffect(() => {
    //     let obj = {}
    //     for (let i in step3Keys) {
    //         if (data.resumeData[i]) {
    //             obj[i] = data?.resumeData?.[i] || step3Keys[i]
    //         }
    //     }
    //     console.log(obj)
    //     setFormData(pre => ({ ...pre, ...obj }));
    // }, [data.resumeData])


    useEffect(() => {
        let obj = {};
        for (let i in step3Keys) {
            if (data.resumeData.hasOwnProperty(i)) {
                obj[i] = data?.resumeData?.[i]
            }
            else {
                obj[i] = step3Keys[i]
            }
        }
        console.log(obj)
        setFormData((pre) => ({ ...pre, ...obj }));
    }, [data.resumeData]);


    const [formData, setFormData] = useState(
        {
            base_compensation: '',
            mock_or_cancelled_cycle_fee: '',
            med_start_fee: '',
            embryo_transfer_fee: '',
            monthly_allowance: '',
            multiple_fee: '',
            invasive_procedure_fee: '',
            childCare_perHour: '',
            houseKeeping_perWeek: '',
            c_section_fee: '',
            multiples: '',
            singleton: '',
            partial_hysterectomy: '',
            full_hysterectomy: '',
            time_off_requested_after_vaginal_delivery: '',
            time_off_requested_after_cSection_delivery: '',
            time_off_pregnancy_post_delivery_recovery: ''
        }
    )
    function handleInputChange(event) {
        const { name, value } = event.target;
        // const parsedValue = value.trim() !== '' ? parseFloat(value) : '';
        setFormData(prevState => ({
            ...prevState,
            [name]: parseInt(value)
        }));
    }



    async function formSubmit3(e) {
        e.preventDefault()

        setLoader(true)
        try {
            let userID = userId();
            let data = { ...formData }
            await resumeForm3(userID, data);
            setLoader(false)
            navigation.next()

        }
        catch (err) {
            setLoader(false)
            console.log(err)
        }

    }
    return (
        <>
            <Loader open={loader} />
            <div className='ResumeStep_con'>
                <div >
                    <h1 className='SubHeading mb-lg-5 mb-md-5 mb-sm-4 mb-4'>{t("Compensation / Benefits Information")}</h1>
                </div>
                <div className='mb-5'>
                    <p className='ParaSub'>
                        {t("Please let us know what you will be requesting for your compensation package.The fields below are pre-populated with “average” values requested by  our surrogates, but you are free to alter any of these numbers to fit your needs and personal comfort level.")}
                    </p>
                </div>
                <form onSubmit={formSubmit3}>
                    <div className='row'>
                        <div className=" col-md-6  mb-lg-0 mb-md-0 mb-sm-4 mb-4 d-flex flex-column">
                            <label className="form-label fs-5" >{t("Base Compensation")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control mt-auto"
                                placeholder={t("")}
                                required
                                name='base_compensation'
                                value={formData.base_compensation}
                                onChange={handleInputChange}
                            />

                            <span className='hidetext1 mt-2'>{t("May be adjusted based on state of residence and insurance coverage.")}</span>

                        </div>


                        <div className=" col-md-6 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                            <label className="form-label fs-5" >{t("Medicated Mock Cycle or Cancelled Cycle Fee")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control"
                                placeholder=""
                                required
                                name='mock_or_cancelled_cycle_fee'
                                value={formData.mock_or_cancelled_cycle_fee}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className='row hidetext2 mb-4 mt-2'>
                        <div className='col-md-6'>
                            <span className=''>{t("May be adjusted based on state of residence and insurance coverage.")}</span>
                        </div>
                    </div>
                    <div className=' row mb-4'>
                        <div className=" col-md-6  mb-lg-0 mb-md-0 mb-sm-4 mb-4 d-flex flex-column">
                            <label className="form-label fs-5" >{t("Med Start Fee")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control mt-auto"
                                placeholder={t("")}
                                required
                                name='med_start_fee'
                                value={formData.med_start_fee}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className=" col-md-6 ">
                            <label className="form-label fs-5" >{t("Embryo Transfer Fee (per transfer)")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control"
                                placeholder={t("")}
                                required
                                name='embryo_transfer_fee'
                                value={formData.embryo_transfer_fee}
                                onChange={handleInputChange} />

                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className=" col-md-6  mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                            <label className="form-label fs-5" >{t("Monthly Allowance (to begin at start of medications)")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control"
                                placeholder={t("")}
                                required
                                name='monthly_allowance'
                                value={formData.monthly_allowance}
                                onChange={handleInputChange}
                            />

                        </div>
                        <div className="col-md-6 d-flex flex-column">
                            <label className="form-label fs-5" >{t("Multiple Fee (per additional fetus)")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control mt-auto"
                                placeholder={t("")}
                                required
                                name='multiple_fee'
                                value={formData.multiple_fee}
                                onChange={handleInputChange}
                            />

                        </div>
                    </div>
                    <div className=' row mb-4'>
                        <div className=" col-md-6  mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                            <label className="form-label fs-5" >{t("Invasive Procedure Fee (per invasive procedure)")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control"
                                placeholder={t("")}
                                required
                                name='invasive_procedure_fee'
                                value={formData.invasive_procedure_fee}
                                onChange={handleInputChange}
                            />


                        </div>
                        <div className=" col-md-6 d-flex flex-column">
                            <label className="form-label fs-5" >{t("Childcare (per hour)")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control mt-auto"
                                placeholder={t("")}
                                required
                                name='childCare_perHour'
                                value={formData.childCare_perHour}
                                onChange={handleInputChange}

                            />


                        </div>
                    </div>
                    <div className=' row mb-4'>
                        <div className=" col-md-6  mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                            <label className="form-label fs-5" >{t("Housekeeping (when medically necessary) (cost/week)")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder={t("")}
                                required
                                name='houseKeeping_perWeek'
                                value={formData.houseKeeping_perWeek}
                                onChange={handleInputChange}

                            />


                        </div>
                        <div className=" col-md-6 d-flex flex-column">
                            <label className="form-label fs-5" >{t("C-section Fee")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control mt-auto"
                                placeholder={t("")}
                                required
                                name='c_section_fee'
                                value={formData.c_section_fee}
                                onChange={handleInputChange}

                            />


                        </div>
                    </div>

                    <div className='InputMiddleHaeding mb-4 fs-4'>
                        {t("Maternity Clothing Allowance (paid at 14 weeks)")}
                    </div>

                    <div className=' row mb-4'>
                        <div className=" col-md-6  mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                            <label className="form-label fs-5" >{t("Singleton")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control"
                                placeholder={t("")}
                                required
                                name='singleton'
                                value={formData.singleton}
                                onChange={handleInputChange}

                            />


                        </div>
                        <div className=" col-md-6 ">
                            <label className="form-label fs-5" >{t("Multiples")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control"
                                placeholder={t("")}
                                required
                                name='multiples'
                                value={formData.multiples}
                                onChange={handleInputChange}

                            />

                        </div>
                    </div>
                    <div className='InputMiddleHaeding  mb-4 fs-4'>
                        {t("Loss of Reproductive Organs Fee")}
                    </div>

                    <div className=' row mb-4'>
                        <div className=" col-md-6  mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                            <label className="form-label fs-5" >{t("Partial Hysterectomy")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control"
                                placeholder={t("")}
                                required
                                name='partial_hysterectomy'
                                value={formData.partial_hysterectomy}
                                onChange={handleInputChange}

                            />

                        </div>
                        <div className=" col-md-6 ">
                            <label className="form-label fs-5" >{t("Full Hysterectomy")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control"
                                placeholder={t("")}
                                required
                                name='full_hysterectomy'
                                value={formData.full_hysterectomy}
                                onChange={handleInputChange}

                            />

                        </div>
                    </div>
                    <div className='InputMiddleHaeding mb-4 fs-4'>
                        {t("Lost wages for Surrogate")}
                    </div>

                    <div className=' row mb-4'>
                        <div className=" col-md-6  mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                            <label className="form-label fs-5" >{t("Time off requested after vaginal delivery")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control"
                                placeholder={t("")}
                                required
                                name='time_off_requested_after_vaginal_delivery'
                                value={formData.time_off_requested_after_vaginal_delivery}
                                onChange={handleInputChange}

                            />

                        </div>
                        <div className=" col-md-6 ">
                            <label className="form-label fs-5" >{t("Time off requested after C-section delivery")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control"
                                placeholder={t("")}
                                required
                                name='time_off_requested_after_cSection_delivery'
                                value={formData.time_off_requested_after_cSection_delivery}
                                onChange={handleInputChange}

                            />

                        </div>

                    </div>
                    <div className='InputMiddleHaeding mb-4 fs-4'>
                        {t("Lost wages for Spouse")}
                    </div>
                    <div className=' row mb-4'>
                        <div className=" col-md-12 ">
                            <label className="form-label fs-5" >{t("Time off requested for spouse from pregnancy confirmation through post-delivery recovery.")}{t('(In $)')}<span className='text-danger'>*</span></label>
                            <input type="number"
                                className="form-control"
                                placeholder={t("")}
                                required
                                name='time_off_pregnancy_post_delivery_recovery'
                                value={formData.time_off_pregnancy_post_delivery_recovery}
                                onChange={handleInputChange}

                            />

                        </div>
                    </div>

                    <div className="ButtonForm mt-3">

                        <button className="btn_non_fill" type="button" onClick={navigation.previous}>{t('Previous')}</button>
                        <button className="btn_fill" type="submit">{t('Next')}</button>
                    </div>
                </form>
            </div>

        </>
    )
}
