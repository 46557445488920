import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import { ForgettDialog } from './ForgettDialog'
import './SignUp.css'
import { useTranslation } from "react-i18next"
import { LogInUser } from '../../../service/Apis'
import { json, useNavigate } from 'react-router'
import { ProgressSpinner } from 'primereact/progressspinner';

export const SignIn = ({ signIn, setSignIn }) => {
  let { t } = useTranslation()
  const navigate = useNavigate()
  const [loginData, setLogInData] = useState({
    email: '',
    password: ''
  });

  const [forget, setForget] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailerr1, setEmailerr1] = useState()
  const [emailerr, setEmailerr] = useState()
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });


  function forgetPassword() {
    setForget(true);
    setSignIn(false);
  }

  const validateForm = () => {
    let valid = true;
    const newErrors = { email: '', password: '' };

    if (!loginData.email) {
      newErrors.email = t('Email is required');
      valid = false;
    }

    if (!loginData.password) {
      newErrors.password = t('Password is required');
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true)
    try {

      const res = await LogInUser(loginData);
      console.log(res);

      localStorage.setItem("userData", JSON.stringify(res.data.data))

      if (res?.data?.data.role === 'surrogate_donor') {
        window.location.href = `https://dashboard.newhopepoints.org/surrogate/dashboard?token=${encodeURIComponent(res.data.data.token)}&email=${encodeURIComponent(res.data.data.email)}&id=${encodeURIComponent(res.data.data.userId)}`
        return;
      }
      else if(res?.data?.data.role === 'clinic'){
        window.location.href = `https://dashboard.newhopepoints.org/ivfclinic/dashboard?token=${encodeURIComponent(res.data.data.token)}&email=${encodeURIComponent(res.data.data.email)}&id=${encodeURIComponent(res.data.data.userId)}`
        return ;
      }
      setLogInData({
        email: '',
        password: ''
      });
    } catch (error) {
      // console.error(error.response.data.errormessage);
      setEmailerr(error?.response?.data.errormessage.message)
      // if (error?.response?.data.errormessage.message.includes("enter correct password")) {
      //   setEmailerr("Please enter correct password")

      // }
      // if (error?.response?.data.errormessage.message.includes("enter correct email id")) {
      //   setEmailerr1("Please enter correct email id")

      // }

    }
    setIsLoading(false)

  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLogInData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };


  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <ForgettDialog forget={forget} setForget={setForget} />
      <Dialog
        closable={false}
        visible={signIn}
        onHide={() => setSignIn(false)}
        style={{ width: "60vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="DialogParent">
          {isLoading ? (
            <div className="bg-none flex justify-content-center">
              <ProgressSpinner />
            </div>
          ) : (
            <div className="" style={{ overflow: "hidden" }}>
              <form onSubmit={handleSubmit}>
                <span className="SignUphedaing text-center ">{t('Sign in')}</span>
                <div className="row mb-3">
                  <div className=" col-md-12">
                    <label htmlFor="email" className="form-label">
                      {t('Email Address')}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder={t('Enter Your Email')}
                      value={loginData.email}
                      onChange={handleInputChange}
                      required
                    />


                  </div>
                </div>
                {emailerr1 && <div className="text-danger">{emailerr1}</div>}
                <div className="row mb-3">
                  <div className=" col-md-12 EyeView">
                    <label htmlFor="password" className="form-label">
                      {t('Password')}
                    </label>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder={t('Enter Password')}
                      value={loginData.password}
                      onChange={handleInputChange}
                      required

                    />

                    <i className="bi bi-eye View pointer" onClick={toggleShowPassword}></i>
                  </div>
                </div>
                {emailerr && <div className="text-danger">{emailerr}</div>}
                <div className="d-flex justify-content-between">
                  <div className="ForgetPassword pointer" onClick={forgetPassword}>
                    {t('Forget Password')}
                  </div>
                  <div>
                    <span className="spanaPara">{t("Don't have any account?")}</span>
                    <span className="Create">{t('Create New')}</span>
                  </div>
                </div>
                <div className="mt-3 form-check">
                  <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    {t("I'm not a robot")}
                  </label>
                </div>
                <div className="ButtonForm mt-3">
                  <button className="CencelButton" type="button" onClick={() => setSignIn(false)}>
                    {t('Cancel')}
                  </button>
                  <button className="SaveButton" type="submit">
                    {t('Submit')}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>

      </Dialog>
    </>
  )
}
