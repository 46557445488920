import React from 'react'

export const PricacyPolicy = () => {


    let data = [
        {
            'heading': 'Introduction',
            'items': [
                'New Hope Points ("the Company," "we," or "us") is committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, and disclose information when you use our services.',
                'By using New Hope Points, you consent to the collection and use of your information as described in this Privacy Policy.',
                'Last updated: 10th April 2024'
            ]
        },
        {
            'heading': 'Information We Collect',
            'items': [
                'Personal Information: When you use New Hope Points, we may collect personal information such as your name, email address, contact information, and payment details.',
                'Usage Data: We may also collect information about how you interact with our services, including your browsing activities and preferences.',
                'Cookies: We use cookies and similar tracking technologies to enhance your experience and collect information about your usage of New Hope Points.'
            ]
        },
        {
            'heading': 'How We Use Your Information',
            'items': [
                'We use the information we collect for various purposes, including:',
                'Providing and maintaining our services.',
                'Personalizing your experience and improving our services.',
                'Communicating with you, including responding to your inquiries and providing customer support.',
                'Processing payments and transactions.',
                'Analyzing usage trends and optimizing our services.'
            ]
        },
        {
            'heading': 'Data Security',
            'items': [
                'We implement appropriate security measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.'
            ]
        },
        {
            'heading': 'Information Sharing',
            'items': [
                'We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted third-party service providers who assist us in operating our services, conducting our business, or servicing you.'
            ]
        },
        {
            'heading': 'Your Choices',
            'items': [
                'You can choose not to provide certain information, but this may limit your ability to access or use certain features of New Hope Points.',
                'You can opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in such communications.'
            ]
        },
        {
            'heading': 'Changes to This Privacy Policy',
            'items': [
                'We may update our Privacy Policy from time to time. Any changes will be effective immediately upon posting the revised policy on this page.'
            ]
        },
        {
            'heading': 'Contact Us',
            'items': [
                'If you have any questions or concerns about our Privacy Policy or practices, please contact us at info@newhopepoints.org'
            ]
        }
    ];




    return (
        <div className=' p-5'>

            <div className='text-center lufga_20px_bg_lightblue'>Privacy Policy</div>

            {data.map((res, key) => {
                return (
                    <div>
                        <div className='lufga_20px_bg_lightblue'>{res.heading}</div>

                        {res.items.map((res2, key2) => {
                            return (
                                <div className='lufga_14px'>{res2}</div>
                            )
                        })}
                        <br />
                    </div>
                )
            })}
            <br />
            <div className='lufga_14px'>
                By using New Hope Points, you consent to the collection and use of your information as described in this Privacy Policy.
                <div>

                    Last updated: [10th April 2024]
                </div>
            </div>


        </div>
    )
}
