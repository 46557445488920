import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router";
import "./Embryodonorsform.css";
import moment from "moment/moment";
import { addEmbryoFather, addEmbryoMother, deleteDocs, getEmbryoDonorFormData, getEmbryoDonorFormDataByUserId, uploadMultipleFiles } from "../../../../service/Apis";
import { useSSR, useTranslation } from "react-i18next";
import { MultipleImageUpload } from "../../../../components/Inputs/MultipleImageUpload";
import Loader from "../../../../components/Loader/Loader";
import { IoMdAdd } from "react-icons/io";
import Toaster from "../../../../components/Toaster";

const Embryodonorsform = () => {

  const userData = JSON.parse(localStorage.getItem('userData'))

  const [motherData, setMotherData] = useState([])
  const [fatherData, setFatherData] = useState({})
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });
  // Create a ref to the form container
  const formRef = useRef(null);


  useEffect(() => {
    const idToGetFormData = userData?.userId;
    if (idToGetFormData) {
      getFormdata(idToGetFormData)
    }
  }, [])

  useEffect(() => {

    if (motherData?.mother_photos) {



      let photoArr = motherData?.mother_photos?.map((res, index) => {
        return {
          ...res,
          fileType: "server",
        };
      });
      console.log(photoArr, "l;llllllllllllllllllllllll")
      setImagesValue(photoArr);
    }
  }, [motherData]);


  async function getFormdata(id) {
    try {
      let res = await getEmbryoDonorFormDataByUserId(id);
      console.log(res?.data?.data, "jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj")
      setMotherData(res.data.data.bio_mother)
      setFatherData(res.data.data.bio_father);

      setFormData({ ...formData, ...res.data.data.bio_mother })
    } catch (error) {
      console.log(error)
    }
  }

  const { id } = useParams();
  let { t } = useTranslation();
  let navigate = useNavigate()
  // let { navigation, data } = useOutletContext()


  let [loader, setLoader] = useState(false);
  const [imgForRemove, setImgForRemove] = useState([]);
  let [imagesValue, setImagesValue] = useState([]);


  function multipleImageInputChange(e) {
    console.log(e, 'aaaaaaa');
    setImagesValue(e);
  }


  const [formData, setFormData] = useState({
    mother_photos: [],
    photo_resume_index: 0,
    full_name: "",
    not_shown_on_public_profile_full_name: false,
    date_of_birth: "",
    height: {
      ft: "",
      in: "",
    },
    weight: {
      lbs: "",
      oz: "",
    },
    hair_color: "",
    eye_color: "",
    blood_type: "",
    race_ethnic_origin: "",
    race_ethnic_of_father: "",
    race_ethnic_of_mother: "",
    your_highest_education: "",
    your_highest_degree_major: "",
    highest_degree_major_college_name: "",
    write_details_about_your_family: {
      father: {
        height: {
          ft: "",
          in: ""
        },
        age: "",
        is_he_still_alive: "",
        profession: ""
      },
      mother: {
        height: {
          ft: "",
          in: ""
        },
        age: "",
        is_she_still_alive: "",
        profession: ""
      },
      grandfather: {
        height: {
          ft: "",
          in: ""
        },
        age: "",
        is_he_still_alive: "",
        profession: ""
      },
      grandmother: {
        height: {
          ft: "",
          in: ""
        },
        age: "",
        is_she_still_alive: "",
        profession: ""
      }
    },
    write_details_about_your_brother_and_sister: [{
      brother_or_sister: "",
      height: {
        ft: "",
        in: "",
      },
      age: "",
      profession: "",
    }],
    current_address: "",
    not_shown_on_public_profile_current_address: false,
    current_working_profession: "",
    where_were_you_born: "",
    current_marital_status: "",
    do_you_have_ever_wore_glasses_due_to_vision_defect: "",
    please_describe_your_personality_traits: "",
    please_provide_reasons_why_you_want_to_donate_your_embryo: "",
    donating_embryo_is_a_significant_commitment: "",
    are_you_ready_to_match_with_different_families: "",
    if_the_intended_parent_express_a_desire_to_meet_you: "",
    if_in_the_future_the_children_want_to_meet_you: "",
    do_you_have_children_of_you_own: "",
    what_activities_bring_you_joy_and_happiness: "",
    do_you_love_to_travel: "",
    what_is_your_favorite_joyful_color: "",
    do_you_have_any_past_or_current_medical_conditions: "",
    do_you_know_that_genetic_diseases_can_run_in_families: "",
    do_you_smoke: "",
    do_you_consume_liquor: "",
    are_you_currently_taking_any_medication: "",
    are_you_using_medications_or_substances_for_sexual_enhancement: "",
    does_anyone_in_your_family_have_history_of_cancer: "",
    how_often_do_you_engage_in_physical_activity: "",
    are_you_having_a_special_talent_in_art: "",
  }
  )

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const path = name.split('.');

    setFormData((prevState) => {
      let newState = { ...prevState };
      let nestedField = newState;

      for (let i = 0; i < path.length - 1; i++) {
        nestedField = nestedField[path[i]];
      }

      nestedField[path[path.length - 1]] = type === 'checkbox' ? checked : value;

      return newState;
    });
    if (e.target.tagName === 'TEXTAREA') {
      adjustTextareaHeight(e.target);
    }
  };

  useEffect(() => {
    if (!formRef.current) {
      console.warn("Form reference is not set yet.");
      return;
    }

    // Get all textarea elements within the form
    const textareas = formRef.current.querySelectorAll("textarea");
    textareas.forEach((textarea) => {
      adjustTextareaHeight(textarea);
    });
  }, [formData]);


  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = 'auto'; // Reset the height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight
  };

  function radioButtonChange(e, val) {
    let { name } = e.target;
    setFormData((pre) => {
      pre[name] = val;
      return { ...pre };
    });
  }

  const handleFamilyChange = (e) => {
    const { name, value } = e.target;
    const nameParts = name.split('.');

    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      let current = newFormData.write_details_about_your_family;

      for (let i = 1; i < nameParts.length - 1; i++) {
        current = current[nameParts[i]];
      }

      current[nameParts[nameParts.length - 1]] = value;
      return newFormData;
    });
  };

  const radioButtonFamilyChange = (e, booleanValue) => {
    const { name } = e.target;
    const nameParts = name.split('.');

    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      let current = newFormData.write_details_about_your_family;

      for (let i = 1; i < nameParts.length - 1; i++) {
        current = current[nameParts[i]];
      }

      current[nameParts[nameParts.length - 1]] = booleanValue;
      return newFormData;
    });
  };

  function addChild() {
    setFormData((pre) => ({
      ...pre,
      write_details_about_your_brother_and_sister: [
        ...pre.write_details_about_your_brother_and_sister,
        {
          brother_or_sister: "",
          height: {
            ft: "",
            in: "",
          },
          age: "",
          profession: "",
        },
      ],
    }));
  }
  console.log(id, 'res2');


  async function handleSubmit(e) {
    e.preventDefault();
    // console.log('Form submitted:', formData);
    setLoader(true)
    try {
      let img = imagesValue;
      if (imagesValue.length == 0) {
        setToaster({
          show: true,
          message: "please select images",
        });
        setLoader(false);
        return;
      }
      if (imgForRemove.length > 0) {
        let imagesData = await Promise.all(
          imgForRemove.map(
            async (e) => await deleteDocs({ fileName: e.fileName })
          )
        );
      }

      let httpimage = [];
      let formData2 = new FormData();
      let nonhttpImage = [];
      img.forEach((e) => {
        if (e?.fileUrl?.includes("blob")) {
          nonhttpImage.push(e);
        } else {
          httpimage.push({
            fileName: e.fileName,
            fileUrl: e.fileUrl,
          });
        }
      });
      for (const item of nonhttpImage.map((e) => e.file)) {
        formData2.append("uploadMultiDocs", item);
      }
      let uploadResponse = await uploadMultipleFiles(formData2);
      let res2 = {
        ...formData,
        mother_photos: [...httpimage, ...uploadResponse.data.data],

        // life_style: selectedLifeStyle,
        // habits: selectedHabits,
      };
      // return
      let res = await addEmbryoMother(id, { bio_mother: res2 })
      console.log("this is res", res)

      let userData = JSON.parse(localStorage.getItem('userData'))
      userData.resumeDataId = res?.data?.data._id;
      localStorage.setItem('userData', JSON.stringify(userData))

      console.log(res2)
      navigate(`/embryodonor/bioFather/${id}`)
      setLoader(false)

    } catch (error) {
      console.log(error)
    }
    finally {
      setLoader(false)
    }



  }


  // async function getFormdata() {
  //   try {
  //     let res = await getConciseFormData(id);
  //     console.log(res);
  //     // setFormData(res.data.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // useEffect(() => {
  //   getFormdata();
  // }, []);

  const [imageSrc, setImageSrc] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImageSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };



  const removeSibling = (index) => {
    // Use filter to create a new array without the sibling at the given index
    const updatedSiblings = formData.write_details_about_your_brother_and_sister.filter(
      (sibling, i) => i !== index
    );

    // Update the state with the new array
    setFormData((prevData) => ({
      ...prevData,
      write_details_about_your_brother_and_sister: updatedSiblings,
    }));
  };

  return (
    <>
      <Loader open={loader} />
      <Toaster
        event={toaster.message}
        show={toaster.show}
        severity={"error"}
        setToaster={setToaster}
      />
      <div className={`p-lg-5 p-md-5 p-sm-4 p-3 bio_mohter_form_wrapper`} ref={formRef}>
        <form onSubmit={handleSubmit}>
          <div className="primary_resume_main_con">
            <div className="p-md-4 p-sm-3 p-2">
              <div className="text-center main_heading">Embryo Donor</div>
              <div className="text-center second_heading">
                Complete your profile and be ready to earn..
              </div>
            </div>
            <div className="text-center mb-3 second_heading mt-5">
              Bio-Mother Information
            </div>

            <MultipleImageUpload
              value={imagesValue}
              onChange={multipleImageInputChange}
              maximg={5}
              setDeletedImage={setImgForRemove}

            />

            <div className="col mt-4 mb-2 p-2 input-text-main rounded-pill">
              <div className="input-text lufga_16px">
                <img className="" src="/images/bulb_icon.png" alt="" />
                {t("Good photos help to catch good intended parent(s)' eyes")}
              </div>
            </div>

            <div className="Form2Parent row">

              <div className="col-md-6 col-xxl-3 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("First Name")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter Your Name"
                  name="full_name"
                  value={formData?.full_name}
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-6 col-xxl-3 mt-4 d-flex flex-column">
                <label className="form-label labelHeadin">
                  {t("Date of Birth")}:<span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="date"
                  className="form-control mt-auto"
                  placeholder="Enter Your DOB"
                  max={moment().format("YYYY-MM-DD")}
                  name="date_of_birth"
                  // value={moment().format("YYYY-MM-DD")}
                  value={formData?.date_of_birth}
                  onChange={handleChange}
                />
              </div>



              <div className="col-md-6 col-xxl-3 mt-4 d-flex flex-column">

                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Height")} <span className="text-danger">*</span>
                </label>

                <div className="row mt-auto">
                  <div className="col-6">
                    <div className="row d-flex align-items-center">
                      <div className="col-10">
                        <select
                          required
                          className="form-select mt-auto"
                          aria-label="Default select example"
                          name="height.ft"
                          value={formData?.height?.ft}
                          onChange={handleChange}
                        >
                          <option value="" selected disabled hidden>Select</option>
                          <option value="4">4'</option>
                          <option value="5">5'</option>
                          <option value="6">6'</option>
                          <option value="7">7'</option>
                        </select>
                      </div>
                      <div className="col-2">
                        <span>Ft</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="row d-flex align-items-center">
                      <div className="col-10">
                        <select
                          className="form-select mt-auto"
                          aria-label="Default select example"
                          name="height.in"
                          value={formData?.height?.in}
                          onChange={handleChange}
                        >
                          <option value="" selected disabled hidden>Select</option>
                          <option value="0">0"</option>
                          <option value="1">1"</option>
                          <option value="2">2"</option>
                          <option value="3">3"</option>
                          <option value="4">4"</option>
                          <option value="5">5"</option>
                          <option value="6">6"</option>
                          <option value="7">7"</option>
                          <option value="8">8"</option>
                          <option value="9">9"</option>
                          <option value="10">10"</option>
                          <option value="11">11"</option>
                        </select>
                      </div>
                      <div className="col-2">
                        <span>In</span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>



              <div className="col-md-6 col-xxl-3 mt-4 d-flex flex-column">

                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Weight")} <span className="text-danger">*</span>
                </label>


                <div className="row mt-auto">
                  <div className="col-6">
                    <div className="row d-flex align-items-center">
                      <div className="col-10">
                        <select
                          required
                          className="form-select mt-auto"
                          aria-label="Default select example"
                          name="weight.lbs"
                          value={formData?.weight?.lbs}
                          onChange={handleChange}
                        >
                          <option value="" selected disabled hidden>Select</option>
                          <option value="100">100 lbs</option>
                          <option value="110">110 lbs</option>
                          <option value="120">120 lbs</option>
                          <option value="130">130 lbs</option>
                          <option value="140">140 lbs</option>
                          <option value="150">150 lbs</option>
                          <option value="160">160 lbs</option>
                          <option value="170">170 lbs</option>
                          <option value="180">180 lbs</option>
                          <option value="190">190 lbs</option>
                          <option value="200">200 lbs</option>
                          <option value="200">210 lbs</option>
                          <option value="200">220 lbs</option>
                          <option value="200">230 lbs</option>
                          <option value="200">240 lbs</option>
                          <option value="200">250 lbs</option>
                          <option value="200">260 lbs</option>
                          <option value="200">270 lbs</option>
                        </select>
                      </div>
                      <div className="col-2">
                        <span>Lbs</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="row d-flex align-items-center">
                      <div className="col-10">
                        <select
                          className="form-select mt-auto"
                          aria-label="Default select example"
                          name="weight.oz"
                          value={formData?.weight?.oz}
                          onChange={handleChange}
                        >
                          <option value="" selected disabled hidden>Select</option>
                          <option value="0">0 oz</option>
                          <option value="1">1 oz</option>
                          <option value="2">2 oz</option>
                          <option value="3">3 oz</option>
                          <option value="4">4 oz</option>
                          <option value="5">5 oz</option>
                          <option value="6">6 oz</option>
                          <option value="7">7 oz</option>
                          <option value="8">8 oz</option>
                          <option value="9">9 oz</option>
                          <option value="10">10 oz</option>
                          <option value="11">11 oz</option>
                          <option value="12">12 oz</option>
                          <option value="13">13 oz</option>
                          <option value="14">14 oz</option>
                          <option value="15">15 oz</option>
                        </select>
                      </div>
                      <div className="col-2">
                        <span>oz</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-12 mt-4">
                <div className=" form-check">
                  <input

                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    name="not_shown_on_public_profile_full_name"
                    value={formData?.not_shown_on_public_profile_full_name}
                    checked={formData?.not_shown_on_public_profile_full_name === true}
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label LabeSubHeading"
                    htmlFor="exampleCheck1"
                  >
                    {t(" Not Shown on Public Profile")}
                  </label>
                </div>
              </div>

              <div className="col-xl-8 mt-4">
                <div className="row">
                  <div className=" col-md-4 d-flex flex-column">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("Hair Color")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control mt-auto"
                      placeholder="Enter Hair Color"
                      name="hair_color"
                      value={formData?.hair_color}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mt-4 mt-md-0 col-md-4 d-flex flex-column">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("Eye Color")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control mt-auto"
                      placeholder="Enter Eye Color"
                      name="eye_color"
                      value={formData?.eye_color}
                      onChange={handleChange} />
                  </div>

                  <div className="mt-4 mt-md-0 col-md-4 d-flex flex-column">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("Blood Type")} <span className="text-danger">*</span>
                    </label>
                    <select
                      required
                      className="form-select mt-auto"
                      aria-label="Default select example"
                      name="blood_type"
                      value={formData?.blood_type}
                      onChange={handleChange}
                    >
                      <option value="" selected disabled hidden>Select Blood Type</option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                    </select>
                  </div>
                </div>
              </div>

              {/**
                * Below Container with no content is to maintain ui , so do not remove it 
              */}
              <div className="d-none d-xl-block col-xl-4">
                {/* container to maintain ui */}
              </div>

              <div className="col-md-4 col-xl-3 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Race Ethnic Origin ")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter Your Race"
                  name="race_ethnic_origin"
                  value={formData?.race_ethnic_origin}
                  onChange={handleChange}
                />
              </div>



              <div className="col-md-4 col-xl-3 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Race Ethnic of Mother")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter Mother Race"
                  name="race_ethnic_of_mother"
                  value={formData?.race_ethnic_of_mother}
                  onChange={handleChange}
                />
              </div>


              <div className="col-md-4 col-xl-3 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Race Ethnic of Father")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter Father Race"
                  name="race_ethnic_of_father"
                  value={formData?.race_ethnic_of_father}
                  onChange={handleChange}
                />
              </div>

              {/**
                * Below Container with no content is to maintain ui , so do not remove it 
              */}
              <div className="d-none d-xl-block col-xl-3">
                {/* container to maintain ui */}
              </div>

              <div className="col-md-4 col-xl-3 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Your Highest Education")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter Your Highest Education"
                  name="your_highest_education"
                  value={formData?.your_highest_education}
                  onChange={handleChange} />
              </div>

              <div className="col-md-4 col-xl-3 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Your Highest Degree Major")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter Your Highest Degree"
                  name="your_highest_degree_major"
                  value={formData?.your_highest_degree_major}
                  onChange={handleChange}
                />
              </div>


              <div className="col-md-4 col-xl-6 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Highest Degree Major College Name")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter Your Highest Degree College Name"
                  name="highest_degree_major_college_name"
                  value={formData?.highest_degree_major_college_name}
                  onChange={handleChange}

                />
              </div>



              <div className="col-12 mt-4">
                <p class="round2">
                  <div className="mt-4">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("Write Details about your family")}
                      <span className="text-danger">*</span>
                    </label>

                    <div className="row gap-4 border rounded-5 p-3">

                      <div className="col-12 col-md-12">

                        <div className="row gap-3 gap-md-0">

                          <div className="col-12 col-md-2">
                            <div className="row h-100">
                              <div className="d-none d-md-flex col-12 invisible">For Invisible purpose</div>
                              <div className="col-12 d-flex justify-content-center justify-content-md-start mt-auto mb-auto">
                                {t("Father")}
                                <span className="text-danger">*</span>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div className="row h-100">
                              <div className="col-4 col-md-12">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Height")} <span className="text-danger">*</span>
                                </label>
                              </div>

                              <div className="col-8 col-md-12 mt-auto">

                                <div className="row">

                                  <div className="col-6">
                                    <div className="d-flex align-items-center gap-2">

                                      <select
                                        className="form-select mt-auto"
                                        aria-label="Default select example"
                                        name="write_details_about_your_family.father.height.ft"
                                        value={formData?.write_details_about_your_family?.father?.height?.ft}
                                        onChange={handleFamilyChange}
                                      >
                                        <option value="" selected disabled hidden>Select</option>
                                        <option value="4">4'</option>
                                        <option value="5">5'</option>
                                        <option value="6">6'</option>
                                        <option value="7">7'</option>
                                      </select>

                                      <div className="d-flex align-items-center">
                                        <span>Ft</span>
                                      </div>

                                    </div>
                                  </div>

                                  <div className="col-6">
                                    <div className="d-flex align-items-center gap-2">

                                      <select
                                        className="form-select mt-auto"
                                        aria-label="Default select example"
                                        name="write_details_about_your_family.father.height.in"
                                        value={formData?.write_details_about_your_family?.father?.height?.in}
                                        onChange={handleFamilyChange}
                                      >
                                        <option value="" selected disabled hidden>Select</option>
                                        <option value="0">0"</option>
                                        <option value="1">1"</option>
                                        <option value="2">2"</option>
                                        <option value="3">3"</option>
                                        <option value="4">4"</option>
                                        <option value="5">5"</option>
                                        <option value="6">6"</option>
                                        <option value="7">7"</option>
                                        <option value="8">8"</option>
                                        <option value="9">9"</option>
                                        <option value="10">10"</option>
                                        <option value="11">11"</option>
                                      </select>

                                      <div className="d-flex align-items-center">
                                        <span>In</span>
                                      </div>

                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>

                          </div>

                          <div className="col-12 col-md-2">
                            <div className="row h-100">
                              <div className="col-4 col-md-12">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Age")} <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-8 col-md-12 mt-auto">
                                <input
                                  required
                                  type="number"
                                  className="form-control mt-auto"
                                  placeholder="Enter Age"
                                  name="write_details_about_your_family.father.age"
                                  value={formData?.write_details_about_your_family?.father?.age}
                                  onChange={handleFamilyChange}
                                />
                              </div>

                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div className="row h-100">
                              <div className="col-4 col-md-12">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Is he/she Still Alive")} <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-8 col-md-12">
                                <div className="row align-items-center h-100">
                                  <div className="col-6">
                                    <div className="form-check">

                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        required
                                        // name="flexRadioDefault"
                                        // id="write_details_about_your_family.father.is_he_still_aliveId1"
                                        name="write_details_about_your_family.father.is_he_still_alive"
                                        value="Yes"
                                        checked={formData?.write_details_about_your_family?.father?.is_he_still_alive === true}
                                        onChange={(e) => radioButtonFamilyChange(e, true)}
                                      />
                                      <label
                                        className="form-check-label LabeSubHeading "
                                        htmlFor="flexRadioDefault1"
                                      >
                                        {t("Yes")}
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-6">
                                    <div className="form-check">
                                      <input
                                        required
                                        className="form-check-input"
                                        type="radio"
                                        // name="flexRadioDefault"
                                        // id="write_details_about_your_family.father.is_he_still_aliveId2"

                                        name="write_details_about_your_family.father.is_he_still_alive"
                                        value="No"
                                        checked={formData?.write_details_about_your_family?.father?.is_he_still_alive === false}
                                        onChange={(e) => radioButtonFamilyChange(e, false)}

                                      />
                                      <label
                                        className="form-check-label LabeSubHeading "
                                        htmlFor="flexRadioDefault1"
                                      >
                                        {t("No")}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-2">
                            <div className="row h-100">
                              <div className="col-4 col-md-12">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Profession")} <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-8 col-md-12 mt-auto">
                                <input
                                  required
                                  type="text"
                                  className="form-control mt-auto"
                                  placeholder="Enter Profession"
                                  name="write_details_about_your_family.father.profession"
                                  value={formData?.write_details_about_your_family?.father?.profession}
                                  onChange={handleFamilyChange}
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>






                      <div className="col-12 col-md-12">

                        <div className="row gap-3 gap-md-0">

                          <div className="col-12 col-md-2">
                            <div className="row h-100">

                              <div className="col-12 d-flex justify-content-center justify-content-md-start mt-auto mb-auto">
                                {t("Mother")}
                                <span className="text-danger">*</span>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div className="row h-100">
                              <div className="col-4 col-md-12 d-block d-md-none">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Height")} <span className="text-danger">*</span>
                                </label>
                              </div>

                              <div className="col-8 col-md-12 mt-auto">

                                <div className="row">

                                  <div className="col-6">
                                    <div className="d-flex align-items-center gap-2">

                                      <select
                                        className="form-select mt-auto"
                                        aria-label="Default select example"
                                        name="write_details_about_your_family.mother.height.ft"
                                        value={formData?.write_details_about_your_family?.mother?.height?.ft}
                                        onChange={handleFamilyChange}
                                      >
                                        <option value="" selected disabled hidden>Select</option>
                                        <option value="4">4'</option>
                                        <option value="5">5'</option>
                                        <option value="6">6'</option>
                                        <option value="7">7'</option>
                                      </select>

                                      <div className="d-flex align-items-center">
                                        <span>Ft</span>
                                      </div>

                                    </div>
                                  </div>

                                  <div className="col-6">
                                    <div className="d-flex align-items-center gap-2">

                                      <select
                                        className="form-select mt-auto"
                                        aria-label="Default select example"
                                        name="write_details_about_your_family.mother.height.in"
                                        value={formData?.write_details_about_your_family?.mother?.height?.in}
                                        onChange={handleFamilyChange}
                                      >
                                        <option value="" selected disabled hidden>Select</option>
                                        <option value="0">0"</option>
                                        <option value="1">1"</option>
                                        <option value="2">2"</option>
                                        <option value="3">3"</option>
                                        <option value="4">4"</option>
                                        <option value="5">5"</option>
                                        <option value="6">6"</option>
                                        <option value="7">7"</option>
                                        <option value="8">8"</option>
                                        <option value="9">9"</option>
                                        <option value="10">10"</option>
                                        <option value="11">11"</option>
                                      </select>

                                      <div className="d-flex align-items-center">
                                        <span>In</span>
                                      </div>

                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>

                          </div>

                          <div className="col-12 col-md-2">
                            <div className="row h-100">
                              <div className="col-4 col-md-12 d-block d-md-none">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Age")} <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-8 col-md-12 mt-auto">
                                <input
                                  required
                                  type="number"
                                  className="form-control mt-auto"
                                  placeholder="Enter Age"
                                  name="write_details_about_your_family.mother.age"
                                  value={formData?.write_details_about_your_family?.mother?.age}
                                  onChange={handleFamilyChange}
                                />
                              </div>

                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div className="row h-100">
                              <div className="col-4 col-md-12 d-block d-md-none">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Is he/she Still Alive")} <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-8 col-md-12">
                                <div className="row align-items-center h-100">
                                  <div className="col-6">
                                    <div className="form-check">

                                      <input
                                        required
                                        className="form-check-input"
                                        type="radio"
                                        // name="flexRadioDefault"
                                        // id="write_details_about_your_family.mother.is_he_still_aliveId1"
                                        name="write_details_about_your_family.mother.is_she_still_alive"
                                        value="Yes"
                                        checked={formData?.write_details_about_your_family?.mother?.is_she_still_alive === true}
                                        onChange={(e) => radioButtonFamilyChange(e, true)}
                                      />
                                      <label
                                        className="form-check-label LabeSubHeading "
                                        htmlFor="flexRadioDefault1"
                                      >
                                        {t("Yes")}
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-6">
                                    <div className="form-check">
                                      <input
                                        required
                                        className="form-check-input"
                                        type="radio"
                                        // name="flexRadioDefault"
                                        // id="write_details_about_your_family.mother.is_he_still_aliveId2"

                                        name="write_details_about_your_family.mother.is_she_still_alive"
                                        value="No"
                                        checked={formData?.write_details_about_your_family?.mother.is_she_still_alive === false}
                                        onChange={(e) => radioButtonFamilyChange(e, false)}

                                      />
                                      <label
                                        className="form-check-label LabeSubHeading "
                                        htmlFor="flexRadioDefault1"
                                      >
                                        {t("No")}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-2">
                            <div className="row h-100">
                              <div className="col-4 col-md-12 d-block d-md-none">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Profession")} <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-8 col-md-12 mt-auto">
                                <input
                                  required
                                  type="text"
                                  className="form-control mt-auto"
                                  placeholder="Enter Profession"
                                  name="write_details_about_your_family.mother.profession"
                                  value={formData?.write_details_about_your_family?.mother?.profession}
                                  onChange={handleFamilyChange}
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>










                      <div className="col-12 col-md-12">

                        <div className="row gap-3 gap-md-0">

                          <div className="col-12 col-md-2">
                            <div className="row h-100">

                              <div className="col-12 d-flex justify-content-center justify-content-md-start mt-auto mb-auto">
                                {t("Grand Father")}
                                <span className="text-danger">*</span>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div className="row h-100">
                              <div className="col-4 col-md-12 d-block d-md-none">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Height")} <span className="text-danger">*</span>
                                </label>
                              </div>

                              <div className="col-8 col-md-12 mt-auto">

                                <div className="row">

                                  <div className="col-6">
                                    <div className="d-flex align-items-center gap-2">

                                      <select
                                        className="form-select mt-auto"
                                        aria-label="Default select example"
                                        name="write_details_about_your_family.grandfather.height.ft"
                                        value={formData?.write_details_about_your_family?.grandfather?.height?.ft}
                                        onChange={handleFamilyChange}
                                      >
                                        <option value="" selected disabled hidden>Select</option>
                                        <option value="4">4'</option>
                                        <option value="5">5'</option>
                                        <option value="6">6'</option>
                                        <option value="7">7'</option>
                                      </select>

                                      <div className="d-flex align-items-center">
                                        <span>Ft</span>
                                      </div>

                                    </div>
                                  </div>

                                  <div className="col-6">
                                    <div className="d-flex align-items-center gap-2">

                                      <select
                                        className="form-select mt-auto"
                                        aria-label="Default select example"
                                        name="write_details_about_your_family.grandfather.height.in"
                                        value={formData?.write_details_about_your_family?.grandfather?.height?.in}
                                        onChange={handleFamilyChange}
                                      >
                                        <option value="" selected disabled hidden>Select</option>
                                        <option value="0">0"</option>
                                        <option value="1">1"</option>
                                        <option value="2">2"</option>
                                        <option value="3">3"</option>
                                        <option value="4">4"</option>
                                        <option value="5">5"</option>
                                        <option value="6">6"</option>
                                        <option value="7">7"</option>
                                        <option value="8">8"</option>
                                        <option value="9">9"</option>
                                        <option value="10">10"</option>
                                        <option value="11">11"</option>
                                      </select>

                                      <div className="d-flex align-items-center">
                                        <span>In</span>
                                      </div>

                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>

                          </div>

                          <div className="col-12 col-md-2">
                            <div className="row h-100">
                              <div className="col-4 col-md-12 d-block d-md-none">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Age")} <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-8 col-md-12 mt-auto">
                                <input
                                  required
                                  type="number"
                                  className="form-control mt-auto"
                                  placeholder="Enter Age"
                                  name="write_details_about_your_family.grandfather.age"
                                  value={formData?.write_details_about_your_family?.grandfather?.age}
                                  onChange={handleFamilyChange}
                                />
                              </div>

                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div className="row h-100">
                              <div className="col-4 col-md-12 d-block d-md-none">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Is he/she Still Alive")} <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-8 col-md-12">
                                <div className="row align-items-center h-100">
                                  <div className="col-6">
                                    <div className="form-check">

                                      <input
                                        required
                                        className="form-check-input"
                                        type="radio"
                                        // name="flexRadioDefault"
                                        // id="write_details_about_your_family.grandfather.is_he_still_aliveId1"
                                        name="write_details_about_your_family.grandfather.is_he_still_alive"
                                        value="Yes"
                                        checked={formData?.write_details_about_your_family?.grandfather?.is_he_still_alive === true}
                                        onChange={(e) => radioButtonFamilyChange(e, true)}
                                      />
                                      <label
                                        className="form-check-label LabeSubHeading "
                                        htmlFor="flexRadioDefault1"
                                      >
                                        {t("Yes")}
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-6">
                                    <div className="form-check">
                                      <input
                                        required
                                        className="form-check-input"
                                        type="radio"
                                        // name="flexRadioDefault"
                                        // id="write_details_about_your_family.grandfather.is_he_still_aliveId2"

                                        name="write_details_about_your_family.grandfather.is_he_still_alive"
                                        value="No"
                                        checked={formData?.write_details_about_your_family?.grandfather.is_he_still_alive === false}
                                        onChange={(e) => radioButtonFamilyChange(e, false)}

                                      />
                                      <label
                                        className="form-check-label LabeSubHeading "
                                        htmlFor="flexRadioDefault1"
                                      >
                                        {t("No")}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-2">
                            <div className="row h-100">
                              <div className="col-4 col-md-12 d-block d-md-none">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Profession")} <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-8 col-md-12 mt-auto">
                                <input
                                  required
                                  type="text"
                                  className="form-control mt-auto"
                                  placeholder="Enter Profession"
                                  name="write_details_about_your_family.grandfather.profession"
                                  value={formData?.write_details_about_your_family?.grandfather?.profession}
                                  onChange={handleFamilyChange}
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>







                      <div className="col-12 col-md-12">

                        <div className="row gap-3 gap-md-0">

                          <div className="col-12 col-md-2">
                            <div className="row h-100">

                              <div className="col-12 d-flex justify-content-center justify-content-md-start mt-auto mb-auto">
                                {t("Grand Mother")}
                                <span className="text-danger">*</span>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div className="row h-100">
                              <div className="col-4 col-md-12 d-block d-md-none">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Height")} <span className="text-danger">*</span>
                                </label>
                              </div>

                              <div className="col-8 col-md-12 mt-auto">

                                <div className="row">

                                  <div className="col-6">
                                    <div className="d-flex align-items-center gap-2">

                                      <select
                                        className="form-select mt-auto"
                                        aria-label="Default select example"
                                        name="write_details_about_your_family.grandmother.height.ft"
                                        value={formData?.write_details_about_your_family?.grandmother?.height?.ft}
                                        onChange={handleFamilyChange}
                                      >
                                        <option value="" selected disabled hidden>Select</option>
                                        <option value="4">4'</option>
                                        <option value="5">5'</option>
                                        <option value="6">6'</option>
                                        <option value="7">7'</option>
                                      </select>

                                      <div className="d-flex align-items-center">
                                        <span>Ft</span>
                                      </div>

                                    </div>
                                  </div>

                                  <div className="col-6">
                                    <div className="d-flex align-items-center gap-2">

                                      <select
                                        className="form-select mt-auto"
                                        aria-label="Default select example"
                                        name="write_details_about_your_family.grandmother.height.in"
                                        value={formData?.write_details_about_your_family?.grandmother?.height?.in}
                                        onChange={handleFamilyChange}
                                      >
                                        <option value="" selected disabled hidden>Select</option>
                                        <option value="0">0"</option>
                                        <option value="1">1"</option>
                                        <option value="2">2"</option>
                                        <option value="3">3"</option>
                                        <option value="4">4"</option>
                                        <option value="5">5"</option>
                                        <option value="6">6"</option>
                                        <option value="7">7"</option>
                                        <option value="8">8"</option>
                                        <option value="9">9"</option>
                                        <option value="10">10"</option>
                                        <option value="11">11"</option>
                                      </select>

                                      <div className="d-flex align-items-center">
                                        <span>In</span>
                                      </div>

                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>

                          </div>

                          <div className="col-12 col-md-2">
                            <div className="row h-100">
                              <div className="col-4 col-md-12 d-block d-md-none">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Age")} <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-8 col-md-12 mt-auto">
                                <input
                                  required
                                  type="number"
                                  className="form-control mt-auto"
                                  placeholder="Enter Age"
                                  name="write_details_about_your_family.grandmother.age"
                                  value={formData?.write_details_about_your_family?.grandmother?.age}
                                  onChange={handleFamilyChange}
                                />
                              </div>

                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div className="row h-100">
                              <div className="col-4 col-md-12 d-block d-md-none">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Is he/she Still Alive")} <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-8 col-md-12">
                                <div className="row align-items-center h-100">
                                  <div className="col-6">
                                    <div className="form-check">

                                      <input
                                        required
                                        className="form-check-input"
                                        type="radio"

                                        name="write_details_about_your_family.grandmother.is_she_still_alive"
                                        value="Yes"
                                        checked={formData?.write_details_about_your_family?.grandmother?.is_she_still_alive === true}
                                        onChange={(e) => radioButtonFamilyChange(e, true)}
                                      />
                                      <label
                                        className="form-check-label LabeSubHeading "
                                        htmlFor="flexRadioDefault1"
                                      >
                                        {t("Yes")}
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-6">
                                    <div className="form-check">
                                      <input
                                        required
                                        className="form-check-input"
                                        type="radio"


                                        name="write_details_about_your_family.grandmother.is_she_still_alive"
                                        value="No"
                                        checked={formData?.write_details_about_your_family?.grandmother.is_she_still_alive === false}
                                        onChange={(e) => radioButtonFamilyChange(e, false)}

                                      />
                                      <label
                                        className="form-check-label LabeSubHeading "
                                        htmlFor="flexRadioDefault1"
                                      >
                                        {t("No")}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-2">
                            <div className="row h-100">
                              <div className="col-4 col-md-12 d-block d-md-none">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label LabelHeading"
                                >
                                  {t("Profession")} <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-8 col-md-12 mt-auto">
                                <input
                                  required
                                  type="text"
                                  className="form-control mt-auto"
                                  placeholder="Enter Profession"
                                  name="write_details_about_your_family.grandmother.profession"
                                  value={formData?.write_details_about_your_family?.grandmother?.profession}
                                  onChange={handleFamilyChange}
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>



                    </div>

                  </div>

                </p>
              </div>


              <div className="col-12 mt-4">
                <p class="round2">
                  <div className="mt-4">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("Write Details about your Brother & Sister")}
                      <span className="text-danger">*</span>
                    </label>

                    <div className="row gap-4 border rounded-5 p-3">



                      {formData?.write_details_about_your_brother_and_sister?.map((res, key) => {
                        return (
                          <>
                            <div className="col-12 col-md-12" key={key}>
                              <div className="row gap-3 gap-md-0">

                                <div className="col-12 col-md-3">
                                  <div className="row h-100 gap-md-3">
                                    <div className={`${key > 0 ? 'd-md-none' : 'd-block'} col-4 col-md-12`}
                                    >
                                      <label
                                        htmlFor="exampleInputEmail1"
                                        className="form-label LabelHeading"
                                      >
                                        {t("Brother or Sister")} <span className="text-danger">*</span>
                                      </label>
                                    </div>
                                    <div className="col-8 col-md-12 mt-auto">
                                      <input
                                        required
                                        type="text"
                                        className="form-control mt-auto"
                                        placeholder="Brother/Sister"
                                        name="brother_or_sister"

                                        onChange={(e) => {
                                          setFormData((pre) => {
                                            pre.write_details_about_your_brother_and_sister[key].brother_or_sister = e.target.value;
                                            return { ...pre };
                                          });
                                        }}
                                        value={res.brother_or_sister}
                                      />
                                    </div>
                                  </div>
                                </div>


                                <div className="col-12 col-md-4">
                                  <div className="row h-100 gap-md-3">
                                    <div className={`${key > 0 ? 'd-md-none' : 'd-block'} col-4 col-md-12`}
                                    >
                                      <label
                                        htmlFor="exampleInputEmail1"
                                        className="form-label LabelHeading"
                                      >
                                        {t("Height")} <span className="text-danger">*</span>
                                      </label>
                                    </div>

                                    <div className="col-8 col-md-12 mt-auto">

                                      <div className="row">

                                        <div className="col-6">
                                          <div className="d-flex align-items-center gap-2">

                                            <select

                                              required
                                              className="form-select mt-auto"
                                              aria-label="Default select example"
                                              name="height.ft"
                                              onChange={(e) => {
                                                setFormData((pre) => {
                                                  pre.write_details_about_your_brother_and_sister[key].height.ft = e.target.value;
                                                  return { ...pre };
                                                });
                                              }}
                                              value={res.height.ft}
                                            >
                                              <option value="" selected disabled hidden>Select</option>
                                              <option value="4">4'</option>
                                              <option value="5">5'</option>
                                              <option value="6">6'</option>
                                              <option value="7">7'</option>
                                            </select>

                                            <div className="d-flex align-items-center">
                                              <span>Ft</span>
                                            </div>

                                          </div>
                                        </div>

                                        <div className="col-6">
                                          <div className="d-flex align-items-center gap-2">

                                            <select
                                              className="form-select mt-auto"
                                              aria-label="Default select example"
                                              name="height.in"
                                              onChange={(e) => {
                                                setFormData((pre) => {
                                                  pre.write_details_about_your_brother_and_sister[key].height.in = e.target.value;
                                                  return { ...pre };
                                                });
                                              }}
                                              value={res.height.in}
                                            >
                                              <option value="" selected disabled hidden>Select</option>
                                              <option value="0">0"</option>
                                              <option value="1">1"</option>
                                              <option value="2">2"</option>
                                              <option value="3">3"</option>
                                              <option value="4">4"</option>
                                              <option value="5">5"</option>
                                              <option value="6">6"</option>
                                              <option value="7">7"</option>
                                              <option value="8">8"</option>
                                              <option value="9">9"</option>
                                              <option value="10">10"</option>
                                              <option value="11">11"</option>
                                            </select>

                                            <div className="d-flex align-items-center">
                                              <span>In</span>
                                            </div>

                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div className="col-12 col-md-2">
                                  <div className="row h-100 gap-md-3">
                                    <div className={`${key > 0 ? 'd-md-none' : 'd-block'} col-4 col-md-12`}
                                    >
                                      <label
                                        htmlFor="exampleInputEmail1"
                                        className="form-label LabelHeading"
                                      >
                                        {t("Age")} <span className="text-danger">*</span>
                                      </label>
                                    </div>
                                    <div className="col-8 col-md-12 mt-auto">
                                      <input
                                        required
                                        type="number"
                                        className="form-control mt-auto"
                                        placeholder="Enter Age"
                                        onChange={(e) => {
                                          setFormData((pre) => {
                                            pre.write_details_about_your_brother_and_sister[key].age = e.target.value;
                                            return { ...pre };
                                          });
                                        }}
                                        value={res.age}
                                        name="age"
                                      />
                                    </div>

                                  </div>
                                </div>



                                <div className="col-12 col-md-2">
                                  <div className="row h-100 gap-md-3">
                                    <div className={`${key > 0 ? 'd-md-none' : 'd-block'} col-4 col-md-12`}
                                    >
                                      <label
                                        htmlFor="exampleInputEmail1"
                                        className="form-label LabelHeading"
                                      >
                                        {t("Profession")} <span className="text-danger">*</span>
                                      </label>
                                    </div>
                                    <div className="col-8 col-md-12 mt-auto">
                                      <input
                                        required
                                        type="text"
                                        className="form-control mt-auto"
                                        placeholder="Enter Profession"
                                        name="profession"
                                        onChange={(e) => {
                                          setFormData((pre) => {
                                            pre.write_details_about_your_brother_and_sister[key].profession = e.target.value;
                                            return { ...pre };
                                          });
                                        }}
                                        value={res.profession}
                                      />
                                    </div>
                                  </div>
                                </div>



                                <div className="col-12 col-md-1">
                                  <div className="row h-100 gap-md-3">
                                    <div className={`${key > 0 ? 'd-md-none' : 'd-block'}col-0 col-md-12`}
                                    >

                                    </div>
                                    <div className="col-md-12 mt-auto">
                                      <div className={`d-flex justify-content-center align-items-center`}>
                                        <div className={`addChild_btn w-25 d-flex justify-content-center lufga_44px fw_700`}
                                          onClick={() => {
                                            removeSibling(key)
                                          }}
                                        >
                                          X
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>




                              </div>
                            </div>



                          </>
                        )
                      })}


                      <div className="col-12 d-flex w-100 justify-content-center mt-4">
                        <button className="addChild_btn" type="button" onClick={addChild}>
                          <IoMdAdd /> {t("Add Brother/Sister")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </p>
              </div>



              <div className="col-md-7 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Current Address (City, State & Country)")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter Your Current Address"
                  name="current_address"
                  value={formData?.current_address}
                  onChange={handleChange}

                />
              </div>

              <div className="col-md-5 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Current Working Profession")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter Your Profession"
                  name="current_working_profession"
                  value={formData?.current_working_profession}
                  onChange={handleChange}

                />
              </div>

              <div className="col-md-12 mt-4 d-flex flex-column">
                <div className=" form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    name="not_shown_on_public_profile_current_address"
                    value={formData?.not_shown_on_public_profile_current_address}
                    checked={formData?.not_shown_on_public_profile_current_address === true}
                    onChange={handleChange}

                  />
                  <label
                    className="form-check-label LabeSubHeading"
                    htmlFor="exampleCheck1"
                  >
                    {t(" Not Shown on Public Profile")}
                  </label>
                </div>
              </div>

              <div className="col-md-3 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Where were you Born")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter Your Born Details"
                  name="where_were_you_born"
                  value={formData?.where_were_you_born}
                  onChange={handleChange}

                />
              </div>

              <div className="col-md-3 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Current Marital Status")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <select
                  required
                  className="form-select mt-auto"
                  name="current_marital_status"
                  value={formData?.current_marital_status}
                  onChange={handleChange}
                  aria-label="Default select example">
                  <option value="" selected disabled hidden>Select</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                  <option value="widowed">Widowed</option>
                  <option value="separated">Separated</option>
                  <option value="domestic-partnership">Domestic Partnership</option>
                </select>
              </div>

              <div className="Form_checkbox3 col-md-6 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  Do you have ever wore glasses due to vision defect ?
                  <span className="text-danger">*</span>
                </label>

                <div className="mt-auto">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          type="radio"
                          id="flexRadioDefault1"
                          value="Yes"
                          name="do_you_have_ever_wore_glasses_due_to_vision_defect"
                          checked={formData?.do_you_have_ever_wore_glasses_due_to_vision_defect === true}
                          onChange={(e) => radioButtonChange(e, true)}


                        />
                        <label
                          className="form-check-label LabeSubHeading "
                          htmlFor="flexRadioDefault1"
                        >
                          {t("Yes")}
                        </label>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          type="radio"

                          id="flexRadioDefault1"
                          name="do_you_have_ever_wore_glasses_due_to_vision_defect"
                          value="No"
                          checked={formData?.do_you_have_ever_wore_glasses_due_to_vision_defect === false}
                          onChange={(e) => radioButtonChange(e, false)}
                        />
                        <label
                          className="form-check-label LabeSubHeading "
                          htmlFor="flexRadioDefault1"
                        >
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </div>



                </div>
              </div>

              <div className="col-md-12 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Please Describe your Personality Traits")}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  required
                  type="text"
                  rows={1}
                  className="form-control mt-auto"
                  placeholder="Enter Your Personality Traits"
                  name="please_describe_your_personality_traits"
                  value={formData?.please_describe_your_personality_traits}
                  onChange={handleChange}

                />
                {/* <i class="bi bi-link-45deg View"></i> */}
              </div>

              <div className=" col-md-12 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Please Provide reasons why you want to donate your embryo")}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  required
                  type="text"
                  rows={1}
                  className="form-control mt-auto"
                  placeholder="Enter why you want to donate your embryo"
                  name="please_provide_reasons_why_you_want_to_donate_your_embryo"
                  value={formData?.please_provide_reasons_why_you_want_to_donate_your_embryo}
                  onChange={handleChange}

                />
                {/* <i class="bi bi-link-45deg View"></i> */}
              </div>

              <div className="Form_checkbox3 col-md-6 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Donating embryo is a significant commitment involving multiple doctors' appointments, injections, and small outpatient clinic visits. Are you prepared to bear this responsibility?")}
                  <span className="text-danger">*</span>
                </label>

                <div className="mt-auto">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          type="radio"
                          id="flexRadioDefault1"
                          value="Yes"
                          name="donating_embryo_is_a_significant_commitment"
                          checked={formData?.donating_embryo_is_a_significant_commitment === true}
                          onChange={(e) => radioButtonChange(e, true)}
                        />
                        <label
                          className="form-check-label LabeSubHeading "
                          htmlFor="flexRadioDefault1"
                        >
                          {t("Yes")}
                        </label>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          type="radio"
                          id="flexRadioDefault1"
                          name="donating_embryo_is_a_significant_commitment"
                          value="No"
                          checked={formData?.donating_embryo_is_a_significant_commitment === false}
                          onChange={(e) => radioButtonChange(e, false)}


                        />
                        <label
                          className="form-check-label LabeSubHeading "
                          htmlFor="flexRadioDefault1"
                        >
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Form_checkbox3 col-md-6 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Are you willing to be matched with different families regardless of their marital status, sexual orientation, sex, or race?")}
                  <span className="text-danger">*</span>
                </label>

                <div className="mt-auto">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          type="radio"
                          id="flexRadioDefault1"
                          value="Yes"
                          name="are_you_ready_to_match_with_different_families"
                          checked={formData?.are_you_ready_to_match_with_different_families === true}
                          onChange={(e) => radioButtonChange(e, true)}


                        />
                        <label
                          className="form-check-label LabeSubHeading "
                          htmlFor="flexRadioDefault1"
                        >
                          {t("Yes")}
                        </label>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          type="radio"
                          id="flexRadioDefault1"
                          name="are_you_ready_to_match_with_different_families"
                          value="No"
                          checked={formData?.are_you_ready_to_match_with_different_families === false}
                          onChange={(e) => radioButtonChange(e, false)}


                        />
                        <label
                          className="form-check-label LabeSubHeading "
                          htmlFor="flexRadioDefault1"
                        >
                          {t("No")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                  </div>
                </div>
              </div>

              <div className="Form_checkbox3 col-md-6 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("If the intended parents express a desire to meet you in person or connect with you, would you be open to that?")}
                  <span className="text-danger">*</span>

                </label>


                <div className="mt-auto">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          type="radio"
                          id="flexRadioDefault1"
                          value="Yes"
                          name="if_the_intended_parent_express_a_desire_to_meet_you"
                          checked={formData?.if_the_intended_parent_express_a_desire_to_meet_you === true}
                          onChange={(e) => radioButtonChange(e, true)}


                        />
                        <label
                          className="form-check-label LabeSubHeading "
                          htmlFor="flexRadioDefault1"
                        >
                          {t("Yes")}
                        </label>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          type="radio"
                          id="flexRadioDefault1"
                          name="if_the_intended_parent_express_a_desire_to_meet_you"
                          value="No"
                          checked={formData?.if_the_intended_parent_express_a_desire_to_meet_you === false}
                          onChange={(e) => radioButtonChange(e, false)}


                        />
                        <label
                          className="form-check-label LabeSubHeading "
                          htmlFor="flexRadioDefault1"
                        >
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="Form_checkbox3 col-md-6 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("If in the future the children want to meet you, would you be willing?")}
                  <span className="text-danger">*</span>
                </label>

                <div className="mt-auto">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          type="radio"
                          id="flexRadioDefault1"
                          value="Yes"
                          name="if_in_the_future_the_children_want_to_meet_you"
                          checked={formData?.if_in_the_future_the_children_want_to_meet_you === true}
                          onChange={(e) => radioButtonChange(e, true)}


                        />
                        <label
                          className="form-check-label LabeSubHeading "
                          htmlFor="flexRadioDefault1"
                        >
                          {t("Yes")}
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          type="radio"
                          id="flexRadioDefault1"
                          name="if_in_the_future_the_children_want_to_meet_you"
                          value="No"
                          checked={formData?.if_in_the_future_the_children_want_to_meet_you === false}
                          onChange={(e) => radioButtonChange(e, false)}


                        />
                        <label
                          className="form-check-label LabeSubHeading "
                          htmlFor="flexRadioDefault1"
                        >
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t(
                    "Do you have children of your own? If so, please provide their names and ages."
                  )}
                  <span className="text-danger">*</span>
                </label>

                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter Your Children's Name and Ages"
                  name="do_you_have_children_of_you_own"
                  value={formData?.do_you_have_children_of_you_own}
                  onChange={handleChange}
                />
                {/* <i class="bi bi-link-45deg View"></i> */}
              </div>

              <div className="col-md-12 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t(
                    "What activities bring you joy and happiness during your leisure time?"
                  )}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  required
                  type="text"
                  rows={1}
                  className="form-control mt-auto"
                  placeholder="Enter Activities that bring you joy and happiness"
                  name="what_activities_bring_you_joy_and_happiness"
                  value={formData?.what_activities_bring_you_joy_and_happiness}
                  onChange={handleChange}

                />
                {/* <i class="bi bi-link-45deg View"></i> */}
              </div>

              <div className="col-md-6 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t(
                    "Do you love to travel? If so, which places do you prefer to go?"
                  )}
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter Your prefer places to travel"
                  name="do_you_love_to_travel"
                  value={formData?.do_you_love_to_travel}
                  onChange={handleChange} />
              </div>

              <div className="col-md-4 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("What is your favorite joyful color?")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter Your Favorite Color"
                  name="what_is_your_favorite_joyful_color"
                  value={formData?.what_is_your_favorite_joyful_color}
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-12 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t(
                    "Do you have any past or current medical conditions, such as major surgeries, congenital defects, or mental health conditions? If so, please provide detailed information about them."
                  )}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  required
                  type="text"
                  rows={1}
                  className="form-control mt-auto"
                  placeholder="Enter Your Medical Conditions"
                  name="do_you_have_any_past_or_current_medical_conditions"
                  value={formData?.do_you_have_any_past_or_current_medical_conditions}
                  onChange={handleChange}
                />
                {/* <i class="bi bi-link-45deg View"></i> */}
              </div>


              {/* <div>kuldeep</div> */}


              <div className="col-11">
                <div className="row">

                  <div className="Form_checkbox3 col-12 col-lg-4 mt-4 d-flex flex-column">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      Do you know that genetic diseases can run in families?
                      <span className="text-danger">*</span>
                    </label>

                    <div className="mt-auto">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              required
                              className="form-check-input"
                              type="radio"
                              id="flexRadioDefault1"
                              name="do_you_know_that_genetic_diseases_can_run_in_families"
                              value="Yes"
                              checked={formData?.do_you_know_that_genetic_diseases_can_run_in_families === true}
                              onChange={(e) => radioButtonChange(e, true)}
                            />
                            <label
                              className="form-check-label LabeSubHeading "
                              htmlFor="flexRadioDefault1"
                            >
                              {t("Yes")}
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              required
                              className="form-check-input"
                              type="radio"
                              id="flexRadioDefault1"
                              value="No"
                              name="do_you_know_that_genetic_diseases_can_run_in_families"
                              checked={formData?.do_you_know_that_genetic_diseases_can_run_in_families === false}
                              onChange={(e) => radioButtonChange(e, false)}
                            />
                            <label
                              className="form-check-label LabeSubHeading "
                              htmlFor="flexRadioDefault1"
                            >
                              {t("No")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="Form_checkbox3 col-12 col-lg-4 mt-4 d-flex flex-column">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("Do you smoke?")}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="mt-auto">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              required
                              className="form-check-input"
                              type="radio"
                              name="do_you_smoke"
                              value="Yes"
                              checked={formData?.do_you_smoke === true}
                              onChange={(e) => radioButtonChange(e, true)}
                            />
                            <label
                              className="form-check-label LabeSubHeading "
                              htmlFor="flexRadioDefault1"
                            >
                              {t("Yes")}
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              required
                              className="form-check-input"
                              type="radio"
                              name="do_you_smoke"
                              value="No"
                              checked={formData?.do_you_smoke === false}
                              onChange={(e) => radioButtonChange(e, false)}


                            />
                            <label
                              className="form-check-label LabeSubHeading "
                              htmlFor="flexRadioDefault1"
                            >
                              {t("No")}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3"></div>

                    </div>
                  </div>

                  <div className="Form_checkbox3 col-12 col-lg-4 mt-4 d-flex flex-column">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label LabelHeading"
                    >
                      {t("Do you consume liquor?")}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="mt-auto">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              required
                              className="form-check-input"
                              type="radio"
                              id="flexRadioDefault1"
                              name="do_you_consume_liquor"
                              value="Yes"
                              checked={formData?.do_you_consume_liquor === true}
                              onChange={(e) => radioButtonChange(e, true)}


                            />
                            <label
                              className="form-check-label LabeSubHeading "
                              htmlFor="flexRadioDefault1"
                            >
                              {t("Yes")}
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              required
                              className="form-check-input"
                              type="radio"
                              id="flexRadioDefault1"
                              name="do_you_consume_liquor"
                              value="No"
                              checked={formData?.do_you_consume_liquor === false}
                              onChange={(e) => radioButtonChange(e, false)}


                            />
                            <label
                              className="form-check-label LabeSubHeading "
                              htmlFor="flexRadioDefault1"
                            >
                              {t("No")}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3"></div>

                    </div>
                  </div>
                </div>
              </div>

              {/* <div>chauhan</div> */}





              <div className=" col-md-12 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t(
                    "Are you currently taking any medication, including over-the-counter or prescription drugs, for physical or mental health conditions? If so, please list all medications, their active substances, and the reasons for taking them."
                  )}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  required
                  type="text"
                  rows={1}
                  className="form-control mt-auto"
                  placeholder="Enter Details if you are you currently taking any medication"
                  name="are_you_currently_taking_any_medication"
                  value={formData?.are_you_currently_taking_any_medication}
                  onChange={handleChange} />
                {/* <i class="bi bi-link-45deg View"></i> */}
              </div>

              <div className=" col-md-12 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t(
                    "Are you using medications or substances for sexual enhancement or entertainment? If so, please provide details about your condition or situation."
                  )}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  required
                  type="text"
                  className="form-control mt-auto"
                  rows={1}
                  placeholder="Enter Details if you using any medications or substances for sexual enhancement"
                  name="are_you_using_medications_or_substances_for_sexual_enhancement"
                  value={formData?.are_you_using_medications_or_substances_for_sexual_enhancement}
                  onChange={handleChange} />
                {/* <i class="bi bi-link-45deg View"></i> */}
              </div>

              <div className=" col-md-12 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t(
                    "Does anyone in your family have a history of: cancer, mental retardation, autoimmune diseases, body dysmorphia, cystic fibrosis, Lyme disease, hypertension, memory loss, depression, kidney disease, bipolar disorder, heart disease, ADHD, anemia, birth defects, vision loss, bleeding disorders, or cardiovascular conditions? If so, please list them."
                  )}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  required
                  type="text"
                  className="form-control mt-auto"
                  rows={1}
                  placeholder="Enter Details of your family member which have a history of above diseases"
                  name="does_anyone_in_your_family_have_history_of_cancer"
                  value={formData?.does_anyone_in_your_family_have_history_of_cancer}
                  onChange={handleChange} />
                {/* <i class="bi bi-link-45deg View"></i> */}
              </div>

              <div className=" col-md-4 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("How often do you engage in physical activity?")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter how oftern you engage in physical activity"
                  name="how_often_do_you_engage_in_physical_activity"
                  value={formData?.how_often_do_you_engage_in_physical_activity}
                  onChange={handleChange}
                />
              </div>

              <div className=" col-md-4 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t("Are you having a special talent in art?")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control mt-auto"
                  placeholder="Enter Your Special Talent in Art"
                  name="are_you_having_a_special_talent_in_art"
                  value={formData?.are_you_having_a_special_talent_in_art}
                  onChange={handleChange} />
              </div>

              <div className=" col-md-12 mt-4 d-flex flex-column">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label LabelHeading"
                >
                  {t(
                    "Have you ever donated eggs before? If so, could you provide details about the situation?"
                  )}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  required
                  type="text"
                  className="form-control mt-auto"
                  rows={1}
                  placeholder="Enter details of previously donated eggs"
                  name="have_you_ever_donated_egg_before"
                  value={formData?.have_you_ever_donated_egg_before}
                  onChange={handleChange} />
                {/* <i class="bi bi-link-45deg View"></i> */}
              </div>
            </div>


            <div className='button_section_for_upper_section justify-content-end'>
              <button
                type="submit"
                className="btn_non_fill"
              >
                {t("Next")}
              </button>
            </div>

          </div>
        </form>

      </div>
    </>
  );
};

export default Embryodonorsform;
