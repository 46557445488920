import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoginKeeper } from "./LoginKeeper";
import { MainKeeper } from "./MainKeeper";
import Home from "../pages/main/Home/Home";
// import Testcontactus from "../pages/test/ContactUs/ContactUs"
import TestUnderConstruction from "../pages/test/UnderConstructionTest/UnderConstructionTest";
import UnderConstruction from "../pages/main/UnderConstruction/UnderConstruction";
import TestHome from "../pages/test/TestHome";
// import { SurrogateMother } from "../pages/main/Home/SurrogateMother/SurrogateMother/SurrogateMother";
import SurrogateMotherTest from "../pages/test/SurrogateMother/SurrogateMotherTest";
import { SurrogateMother } from "../pages/main/SurrogateMother/SurrogateMother";
import ContactUsTest from "../pages/test/ContactUs/ContactUsTest";
import ContactUs from "../pages/main/ContactUs/ContactUs";
import Testlatestnews from "../pages/test/LatestNews/LatestNews";
import LatestNews from "../pages/main/LatestNews/LatestNews";
import ApprovedTest from "../pages/test/SurrogateMother/ApprovedTest/ApprovedTest";
import { IntendedParentsTest } from "../pages/test/IntendedParentsTest/IntendedParentsTest";
import IntendedParents from "../pages/main/IntendedParents/IntendedParents";
import HowItWorksForIp from "../pages/main/IntendedParents/HowItWorks/HowItWorks";
import UnderConstructionTest from "../pages/test/UnderConstructionTest/UnderConstructionTest";
import Approved from "../pages/main/Approved/Approved";
import InternalNewsTest from "../pages/test/InternalNews/InternalNews";
import InternalNews from "../pages/main/InternalNews/InternalNews";
import { Resume } from "../pages/main/SurrogateMother/SurrogateResume/Resume";
import { TestResume } from "../pages/test/SurrogateMother/SurrogateResume/TestResume";
import VideoLinks from "../pages/main/VideoLinks/VideoLinks";
import IvfClinic from "../pages/main/Ivf_clinic/IvfClinic";
import ScrollToTop from "../components/ScrollToTop";
import { ResumeStep1 } from "../pages/main/SurrogateMother/ResumePages/ResumeStep1";
import { ResumeStep2 } from "../pages/main/SurrogateMother/ResumePages/ResumeStep2";
import ResumeStep3 from "../pages/main/SurrogateMother/ResumePages/ResumeStep3";
import ResumeStep4 from "../pages/main/SurrogateMother/ResumePages/ResumeStep4";
import ResumeStep5 from "../pages/main/SurrogateMother/ResumePages/ResumeStep5";
import { ResumeStep6 } from "../pages/main/SurrogateMother/ResumePages/ResumeStep6";
import { ResumeStep7 } from "../pages/main/SurrogateMother/ResumePages/ResumeStep7";
import { ResumeStep8 } from "../pages/main/SurrogateMother/ResumePages/ResumeStep8";
import { ResumeStep9 } from "../pages/main/SurrogateMother/ResumePages/ResumeStep9";
import { ResumeStep10 } from "../pages/main/SurrogateMother/ResumePages/ResumeStep10";
import { ResumeStep11 } from "../pages/main/SurrogateMother/ResumePages/ResumeStep11";
import { IntendedParentSignUp } from "../pages/main/IntendedParents/IntendedParentSignUp";
import { IntendedParentsSignIn } from "../pages/main/IntendedParents/IntendedParentsSignIn";
// import VerifyEmailModal from "../pages/main/IntendedParents/VerifyEmailModal";
import { ForgetPassword } from "../pages/main/IntendedParents/ForgetPassword";
import { EnterPassword } from "../pages/main/IntendedParents/EnterPassword";
import EmailSentSucces from "../pages/main/IntendedParents/EmailSentSucces";
import { SurrogateMotherSignUp } from "../pages/main/SurrogateMother/SurrogateMotherSignUp";
import { SurrogateSignIn } from "../pages/main/SurrogateMother/SurrogateSignIn";
import { SurrogateForgotPassword } from "../pages/main/SurrogateMother/SurrogateForgotPassword";
import EmailSentSuccesToS from "../pages/main/SurrogateMother/EmailSentSuccesToS";
import { TermsAndConditions } from "../pages/main/TermsAndConditions/TermsAndConditions";
import { PricacyPolicy } from "../pages/main/PrivacyPolicy/PricacyPolicy";
import VerifyEmailModal from "../pages/main/Home/VerifyEmailModal";
import { SurrogateSignupSuccess } from "../pages/main/SurrogateMother/SurrogateSignupSuccess";
import { PrimaryResumeForm } from "../pages/main/SurrogateMother/PrimaryResumeSteps/PrimaryResumeForm";
import { PrimaryResumeStep1 } from "../pages/main/SurrogateMother/PrimaryResumeSteps/PrimaryResumeStep1";
import { PrimaryResumeStep2 } from "../pages/main/SurrogateMother/PrimaryResumeSteps/PrimaryResumeStep2";
import ResumeProfileView from "../pages/main/SurrogateMother/PrimaryResumeSteps/ResumeProfileView";
import { AdoptionParent } from "../pages/main/AdoptionParent/AdoptionParent";
import { Embryodonor } from "../pages/main/Embryodonor/Embryodonor";
import { Embryodonorstep1 } from "../pages/main/Embryodonor/Embryodonorstep1";

import Embryodonorsform from "../pages/main/Embryodonor/Embryodonors_form/Embryodonorsform";
import StartHelpingNow from "../pages/main/Embryodonor/EmbryoDonorSuccess";
import Fatherinformation from "../pages/main/Embryodonor/Embryodonors_form/Fatherinformation";
// import Embryodonorscongratsdialog from "../pages/main/Embryodonor/Embryodonors_form/Embryodonorscongratsdialog";
// import { EmbryoDonorSignup } from "../pages/main/Embryodonor/EmbryoDonorSignup";
import EmbryoDonorSuccess from "../pages/main/Embryodonor/EmbryoDonorSuccess";
import EmbryoDonorSignup from "../pages/main/Embryodonor/Embryodonorsignup"
import EmbryoResume from "../pages/main/Embryodonor/GeneratedResume/EmbryoResume";
import AdoptingParentSuccess from "../pages/main/AdoptionParent/AdoptingParentSuccess";
import AdoptingParentForm from "../pages/main/AdoptionParent/AdoptingParentForm/AdoptingParentForm";
import AlertModal from "../pages/main/SurrogateMother/AlertModal";
import AlertModalI from "../pages/main/IntendedParents/AlertModalI";
import AlertModalE from "../pages/main/Embryodonor/AlertModalE";
import AlertModalAP from "../pages/main/AdoptionParent/AlertModalAP";
import AdoptingParentResume from "../pages/main/AdoptionParent/AdoptingParentForm/GeneratedResume/AdoptingParentResume";



export const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="" element={<MainKeeper />}>
          <Route path="" element={<Home />}>
            <Route
              path="emailVerification/:id"
              element={<VerifyEmailModal />}
            />
            <Route path="enterPassword/:id" element={<EnterPassword />} />
            <Route path="signIn" element={<SurrogateSignIn />} />
          </Route>
          <Route path="termsAndConditions" element={<TermsAndConditions />} />
          <Route path="privacyPolicy" element={<PricacyPolicy />} />
          <Route path="surrogateMother" element={<SurrogateMother />}>
            <Route path="signUp/:role" element={<SurrogateMotherSignUp />} />
            <Route path="success" element={<AlertModal />} />
            <Route path="forgetPassword/:role" element={<SurrogateForgotPassword />} />
            <Route path="emailSend" element={<EmailSentSuccesToS />} />
          </Route>
          <Route path="surrogateMother/signUp/success" element={<SurrogateSignupSuccess />} />
          <Route path="surrogateMother/primaryResume/:id" element={<PrimaryResumeForm />}>
            <Route path="step1" element={<PrimaryResumeStep1 />} />
            <Route path="step2" element={<PrimaryResumeStep2 />} />
            <Route path="profile" element={<ResumeProfileView />} />
          </Route>

          <Route path="surrogateMother/resume" element={<Resume />}>
            <Route path="step-1" element={<ResumeStep1 />} />
            <Route path="step-2" element={<ResumeStep2 />} />
            <Route path="step-3" element={<ResumeStep3 />} />
            <Route path="step-4" element={<ResumeStep4 />} />
            <Route path="step-5" element={<ResumeStep5 />} />
            <Route path="step-6" element={<ResumeStep6 />} />
            <Route path="step-7" element={<ResumeStep7 />} />
            <Route path="step-8" element={<ResumeStep8 />} />
            <Route path="step-9" element={<ResumeStep9 />} />
            <Route path="step-10" element={<ResumeStep10 />} />
            <Route path="step-11" element={<ResumeStep11 />} />
          </Route>

          <Route path="underconstruction" element={<UnderConstruction />} />
          <Route path="contactus" element={<ContactUs />} />

          <Route path="embryodonor" element={<Embryodonor />} >
            <Route path="signUp/:role" element={<EmbryoDonorSignup />} />
            <Route path="success" element={<AlertModalE />} />
            <Route path="embryodonorstep1" element={<Embryodonorstep1 />} />
          </Route>
          <Route path="embryodonor/signUp/success" element={<EmbryoDonorSuccess />} />
          <Route path="embryodonor/bioMother/:id" element={<Embryodonorsform />} />
          <Route path="embryodonor/bioFather/:id" element={<Fatherinformation />} />
          <Route path="embryodonor/profile/:id" element={<EmbryoResume />} />


          <Route path="adoptingparent" element={<AdoptionParent />} >
            <Route path="signUp/:role" element={<EmbryoDonorSignup />} />
            <Route path="success" element={<AlertModalAP />} />

          </Route>
          <Route path="adoptingparent/signUp/success" element={<AdoptingParentSuccess />} />
          <Route path="adoptingparent/form/:id" element={<AdoptingParentForm />} />
          <Route path="adoptingparent/profile/:id" element={<AdoptingParentResume />} />

          <Route path="intendedparents" element={<IntendedParents />}>
            <Route path="signUp/:role" element={<IntendedParentSignUp />} />
            <Route path="signIn/:role" element={<IntendedParentsSignIn />} />
            {/* <Route path="emailVerification/:id" element={<VerifyEmailModal />} /> */}
            <Route path="success" element={<AlertModalI />} />
            <Route path="emailSend" element={<EmailSentSucces />} />
            <Route path="forgetPassword/:role" element={<ForgetPassword />} />
            <Route path="enterPassword/:id" element={<EnterPassword />} />
          </Route>
          <Route
            path="intendedparents/howitworks"
            element={<HowItWorksForIp />}
          />
            <Route path="intendedparents/signUp/success" element={<AdoptingParentSuccess />} />
            <Route path="intendedparents/form/:id" element={<AdoptingParentForm />} />
          <Route path="intendedparents/profile/:id" element={<AdoptingParentResume />} />


          <Route path="latestnews" element={<LatestNews />} />

          <Route path="surrogateMother/approved" element={<Approved />} >
            <Route path="signUp/:role" element={<SurrogateMotherSignUp />} />
          </Route>

          <Route path="latestnews/internalnews" element={<InternalNews />} />
          <Route path="videolinks" element={<VideoLinks />} />
          <Route path="ivfclinic" element={<IvfClinic />} />
        </Route>




        <Route path="test" element={<LoginKeeper />}>
          <Route index element={<TestHome />} />
          <Route
            path="underconstructiontest"
            element={<UnderConstructionTest />}
          />
          {/* <Route path="intendedparenttest" element={<IntendedParentsTest/>} /> */}

          <Route path="surrogateMother" element={<SurrogateMotherTest />} />

          <Route path="surrogateMother/resume" element={<TestResume />}>
            <Route path="step-1" element={<ResumeStep1 />} />
            <Route path="step-2" element={<ResumeStep2 />} />
            <Route path="step-3" element={<ResumeStep3 />} />
            <Route path="step-4" element={<ResumeStep4 />} />
            <Route path="step-5" element={<ResumeStep5 />} />
            <Route path="step-6" element={<ResumeStep6 />} />
            <Route path="step-7" element={<ResumeStep7 />} />
            <Route path="step-8" element={<ResumeStep8 />} />
            <Route path="step-9" element={<ResumeStep9 />} />
            <Route path="step-10" element={<ResumeStep10 />} />
            <Route path="step-11" element={<ResumeStep11 />} />
          </Route>


          <Route path="contactus" element={<ContactUsTest />} />
          <Route path="underconstruction" element={<TestUnderConstruction />} />
          <Route path="latestnews" element={<Testlatestnews />} />
          <Route
            path="surrogateMother/approvedtest"
            element={<ApprovedTest />}
          />
          <Route
            path="latestnews/internalnews"
            element={<InternalNewsTest />}
          />
        </Route>
      </Routes>
    </BrowserRouter >
  );
};
