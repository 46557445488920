import React, { useEffect, useState } from "react";
import "./surrogateMother.css";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { addDocument } from "../../../Firebase/cloudFirestore/setData";
import { Outlet, useNavigate } from "react-router";
import { DialogSignUp } from "../DialogSignUp/DialogSignUp";




export const SurrogateMother = () => {

  const [showVideo, setShowVideo] = useState(false);



  const [visible, setVisible] = useState(false);
  const [signIn, setSignIn] = useState(false);
  // const [visible, setVisible] = useState(false);
  const [forget, setForget] = useState(false);

  const navigate = useNavigate()
  let { t } = useTranslation();


  let te = localStorage.getItem("langCode");

  let currentLanguage = te === "en-US" ? "en" : te || "en";



  useEffect(() => {

    const timer = setTimeout(() => {
      setShowVideo(true);
    }, 3000); // 3000 milliseconds = 3 seconds

    // Clean up the timer if the component unmounts
    return () => clearTimeout(timer);

  }, [showVideo])

  useEffect(() => {
    // Ensure showVideo is reset each time currentLanguage changes
    setShowVideo(false);

    // Use a ref to store the timer id to avoid closure issues
    const timerId = setTimeout(() => {
      setShowVideo(true);
    }, 3000);

    // Clear the timer on component unmount or when dependencies change
    return () => {
      clearTimeout(timerId);
    };

  }, [currentLanguage]);


  let cardsArr = [
    {
      name: "Become a Surrogate",
      imgUrl: "/images/surrogate_card_image_one.png",
      role: 'surrogate_donor'
    },
    {
      name: "Find an Intended Parent",
      imgUrl: "/images/surrogate_card_image_three.png",
      role: ''
    },
    {
      name: "Find an Attorney",
      imgUrl: "/images/surrogate_card_image_four.png",
      role: ''
    },
    { name: "Need an Agent", imgUrl: "/images/surrogate_card_image_five.png", role: '' },
    {
      name: "Referral to others",
      imgUrl: "/images/surrogate_card_image_six.png",
      role: ''
    },
  ];






  function SecondSubmit(e) {
    e.preventDefault()
    setForget(true)
    setSignIn(false)

  }

  function ForgetPassword(e) {
    e.preventDefault()
    setForget(true)
    setSignIn(false)

  }
  function HowDoesSubmit() {
    navigate('approved')
  }

  return (
    <>
      <div className="english_page">
        <div className="main_div">
          <div className="upper_section">
            <div className="upper_section_content py-md-2 py-5">
              <div className="lufga_20px_bg_lightblue pe-4">
                {t("BEST CHILD BIRTH, CHILD CARE, CHILD EDUCATION PLATFORM")}
              </div>
              <div className="volkhov_48px pt-3">{t("Surrogate Mother")}</div>
              <div className="lufga_18px_bg_gray pt-2 pe-4">
                {t("People respect Surrogate Mothers because they helping people dream to be true. To be a Surrogate Mother can be a full time or part time job which not difficult for a health woman. Good extra income!NewHopePoints can give you extra bonus through BIDDING process or REFERRAL program.You can approach Intended Parents on this platform if you wish")}
              </div>
              <div >
                <button className="mt-3"
                  style={{
                    background: "#187C8B",
                    padding: "21px 26px",
                    border: "None",
                    borderRadius: "12px",
                    color: "white",
                    fontFamily: "Lufga",
                    fontSize: "20",
                    fontWeight: "500"


                  }} onClick={HowDoesSubmit}>{t("How Does it Works ?")}</button>
              </div>
            </div>
            <div className="upper_section_image_couple">

              {!showVideo ? (
                <img
                  src="/images/surrogate_mother_upper_right_image.png"
                  alt="couple image"
                />
              ) : currentLanguage === "en" || currentLanguage === "zh" ? (<div className="p-4">
                <iframe
                  width="100%"
                  height="380"
                  src="https://www.youtube.com/embed/4vRiecER5IE?autoplay=1&mute=1"
                  title="Surrogacy: Make Dreams Come True and Earn Money!"
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; autoplay"
                  allowFullScreen
                ></iframe>
              </div>
              ) : currentLanguage === "es" ? (
                <div className="p-4">
                  <iframe
                    width="100%"
                    height="380"
                    src="https://www.youtube.com/embed/GzrAOLN7pDE?autoplay=1&mute=1"
                    title="Gestación Subrogada: ¡Haz Realidad los Sueños y Gana Dinero!"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : null}


              {/* <img
              src="/images/surrogate_mother_upper_right_image.png"
              alt="couple image"
            /> */}
              {/* {currentLanguage==="en"?
            <iframe width="100%" height="480" src="https://www.youtube.com/embed/4vRiecER5IE" title="Surrogacy: Make Dreams Come True and Earn Money!" frameborder="0" allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            :
            currentLanguage==="zh"?
            <iframe width="100%" height="480" src="https://www.youtube.com/embed/4vRiecER5IE" title="Surrogacy: Make Dreams Come True and Earn Money!" frameborder="0" allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>:
            currentLanguage==="es"?
            <iframe width="100%" height="480" src="https://www.youtube.com/embed/GzrAOLN7pDE" title="Gestación Subrogada: ¡Haz Realidad los Sueños y Gana Dinero!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            :null
            } */}
            </div>
          </div>
          <div className="all_stories">
            <div className="card_container">
              <div className="card_padding">
                <div className="row row_section">
                  {cardsArr?.map((res) => {
                    return (
                      <div className="col-12 col-md-4 col-sm-6 col-lg-4 p-2" onClick={() => res.role !== '' ? navigate(`signUp/${res.role}`) : {}} >
                        <div className="card">

                          <img
                            className="card-img-top"
                            src={res.imgUrl}
                            alt="Card image cap"
                          />

                          <div className="card-body">
                            <div className="lufga_14px">{t(res.name)}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}


                </div>
              </div>
            </div>
          </div>

          <div className="any_question_section">
            <div className="left_of_any_question">
              <div>
                <img src="/images/aq1.png" alt="images" />
              </div>
              <div>
                <img src="/images/aq2.png" alt="images" />
              </div>
              <div>
                <img src="/images/aq3.png" alt="images" />
              </div>
            </div>
            <div className="content_of_any_question d-flex flex-column gap-3">
              <div className="upper_section_of_ask_question">
                <div className="lufga_36px text-white">
                  {t("Do you still have any questions?")}
                </div>
              </div>
              <div className="middle_section_of_ask_question">
                <div className="lufga_20px text-white">
                  {t("Don't hesitate to leave us your phone number. We will contact you")}
                </div>
                <div className="lufga_20px text-white">
                  {t("to discuss any questions you may have")}
                </div>
              </div>
              <div className="lower_section_of_ask_question d-flex justify-content-center">
                <div className="input_width">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control py-2"
                      placeholder={t('Enter your phone number')}
                      aria-label="Recipient's username"
                    ></input>

                    <button
                      type="button"
                      className="btn btn-dark"
                      id="button-addon2"
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="right_of_any_question">
              <div>
                <img src="/images/aq4.png" alt="images" />
              </div>
              <div>
                <img src="/images/aq5.png" alt="images" />
              </div>
              <div>
                <img src="/images/aq6.png" alt="images" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <DialogSignUp visible={visible} setVisible={setVisible} /> */}
      {/* <Dialog
    closable={false}
      visible={visible}
      onHide={() => setVisible(false)}
      style={{ width: "60vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
    >
      <div className="DialogParent">
        <div className="">
        <h1 className="SignUphedaing text-center ">Sign Up</h1>


<form onSubmit={firstSubmit}>
      <div className="row mb-2">
        <div className="col-md-6">
          <label htmlFor="name" className="form-label">FullName</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Your Name"
            name="name"
            required
            value={formData.name}
            onChange={inpChange}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="gender" className="form-label">Gender</label>
          <select
            className="form-select"
            aria-label="Default select example"
            name="gender"
            value={formData.gender}
            onChange={inpChange}
          >
            <option value="">Open this select menu</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6 Codeparent">
          <label htmlFor="phone_number" className="form-label">Contact Number</label>
          <input
            type="text" // Changed to text for phone number input
            className="form-control"
            placeholder="Enter Number"
            name="phone_number"
            required
            value={formData.phone_number}
            onChange={inpChange}
          />
          <div className="GetCode">Get Code</div>
        </div>
        <div className="col-md-6">
          <label htmlFor="code" className="form-label">Code</label>
          <input
            type="password"
            className="form-control"
            placeholder="Enter Code"
            name="code"
            value={formData.code}
            onChange={inpChange}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-12 Codeparent">
          <label htmlFor="email" className="form-label">Email Address</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter Email Address"
            name="email"
            required
            value={formData.email}
            onChange={inpChange}
          />
          <div className="GetCode">Verify</div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6 EyeView">
          <label htmlFor="password" className="form-label">Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Enter Password"
            name="password"
            value={formData.password}
            onChange={inpChange}
          />
          <i className="bi bi-eye View"></i>
        </div>
        <div className="col-md-6 EyeView">
          <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Confirm Password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={inpChange}
          />
          <i className="bi bi-eye View"></i>
        </div>
      </div>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="agreedToTerms"
          name="agreedToTerms"
          checked={formData.agreedToTerms}
          onChange={inpChange}
        />
        <label className="form-check-label" htmlFor="agreedToTerms">
          I’ve read and agree with  
          <span className="TermColor ps-3">Terms of service and our privacy policy</span>
        </label>
      </div>
      <div className="mt-3 form-check d-flex">
     <div>   <input
          type="checkbox"
          className="form-check-input"
          id="notARobot"
          name="notARobot"
          checked={formData.notARobot}
          onChange={inpChange}
        />
        <label className="form-check-label" htmlFor="notARobot">
        I'm not a robot
        </label></div>
      <div>        <img src='images/download 2.png'/>
</div>
      </div>
      <div className="ButtonForm mt-3">
        <button className="CencelButton" type="button" onClick={() => setVisible(false)}>Cancel</button>
        <button className="SaveButton" type="submit">Save</button>
      </div>
    </form>
    

        </div>

      </div>
    </Dialog> */}








      <Outlet />

    </>
  );
};
