import React, { useEffect } from 'react'
import style from './home.module.css'
import { Dialog } from 'primereact/dialog'
import { useNavigate, useParams } from "react-router";
import { io } from "socket.io-client";
import { t } from 'i18next';
import { VscChromeClose } from "react-icons/vsc";
import { baseUrl } from '../../../service/Endpoint';
import { verifyEmail } from '../../../service/Apis';
const socket = io(baseUrl);
export default function VerifyEmailModal() {
    const navigate = useNavigate();
    const params= useParams()
    useEffect(()=>{
        socket.emit("emailVerfy",{email:params.id})
        verify()
        // // Close the current tab after 4 seconds
        // const timer = setTimeout(() => {
        //     if (window.close) {
        //         window.close();
        //     } else {
        //         alert('Please close this tab by click "Done" on top.');
        //     }
        // }, 4000);

        // // Cleanup the timeout if the component unmounts
        // return () => clearTimeout(timer)
    },[])

   async function verify(){
       try{
        await verifyEmail({email:params.id})
       }catch(Err){
        console.log(Err)
       }
    }


    function closeTab(){
      navigate('/signIn')
    }

    return (
        <>
            <Dialog
                closable={false}
                visible={true}
                onHide={() => { }}
                style={{ width: "50vw", border: '3px solid rgba(24, 124, 139, 1)', borderRadius: '25px' }}
                contentClassName={`${style.bgImg} p-md-4 p-sm-4 p-3`}
                breakpoints={{ "960px": "75vw", "641px": "98vw" }}
            >
                <div className='w-100 d-flex justify-content-end'><VscChromeClose className={style.closeIcon} onClick={closeTab}/></div>
                <div className='py-2'>
                    <div className={`d-flex justify-content-center`}>
                       <img src='/images/Tick.png' style={{width:'100px'}} alt=''/>
                    </div>
                    <div className={`mt-2 ${style.heading} fs-3 fw-bold`}>{t('Congratulations')}</div>
                    <div className={`mt-2 ${style.heading} fs-4 mb-4`}>{t('Your email has been verified! Please login to complete your profile.')}</div>
                    <div className='mt-2 d-flex justify-content-center'>
                        <button className={`btn_fill py-2 px-3`} onClick={()=>navigate(`/signIn`)}>Log in</button>
                    </div>
                </div>


            </Dialog>
        </>
    )
}
