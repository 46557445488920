import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import i18next from 'i18next';
import './header.css';

export const Header = () => {

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);


  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("langCode") || "en");
let navigator = useNavigate()

  // const controlNavbar = () => {
  //   if (typeof window !== 'undefined') {
  //     // Check the current scroll position vs the last scroll position
  //     if (window.scrollY > lastScrollY) { // If current scroll position is greater, user is scrolling down
  //       setShowNavbar(false);
  //     } else { // User is scrolling up
  //       setShowNavbar(true);
  //     }
  //     // Update the last scroll position to current position
  //     setLastScrollY(window.scrollY);
  //   }
  // };

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     window.addEventListener('scroll', controlNavbar);

  //     return () => {
  //       window.removeEventListener('scroll', controlNavbar);
  //     };
  //   }
  // }, [lastScrollY]);

  const closeNavbar = () => setIsNavCollapsed(true);

  const languageChange = async (code) => {
    await i18next.changeLanguage(code);
    localStorage.setItem("langCode", code);
    setCurrentLanguage(code);
    closeNavbar(); // Close the navbar
  };


  let { t } = useTranslation();

  useEffect(() => {
    // This useEffect hook is to ensure that the component reacts to changes in language state
    languageChange(currentLanguage);
  }, [currentLanguage]);

  return (
    <nav className={`shadow-sm navbar navbar-expand-lg d-flex flex-lg-row flex-md-column flex-column justify-content-lg-between justify-content-md-center justify-content-center navbar-light p-4 sticky-top top-0 bg-white ${showNavbar ? '' : 'hide-navbar'}`}>
      <div className="v_header_toggle">
      <Link className="navbar-brand" to="/">
        <div className="image_section_of_header">
          {/* <div className="image_header_left">
            <img className="w-100" src="/images/Frame.png" alt="nav_img" />
          </div>
          <div className="text_header_right">
            <div>
              New <span>Hope</span>
            </div>
            <div>Points</div>
          </div> */}
            <img className="w-75" src="/images/ligoImage.png" alt="nav_img" />

        </div>
      </Link>

      <button
        className="navbar-toggler no_style"
        type="button"
        onClick={handleNavCollapse}
        aria-expanded={!isNavCollapsed}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      </div>


      {/* <button
        className="navbar-toggler no_style"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button> */}

      <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse navbarCustomClass`} id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item active">
            <div onClick={closeNavbar}>
              <Link to="" className="navLink">
                <a className="nav-link" href="#">
                  {" "}
                  <span>{t("Home")}</span>
                </a>
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <div onClick={closeNavbar}>
              <Link to="videolinks" className="navLink">
                <a className="nav-link" href="#">
                  {t("Video Links")}
                </a>
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <div onClick={closeNavbar}>
              <Link to="latestnews" className="navLink">
                <a className="nav-link" href="#">
                  {t("Latest News")}
                </a>
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <div onClick={closeNavbar}>
              <div  onClick={()=>{
                window.open("https://www.blogs.newhopepoints.org/", "_blank");
              }}  className="navLink">
                <a className="nav-link" href="#">
                  {t("Knowledge/Blog")}
                </a>
              </div>
            </div>
          </li>
          <li className="nav-item">
            <div onClick={closeNavbar}>
              <Link to="underconstruction" className="navLink">
                <a className="nav-link" href="#">
                  {t("About Us")}
                </a>
              </Link>
            </div>
          </li>

          <li className="nav-item">
            <div onClick={closeNavbar}>
              <Link to="contactus" className="navLink">
                <a className="nav-link" href="#">
                  {t("Contact")}
                </a>
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <div onClick={closeNavbar}>
              <Link to="underconstruction" className="navLink">
                <a className="nav-link" href="#">
                  {t("Referral Program")}
                </a>
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <div className="login-nav-item" onClick={closeNavbar}>
              {/* <a className="nav-link" href="#"> */}
<span className="" onClick={()=>{
  navigator(`/signIn`,{state:"all"})
}}>
            {t("Login Account")}

</span>
              {/* </a> */}
            </div>
          </li>
          <li className="nav-item language_buttons">

            {currentLanguage !== "zh" ?
              <div onClick={closeNavbar}>
                <button onClick={() => languageChange("zh")}>
                  <img className="" src="/images/twemoji_flag-china.png" />
                  <span className="custom_color quicksand_17px">中文</span>
                </button>
              </div>
              : null

            }

            {currentLanguage !== "es" ?
              <div onClick={closeNavbar}>
                <button onClick={() => languageChange("es")}>
                  <img className="" src="/images/espanol.png" />
                  <span className="custom_color quicksand_17px">Español</span>
                </button>
              </div>
              : null

            }

            {currentLanguage !== "en" ?
              <div onClick={closeNavbar}>
                <button onClick={() => languageChange("en")}>
                  <img className="" src="/images/america.png" />
                  <span className="custom_color quicksand_17px">English</span>
                </button>
              </div>
              : null

            }

          </li>
        </ul>
        




        {/* <ul className="navbar-nav">
          
          <li className="nav-item language_buttons">

            {currentLanguage !== "zh" ?
              <div onClick={closeNavbar}>
                <button onClick={() => languageChange("zh")}>
                  <img className="" src="/images/twemoji_flag-china.png" />
                  <span className="custom_color quicksand_17px">中文</span>
                </button>
              </div>
              : null

            }

            {currentLanguage !== "es" ?
              <div onClick={closeNavbar}>
                <button onClick={() => languageChange("es")}>
                  <img className="" src="/images/espanol.png" />
                  <span className="custom_color quicksand_17px">Español</span>
                </button>
              </div>
              : null

            }

            {currentLanguage !== "en" ?
              <div onClick={closeNavbar}>
                <button onClick={() => languageChange("en")}>
                  <img className="" src="/images/america.png" />
                  <span className="custom_color quicksand_17px">English</span>
                </button>
              </div>
              : null

            }

          </li>
        </ul> */}
      </div>
    </nav>
  );
};