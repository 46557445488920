import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
// import { ForgettDialog } from './ForgettDialog'
import "./IntendedParents.css";
import { useTranslation } from "react-i18next";
import { LogInUser } from "../../../service/Apis";
import { useNavigate, useParams } from "react-router";
import Loader from "../../../components/Loader/Loader";
import ReCAPTCHA from 'react-google-recaptcha';

export const IntendedParentsSignIn = () => {
  let { t } = useTranslation();
  const navigate = useNavigate();
  const { role } = useParams();
  const [loginData, setLogInData] = useState({
    email: "",
    password: "",
  });

  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  let [captchaValue, setCaptchaValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [emailerr1, setEmailerr1] = useState();
  const [emailerr, setEmailerr] = useState();

  function captchaChange(e) {
    setCaptchaValue(e);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (!captchaValue) {
      alert(t("Please complete the reCAPTCHA verification."));
      return;
    }
    let payload = {
      email: loginData.email.toLocaleLowerCase(),
      password: loginData.password,
    };
    setLoader(true);
    try {
      const res = await LogInUser(loginData);
      if (res?.response?.data) {
        if (
          res?.response?.data.errormessage.message.includes(
            "enter correct password"
          )
        ) {
          setEmailerr("Please enter correct password");
          setLoader(false);
        }
        if (
          res?.response?.data.errormessage.message.includes(
            "enter correct email id"
          )
        ) {
          setEmailerr1("Please enter correct email id");
          setLoader(false);
        }
      }
      if (res?.data?.data.role === "parents") {
        window.location.href = `https://ivf-dashboard.d1i3emxiss1log.amplifyapp.com/intendedParent/dashboard?token=${encodeURIComponent(
          res.data.data.token
        )}&id=${encodeURIComponent(res.data.data.userId)}`;
        return;
      }
      setLogInData({
        email: "",
        password: "",
      });
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  }

  return (
    <>
      <Loader open={loader} />
      <Dialog
        closable={false}
        visible={true}
        onHide={() => { }}
        style={{ width: "60vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="DialogParent">
          {/* {isLoading ? (
            <div className="bg-none flex justify-content-center">
              <ProgressSpinner />
            </div>
          ) : ( */}
          <div className="" style={{ overflow: "hidden" }}>
            <form onSubmit={handleSubmit}>
              <span className="SignUphedaing text-center ">{t("Sign in")}</span>
              <div className="row mb-3">
                <div className=" col-md-12">
                  <label htmlFor="email" className="form-label">
                    {t("Email Address")}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder={t("Enter Your Email")}
                    value={loginData.email}
                    onChange={(e) =>
                      setLogInData((pre) => {
                        return { ...pre, [e.target.name]: e.target.value };
                      })
                    }
                    required
                  />
                  {emailerr1 && <div className="text-danger">{emailerr1}</div>}
                </div>
              </div>
              <div className="row mb-3">
                <div className=" col-md-12">
                  <label htmlFor="password" className="form-label">
                    {t("Password")}
                  </label>
                  <div className="p-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder={t("Enter Password")}
                      value={loginData.password}
                      onChange={(e) => {
                        setLogInData((pre) => {
                          return { ...pre, [e.target.name]: e.target.value };
                        });
                        setEmailerr("");
                      }}
                      required
                    />
                    {!showPassword ? (
                      <i
                        class="bi bi-eye-slash View pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-eye View pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      ></i>
                    )}
                  </div>
                  {emailerr && <div className="text-danger">{emailerr}</div>}
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div
                  className="ForgetPassword pointer"
                  onClick={() =>
                    navigate(`/intendedparents/forgetPassword/${role}`)
                  }
                >
                  {t("Forgot Password")}
                </div>
                <div>
                  <span className="spanaPara fs-6">
                    {t("Don't have any account?")}
                  </span>
                  &nbsp;
                  <span
                    className="Create fs-6 pointer"
                    onClick={() => navigate(`/intendedparents/signUp/${role}`)}
                  >
                    {t("Create New")}
                  </span>
                </div>
              </div>
              <div className="mt-4 d-flex px-1">
                <div>
                  <ReCAPTCHA
                    sitekey={"6LfNdI4pAAAAALEDG_OG1aDvRqK_f8_ye513OfRY"}
                    onChange={captchaChange}
                  />
                </div>
              </div>
              <div className="ButtonForm mt-3">
                <button
                  className="CencelButton"
                  type="button"
                  disabled={isLoading}
                  onClick={() => navigate(`/intendedparents`)}
                >
                  {t("Cancel")}
                </button>
                <button
                  className="SaveButton"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div
                      class="spinner-border text-light"
                      role="status"
                      style={{ width: "1rem", height: "1rem" }}
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    t("Submit")
                  )}
                </button>
              </div>
            </form>
          </div>
          {/* )} */}
        </div>
      </Dialog>
    </>
  );
};
