import React from 'react'
import AskAnyQuestion from "../CommonComponents/AskAnyQuestion/AskAnyQuestion"
import style from './IvfClinic.module.css'
import "../SurrogateMother/surrogateMother.css"
import { DialogSignUp } from "../DialogSignUp/DialogSignUp";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router';
import { SignIn } from '../DialogSignUp/SignIn';

const IvfClinic = () => {



    const [visible, setVisible] = useState(false);
    const [signIn, setSignIn] = useState(false);
    // const [visible, setVisible] = useState(false);
    const [forget, setForget] = useState(false);

    const navigate = useNavigate()
    let { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    let cardsArr = [
        {
            name: "IVF Clinic Services",
            imgUrl: "/images/ivf_clinic_card_image_one.png",
        },
        {
            name: "Search a Donor",
            imgUrl: "/images/ivf_clinic_card_image_two.png",
        },
        {
            name: "Search an Agent",
            imgUrl: "/images/ivf_clinic_card_image_three.png",
        },
        {
            name: "Search a Lawyer",
            imgUrl: "/images/ivf_clinic_card_image_four.png",
        },
        {
            name: "Search Intended Parents",
            imgUrl: "/images/ivf_clinic_card_image_five.png",
        },
    ];






    function SecondSubmit(e) {
        e.preventDefault()
        setForget(true)
        setSignIn(false)

    }

    function ForgetPassword(e) {
        e.preventDefault()
        setForget(true)
        setSignIn(false)

    }
    function HowDoesSubmit() {
        navigate('approved')
    }


    return (
        <div>


            <div className="english_page">
                <div className="main_div">
                    <div className="upper_section">
                        <div className="upper_section_content py-md-2 py-5 mb-0">
                            <div className="lufga_20px_bg_lightblue pe-4">
                                {t("One-stop platform to make people dream to be true")}
                            </div>
                            <div className="volkhov_48px pt-3">{t("IVF Clinics")}</div>
                            <div className="lufga_18px_bg_gray pt-2 pe-4">
                                {t("Connect with our platform to expand your reach and offer your services to individuals and couples seeking assisted reproduction options. By joining our network, you can showcase your expertise, connect with potential clients, and provide comprehensive fertility solutions to help them achieve their dream of parenthood. Together, we can make a difference in building families and spreading hope.")}
                            </div>

                        </div>
                        <div className="upper_section_image_couple">
                            <img
                                src="/images/ivf_clinic_upper_right_image.png"
                                alt="couple image"
                            />
                        </div>
                    </div>
                    <div className="all_stories">
                        <div className="card_container">
                            <div className="card_padding">
                                <div className="row row_section">
                                    {cardsArr?.map((res) => {
                                        return (
                                            <div className="col-12 col-md-4 col-sm-6 col-lg-4 p-2" onClick={() => navigate(`/signIn`,{state:"clinic"})} >
                                                <div className="card">

                                                    <img
                                                        className="card-img-top"
                                                        src={res.imgUrl}
                                                        alt="Card image cap"
                                                    />

                                                    <div className="card-body">
                                                        <div className="lufga_14px">{t(res.name)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}


                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <SignIn signIn={visible} setSignIn={setVisible} />
            {/* <div className={`${style.comparision_container}`} style={{marginBottom:'7rem'}}>
                <div className={`d-flex justify-content-center volkhov_48px custom_color ${style.comparision_container_v_1}`}>
                {t("In vitro fertilization (IVF) vs. natural conception:")}
                </div>

                <div className={`${style.v_2}`}>
                    <img src='/images/ivf_vs_conception.png' />
                    <div className={`${style.v_2_1}`}>
                        <div style={{fontWeight:'600'}} className={`clinic_green lufga_18px`}>{t("Since people are capable of natural conception, why choose IVF? IVF offers several advantages:")}
                        </div>
                        <div className={`clinic_green lufga_18px`}>{t("Prior to embryo creation, it allows for pre-screening of sperm and egg quality to detect any genetic defects. If present, treatment can be sought, and conception attempted again. This is not possible with natural conception.")}
                        </div>
                        <div className={`clinic_green lufga_18px`}>{t("After embryo creation, it enables pre-screening of embryo quality, allowing for selection of high-quality embryos for implantation.")}
                        </div>
                        <div className={`clinic_green lufga_18px`}>{t("Choice of desired gender: Some families may already have several boys and desire a girl, or vice versa. They may be concerned about the possibility of having another child of the same gender. IVF allows for the selection of the desired gender.")}</div>
                    </div>
                </div>

                <div className={`${style.v_3}`}>
                    <div className={`${style.v_3_1}`}>
                        <div className={`clinic_green lufga_18px`} style={{lineHeight:'26px'}}>{t("Preservation of sperm, eggs, and embryos when both partners are in good health, to be used for conception when economic or other conditions are favorable. Health conditions can change unexpectedly, and sometimes age is not a determining factor. Even from health to illness is just a step away. Life is unpredictable; someone who was lively and healthy yesterday could be bedridden today. Taking advantage of modern medical technology to freeze sperm, eggs, and embryos while in good health can secure the future of the next generation and mitigate the uncertainties of health and aging.")}

                        </div>
                        <div className={`clinic_green lufga_18px`} style={{lineHeight:'26px'}}>{t("IVF allows for the involvement of altruistic surrogate mothers, freeing and preserving the youth of young mothers. If young mothers were to conceive every 2 to 3 years on their own, raising 5 children would take 10 to 15 years. Additionally, raising these children to the age of 13 would take another 23 to 28 years. Much of the mother's life would be consumed by childbearing and child-rearing. With multiple surrogate mothers helping simultaneously, the biological parents can save significantly on the costs of conception and upbringing. The cost of raising multiple children is similar to that of raising one child, and the children can learn from each other. This maximizes the efficiency of the parenting cycle, allowing biological parents to have more time to enjoy their lives.")}</div>

                    </div>

                    <img src='/images/ivf_vs_conception_one.png' />
                </div>
            </div> */}



























            <AskAnyQuestion />
        </div>
    )
}

export default IvfClinic