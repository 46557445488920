import { auth } from "../firebase";
import {
  updateProfile,
  sendEmailVerification,
  createUserWithEmailAndPassword,
} from "firebase/auth";


// SignUp users using their Email and Password

export const emailPasswordSignUp = async (name, mail, pass) => {
  return await createUserWithEmailAndPassword(auth, mail, pass)
    .then(async (r) => {
      return await updateProfile(auth.currentUser, { displayName: name }).then(async (res1) => {
        await sendEmailVerification(auth.currentUser).then((res) => {
          return r;
        })
      }).catch((err) => {
        throw new Error(err)
      })
    })
    .catch((err) => {
      throw new Error(err)
    });
};

// use this from signup with email password 
export const signUpWithEmailPassword = async (name, email, password) => {
  try {
    const { user } = await createUserWithEmailAndPassword(auth, email, password)
    await updateProfile(auth.currentUser, { displayName: name })
    await sendEmailVerification(auth.currentUser)
    return user
  }
  catch (err) {
    throw new Error(err)
  }
}



export const updateName = async (nameOne, nameTwo) => {
  const res = await updateProfile(auth.currentUser, {
    displayName: nameOne || nameTwo,
  });

  return res;
};




// export const SignUpContext = createContext()

// export const SignUpContainer = () =>{
//   const [name1,setName1] = useState('')
//   const [email1,setEmail1] = useState('')
//   const [pass1,setPass1] = useState('')

//   console.log(name1)
//   console.log(email1)
//   console.log(pass1)

//   const check = () =>{
//     console.log('okokokokokokoo')
//   }
//   return (
//     <SignUpContext.Provider value={{setName1,setEmail1,setPass1,name1,email1,pass1,check}}>
//       <SignUp/>
//     </SignUpContext.Provider>
//   )
// }
