import React, { useRef, useEffect, useState } from "react";
import "./footer.css";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import { getSocialPage } from "../../service/Apis";

export const Footer = () => {
  const [email, setEmail] = useState(""); // State to hold the email input
  const formRef = useRef(null); // Ref for the form if needed for emailjs

  useEffect(() => emailjs.init("T91Mgsjn5mGkqRYy7"), []);

  const
    handleSubmit = async (e) => {
      e.preventDefault();
      const serviceId = "service_hxv722e";
      const templateId = "template_keva9vm";
      try {
        await emailjs.sendForm(serviceId, templateId, formRef.current);
        alert("your email is already sent to info@NewHopePoints.org , we will get back to you soon");
        setEmail(""); // Clear the email input after sending the email
      } catch (error) {
        console.error(error);
      }
    };

  const [data, setData] = useState([])

  const fetchSocialMediaLinks = async () => {
    const result = await getSocialPage()
    console.log(result)
    setData(result.data.data)
  }

  useEffect(function () {
    fetchSocialMediaLinks()
  }, [])

  // Update the email state on input change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  let { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="left_footer">
        <div className="left_upper_footer">
          <div className="lufga_16px text-white">
            {t("At")}<b>{t("New Hope Points")}</b>{t(", Our mission goes beyond facilitating parenthood; we aim to forge a foundation of transparency, support, and trust in every assisted reproduction & contribution experience, ensuring a personalized and compassionate path toward creating families.")}
          </div>
        </div>
        <div className="left_lower_footer">
          <div className="left_right_section_footer">
            <div className="left_right_section_heading_footer">{t("Phone")}</div>
            <div className="left_right_section_content_footer">
              {t("432.556.6835")}
            </div>
          </div>

          <div className="left_right_section_footer">
            <div className="left_right_section_heading_footer">{t("Email")}</div>
            <div className="left_right_section_content_footer">
              {t("info@newhopepoints.org")}
              <br />
              {t("newhopepoints@gmail.com")}
            </div>
          </div>

          <div className="left_right_section_footer">
            <div className="left_right_section_heading_footer">{t("Address")}</div>
            <div className="left_right_section_content_footer">
              {t("2469 FM 359 Rd. S, Ste XBrookshire, TX 77423, USA")}
            </div>
          </div>
        </div>
      </div>
      <div className="right_footer">
        <div className="right_upper">
          <div className="upper_upper lufga_36px">{t("Join us as an Agent!")}</div>
        </div>
        <div className="right_middle">
          <form ref={formRef} onSubmit={handleSubmit} className="d-flex flex-column gap-3">
            <div className="middle_upper">
              <input
                type="email"
                className="form-control py-3 radius-10px ps-4"
                placeholder={t('Enter Email')}
                value={email}
                onChange={handleEmailChange} // Update the email state on input change
                name="recipient" // Make sure to give the input a name attribute if using emailjs.sendForm
              />
            </div>
            <div className="middle_lower">
              <button type="submit" className="lufga_16px">
                {t("Submit")}
              </button>
            </div>
          </form>
        </div>


        <div className="right_lower">
          <div className="lower_upper">{t("connect with us")}</div>
          <div className="lower_lower  d-flex justify-content-between flex-wrap">
            {data.map((item,index) => {
              return (
                <a href={item?.platformLink} key={index} className="FooterIcon">
                  <img src={
                    item?.platform_logo_url
                  } alt="social_media_links" className="w-100 h-100"/>
                </a>
              )
            })}
          </div>
        </div>
      </div>

    </footer>
  );
};